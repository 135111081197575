<template>
  <div class="space-y-6">
    <reports-tabs v-if="!isVisit" />
    <filter-box
      :hasCards="hasCards"
      v-bind="$attrs"
      ref="filterBox"
      has-from-date
      has-to-date
    >
      <slot></slot>
    </filter-box>
  </div>
</template>

<script>
import ReportsTabs from '../ReportsTabs.vue'
import FilterBox from '@/components/basics/FilterBox.vue'
export default {
  name: 'ReportsFilter',
  components: { ReportsTabs, FilterBox },
  props: {
    hasCards: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
  },
}
</script>
