export default {
  namespaced: true,
  state: {
    user_info: {},
    token: null,
    permissions: [],
    tasks: [],
  },
  getters: {
    getUser: (state) => state.user_info,
    permissions: (state) => state.permissions,
    tasks: (state) => state.tasks,
  },
  mutations: {
    SET_USER(state, { profile, access_token }) {
      state.user_info = {
        ...profile,
      }
      state.token = access_token
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data
    },
    SET_TASKS(state, data) {
      state.tasks = data
    },
  },
  actions: {},
  modules: {},
}
