<template>
  <div>
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter v-show="!isVisit" ref="reportsFilter"> </reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full space-y-3">
        <slide-transition>
          <div class="actions-group" v-if="export_items.length">
            <button
              class="
                shrink-0
                py-1.5
                px-2
                text-center text-xs text-white
                font-medium
                bg-primary
                rounded
              "
              type="button"
              @click.prevent="displayImages"
            >
              {{ $t('view-images') }} ({{ export_items.length }})
            </button>
            <button
              class="
                shrink-0
                py-1.5
                px-2
                text-center text-xs text-slate-600
                font-medium
                bg-slate-200
                rounded
              "
              v-if="(add_data.images || []).length"
              @click.prevent="ExportImages"
              type="button"
              :disabled="export_disabled"
            >
              <span v-if="export_disabled">
                <spinner size="w-4 h-4 inline-block" />
              </span>
              <span v-else>
                {{ $t('export-images') }} {{ (add_data.images || []).length }}
              </span>
            </button>
            <button
              class="
                shrink-0
                py-1.5
                px-2
                text-center text-xs text-slate-600
                font-medium
                bg-slate-200
                rounded
              "
              type="button"
              @click.prevent="handleSelectAll"
            >
              {{ $t('select_all') }}
            </button>
            <button
              class="
                shrink-0
                py-1.5
                px-2
                text-center text-xs text-slate-600
                font-medium
                bg-slate-200
                rounded
              "
              type="button"
              @click.prevent="handleClose"
            >
              {{ $t('clear_all') }}
            </button>
          </div>
        </slide-transition>
        <div
          class="
            w-full
            grid
            xs:grid-cols-1
            grid-cols-[repeat(auto-fill,minmax(17rem,1fr))]
            gap-4
            items-stretch
          "
          v-if="(generateResult || []).length !== 0"
        >
          <figure
            class="bg-white border border-slate-200 flex flex-col"
            v-for="(item, index) in generateResult"
            :key="index"
          >
            <figcaption class="p-2 relative flex-1">
              <div class="grid grid-cols-2 h-full">
                <img
                  :src="
                    (item.images_before || [])[0] ||
                    require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="image"
                  class="w-full h-full min-h-[10rem] max-h-full object-cover"
                />
                <img
                  :src="
                    (item.images_after || [])[0] ||
                    require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="image"
                  class="w-full h-full min-h-[10rem] max-h-full object-cover"
                />
              </div>
              <button
                class="
                  absolute
                  top-4
                  right-4
                  rtl:right-auto rtl:left-4
                  w-9
                  h-9
                  rounded-full
                  bg-white
                  shadow-lg
                  flex
                  items-center
                  justify-center
                "
                v-if="(item.images || []).length > 1"
                @click.prevent="openItem(item)"
              >
                <!-- @click="openLightBox(item.images)" -->
                <i class="fa-solid fa-expand fa-sm text-slate-600"></i>
              </button>
              <div
                class="
                  absolute
                  top-4
                  left-4
                  rtl:left-auto rtl:right-4
                  form-group form-group-with-check
                  bg-white
                "
              >
                <input
                  type="checkbox"
                  :id="'select-image-' + item.id"
                  v-model="export_items"
                  :value="item.id"
                />
                <!-- <label
                  :for="'select-image-' + item.id"
                  class="
                    w-9
                    h-9
                    rounded-full
                    bg-white
                    shadow-lg
                    flex
                    items-center
                    justify-center
                    peer-checked:bg-secondary
                  "
                >
                  <i
                    class="
                      fa-solid fa-square fa-sm
                      text-slate-600
                      peer-checked:hidden
                    "
                  ></i>
                  <i
                    class="
                      fa-solid fa-square-check fa-sm
                      text-white
                      hidden
                      peer-checked:inline
                    "
                  ></i>
                </label> -->
              </div>
            </figcaption>
            <blockquote class="w-full pt-2 p-4 space-y-3" v-if="!isVisit">
              <div class="flex">
                <div class="space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="
                          text-xs text-slate-600
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                      >
                        {{
                          (item.visit.company_branch.company_store || {})
                            .name || '...'
                        }}
                      </p>
                      <p
                        class="
                          text-xs text-slate-400
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                      >
                        {{ (item.visit.company_branch || {}).name || '...' }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex-1">
                  <span class="text-xs text-slate-400 font-semibold"
                    >{{ item.images_count }} {{ $t('image') }}</span
                  >
                </div>
              </div>
              <!-- <div class="flex">
                <div class="space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="
                          text-xs text-slate-600
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                      >
                        {{ $t('store_placement') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex-1">
                  <span class="text-xs text-slate-400 font-semibold">{{
                    (item.store_placement || {}).name || '...'
                  }}</span>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1">
                  <span class="text-xs text-slate-400 font-semibold">
                    {{ $t('user') }}
                  </span>
                </div>
                <div class="space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="
                          text-xs text-slate-600
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                      >
                        {{ (item.visit.user || {}).name || '...' }}
                      </p>

                      <a
                        :href="`tel:${(item.visit.user || {})['mobile']}`"
                        class="
                          text-sm text-primary
                          font-medium
                          rtl:font-semibold
                          table
                          truncate
                        "
                      >
                        {{ (item.visit.user || {})['mobile'] }}
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
            </blockquote>
          </figure>
        </div>
        <div v-if="generateResult.length === 0 || !load">
          <EmptyCard :title="$t('no_data')" icon="fa-solid fa-images fa-xl" />
        </div>
        <div
          class="table mx-auto !py-6 px-4"
          v-if="load && pagination.total_pages > 1"
        >
          <paginate
            v-model="pagination.page"
            :page-count="pagination.total_pages"
            :page-range="5"
            :margin-pages="2"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :click-handler="handlePagination"
          >
          </paginate>
        </div>
      </main>
    </section>
    <planogram-modal :item="item" v-model="item_active" />
    <Modal
      :title="$t('export-images')"
      v-if="export_active"
      size="!max-w-screen-md"
      @close="export_active = $event"
    >
      <template>
        <form class="p-6 pt-3">
          <div
            v-for="(item, idx) in map_exports"
            :key="idx"
            class="divide-y divide-slate-200 space-y-3"
          >
            <div
              v-for="(store, sIdx) in item.items"
              :key="sIdx"
              class="divide-y divide-slate-200 pt-3"
            >
              <div class="pb-3">
                <!-- v-if="!isVisit" -->
                <div class="grid">
                  <p class="text-sm text-slate-500 font-semibold">
                    {{ $t('store_placement') }}:
                    <span class="text-slate-800">{{
                      (store.store_placement || {}).name || '...'
                    }}</span>
                  </p>
                  <p
                    class="text-xs text-slate-500 font-semibold"
                    v-if="item.visit"
                  >
                    {{ $t('branch') }}:
                    <span class="text-slate-800">{{
                      (item.visit.company_branch.company_store || {}).name ||
                      (item.visit.company_branch || {}).name
                    }}</span>
                  </p>
                  <!-- <p
                    class="
                      text-xs text-slate-600
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{
                      (item.visit.company_branch.company_store || {}).name ||
                      '...'
                    }}
                  </p>
                  <p
                    class="
                      text-xs text-slate-400
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{ (item.visit.company_branch || {}).name || '...' }}
                  </p> -->
                </div>
              </div>
              <div
                class="
                  grid grid-cols-[repeat(auto-fill,minmax(7rem,1fr))]
                  gap-2
                  flex-wrap
                  pt-3
                "
              >
                <div v-for="(image, index) in store.images_after" :key="index">
                  <input
                    type="checkbox"
                    :name="
                      'checkbox-select-image-to-export-' +
                      index +
                      '-' +
                      idx +
                      '-' +
                      sIdx
                    "
                    :id="
                      'checkbox-select-image-to-export-' +
                      index +
                      '-' +
                      idx +
                      '-' +
                      sIdx
                    "
                    class="peer"
                    v-model="add_data.images"
                    :value="image"
                    hidden
                  />
                  <label
                    :for="
                      'checkbox-select-image-to-export-' +
                      index +
                      '-' +
                      idx +
                      '-' +
                      sIdx
                    "
                    class="
                      w-full
                      h-auto
                      ring-1 ring-slate-200
                      inline-block
                      p-1
                      rounded
                      cursor-pointer
                      relative
                    "
                  >
                    <span
                      class="
                        absolute
                        top-2
                        left-2
                        rtl:left-auto rtl:right-2
                        w-9
                        h-9
                        rounded-full
                        bg-white
                        shadow-lg
                        flex
                        items-center
                        justify-center
                      "
                      :class="
                        add_data.images.some((e) => e === image)
                          ? ' text-red-500'
                          : ' text-gray-600'
                      "
                    >
                      <i
                        class="fa-solid fa-heart"
                        v-if="add_data.images.some((e) => e === image)"
                      ></i>
                      <i class="fa-regular fa-heart" v-else></i>
                    </span>
                    <img
                      :src="image"
                      alt="image"
                      title="image"
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      class="
                        w-full
                        h-full
                        max-h-[7rem]
                        object-contain
                        m-auto
                        rounded
                      "
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <slide-transition>
            <div class="actions-group" v-if="add_data.images.length">
              <button
                class="
                  shrink-0
                  py-1.5
                  px-2
                  text-center text-xs text-white
                  font-medium
                  bg-primary
                  rounded
                "
                @click.prevent="ExportImages"
                type="button"
                :disabled="export_disabled"
              >
                <span v-if="export_disabled">
                  <spinner size="w-4 h-4 inline-block" />
                </span>
                <span v-else>
                  {{ $t('export-selected-images') }} ({{
                    add_data.images.length || 0
                  }})
                </span>
              </button>
            </div>
          </slide-transition>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'
import PlanogramModal from './components/PlanogramModal.vue'
import Modal from '../basics/Modal.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'PlanogramAduits2',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      items: [],
      index: null,

      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Store placements',
          value: 0,
          key: 'store_placements_count',
          translate_key: 'store_placements',
          icon: 'fa-solid fa-cubes-stacked',
          style: {
            icon: 'text-blue-500',
            parent: 'bg-blue-500/20',
          },
        },
        {
          title: 'Images before',
          value: 0,
          key: 'images_before_count',
          translate_key: 'images_before',
          icon: 'fa-solid fa-images',
          style: {
            icon: 'text-slate-500',
            parent: 'bg-slate-500/20',
          },
        },
        {
          title: 'Image after',
          value: 0,
          key: 'images_after_count',
          translate_key: 'images_after',
          icon: 'fa-solid fa-images',
          style: {
            icon: 'text-teal-500',
            parent: 'bg-teal-500/20',
          },
        },
      ],
      add_data: {
        images: [],
      },
      item: {},
      export_items: [],
      map_exports: [],
      export_active: false,
      item_active: false,
      export_disabled: false,
    }
  },

  computed: {
    ...mapGetters('customize', ['getColumns', 'getSelectedColumns']),
    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
    generateResult() {
      const items = this.result || []
      return items.map((e) => {
        const imagesBefore = new Array().concat(
          ...(this.isVisit
            ? e?.images_before
            : e?.items?.map((item) => item.images_before))
        )
        const imagesAfter = new Array().concat(
          ...(this.isVisit
            ? e?.images_after
            : e?.items?.map((item) => item.images_after))
        )

        return {
          ...e,
          images_before: imagesBefore,
          images_after: imagesAfter,
          images_count: new Array().concat(imagesBefore, imagesAfter).length,
          images: new Array().concat(imagesBefore, imagesAfter),
          id: uuidv4(),
          ...(e.items
            ? { items: e.items }
            : {
                items: [
                  {
                    ...e,
                    images_before: imagesBefore,
                    images_after: imagesAfter,
                    images_count: new Array().concat(imagesBefore, imagesAfter)
                      .length,
                    images: new Array().concat(imagesBefore, imagesAfter),
                    id: uuidv4(),
                  },
                ],
              }),
        }
      })
    },
  },

  methods: {
    async openLightBox(images) {
      try {
        this.items = await this.loadImages(images)

        this.index = 0
      } catch (error) {
        console.log(error)
      }
    },
    async loadImages(images) {
      let result = []

      for (let i = 0; i < images.length; i++) {
        const item = images[i]

        const error = await this.handleImgLoad(item)
        if (error) {
          result.push(require('@/assets/images/placeholder.png'))
        } else {
          result.push(item)
        }
      }
      return result
    },
    handleImgLoad(item) {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = item
        img.addEventListener('error', () => resolve(true))
        resolve(false)
      })
    },
    handlePagination() {
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        this.pagination
      )
    },
    openItem(item) {
      this.item = item
      this.item_active = true
    },
    async ExportImages() {
      this.export_disabled = true
      try {
        const { data } = await this.axios.post(
          'reports/planogram_audits/export_pdf',
          { images: this.add_data.images?.map((e) => e.split('/').pop()) },
          {
            responseType: 'blob',
            // headers: {
            //   'Content-type': 'blob',
            // },
          }
        )
        let blob = new Blob([data])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `planogram-images-${new Date().getTime()}.pdf`
        link.click()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        // this.createAlert(res?.data?.message, 'error')
        this.createAlert(
          'You cannot export current table at that moment, please try again or contact us through live chat.',
          'error'
        )
      } finally {
        this.export_disabled = false
      }
    },
    handleClose() {
      this.add_data.images = []
      this.export_active = false
      this.map_exports = []
      this.export_items = []
    },
    handleSelectAll() {
      this.export_items = this.generateResult?.map((e) => e.id)
      this.displayImages()
      // this.export_items = []
    },
    displayImages() {
      const items = this.generateResult.filter((e) =>
        this.export_items.includes(e.id)
      )
      this.map_exports = items
      this.export_active = true
    },
  },
  components: {
    Table,
    TableLoad,
    ReportsFilter,
    CardsContainer,
    PlanogramModal,
    Modal,
  },
}
</script>
