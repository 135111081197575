var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-stretch justify-between min-h-screen"},[(
      ![
        'Login',
        'Join',
        'CreateVisitPage',
        'NewVisit',
        'CreateSku',
        'UpdateSku',
        'SingleJourneyPlane',
        'SingleVisit' ].includes(_vm.$route.name)
    )?_c('Header'):_vm._e(),_c('div',{staticClass:"flex-1 h-full"},[(
        ![
          'CreateVisitPage',
          'NewVisit',
          'CreateSku',
          'UpdateSku',
          'SingleJourneyPlane',
          'SingleVisit' ].includes(_vm.$route.name)
      )?_c('div',{staticClass:"flex-1",attrs:{"data-type":!['Login', 'Join'].includes(_vm.$route.name) && 'centered-item'}},[(
          ![
            'Login',
            'Error',
            'CreateVisitPage',
            'CreateSku',
            'UpdateSku',
            'Join',
            'NewVisit' ].includes(_vm.$route.name) && false
        )?_c('div',{staticClass:"w-full",class:{
          'xl:container': ['SingleVisit'].includes(_vm.$route.name),
        }},[(_vm.hasPath)?_c('div',{staticClass:"w-full flex items-center gap-4 py-4 px-6"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"space-y-2"},[_c('h1',{staticClass:"text-2xl font-bold text-slate-800 capitalize"},[_vm._v(" "+_vm._s(_vm.routeName_2 || _vm.routeName)+" ")])])]),_c('div',[_c('button',{staticClass:"flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm py-2 px-4 rounded bg-transparent hover:bg-slate-200",on:{"click":function($event){$event.preventDefault();return _vm.handleBack.apply(null, arguments)}}},[_c('span',[_vm._v("Back")]),_c('i',{staticClass:"fa-solid fa-arrow-right-long block rtl:hidden"}),_c('i',{staticClass:"fa-solid fa-arrow-left-long hidden rtl:block"})])])]):_vm._e()]):_vm._e(),_c('router-view',{staticClass:"pt-4 pb-10"}),_c('vue-tour'),(!['Login', 'Join', 'CalendarPage'].includes(_vm.$route.name))?_c('button',{staticClass:"fixed bottom-20 right-6 rtl:right-auto rtl:left-6 w-10 h-10 rounded-full flex items-center justify-center bg-secondary text-white z-[21]",on:{"click":function($event){$event.preventDefault();return _vm.backToTop.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-circle-arrow-up"})]):_vm._e(),(!['Login', 'Join', 'CalendarPage'].includes(_vm.$route.name))?_c('SupportChat'):_vm._e()],1):_c('div',{staticClass:"flex-1"},[_c('router-view')],1)]),(!['Login', 'Join'].includes(_vm.$route.name))?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }