<template>
  <section
    ref="sweetAlert"
    class="w-full py-16 px-8 bg-slate-800/25 overflow-y-auto h-screen fixed inset-0 z-30 sm:block !m-0"
  >
    <main
      class="w-full sm:max-w-xl bg-white block mx-auto rounded-md relative"
      :class="size"
    >
      <header
        v-if="title"
        class="p-4 border-b border-slate-100 flex items-center gap-2 w-full"
      >
        <div class="flex-1">
          <p
            class="text-slate-600 font-semibold rtl:font-bold"
            v-html="title"
          ></p>
        </div>
        <div>
          <button
            class="py-1 p-3 text-slate-600"
            @click.prevent="$emit('close', false)"
          >
            <i class="fa-solid fa-times"></i>
          </button>
        </div>
      </header>

      <main class="w-full h-auto">
        <slot name="default"></slot>
      </main>
    </main>
  </section>
</template>
<script>
export default {
  name: 'ModalComponent',
  props: {
    hasClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  // mounted() {
  //   window.addEventListener('click', (e) => {
  //     if (e.target === this.$refs.sweetAlert) {
  //       this.$emit('close', false)
  //     }
  //   })
  // },
}
</script>
