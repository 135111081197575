<template>
  <div>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter
        ref="reportsFilter"
        v-show="!isVisit"
        has-main-categories
      ></reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('skus')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          :fields="fields"
          v-model="pagination.page"
        >
          <template #customTabs>
            <nav class="grid">
              <ul class="inline-flex gap-2 overflow-auto p-2">
                <li v-for="(item, index) in subCategories" :key="index">
                  <button
                    class="
                      py-3
                      px-4
                      rounded-md
                      bg-white
                      font-semibold
                      text-slate-600
                      ring-1 ring-slate-200
                      shrink-0
                      [text-align:inherit]
                      whitespace-nowrap
                    "
                    type="button"
                    :class="{
                      'active-tab-pills':
                        pagination.sub_category_id === item.id,
                    }"
                    @click.prevent="
                      handleChangeSubCategory(
                        'sub-category-id-' + item.id,
                        item.id
                      )
                    "
                    :disabled="disables['sub-category-id-' + item.id]"
                  >
                    <span class="text-xs">
                      <p class="text-sm mb-0.5">
                        {{ item[$i18n.locale === 'en' ? 'name' : 'alt_name'] }}
                      </p>
                      <i
                        class="fa-solid fa-arrow-trend-up fa-sm text-teal-500"
                      ></i>
                      {{ item.available_skus_count }} {{ $t('available') }}
                      <!-- <br /> -->
                      -
                      <i
                        class="fa-solid fa-arrow-trend-down fa-sm text-red-500"
                      ></i>
                      {{ item.not_available_skus_count }}
                      {{ $t('not_available') }}
                    </span>
                  </button>
                </li>
              </ul>
              <slide-transition>
                <div v-if="pagination.sub_category_id" class="p-2 pb-0">
                  <button
                    class="
                      py-2.5
                      px-3
                      rounded-md
                      bg-slate-200
                      hover:bg-slate-300
                      font-semibold
                      text-slate-600
                      ring-1 ring-slate-200
                      shrink-0
                      [text-align:inherit]
                      text-sm
                      table
                      [margin-inline-start:auto]
                    "
                    :disabled="disables['sub-category-clear']"
                    @click.prevent="resetPagination"
                  >
                    <spinner
                      v-if="disables['sub-category-clear']"
                      size="w-4 h-4 inline-block"
                    />
                    <span v-else>{{ $t('clear') }}</span>
                  </button>
                </div>
              </slide-transition>
            </nav>
          </template>
          <template #head>
            <th v-if="!isVisit && columns.includes('user')">
              {{ $t('user') }}
            </th>
            <th v-if="columns.includes('sku')">{{ $t('sku') }}</th>
            <th v-if="!isVisit && columns.includes('store')">
              {{ $t('store') }}
            </th>
            <th v-if="!isVisit && columns.includes('branch')">
              {{ $t('branch') }}
            </th>
            <!-- <th v-if="columns.includes('expiry_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('expiry_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('production_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('production_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('selling_price')">
              <div class="sort">
                <div>
                  <p>{{ $t('selling_price') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th> -->
            <th v-if="columns.includes('qty')">
              <div class="sort">
                <div>
                  <p>{{ $t('qty') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('is_available')">
              <div class="sort">
                <div>
                  <p>{{ $t('available') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    py-4
                    px-6
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_available ===
                      (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && result.length !== 0">
            <tr v-for="(item, index) in result" :key="index">
              <td v-if="!isVisit && columns.includes('user')">
                <p>{{ (item.user || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.user || {}).mobile || '...' }}</a
                >
              </td>
              <td v-if="columns.includes('sku')">
                <div class="actions-group !flex-no-wrap">
                  <div>
                    <div class="w-12 h-auto">
                      <img
                        :src="
                          (item.sku || {}).image ||
                          require('@/assets/images/placeholder.png')
                        "
                        :alt="`${(item.sku || {}).name} image`"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                  <div class="flex-1 whitespace-nowrap">
                    <p class="text-sky-500">
                      {{ (item.sku || {}).barcode || '...' }}
                    </p>
                    <p class="text-slate-600">
                      {{ (item.sku || {}).name || '...' }}
                    </p>
                  </div>
                </div>
              </td>
              <td v-if="!isVisit && columns.includes('store')">
                {{ (item.visit.company_store || {}).name || '...' }}
              </td>
              <td v-if="!isVisit && columns.includes('branch')">
                <p>{{ (item.visit.company_branch || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.visit.company_branch || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.visit.company_branch || {}).mobile || '...' }}</a
                >
              </td>

              <!-- <td
                v-if="columns.includes('expiry_date')"
                v-html="createTime((item.sku || {}).exp_date)"
              ></td>
              <td
                v-if="columns.includes('production_date')"
                v-html="createTime((item.sku || {}).prod_date)"
              ></td>
              <td v-if="columns.includes('selling_price')">
                {{ (item.sku || {}).selling_price || '...' }} SAR
              </td> -->
              <td v-if="columns.includes('qty')">{{ item.qty || 0 }}</td>
              <td v-if="columns.includes('is_available')">
                <span class="status" :class="item_status[item.is_available]">
                  {{ item.is_available ? $t('yes') : $t('no') }}
                </span>
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="
                  createTime(
                    !isVisit ? (item.visit || {}).visit_date : item.visit_date
                  )
                "
              ></td>
            </tr>
          </template>
          <template #tbody v-else-if="load && result.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'
import Tabs from '../basics/Tabs.vue'

export default {
  name: 'SkuAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
    subCategories: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      store_placements: [],
      add_data: {
        store_placement_id: null,
        is_available: this.$route.query.is_available
          ? this.$route.query.is_available
            ? 'available'
            : 'not_available'
          : null,
        listed_company_store_id: null,
        listed_company_branch_id: null,
      },
      status: ['all', 'available', 'not_available'],
      disables: {},
      cards: [
        {
          title: 'Total SKUs',
          value: 0,
          key: 'skus_count',
          translate_key: 'skus',
          icon: 'fa-solid fa-basket-shopping',
          style: {
            icon: 'text-yellow-600',
            parent: 'bg-yellow-600/20',
          },
        },
        // {
        //   title: 'SKUs amount',
        //   value: `0.00 SAR`,
        //   key: null,
        //   translate_key: 'skus_amount',

        //   icon: 'fa-solid fa-dollar',
        //   style: {
        //     icon: 'text-teal-600',
        //     parent: 'bg-teal-600/20',
        //   },
        // },
        // {
        //   title: 'Available SKUs',
        //   value: 0,
        //   key: 'available_count',
        //   translate_key: 'available',
        //   icon: 'fa-solid fa-arrow-trend-up',
        //   style: {
        //     icon: 'text-blue-500',
        //     parent: 'bg-blue-500/20',
        //   },
        // },
        {
          title: 'Out of stock',
          value: 0,
          key: 'out_of_stock_count',
          translate_key: 'out_of_stock',
          icon: 'fa-solid fa-arrow-trend-down',
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
        //  {
        //     title: 'OSA Reports',
        //     value: 0,
        //     key: 'osa_report',
        //     translate_key: 'osa_reports',
        //     icon: 'fa-solid fa-chart-simple',
        //     style: {
        //       icon: 'text-orange-500',
        //       parent: 'bg-orange-500/20',
        //     },
        //   },
      ],
      main_categories: [],
    }
  },
  computed: {
    ...mapGetters('table', ['is_called']),
    ...mapGetters('customize', ['getColumns', 'getSelectedColumns']),
    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
    filterBranches() {
      const storeIds = this.add_data?.listed_company_store_id?.map((e) => e?.id)
      if (!storeIds?.length) return this.branches

      return (
        this.branches.filter((e) => storeIds.includes(e?.company_store?.id)) ||
        this.branches
      )
    },
  },
  watch: {
    is_called() {
      if (!this.is_called) {
        this.disables = {}
      }
    },
  },

  methods: {
    ...mapMutations('customize', ['updateCards']),
    ...mapMutations('table', ['changeIsCalled']),

    changeFilterStatus(item, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_available', item)
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        Object.assign({}, this.add_data, this.pagination)
      )
    },
    handleChangeSubCategory(str, id) {
      this.$set(this.disables, str, true)
      this.$set(this.pagination, 'sub_category_id', id)
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        Object.assign({}, this.add_data, this.pagination)
      )
    },
    resetPagination(str) {
      this.$set(this.disables, str, true)
      this.$set(this.pagination, 'sub_category_id', null)
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        Object.assign({}, this.add_data, this.pagination)
      )
    },
  },
  components: { Table, TableLoad, ReportsFilter, CardsContainer, Tabs },
}
</script>
