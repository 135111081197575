<template>
  <div class="flex flex-col items-stretch justify-between min-h-screen">
    <Header
      v-if="
        ![
          'Login',
          'Join',
          'CreateVisitPage',
          'NewVisit',
          'CreateSku',
          'UpdateSku',
          'SingleJourneyPlane',
          'SingleVisit',
        ].includes($route.name)
      "
    />
    <div class="flex-1 h-full">
      <div
        class="flex-1"
        :data-type="!['Login', 'Join'].includes($route.name) && 'centered-item'"
        v-if="
          ![
            'CreateVisitPage',
            'NewVisit',
            'CreateSku',
            'UpdateSku',
            'SingleJourneyPlane',
            'SingleVisit',
          ].includes($route.name)
        "
      >
        <div
          class="w-full"
          v-if="
            ![
              'Login',
              'Error',
              'CreateVisitPage',
              'CreateSku',
              'UpdateSku',
              'Join',
              'NewVisit',
            ].includes($route.name) && false
          "
          :class="{
            'xl:container': ['SingleVisit'].includes($route.name),
          }"
        >
          <div class="w-full flex items-center gap-4 py-4 px-6" v-if="hasPath">
            <div class="flex-1">
              <div class="space-y-2">
                <h1 class="text-2xl font-bold text-slate-800 capitalize">
                  {{ routeName_2 || routeName }}
                </h1>
                <!-- <p
                  class="text-sm font-medium rtl:font-semibold text-slate-600"
                  v-html="getCurrentRoute()"
                ></p> -->
              </div>
            </div>
            <div>
              <button
                class="flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm py-2 px-4 rounded bg-transparent hover:bg-slate-200"
                @click.prevent="handleBack"
              >
                <span>Back</span>
                <i class="fa-solid fa-arrow-right-long block rtl:hidden"></i>
                <i class="fa-solid fa-arrow-left-long hidden rtl:block"></i>
              </button>
            </div>
          </div>
        </div>
        <router-view class="pt-4 pb-10"></router-view>
        <vue-tour />
        <button
          class="fixed bottom-20 right-6 rtl:right-auto rtl:left-6 w-10 h-10 rounded-full flex items-center justify-center bg-secondary text-white z-[21]"
          @click.prevent="backToTop"
          v-if="!['Login', 'Join', 'CalendarPage'].includes($route.name)"
        >
          <i class="fa-solid fa-circle-arrow-up"></i>
        </button>
        <SupportChat
          v-if="!['Login', 'Join', 'CalendarPage'].includes($route.name)"
        />
      </div>
      <div class="flex-1" v-else>
        <router-view></router-view>
      </div>
    </div>
    <Footer v-if="!['Login', 'Join'].includes($route.name)" />
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import SupportChat from './components/basics/SupportChat.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import VueTour from './components/basics/VueTour.vue'

export default {
  components: { Header, SupportChat, Footer, VueTour },
  data() {
    return {
      hasPath: false,
      redirects: {
        SingleVisit: '/reports/visits',
        SingleMessage: '/messages',
        SingleJourneyPlane: '/setup/journey-plans',
        SingleClient: '/setup/clients',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customize', [
      'getBasicColumn',
      'getSelectedColumns',
      'getColumns',
      'routeName_2',
    ]),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    routeName() {
      const route = this.$route
      const name = route.path
        .split('/')
        .filter((e) => e)
        .at(-1)
        .split('-')
        .join(' ')
      return route.meta.title ? this.$t(route.meta.title) : name
    },
  },
  watch: {
    $route() {
      // this.getRoute()
      if (!/reports|visits|audits/gi.test(this.$route.path)) {
        this.resetPagination()
        this.resetCards()
      }
      this.updateSearch(null)
      if (this.$route.name !== 'SingleVisit') this.setRouteName(null)
      this.changeIsCalled(false)
      this.setNewPage(1)
    },
    endpointName() {
      this.checkSelectedColumns()
    },
    getUser() {
      if (this.getUser?.id) {
        this.checkUserToAccessPusher()
      }
    },
  },

  created() {
    this.getRoute()

    if (!localStorage.getItem('window')) {
      localStorage.setItem('window', 'full')
    }
    this.checkSelectedColumns()
  },
  async mounted() {
    if (this.$cookies.get('t')) {
      const enabledTour = JSON.parse(localStorage.getItem('tour-enabled'))
      if (!enabledTour) {
        this.$tours['myTour'].start()
      }
      await Promise.all([
        this.getRegions(),
        this.getCities(),
        this.getZones(),
        this.getBranches(),
        this.getStores(),
        this.getUsers(),
        this.getClients(),
        this.getMainCategories(),
      ]).finally(() => {
        this.CHANGE_LOAD(true)
      })
    }
  },

  methods: {
    ...mapMutations('table', [
      'changePagination',
      'resetPagination',
      'updateSearch',
      'changeIsCalled',
    ]),
    ...mapActions('table', ['setNewPage']),
    ...mapMutations('customize', [
      'resetCards',
      'updatedSelectedColumns',
      'setRouteName',
    ]),
    ...mapMutations('filters', ['CHANGE_LOAD']),
    ...mapActions('filters', [
      'getRegions',
      'getCities',
      'getBranches',
      'getStores',
      'getUsers',
      'getClients',
      'getZones',
      'getMainCategories',
    ]),
    getLastRoute() {
      return window.location.pathname
        .split('/')
        .filter((e) => e)
        .at(-1)
        .split('-')
        .join(' ')
    },
    getCurrentRoute() {
      const pathname = this.$route.path
      const splitPath = pathname.split('/')
      const newSplit = [...new Set(splitPath)]
      let routes = ''

      newSplit.forEach((route, idx) => {
        routes += `${
          idx !== newSplit.length - 1
            ? `<span class="inline-block"> ${
                route ? route : 'Home'
              }</span> <i class="fa-solid fa-angle-right inline-block rtl:hidden mx-1 fa-xs align-middle"></i>
      <i class="fa-solid fa-angle-left hidden rtl:inline-block mx-1 fa-xs align-middle"></i>`
            : `<a href="${this.$route.path}" class="text-sky-600 inline-block">${route}</a>`
        }`
      })

      return routes
    },
    getRoute() {
      let pathname = this.$route.path.split('/').filter((e) => e).length !== 0
      return (this.hasPath = pathname)
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    checkUserToAccessPusher() {
      const channel = this.$echo?.private(`user-${this.getUser?.id}-channel`)
      channel?.listen('.chat_messages', ({ chat_message }) => {
        this.$store.commit('chat/UPDATE_MESSAGE', chat_message)
        this.$store.commit('chat/UPDATE_ITEMS', chat_message)
        new Audio(require('@/assets/sounds/message.mp3')).play()
      })
    },
    checkSelectedColumns() {
      const columns = this.getColumns
      const routes = this.$router.options.routes
      const mapRoutes = routes.filter((e) => e.meta.endpoint_name)
      for (let rIdx = 0; rIdx < mapRoutes.length; rIdx++) {
        const route = mapRoutes[rIdx]
        const key = route.meta.endpoint_name
        if (Object.hasOwnProperty.call(columns, key)) {
          if (!this.getSelectedColumns(key)) {
            this.updatedSelectedColumns({
              type: key,
              data: columns[key],
            })
          }
        }
      }
    },
    handleBack() {
      const name = this.$route.name

      if (Object.hasOwnProperty.call(this.redirects, name)) {
        this.$router.push({
          path: this.redirects[name],
        })
        return
      }

      this.$router.go(-1)
    },
  },
}
</script>
