<template>
  <div class="space-y-6">
    <div>
      <Tabs :link_type="'link'" :tabs="pagesTab" has-permission />
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/basics/Tabs.vue'
export default {
  name: 'ReportsTabs',

  data() {
    return {
      pagesTab: [
        {
          name: 'Visits',
          url: '/visits',
          key: 'visits',
          permission: 'visits',
        },
        {
          name: 'Skus',
          url: '/reports/skus-audits',
          key: 'skus',
          permission: 'sku_audits',
        },
        {
          name: 'Expires',
          url: '/reports/expiry-audits',
          key: 'expiries',
          permission: 'expiry_audits',
        },

        {
          name: 'Stock',
          url: '/reports/stock-audits',
          key: 'stock_audits',
          permission: 'stock_audits',
        },
        {
          name: 'Delivery',
          url: '/reports/delivery-audits',
          key: 'delivery_audits',
          permission: 'delivery_audits',
        },

        {
          name: 'Shelf',
          url: '/reports/shelf-audits',
          key: 'shelf_audits',
          permission: 'shelf_audits',
        },
        {
          name: 'Returns',
          url: '/reports/returns-audits',
          key: 'returns',
          permission: 'return_audits',
        },
        {
          name: 'Promotion',
          url: '/reports/promotion-audits',
          key: 'promotion',
          permission: 'promotion_audits',
        },
        {
          name: 'Planogram',
          url: '/reports/planogram-audits',
          key: 'planogram',
          permission: 'planogram_audits',
        },
      ],
    }
  },

  components: {
    Tabs,
  },
}
</script>
