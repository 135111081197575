<template>
  <div>
    <ConfigurationWrapper>
      <section class="w-full space-y-6 px-6">
        <filter-box
          :has-to-date="false"
          :has-branches="false"
          :has-zones="false"
          :has-cities="false"
          :has-regions="false"
          :has-stores="false"
          :has-supervisors="false"
          :has-merchandisers="false"
        />
        <main class="w-full">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('stores')"
            :records="`${pagination.total || 0} ${$t('records')}`"
            :pagination="pagination"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
            :fields="fields"
          >
            <template #actions>
              <div>
                <button
                  class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.store') }}</span>
                </button>
              </div>
            </template>
            <template #filter v-if="false">
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, '_')) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th v-if="columns.includes('name')">{{ $t('name') }}</th>
              <th v-if="columns.includes('alt_name')">{{ $t('alt_name') }}</th>

              <th v-if="columns.includes('total_skus')">
                <div class="sort">
                  <div>
                    <p>{{ $t('total_skus') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('total_skus')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('monthly_visits')">
                <div class="sort">
                  <div>
                    <p>{{ $t('monthly_visits') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('month_visits')"
                    ></button>
                  </div>
                </div>
              </th>
              <!-- <th v-if="columns.includes('merchandisers_count')">
                <div class="sort">
                  <div>
                    <p>{{ $t('merchandisers_count') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('merchandisers_count')"
                    ></button>
                  </div>
                </div>
              </th> -->
              <th v-if="columns.includes('branches')">
                <div class="sort">
                  <div>
                    <p>{{ $t('branches') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('listed_branches_count')"
                    ></button>
                  </div>
                </div>
              </th>
              <!-- <th v-if="columns.includes('unlisted_branches')">
                <div class="sort">
                  <div>
                    <p>{{ $t('not_listed_branches') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('unlisted_branches_count')"
                    ></button>
                  </div>
                </div>
              </th> -->
              <th v-if="columns.includes('active')">
                <div class="sort">
                  <div>
                    <p>{{ $t('active') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('is_active')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('updated_at')">
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>

              <th v-if="columns.includes('actions')"></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="columns.includes('name')">
                  {{ item.name || '...' }}
                </td>
                <td v-if="columns.includes('alt_name')">
                  {{ item.alt_name || '...' }}
                </td>
                <td v-if="columns.includes('total_skus')">
                  <button
                    class="py-2 px-3 rounded border border-gray-300 bg-white text-gray-600 font-semibold text-center text-xs inline-flex gap-2 items-center"
                    @click.prevent="getSkus(item.id)"
                    :disabled="!item.skus_count || disables[`skus-${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`skus-${item.id}`]"
                    />
                    <i class="fa-solid fa-eye" v-else></i>
                    <span>{{ $t('view') }} ( {{ item.skus_count || 0 }} )</span>
                  </button>
                </td>
                <td v-if="columns.includes('monthly_visits')">
                  {{ item.month_visits_count || 0 }}
                </td>
                <!-- <td v-if="columns.includes('merchandisers_count')">
                  {{ item.merchandisers_count || 0 }}
                </td> -->
                <td v-if="columns.includes('branches')">
                  {{ item.listed_branches_count || 0 }}
                </td>
                <!-- <td v-if="columns.includes('unlisted_branches')">
                  {{ item.unlisted_branches_count || 0 }}
                </td> -->

                <td v-if="columns.includes('active')">
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                </td>
                <td
                  v-if="columns.includes('updated_at')"
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td v-if="columns.includes('actions')">
                  <div class="actions-group">
                    <div v-if="false">
                      <button
                        class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                        @click.prevent="deleteItem(item.id)"
                        :disabled="disables[`delete_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${item.id}`]"
                        />
                        <span v-else> {{ $t('delete') }} </span>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                        @click.prevent="showChangeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                      >
                        <i class="fa-solid fa-right-left"></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                        @click.prevent="getBranchesToSync(item.id)"
                        :content="$t('sync_skus')"
                        :disabled="disables[`sync_${item.id}`]"
                        v-tippy
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`sync_${item.id}`]"
                        />
                        <i class="fa-solid fa-sync" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded"
                        @click.prevent="showUpdate(item.id)"
                        :disabled="!item.is_active"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td :colspan="columns.length">
                  <div class="table mx-auto my-4">
                    <button
                      class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.store') }}</span>
                    </button>
                  </div>
                  <p
                    class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.store')"
          v-if="add_active"
          @close="add_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="add_data.store_id"
                    :options="stores"
                    :disabled="!stores.length"
                    :multiple="true"
                    :closeOnSelect="false"
                    :taggable="true"
                    :hideSelected="false"
                    :clearOnSelect="true"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="store_id"
                  ></multiselect>
                  <label for="store_id">{{ $t('store') }}</label>
                </div>
                <slide-transition>
                  <button
                    class="shrink-0 py-1.5 px-2 text-center text-xs text-slate-600 font-medium bg-slate-200 rounded [margin-inline-start:auto] mt-3"
                    type="button"
                    @click.prevent="handleSelectAll"
                    v-if="stores.length"
                  >
                    {{ $t('select_all') }} ({{ stores.length }})
                  </button></slide-transition
                >
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-html="
                    Object.keys(add_errors)
                      .filter((e) => /store_id.\w+/.test(e))
                      .map((e) => add_errors[e])
                      .join('<br />')
                  "
                ></p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t('add') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>

      <transition name="scale">
        <Modal
          :title="$t('sync_skus')"
          v-if="sync_active"
          @close="sync_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="sync_data.branch_ids"
                    :options="branches"
                    :disabled="!branches.length"
                    :multiple="true"
                    :closeOnSelect="false"
                    :taggable="true"
                    :hideSelected="false"
                    :clearOnSelect="true"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="branch_ids"
                  ></multiselect>
                  <label for="branch_ids">{{ $t('branches') }}</label>
                </div>
                <slide-transition>
                  <button
                    class="shrink-0 py-1.5 px-2 text-center text-xs text-slate-600 font-medium bg-slate-200 rounded [margin-inline-start:auto] mt-3"
                    type="button"
                    @click.prevent="selectAllBranches"
                    v-if="branches.length"
                  >
                    {{ $t('select_all') }} ({{ branches.length }})
                  </button></slide-transition
                >
              </div>
              <div class="space-y-3">
                <div class="form-group-with-check">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="add_all_skus"
                    id="add_all_skus"
                    v-model="sync_data.add_all_skus"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="add_all_skus">{{ $t('add_all_skus') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium rtl:font-semibold text-sm"
                  v-if="sync_errors.add_all_skus"
                >
                  {{ sync_errors.add_all_skus.join(' ') }}
                </p>
              </div>
              <figure class="space-y-4" v-if="!sync_data.add_all_skus">
                <figcaption v-if="sync_data.file" class="relative table">
                  <div
                    class="flex max-w-fit py-2 px-3 rounded bg-gray-100 items-center gap-4"
                  >
                    <div class="flex-1 grid">
                      <p class="text-sm font-medium truncate">
                        {{ sync_data.file.name }}
                      </p>
                    </div>
                    <button
                      class="text-red-500 shrink-0"
                      @click.prevent="
                        () => {
                          ;($refs.chainFile.value = null),
                            $set(sync_data, 'file', null)
                        }
                      "
                    >
                      <i class="fa-solid fa-trash fa-sm align-middle"></i>
                    </button>
                  </div>
                </figcaption>
                <blockquote class="space-y-2">
                  <label class="block">
                    <!-- <span class="sr-only">{{ $t('choose_image') }}</span> -->
                    <input
                      autocomplete="off"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      class="custom-file"
                      @change="
                        (e) => $set(sync_data, 'file', e.target.files[0])
                      "
                      ref="chainFile"
                    />
                  </label>
                  <p
                    class="text-red-500 font-medium rtl:font-semibold text-sm"
                    v-if="sync_errors.file"
                  >
                    {{ sync_errors.file.join(' ') }}
                  </p>
                </blockquote>
              </figure>
              <div class="space-y-3" v-if="!sync_data.add_all_skus">
                <div class="form-group-with-check">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="delete_replace"
                    id="delete_replace"
                    v-model="sync_data.delete_replace"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="delete_replace">{{ $t('delete_replace') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium rtl:font-semibold text-sm"
                  v-if="sync_errors.delete_replace"
                >
                  {{ sync_errors.delete_replace.join(' ') }}
                </p>
              </div>
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="sync_errors.add_all_skus"
              >
                {{ sync_errors.add_all_skus.join(' ') }}
              </p>
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="sync_errors.logs"
                v-html="sync_errors.logs.join('<br />')"
              ></p>
              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="syncSKUs"
                    :disabled="sync_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="sync_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>

      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="edit_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    v-model="edit_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }} </label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name"
                >
                  {{ edit_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    v-model="edit_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_name"
                >
                  {{ edit_errors.alt_name.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>

      <transition name="scale">
        <Modal v-if="delete_active" @close="delete_active = $event">
          <template>
            <div>
              <div class="p-6 space-y-2">
                <h3 class="text-lg font-bold text-red-500">
                  {{ $t('deletion_modal.title') }}
                </h3>
                <p class="text-sm font-medium rtl:font-semibold text-slate-500">
                  {{ $t('deletion_modal.content') }}
                  <span
                    class="text-xs bg-slate-50 rounded border border-slate-100 font-bold py-0.5 px-1.5 text-slate-800 hover:bg-slate-100 hover:border-slate-200 select-none"
                    >{{ store_name }}</span
                  >
                </p>
                <div class="mx-auto max-w-sm !mt-4">
                  <div class="form-group">
                    <input
                      type="text"
                      name="store-name"
                      id="store-name"
                      :placeholder="$t('name')"
                      autocomplete="off"
                      spellcheck="true"
                      v-model.trim="c_store_name"
                    />
                  </div>
                </div>
              </div>
              <div class="bg-slate-100 p-6 rounded-b-md">
                <div class="actions-group">
                  <div>
                    <button
                      class="py-2.5 px-6 rounded-md text-xs font-medium rtl:font-semibold text-white bg-primary table"
                      :disabled="
                        c_store_name !== store_name ||
                        disables[`status_${store_id}`]
                      "
                      @click.prevent="changeStatus"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${store_id}`]"
                      />
                      {{ $t('submit') }}
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-2.5 px-6 rounded-md text-xs font-medium rtl:font-semibold text-slate-600 bg-slate-200 table"
                      @click.prevent="delete_active = false"
                    >
                      <!-- <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" /> -->
                      {{ $t('cancel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Modal>
      </transition>
    </ConfigurationWrapper>
    <Modal
      :title="$t('skus')"
      size="!max-w-screen-xl"
      v-if="skus_active"
      @close="skus_active = $event"
    >
      <template>
        <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
          <li class="sm:col-span-2 md:col-span-3 space-y-3">
            <input
              type="text"
              :placeholder="$t('search')"
              class="w-full py-3 px-4 rounded bg-white border border-gray-200 text-sm font-medium"
              v-model="sku_search"
            />
            <slide-transition>
              <div
                class="flex items-center justify-end gap-4"
                v-if="(skus_data.sku_ids || []).length"
              >
                <button
                  class="py-2 px-3 rounded bg-red-500 text-white text-center font-medium inline-flex items-center text-sm gap-3"
                  type="button"
                  @click.prevent="DeleteSelectedSkus"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="skus_disabled" />
                  <i class="fa-solid fa-trash fa-sm" v-else></i>
                  <span
                    >{{ $t('delete') }} ({{ (skus_data.sku_ids || []).length }})
                  </span>
                </button>
              </div>
            </slide-transition>
          </li>
          <slide-transition>
            <li class="sm:col-span-2 md:col-span-3" v-if="!filterSkus.length">
              <p class="text-sm font-medium text-center text-gray-600">
                {{ $t('no_data') }}
              </p>
            </li>
          </slide-transition>
          <li
            class="p-3 rounded-lg border border-gray-200"
            v-for="(item, index) in filterSkus"
            :key="index"
          >
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :name="'skus-' + index"
                :id="'skus-' + index"
                :value="item.id"
                v-model="skus_data.sku_ids"
              />
              <label :for="'skus-' + index">
                <div class="flex gap-4 items-start">
                  <div class="flex-1 space-y-0.5">
                    <p class="font-semibold text-gray-800 line-clamp-1">
                      {{ item.name || 'N/A' }}
                    </p>
                    <p
                      class="text-sm font-medium"
                      :class="
                        item.duplicated ? 'text-red-500' : 'text-gray-500'
                      "
                    >
                      {{ item.barcode || 'N/A' }}
                    </p>
                  </div>
                  <!-- <button
                    class="shrink-0 w-8 h-8 rounded-full bg-red-100 text-red-500 flex items-center justify-center"
                    type="button"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                  </button> -->
                </div>
              </label>
            </div>
          </li>
        </ul>
      </template></Modal
    >
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import ConfigurationWrapper from '@/components/setup/configuration/ConfigurationWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'
import FilterBox from '@/components/basics/FilterBox.vue'

export default {
  name: 'Stores',
  data() {
    return {
      load: false,
      items: [],
      stores: [],
      add_data: {
        multi_stores: 1,
      },
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      fields: [],
      store_name: null,
      c_store_name: null,
      delete_disabled: false,
      delete_active: false,
      store_id: null,
      branches: [],
      sync_active: false,
      sync_disabled: false,
      sync_errors: {},
      sync_error: {},
      sync_data: {
        branch_ids: [],
        store_id: null,
        file: null,
        delete_replace: 0,
        add_all_skus: 0,
      },
      skus_active: false,
      skus_disabled: false,
      skus_errors: {},
      skus_error: {},
      skus_data: {
        sku_ids: [],
        company_store_id: null,
      },
      skus: [],
      sku_search: null,
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    sync_active() {
      if (!this.sync_active) {
        this.sync_data = {
          branch_ids: [],
          store_id: null,
          file: null,
          delete_replace: 0,
          add_all_skus: 0,
        }
        this.sync_errors = {}
        this.sync_disabled = false
      }
    },
    skus_active() {
      if (!this.skus_active) {
        this.skus_data = {
          sku_ids: [],
          company_store_id: null,
        }
        this.skus_errors = {}
        this.skus_disabled = false
        this.skus = []
      }
    },

    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
    delete_active(val) {
      if (!val) {
        this.store_id = null
        this.store_name = null
        this.c_store_name = null
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'table_paginate', 'search']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
    filterSkus() {
      return this.skus
        ?.filter((item) => {
          return this.sku_search
            ? new RegExp('^' + this.sku_search, 'gi').test(item?.name)
            : true
        })
        .map((item, index, arr) => {
          const ids = arr
            .filter((e) => e.id !== item.id && item.barcode === e.barcode)
            .map((e) => e.barcode)

          if (ids.includes(item.barcode)) return { ...item, duplicated: true }
          return item
        })
    },
  },

  async created() {
    await Promise.all([this.getItems(), this.getStores()])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getStores() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'stores',
          options: {
            is_active: 1,
            not_listed_stores: 1,
          },
        })
        this.stores = result.stores
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getSkus(id) {
      try {
        this.$set(this.disables, 'skus-' + id, true)
        this.$set(this.skus_data, 'company_store_id', id)
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'skus',
          options: {
            company_store_id: id,
          },
        })
        this.skus = result.skus
        this.skus_active = true
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.disables = {}
      }
    },
    getItems() {
      const { page, sort_key, sort_type, is_active } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'company_stores',
          options: {
            page,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            search_key: this.search,
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.company_stores
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.skus_data.company_store_id = this.items[0]?.id
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.fields = result.export_fields
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data
      let dataResult = {}

      for (const key in item) {
        if (item.multi_stores) {
          if (['store_id'].includes(key)) {
            dataResult[key] = item[key]
          }
        } else {
          dataResult[key] = item[key]
        }
      }
      try {
        const { data } = await this.axios.post('company_stores/add', {
          ...dataResult,
          store_id: Array.isArray(dataResult.store_id)
            ? dataResult.store_id?.map((e) => e.id)
            : dataResult.store_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async syncSKUs() {
      this.sync_disabled = true
      this.sync_errors = {}
      let fd = new FormData()

      for (const key in this.sync_data) {
        const item = this.sync_data[key]
        if (key === 'file') {
          if (item instanceof File) {
            fd.append(key, item, item.name)
          } else {
            fd.delete(key)
          }
        } else if (key === 'branch_ids') {
          for (const index in item) {
            fd.append(key + '[' + index + ']', item[index]?.id)
          }
        } else if (key === 'add_all_skus') {
          fd.append(key, item)
          if (item) {
            fd.delete('file')
            fd.delete('delete_replace')
          }
        } else {
          fd.append(key, item)
        }
      }

      try {
        const { data } = await this.axios.post(
          'company_stores/sync_skus_from_excel',
          fd
        )
        const { result, message } = data
        this.getItems()
        this.createAlert(message)
        if (result) {
          this.sync_errors = result
          return
        }
        this.sync_active = false
        this.sync_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.sync_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.sync_errors = res?.data?.message
      } finally {
        this.sync_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('company_stores/update', {
          ...this.edit_data,
          store_id: this.edit_data?.store_id?.id,
        })
        const { message, result } = data
        const finalResult = result.company_store ?? {}
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...finalResult,
          store_id: finalResult?.store,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus() {
      this.$set(this.disables, `status_${this.store_id}`, true)
      try {
        const { data } = await this.axios.post(
          `company_stores/update_is_active/${this.store_id}`
        )
        this.createAlert(data.message)
        this.getItems()
        this.getStores()
        this.delete_active = false
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`company_stores/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async DeleteSelectedSkus() {
      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        this.skus_disabled = true
        const { data } = await this.axios.post(
          `skus/delete_from_company_store`,
          this.skus_data
        )
        this.createAlert(data.message)
        this.getItems()
        this.skus = []
        this.skus_active = false
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.skus_disabled = false
      }
    },
    showChangeStatus(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.store_id = id
      this.store_name = item.name || item.alt_name
      this.delete_active = true
    },
    async getBranchesToSync(id) {
      try {
        this.$set(this.disables, `sync_${id}`, true)
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'company_branches',
          options: {
            company_store_id: id,
          },
        })

        const data = result.company_branches || []
        if (!data.length)
          return this.createAlert(
            'There are no stores assigned to current chain to sync, try another chain or assign stores then try again.'
          )
        this.branches = data
        this.sync_active = true
        this.sync_data.store_id = id
        // if()
      } catch (error) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        store_id: item?.store,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    handleSelectAll() {
      this.add_data.store_id = this.stores
    },
    selectAllBranches() {
      this.sync_data.branch_ids = this.branches
    },
  },
  components: { Table, Modal, TableLoad, ConfigurationWrapper, FilterBox },
}
</script>
