<template>
  <div>
    <users-table />
  </div>
</template>

<script>
import UsersTable from '@/components/setup/users/UsersTable.vue'
export default {
  components: { UsersTable },
  name: 'Users',
}
</script>
