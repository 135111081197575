<template>
  <div>
    <transition name="scale">
      <Modal
        :title="'Import EXCEL file'"
        v-if="show_active"
        @close="
          (e) => {
            show_active = e
            $refs.fileImported.value = null
          }
        "
      >
        <template>
          <form class="w-full space-y-6 p-6">
            <div class="w-full">
              <label
                for="attachments"
                class="w-full border-dashed border-2 border-slate-200 py-10 px-8 text-center space-y-4 text-slate-600 flex items-center justify-center flex-col rounded-md cursor-pointer"
                @dragover="
                  $event.target.classList.replace('border-dashed', 'border')
                "
                @dragleave="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @dragend="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @drop="dropControl($event)"
              >
                <h1 class="text-xl">
                  <i class="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                </h1>
                <p class="font-medium rtl:font-semibold text-sm">
                  Drag or drop .excel file here.
                </p>
                <p
                  class="font-medium rtl:font-semibold text-xs text-slate-600"
                  v-if="file_name"
                >
                  {{ file_name }}
                </p>
              </label>
              <input
                autocomplete="off"
                type="file"
                name="attachments"
                id="attachments"
                hidden
                aria-hidden="true"
                class="hidden"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref="fileImported"
                @change.prevent="uploadAttachment"
              />
              <!-- @change.prevent="uploadAttachments" -->
            </div>
            <div class="space-y-2" v-if="isJourneyPlans">
              <div class="form-group-with-check">
                <div>
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="RunTomorrow"
                    id="tomorrow"
                    :true-value="1"
                    :false-value="0"
                    v-model="run_tomorrow"
                  />
                </div>
                <label for="tomorrow" class="capitalize">
                  {{ $t('run_tomorrow') }}
                </label>
              </div>
              <div class="form-group-with-check">
                <div>
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="delete_current_journey_plan"
                    id="delete_current_journey_plan"
                    :true-value="1"
                    :false-value="0"
                    v-model="delete_current_journey_plan"
                  />
                </div>
                <label for="delete_current_journey_plan" class="capitalize">
                  {{ $t('delete_current_journey_plan') }}
                </label>
              </div>
            </div>
            <div v-if="isJourneyPlans">
              <ul class="list-none space-y-4" v-if="!load">
                <li v-for="i in 4" :key="i" class="w-full">
                  <div class="actions-group !flex-nowrap !gap-4">
                    <div
                      class="w-10 h-10 rounded bg-slate-200 animate-pulse"
                    ></div>
                    <div class="space-y-2 flex-1">
                      <div
                        class="w-1/3 h-4 rounded bg-slate-200 animate-pulse"
                      ></div>
                      <div
                        class="w-2/3 h-3 rounded bg-slate-200 animate-pulse"
                      ></div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul
                class="w-full list-none space-y-4"
                v-if="load && tasks.length !== 0"
              >
                <li v-for="(item, index) in tasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="task_ids"
                            @change.prevent="handleParentItem($event, item.id)"
                            v-if="!item.sub_tasks.length"
                          />
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="w-full list-none space-y-4 mt-4 [padding-inline-start:1.5rem]"
                    v-if="item.sub_tasks.length"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id)
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="Object.keys(errors).length"
              v-html="Object.values(errors).join('<br />')"
            ></p>
            <div class="w-full p-4 rounded-md bg-slate-100 flex gap-4">
              <div>
                <div
                  class="w-10 h-10 rounded-full bg-slate-200 flex items-center justify-center"
                >
                  <i class="fa-solid fa-file-excel text-slate-600"></i>
                </div>
              </div>
              <div class="flex-1 space-y-1">
                <h1 class="font-semibold rtl:font-bold text-sm">
                  Explanatory template for the form of the required data
                </h1>
                <p class="font-medium rtl:font-semibold text-sm">
                  The following template can be downloaded to display the form
                  of the data required to import the data well by clicking on
                  the next button and then filling in the data in the same form
                  shown in the file
                </p>
                <a
                  class="table p-2 rounded-sm bg-primary text-white font-medium rtl:font-semibold text-center text-xs !mt-4"
                  href="javascript:void(0)"
                  @click.prevent="downloadTemplate"
                >
                  Download now
                </a>
              </div>
            </div>
            <div class="actions-group">
              <div>
                <!-- @click.prevent="assignUnits"
                  :disabled="add_disabled" -->
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="() => $emit('click-event')"
                  :disabled="disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="disabled" />
                  Submit
                </button>
              </div>
              <div>
                <!-- @click.prevent="assignUnits"
                  :disabled="add_disabled" -->
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-slate-600 bg-slate-200 table"
                  @click.prevent="
                    () => {
                      $refs.fileImported.value = null
                      show_active = false
                    }
                  "
                >
                  <!-- <spinner size="w-4 h-4 inline-block" v-if="add_disabled" /> -->
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import Modal from '@/components/basics/Modal'
export default {
  name: 'ImportFile',
  components: {
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      default: Object,
      type: Object,
    },
  },
  data() {
    return {
      show_active: false,
      file_name: null,
      run_tomorrow: 0,
      delete_current_journey_plan: 0,
      tasks: [],
      task_ids: [],
      load: false,
    }
  },
  mounted() {
    ;[
      'drop',
      'dragstart',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
    ].forEach((event) => {
      window.addEventListener(event, (e) => e.preventDefault())
    })

    if (this.isJourneyPlans) {
      this.getTasks()
    }
  },
  watch: {
    show_active(val) {
      if (val) return
      console.log(val)
      this.hide()
    },
  },
  computed: {
    isJourneyPlans() {
      return this.$route.name === 'JourneyPlans'
    },
  },
  methods: {
    async getTasks() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'tasks'
        )
        this.tasks = result.tasks
        this.task_ids = new Array().concat(
          ...this.tasks.map((e) =>
            e.sub_tasks?.length >= 1 ? e.sub_tasks.map((s) => s.id) : [e.id]
          )
        )
      } catch ({ result: result_1 }) {
        this.createAlert(result_1.message, 'error')
      } finally {
        this.load = true
      }
    },
    show() {
      this.show_active = true
    },
    hide() {
      this.show_active = false
      this.file_name = null
      let data = {}
      data.file = null
      if (this.isJourneyPlans) {
        data.run_tomorrow = 0
        data.delete_current_journey_plan = 0
        data.tasks = null
        this.run_tomorrow = 0
        this.delete_current_journey_plan = 0
      }
      this.getTasks()
      this.$emit('uploaded-file', data)
    },
    dropControl(e) {
      e.preventDefault()
      const files = e.dataTransfer.files
      e.currentTarget.classList.replace('border', 'border-dashed')
      if (!files.length) {
        this.createAlert(
          'There is something went wrong while uploading file',
          'error'
        )
        return
      }
      let data = {}
      data.file = files[0]
      if (this.isJourneyPlans) {
        data.run_tomorrow = this.run_tomorrow
        data.delete_current_journey_plan = this.delete_current_journey_plan
        data.task_ids = this.task_ids
      }
      this.file_name = files[0]?.name
      this.$emit('uploaded-file', data)
    },
    uploadAttachment(e) {
      e.preventDefault()
      const files = e.target.files
      if (!files.length) {
        this.createAlert(
          'There is something went wrong while uploading file',
          'error'
        )
        return
      }
      let data = {}
      data.file = files[0]
      if (this.isJourneyPlans) {
        data.run_tomorrow = this.run_tomorrow
        data.delete_current_journey_plan = this.delete_current_journey_plan
        data.task_ids = this.task_ids
      }
      this.file_name = files[0]?.name
      this.$emit('uploaded-file', data)
    },
    downloadTemplate() {
      let link = document.createElement('a')
      link.href = `/${this.$route.meta.template_name}`
      link.download = this.$route.meta.template_name
      link.click()
    },
    handleParentItem(e, id) {
      const tasks = this.tasks
      const task = tasks.find((e) => e.id === id)
      const isChecked = e.target.checked

      if (!task?.sub_tasks?.length) return
      const taskIds = task?.sub_tasks?.map((e) => e.id)
      if (isChecked) {
        this.$set(
          this.$data,
          'task_ids',
          new Array().concat(this.task_ids, taskIds)
        )
      } else {
        this.$set(
          this.$data,
          'task_ids',
          this.task_ids?.filter(
            (e) => !new Array().concat(id, taskIds).includes(e)
          )
        )
      }
    },
    handleChildItem(e, id) {
      const isChecked = e.target.checked
      const selectedTasks = this.task_ids

      if (isChecked) {
        if (selectedTasks.includes(id)) return
        this.$set(this.$data, 'task_ids', new Array().concat(selectedTasks))
      } else {
        const parentSubTasks = this.tasks.find((e) => e.id === id)
        const siblingIds = parentSubTasks.sub_tasks.map((e) => e.id)
        const hasSiblingIds = siblingIds.some((e) =>
          new RegExp(selectedTasks.join('|')).test(e)
        )

        if (hasSiblingIds) return
        this.$set(
          this.$data,
          'task_ids',
          selectedTasks.filter((e) => e !== id)
        )
      }
    },
  },
}
</script>
