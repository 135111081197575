<template>
  <div class="px-6 space-y-6">
    <div class="table p-6 mx-auto" v-if="false">
      <spinner size="w-8 h-8" class="align-sub text-primary" />
    </div>
    <filter-box isWeekly></filter-box>

    <!-- <cards-container :cards="filterCards" :load="!load" /> -->
    <main class="w-full space-y-6">
      <header class="table mx-auto md:sticky top-[4.5rem]">
        <div class="grid">
          <ul
            class="
              list-none
              inline-flex
              gap-4
              items-center
              overflow-x-auto
              scroll-p-4
              snap-x snap-mandatory
            "
          >
            <li v-for="(day, index) in days" :key="index" class="snap-center">
              <div>
                <label
                  :for="`day-${index}`"
                  class="
                    p-4
                    rounded
                    space-y-1
                    text-center
                    block
                    cursor-pointer
                    transition
                  "
                  :class="
                    date === day.date
                      ? 'bg-primary'
                      : 'bg-white hover:bg-slate-200 border border-slate-200'
                  "
                >
                  <p
                    class="text-xl font-bold tabular-nums"
                    :class="date === day.date ? 'text-white' : 'text-slate-800'"
                  >
                    {{ day.date | moment('DD') }}
                  </p>
                  <p
                    class="text-xs font-semibold"
                    :class="date === day.date ? 'text-white' : 'text-slate-400'"
                  >
                    {{ day.day }}
                  </p>
                </label>
                <input
                  type="radio"
                  name="daysGroup"
                  :id="`day-${index}`"
                  hidden
                  aria-hidden="true"
                  class="hidden"
                  :value="day.date"
                  v-model="date"
                />
              </div>
            </li>
          </ul>
        </div>
      </header>
      <!-- class="grid sm:grid-cols-[repeat(auto-fill,minmax(20rem,auto))] gap-4" -->
      <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <figure
          v-for="user in users"
          :key="user.id"
          class="bg-white border border-slate-200 rounded"
        >
          <figcaption class="p-4 border-b border-b-slate-100">
            <p class="text-sm text-primary font-semibold table truncate">
              {{ user.name }}
            </p>
            <a
              :href="`mailto:${user.username}`"
              class="text-xs text-slate-400 table font-medium truncate"
              >{{ user.username }}</a
            >
          </figcaption>
          <blockquote>
            <div class="border-b border-slate-100">
              <div class="p-4">
                <details>
                  <summary
                    class="
                      text-sm
                      font-semibold
                      text-slate-600
                      cursor-pointer
                      select-none
                    "
                  >
                    {{ $t('statuses') }}
                  </summary>
                  <div class="mt-4">
                    <ul class="actions-group">
                      <li v-for="(_status_, idxs) in status" :key="idxs * 10">
                        <span
                          class="status tabular-nums"
                          :class="styles[_status_.replace(/\s/g, '_')]"
                          >{{ $t(_status_.replace(/\s/g, '_')) }} - ({{
                            getStatusCount(
                              user.id,
                              _status_.replace(/\s/g, '_')
                            )
                          }})</span
                        >
                      </li>
                    </ul>
                  </div>
                </details>
              </div>
            </div>
            <div class="p-4">
              <details
                v-bind="{
                  open: filterUserVisits(user.id).length > 0,
                }"
              >
                <summary
                  class="
                    text-sm
                    font-semibold
                    text-slate-600
                    cursor-pointer
                    select-none
                  "
                >
                  {{ $t('visits') }} ({{ filterUserVisits(user.id).length }})
                </summary>
                <div class="mt-4">
                  <ul class="actions-group max-h-[20rem] overflow-y-auto">
                    <li
                      class="w-full p-2 text-slate-600"
                      v-if="
                        !visits[date] || filterUserVisits(user.id).length === 0
                      "
                    >
                      <p class="text-xs font-semibold rtl:font-bold">
                        {{ $t('visits_no_data') }}
                      </p>
                    </li>
                    <li
                      v-else
                      :class="visit_status_styled[visit.visit_status]"
                      v-for="(visit, i) in filterUserVisits(user.id)"
                      :key="i"
                      class="p-3 rounded"
                    >
                      <details class="select-none" open>
                        <summary class="text-xs font-medium rtl:font-semibold">
                          {{ (visit.company_store || {}).name || '...' }}
                        </summary>

                        <div>
                          <p
                            class="
                              text-xs
                              font-medium
                              rtl:font-semibold
                              line-clamp-2
                            "
                          >
                            {{ visit.name }}
                          </p>
                        </div>
                      </details>
                    </li>
                  </ul>
                </div>
              </details>
            </div>
          </blockquote>
        </figure>
      </div>

      <!-- fixed statues -->
      <div class="sticky bottom-5 z-10 mx-auto table">
        <div class="grid">
          <ul
            class="
              list-none
              inline-flex
              gap-4
              items-center
              overflow-x-auto overflow-y-hidden
              py-2
              snap-x snap-mandatory
            "
          >
            <li
              v-for="(_status_, index) in status"
              :key="index * 10"
              class="snap-center"
            >
              <label
                :for="`status-${index}`"
                class="cursor-pointer select-none"
              >
                <span
                  class="status tabular-nums"
                  :class="styles[_status_.replace(/\s/g, '_')]"
                  >{{ $t(_status_.replace(/\s/g, '_')) }} - ({{
                    getAllStatusCount(_status_.replace(/\s/g, '_'))
                  }})</span
                >
              </label>
              <input
                type="radio"
                name="statusGroup"
                :id="`status-${index}`"
                hidden
                aria-hidden="true"
                class="hidden"
                :value="_status_.replace(/\s/g, '_')"
                v-model="filter_status"
              />
            </li>
          </ul>
        </div>
      </div>
      <!-- ./fixed statues -->
    </main>
  </div>
</template>
<script>
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import FilterBox from '@/components/basics/FilterBox.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'
export default {
  components: { FilterWrapper, FilterBox, CardsContainer },
  name: 'NewCalendar',
  data() {
    return {
      load: false,
      status: [
        'done',
        'scheduled',
        'added by user',
        'extra mission',
        'extra mission done',
        'skipped',
        'missed',
        'cancelled',
      ],
      styles: {
        done: 'bg-sky-500 text-white',
        missed: 'bg-red-500 text-white',
        pending: 'bg-slate-400 text-white',
        extra_mission: 'bg-green-600 text-white',
        extra_mission_done: 'bg-yellow-500 text-white',
        added_by_user: 'bg-green-800 text-white',
        skipped: 'bg-pink-800 text-white',
        cancelled: 'bg-red-600 text-white',
        scheduled: 'bg-slate-200 text-slate-600',
      },
      visit_status_styled: {
        done: 'bg-sky-500/10 text-sky-500 [border-inline-start-width:2px] border-sky-500/30',
        missed:
          'bg-red-500/10 text-red-500 [border-inline-start-width:2px] border-red-500/30',
        pending:
          'bg-slate-400/10 text-bg-slate-400 [border-inline-start-width:2px] border-slate-400/30',
        extra_mission:
          'bg-green-600/10 text-green-600 [border-inline-start-width:2px] border-green-600/30',
        extra_mission_done:
          'bg-yellow-500/10 text-yellow-500 [border-inline-start-width:2px] border-yellow-500/30',
        added_by_user:
          'bg-green-800/10 text-green-800 [border-inline-start-width:2px] border-green-800/30',
        skipped:
          'bg-pink-800/10 text-pink-800 [border-inline-start-width:2px] border-pink-800/30',
        cancelled:
          'bg-red-500/10 text-red-500 [border-inline-start-width:2px] border-red-500/30',
        scheduled:
          'bg-slate-600/10 text-slate-600 [border-inline-start-width:2px] border-slate-600/30',
      },
      days: [],
      users: [],
      visits: {},
      branches: [],
      pagination: {
        status: [],
        from: null,
        company_branch_id: [],
      },
      disables: {},
      apply_disabled: false,
      reset_disabled: false,
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Merchandisers',
          value: 0,
          key: 'merchandisers_count',
          translate_key: 'merchandisers',
          icon: 'fa-solid fa-users-between-lines',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        {
          title: 'Supervisors',
          value: 0,
          key: 'supervisors_count',
          translate_key: 'supervisors',
          icon: 'fa-solid fa-user-group',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        {
          title: 'Total SKUs',
          value: 0,
          key: 'skus_count',
          translate_key: 'skus',
          icon: 'fa-solid fa-basket-shopping',
          style: {
            icon: 'text-yellow-600',
            parent: 'bg-yellow-600/20',
          },
        },
        {
          title: 'Scheduled',
          value: 0,
          key: 'scheduled_visits_count',
          translate_key: 'scheduled',
          icon: 'fa-solid fa-list-ol',
          style: {
            icon: 'text-slate-600',
            parent: 'bg-slate-600/20',
          },
        },
        {
          title: 'Added by user',
          value: 0,
          key: 'added_by_user_visits_count',
          translate_key: 'added_by_user',
          icon: 'fa-solid fa-user-plus',
          style: {
            icon: 'text-green-600',
            parent: 'bg-green-600/20',
          },
        },

        {
          title: 'Missed',
          value: 0,
          key: 'missed_visits_count',
          translate_key: 'missed',
          icon: 'fa-solid fa-arrow-down-short-wide',
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },

        {
          title: 'Extra mission',
          value: 0,
          key: 'extra_mission_visits_count',
          translate_key: 'extra_mission',
          icon: 'fa-solid fa-file-circle-plus',
          style: {
            icon: 'text-green-500',
            parent: 'bg-green-500/20',
          },
        },
        {
          title: 'Extra mission done',
          value: 0,
          key: 'extra_mission_done_visits_count',
          translate_key: 'extra_mission_done',
          icon: 'fa-solid fa-check-double',
          style: {
            icon: 'text-yellow-500',
            parent: 'bg-yellow-500/20',
          },
        },
        {
          title: 'done',
          value: 0,
          key: 'done_visits_count',
          translate_key: 'done',
          icon: 'fa-solid fa-check',
          style: {
            icon: 'text-sky-500',
            parent: 'bg-sky-500/20',
          },
        },
        {
          title: 'Skipped',
          value: 0,
          key: 'skipped_visits_count',
          translate_key: 'skipped',
          icon: 'fa-solid fa-forward',
          style: {
            icon: 'text-pink-800',
            parent: 'bg-pink-800/20',
          },
        },
      ],
      summary: {},
      date: new Date().toISOString().substring(0, 10),
      filter_status: null,
    }
  },
  async created() {
    await this.getItems()
  },
  watch: {
    'pagination.status'() {
      this.$set(this.disables, this.pagination.status.at(-1), true)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    async is_called() {
      if (this.is_called) {
        await this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'table_paginate']),
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
    getStatusCount() {
      return (id, status) => {
        const mapVisits = new Array().concat(
          ...(Object.values(this.visits) || [])
        )
        const currentUserVisits = mapVisits.filter(
          (user) => user.user_id === id
        )
        const filterUserVisitsByStatus = currentUserVisits.filter(
          (user) => user.visit_status === status
        )

        return filterUserVisitsByStatus.length
      }
    },
    filterUserVisits() {
      return (id) => {
        return this.visits[this.date]
          .filter((e) => e.user_id === id)
          .filter((e) =>
            this.filter_status ? e.visit_status === this.filter_status : e
          )
      }
    },
    getAllStatusCount() {
      return (status) => {
        const mapVisits = new Array().concat(
          ...(Object.values(this.visits) || [])
        )

        return mapVisits.filter((e) => e.visit_status === status).length
      }
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getItems() {
      const { status, from, company_branch_id } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'visits/calendar',
          options: {
            status,
            from,
            company_branch_id: company_branch_id?.map((e) => e.id),
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { days, users, visits, company_branches, summary } = result
          this.days = days || []
          this.users = users || []
          this.visits = visits || {}
          this.branches = company_branches || []
          // console.log(result)
          this.summary = summary || {}
        })
        .catch(({ result, type }) => {
          this.createAlert(result?.message, type)
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    openCurrent(e) {
      const parElement =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .parentElement
      const calendarParent = parElement.querySelector('[data-type="calendar"]')
      let height = 0
      for (let i = 0; i < calendarParent.children.length; i++) {
        const childHeight =
          calendarParent.children[i].getBoundingClientRect().height
        height += childHeight
      }

      if (calendarParent.classList.contains('h-0')) {
        calendarParent.classList.remove('h-0')
        calendarParent.style.cssText = `--height: ${height}px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-down', 'fa-angle-up')
      } else {
        calendarParent.classList.add('h-0')
        calendarParent.style.cssText = `--height: 0px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-up', 'fa-angle-down')
      }
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination).forEach((key) => {
        if (!['page', 'total_pages', 'total'].includes(key)) {
          if (Array.isArray(this.pagination[key])) {
            this.$set(this.pagination, key, [])
          } else {
            this.$set(this.pagination, key, null)
          }
        }
      })
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(item) {
      this.$set(this.disables, item, true)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
}
</script>
