<template>
  <div class="px-6 space-y-6">
    <div class="table p-6 mx-auto" v-if="false">
      <spinner size="w-8 h-8" class="align-sub text-primary" />
    </div>
    <filter-box isWeekly></filter-box>

    <cards-container :cards="filterCards" :load="!load" />
    <main class="w-full py-6">
      <div class="grid">
        <div class="w-full flex space-y-4">
          <div
            class="flex-1 grid gap-4 sm:gap-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7"
            v-if="users.length !== 0"
          >
            <figure
              v-for="(item, i) in days"
              :key="i"
              class="hidden xl:block bg-white xl:sticky xl:top-[4.5rem] z-10 border border-slate-200"
            >
              <header class="w-full text-center p-4 space-y-1">
                <p class="text-sm font-semibold rtl:font-bold">
                  {{ item.day }}
                </p>
                <p class="text-slate-600 text-xs font-medium rtl:font-semibold">
                  {{ item.date | moment('DD-yyyy') }}
                </p>
              </header>
            </figure>
            <div
              class="sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-7 sm:mt-2 rounded"
              v-for="user in users"
              :key="user.id"
            >
              <header
                class="sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-7 p-4 bg-white border border-slate-200 rounded"
              >
                <div class="flex items-center">
                  <div>
                    <div class="w-10 h-10">
                      <img
                        src="@/assets/images/profile.jpg"
                        alt="user profile image"
                        class="w-full h-full rounded-full object-cover block sticky top-20"
                      />
                    </div>
                  </div>
                  <div class="px-4 space-y-1 flex-1">
                    <div class="table">
                      <div class="grid">
                        <p
                          class="text-sm text-primary font-medium rtl:font-semibold table truncate"
                        >
                          {{ user.name }}
                        </p>
                        <a
                          :href="`mailto:${user.username}`"
                          class="text-xs text-slate-400 block font-medium rtl:font-semibold truncate"
                          >{{ user.username }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="actions-group">
                    <div>
                      <button
                        class="w-8 h-8 rounded-full flex items-center justify-center sm:hidden"
                        @click.prevent="openCurrent"
                      >
                        <i class="fa-solid fa-angle-down fa-sm"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </header>
              <slide-transition>
                <div
                  class="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 h-0 sm:h-auto transition-all ease-linear overflow-hidden divide-x divide-slate-200"
                  data-type="calendar"
                >
                  <header
                    class="bg-white p-5 my-2 rounded border border-slate-200 sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-7"
                  >
                    <ul class="actions-group">
                      <li v-for="(_status_, idxs) in status" :key="idxs * 10">
                        <span
                          class="status tabular-nums"
                          :class="styles[_status_.replace(/\s/g, '_')]"
                          >{{ $t(_status_.replace(/\s/g, '_')) }} - ({{
                            getStatusCount(
                              user.id,
                              _status_.replace(/\s/g, '_')
                            )
                          }})</span
                        >
                      </li>
                    </ul>
                  </header>
                  <figure
                    v-for="(item, i) in days"
                    :key="i"
                    class="bg-white border border-slate-200"
                  >
                    <figcaption
                      class="w-full border-b border-slate-200 text-center p-4 space-y-1 block xl:hidden"
                    >
                      <p class="text-sm font-semibold rtl:font-bold">
                        {{ item.day }}
                      </p>
                      <p
                        class="text-slate-600 text-xs font-medium rtl:font-semibold"
                      >
                        {{ item.date | moment('DD-yyyy') }}
                      </p>
                    </figcaption>
                    <blockquote>
                      <ul
                        class="list-none overflow-auto sm:min-h-[20rem] max-h-[20rem] space-y-2 p-2"
                      >
                        <li
                          class="w-full p-3 transition-[border-color] last:border-b-0 rounded"
                          :class="visit_status_styled[visit.visit_status]"
                          v-for="(visit, i) in (visits[item.date] || []).filter(
                            (e) => e.user_id === user.id
                          )"
                          :key="i"
                        >
                          <a
                            href="javascript:void(0)"
                            @click.prevent="navigateToVisit(visit)"
                            :title="'/visits/' + visit.visit_id"
                            v-tippy
                            :content="$t('view_details')"
                          >
                            <details
                              class="select-none"
                              open
                              @click.prevent="() => false"
                            >
                              <summary
                                class="text-xs font-medium rtl:font-semibold"
                              >
                                {{ (visit.company_store || {}).name || '...' }}
                              </summary>

                              <div>
                                <p
                                  class="text-xs font-medium rtl:font-semibold line-clamp-2"
                                >
                                  {{ visit.name }}
                                </p>
                              </div>
                            </details>
                          </a>
                        </li>
                        <li
                          class="w-full p-3 text-slate-600"
                          v-if="
                            !visits[item.date] ||
                            (visits[item.date] || []).filter(
                              (e) => e.user_id === user.id
                            ).length === 0
                          "
                        >
                          <p
                            class="text-xs font-medium rtl:font-semibold text-center"
                          >
                            {{ $t('visits_no_data') }}
                          </p>
                        </li>
                      </ul>

                      <!-- ul. -->
                    </blockquote>
                  </figure>
                </div>
              </slide-transition>
            </div>
          </div>

          <div class="table m-auto max-w-lg" v-else>
            <div class="flex items-center justify-center flex-col gap-3">
              <div>
                <div
                  class="w-16 h-16 flex items-center justify-center bg-red-500/10 rounded-full text-red-500"
                >
                  <i class="fa-solid fa-calendar-plus fa-xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-4 text-center">
                <h1 class="text-xl text-primary font-bold">
                  {{ $t('user_no') }}
                </h1>
                <p
                  class="text-slate-400 max-w-2xl font-medium rtl:font-semibold"
                >
                  {{ $t('user_no_content') }}
                </p>
                <router-link
                  to="/reports/visits/urgent-visit"
                  class="w-full max-w-sm text-center py-3 px-6 rounded-full text-white bg-secondary text-xs font-medium rtl:font-semibold shadow-xl shadow-secondary/30 !mt-8 block mx-auto"
                >
                  {{ $t('add_new.visit') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import FilterBox from '@/components/basics/FilterBox.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'
export default {
  components: { FilterWrapper, FilterBox, CardsContainer },
  name: 'CalendarPage',
  data() {
    return {
      load: false,
      status: [
        'scheduled',
        'extra mission',
        'extra mission done',
        'done',
        'not_done',
      ],
      styles: {
        done: 'bg-sky-500 text-white',
        missed: 'bg-red-500 text-white',
        pending: 'bg-slate-400 text-white',
        extra_mission: 'bg-green-600 text-white',
        extra_mission_done: 'bg-yellow-500 text-white',
        added_by_user: 'bg-green-800 text-white',
        skipped: 'bg-pink-800 text-white',
        not_done: 'bg-pink-800 text-white',
        scheduled: 'bg-slate-200 text-slate-600',
      },
      visit_status_styled: {
        done: 'bg-sky-500/10 text-sky-500 [border-inline-start-width:2px] border-sky-500/30',
        missed:
          'bg-red-500/10 text-red-500 [border-inline-start-width:2px] border-red-500/30',
        pending:
          'bg-slate-400/10 text-bg-slate-400 [border-inline-start-width:2px] border-slate-400/30',
        extra_mission:
          'bg-green-600/10 text-green-600 [border-inline-start-width:2px] border-green-600/30',
        extra_mission_done:
          'bg-yellow-500/10 text-yellow-500 [border-inline-start-width:2px] border-yellow-500/30',
        added_by_user:
          'bg-green-800/10 text-green-800 [border-inline-start-width:2px] border-green-800/30',
        skipped:
          'bg-pink-800/10 text-pink-800 [border-inline-start-width:2px] border-pink-800/30',
        not_done:
          'bg-pink-800/10 text-pink-800 [border-inline-start-width:2px] border-pink-800/30',
        cancelled:
          'bg-red-500/10 text-red-500 [border-inline-start-width:2px] border-red-500/30',
        scheduled:
          'bg-slate-600/10 text-slate-600 [border-inline-start-width:2px] border-slate-600/30',
      },
      days: [],
      users: [],
      visits: {},
      branches: [],
      pagination: {
        status: [],
        from: null,
        company_branch_id: [],
      },
      disables: {},
      apply_disabled: false,
      reset_disabled: false,
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Merchandisers',
          value: 0,
          key: 'merchandisers_count',
          translate_key: 'merchandisers',
          icon: 'fa-solid fa-users-between-lines',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        // {
        //   title: 'Supervisors',
        //   value: 0,
        //   key: 'supervisors_count',
        //   translate_key: 'supervisors',
        //   icon: 'fa-solid fa-user-group',
        //   style: {
        //     icon: 'text-blue-400',
        //     parent: 'bg-blue-400/20',
        //   },
        // },

        {
          title: 'Scheduled',
          value: 0,
          key: 'scheduled_visits_count',
          translate_key: 'scheduled',
          icon: 'fa-solid fa-list-ol',
          style: {
            icon: 'text-slate-600',
            parent: 'bg-slate-600/20',
          },
        },

        {
          title: 'Extra mission',
          value: 0,
          key: 'extra_mission_visits_count',
          translate_key: 'extra_mission',
          icon: 'fa-solid fa-file-circle-plus',
          style: {
            icon: 'text-green-500',
            parent: 'bg-green-500/20',
          },
        },
        {
          title: 'Extra mission done',
          value: 0,
          key: 'extra_mission_done_visits_count',
          translate_key: 'extra_mission_done',
          icon: 'fa-solid fa-check-double',
          style: {
            icon: 'text-yellow-500',
            parent: 'bg-yellow-500/20',
          },
        },
        {
          title: 'done',
          value: 0,
          key: 'done_visits_count',
          translate_key: 'done',
          icon: 'fa-solid fa-check',
          style: {
            icon: 'text-sky-500',
            parent: 'bg-sky-500/20',
          },
        },
        {
          title: 'Not done',
          value: 0,
          key: 'not_done_count',
          translate_key: 'not_done',
          icon: 'fa-solid fa-xmark',
          style: {
            icon: 'text-pink-800',
            parent: 'bg-pink-800/20',
          },
        },
        // {
        //   title: 'Skipped',
        //   value: 0,
        //   key: 'skipped_visits_count',
        //   translate_key: 'skipped',
        //   icon: 'fa-solid fa-forward',
        //   style: {
        //     icon: 'text-pink-800',
        //     parent: 'bg-pink-800/20',
        //   },
        // },
        // {
        //   title: 'Missed',
        //   value: 0,
        //   key: 'missed_visits_count',
        //   translate_key: 'missed',
        //   icon: 'fa-solid fa-arrow-down-short-wide',
        //   style: {
        //     icon: 'text-red-500',
        //     parent: 'bg-red-500/20',
        //   },
        // },
      ],
      summary: {},
    }
  },
  async created() {
    await this.getItems()
  },
  watch: {
    'pagination.status'() {
      this.$set(this.disables, this.pagination.status.at(-1), true)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    async is_called() {
      if (this.is_called) {
        await this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'table_paginate']),
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
    getStatusCount() {
      return (id, status) => {
        const mapVisits = new Array().concat(
          ...(Object.values(this.visits) || [])
        )
        const currentUserVisits = mapVisits.filter(
          (user) => user.user_id === id
        )
        const filterUserVisitsByStatus = currentUserVisits.filter((user) =>
          status === 'not_done'
            ? ['missed', 'skipped'].includes(user.visit_status)
            : user.visit_status === status
        )

        return filterUserVisitsByStatus.length
      }
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getItems() {
      const { status, company_branch_id } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'visits/calendar',
          options: {
            ...this.table_paginate,
            status: status === 'not_done' ? ['missed', 'skipped'] : status,
            company_branch_id: company_branch_id?.map((e) => e.id),
          },
        })
        .then(({ result }) => {
          const { days, users, visits, company_branches, summary } = result
          this.days = days || []
          this.users = users || []
          this.visits = visits || {}
          this.branches = company_branches || []
          // console.log(result)
          this.summary = summary || {}
        })
        .catch(({ result, type }) => {
          this.createAlert(result?.message, type)
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    openCurrent(e) {
      const parElement =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .parentElement
      const calendarParent = parElement.querySelector('[data-type="calendar"]')
      let height = 0
      for (let i = 0; i < calendarParent.children.length; i++) {
        const childHeight =
          calendarParent.children[i].getBoundingClientRect().height
        height += childHeight
      }

      if (calendarParent.classList.contains('h-0')) {
        calendarParent.classList.remove('h-0')
        calendarParent.style.cssText = `--height: ${height}px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-down', 'fa-angle-up')
      } else {
        calendarParent.classList.add('h-0')
        calendarParent.style.cssText = `--height: 0px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-up', 'fa-angle-down')
      }
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination).forEach((key) => {
        if (!['page', 'total_pages', 'total'].includes(key)) {
          if (Array.isArray(this.pagination[key])) {
            this.$set(this.pagination, key, [])
          } else {
            this.$set(this.pagination, key, null)
          }
        }
      })
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(item) {
      this.$set(this.disables, item, true)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    navigateToVisit(visit) {
      if (visit.visit_status === 'done')
        return this.$router.push('/visits/' + visit.visit_id)
      return false
    },
  },
}
</script>
