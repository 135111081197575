<template>
  <div>
    <client-table />
  </div>
</template>

<script>
import ClientTable from '@/components/setup/users/ClientTable.vue'
export default {
  components: { ClientTable },
  name: 'Clients',
}
</script>
