const requireModule = require.context('.', false, /\.vue$/)

const components = {}

requireModule.keys().forEach((filename) => {
  if (['./index.js', './Images.vue', '/PlanogramAudits.vue'].includes(filename))
    return // don't import index file
  const replaceFilename = filename.replace(/(\.\/|\.vue)/gi, '')

  components[replaceFilename] = requireModule(filename).default
})

export default components
