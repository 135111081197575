<template>
  <div>
    <ConfigurationWrapper>
      <section class="w-full space-y-6">
        <header class="w-full space-y-4 px-6">
          <div
            class="w-full flex bg-white border border-slate-200 py-3 px-4 sm:hidden items-center"
          >
            <div class="flex-1">
              <p
                class="text-sm text-slate-600 block font-medium rtl:font-semibold truncate"
              >
                <i class="fa-solid fa-list mr-2 rtl:mr-0 rtl:ml-2"></i>
                Filter
              </p>
            </div>
            <div>
              <button
                class="w-8 h-8 rounded-full flex items-center justify-center"
                @click.prevent="openFilter"
              >
                <i class="fa-solid fa-angle-down fa-sm"></i>
              </button>
            </div>
          </div>
          <div
            class="hidden space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:!mt-0"
            ref="filter"
          >
            <div class="w-full">
              <multiselect
                v-model="pagination.brand_id"
                :options="brands"
                :placeholder="$t('brands')"
                label="name"
                track-by="id"
                id="brand_id"
                :hideSelected="true"
                :clearOnSelect="true"
                :multiple="true"
                :closeOnSelect="false"
                :disabled="brands.length === 0"
                style="background-color: #ffffff !important"
                :limit="1"
                :limit-text="(e) => `+${e}`"
                :show-no-results="true"
                ><span
                  slot="noResult"
                  class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
                  >{{ $t('no_data') }}</span
                ></multiselect
              >
            </div>

            <div class="w-full">
              <div class="actions-group">
                <div>
                  <button
                    class="w-full h-auto bg-secondary text-white font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
                    @click.prevent="applyFilter"
                    :disabled="apply_disabled"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="apply_disabled"
                    />
                    {{ $t('apply') }}
                  </button>
                </div>
                <div>
                  <button
                    class="w-full h-auto bg-slate-300 text-slate-600 font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
                    @click.prevent="resetFilter"
                    :disabled="reset_disabled"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="reset_disabled"
                    />
                    {{ $t('reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main class="w-full px-6">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('competitors')"
            :records="`${pagination.total || 0} ${$t('records')}`"
            :pagination="pagination"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
            :fields="fields"
          >
            <template #actions>
              <div>
                <button
                  class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.competitor') }}</span>
                </button>
              </div>
            </template>
            <template #filter>
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, '_')) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th v-if="columns.includes('name')">{{ $t('name') }}</th>
              <th v-if="columns.includes('alt_name')">{{ $t('alt_name') }}</th>

              <th v-if="columns.includes('brand')">
                <div class="sort">
                  <div>
                    <p>{{ $t('brand') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('brand_id')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('active')">
                <div class="sort">
                  <div>
                    <p>{{ $t('active') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('is_active')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('updated_at')">
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>

              <th v-if="columns.includes('actions')"></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="columns.includes('name')">
                  {{ item.name || '...' }}
                </td>
                <td v-if="columns.includes('alt_name')">
                  {{ item.alt_name || '...' }}
                </td>
                <td v-if="columns.includes('brand')">
                  {{
                    (item.brand || {}).name
                      ? `${(item.brand || {}).name} - ${
                          (item.brand || {}).alt_name
                        }`
                      : '- -'
                  }}
                </td>

                <td v-if="columns.includes('active')">
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                </td>
                <td
                  v-if="columns.includes('updated_at')"
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td v-if="columns.includes('actions')">
                  <div class="actions-group">
                    <div v-if="false">
                      <button
                        class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                        @click.prevent="deleteItem(item.id)"
                        :disabled="disables[`delete_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${item.id}`]"
                        />
                        <span v-else> {{ $t('delete') }} </span>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                        @click.prevent="changeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                        :disabled="disables[`status_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${item.id}`]"
                        />
                        <i class="fa-solid fa-right-left" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded"
                        @click.prevent="showUpdate(item.id)"
                        :disabled="!item.is_active"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td :colspan="columns.length">
                  <div class="table mx-auto my-4">
                    <button
                      class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.competitor') }}</span>
                    </button>
                  </div>
                  <p
                    class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.competitor')"
          v-if="add_active"
          @close="add_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    v-model="add_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name"
                >
                  {{ add_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    v-model="add_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alt_name"
                >
                  {{ add_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="add_data.brand_id"
                    :options="brands"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="brand_id"
                  ></multiselect>
                  <label for="brand_id">{{ $t('brand') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.brand_id"
                >
                  {{ add_errors.brand_id.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t('add') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="edit_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    v-model="edit_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name"
                >
                  {{ edit_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    v-model="edit_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_name"
                >
                  {{ edit_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="edit_data.brand_id"
                    :options="brands"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="brand_id"
                  ></multiselect>
                  <label for="brand_id">{{ $t('brand') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.brand_id"
                >
                  {{ edit_errors.brand_id.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
    </ConfigurationWrapper>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import ConfigurationWrapper from '@/components/setup/configuration/ConfigurationWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Competitors',
  data() {
    return {
      load: false,
      items: [],
      brands: [],
      add_data: {
        brand_id: null,
      },
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_type: 'asc',
        is_active: 'active',
        total: 0,
        brand_id: null,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      fields: [],
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
  },

  async created() {
    await Promise.all([this.getItems(), this.getBrands()])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getBrands() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'brands'
        )
        this.brands = result.brands
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    getItems() {
      const { page, sort_key, sort_type, is_active, brand_id } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'competitors',
          options: {
            page,
            search_key: this.search,

            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            brand_id: brand_id?.map((e) => e.id),
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.competitors
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.fields = result.export_fields
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { data } = await this.axios.post('competitors/add', {
          ...this.add_data,
          brand_id: this.add_data?.brand_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('competitors/update', {
          ...this.edit_data,
          brand_id: this.edit_data?.brand_id?.id,
        })
        const { message, result } = data
        const finalResult = result.sub_category ?? {}
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...finalResult,
          brand_id: finalResult?.category,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `competitors/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`competitors/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        brand_id: item?.brand,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, ConfigurationWrapper },
}
</script>
