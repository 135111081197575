<template>
  <div>
    <section class="w-full px-6 flex gap-4 flex-col lg:flex-row" v-if="load">
      <aside class="w-full lg:w-1/3 space-y-4">
        <div
          class="
            w-full
            grid grid-cols-1
            sm:grid-cols-[repeat(auto-fill,minmax(16em,auto))]
            gap-4
            items-stretch
          "
        >
          <single-card
            :item="{
              title: 'Supervisors',
              value: supervisors_count,
              key: 'supervisors_count',
              translate_key: 'supervisors',
              icon: 'fa-solid fa-user-group',
              style: {
                icon: 'text-blue-400',
                parent: 'bg-blue-400/20',
              },
            }"
          />

          <single-card
            :item="{
              title: 'Merchandisers',
              value: merchandisers_count,
              key: 'merchandisers_count',
              translate_key: 'merchandisers',
              icon: 'fa-solid fa-user-tie',
              style: {
                icon: 'text-yellow-600',
                parent: 'bg-yellow-600/20',
              },
            }"
          />
          <single-card
            :item="{
              title: 'Users',
              value: users_count,
              key: 'users_count',
              translate_key: 'users',
              icon: 'fa-solid fa-users-viewfinder',
              style: {
                icon: 'text-teal-600',
                parent: 'bg-teal-600/20',
              },
            }"
          />
          <single-card
            :item="{
              title: 'Teams',
              value: teams_count,
              key: 'teams_count',
              translate_key: 'teams',
              icon: 'fa-solid fa-users-gear',
              style: {
                icon: 'text-teal-600',
                parent: 'bg-teal-600/20',
              },
            }"
          />
        </div>
        <div class="w-full">
          <figure class="bg-white ring-1 ring-slate-200 rounded-md w-full">
            <header class="w-full border-b border-slate-100 p-4">
              <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                {{ $t('assign_info') }}
              </p>
            </header>
            <blockquote class="p-6">
              <ul class="list-none space-y-3">
                <li class="w-full">
                  <div class="flex w-full">
                    <div class="w-1/2 p-1.5">
                      <p
                        class="
                          text-sm text-slate-500
                          font-medium
                          rtl:font-semibold
                        "
                      >
                        <i class="fa-solid fa-user mr-2 rtl:mr-0 rtl:ml-2"></i>
                        {{ $t('name') }}
                      </p>
                    </div>
                    <div class="w-1/2 p-1.5">
                      <p class="text-sm font-medium rtl:font-semibold">
                        {{ client.name || '...' }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="w-full">
                  <div class="flex w-full flex-wrap">
                    <div class="w-1/2 p-1.5">
                      <p
                        class="
                          text-sm text-slate-500
                          font-medium
                          rtl:font-semibold
                        "
                      >
                        <i
                          class="fa-solid fa-right-left mr-2 rtl:mr-0 rtl:ml-2"
                        ></i>
                        {{ $t('active') }}
                      </p>
                    </div>
                    <div class="w-1/2 p-1.5">
                      <p
                        class="status capitalize"
                        :class="item_status[client.is_active]"
                      >
                        {{
                          client.is_active === 1
                            ? $t('active')
                            : $t('not_active')
                        }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="w-full">
                  <div class="flex w-full">
                    <div class="w-1/2 p-1.5">
                      <p
                        class="
                          text-sm text-slate-500
                          font-medium
                          rtl:font-semibold
                        "
                      >
                        <i class="fa-solid fa-at mr-2 rtl:mr-0 rtl:ml-2"></i>
                        {{ $t('email') }}
                      </p>
                    </div>
                    <div class="w-1/2 p-1.5">
                      <a
                        :href="`mailto:${client.email || '...'}`"
                        class="
                          text-xs text-primary
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                        >{{ client.email || '...' }}
                      </a>
                    </div>
                  </div>
                </li>
                <li class="w-full">
                  <div class="flex w-full">
                    <div class="w-1/2 p-1.5">
                      <p
                        class="
                          text-sm text-slate-500
                          font-medium
                          rtl:font-semibold
                        "
                      >
                        <i class="fa-solid fa-phone mr-2 rtl:mr-0 rtl:ml-2"></i>
                        {{ $t('mobile') }}
                      </p>
                    </div>
                    <div class="w-1/2 p-1.5">
                      <a
                        :href="`tel:${client.mobile || '...'}`"
                        class="
                          text-xs text-primary
                          block
                          font-medium
                          rtl:font-semibold
                          truncate
                        "
                        >{{ client.mobile || '...' }}
                      </a>
                    </div>
                  </div>
                </li>
                <li class="w-full">
                  <div class="flex w-full">
                    <div class="w-1/2 p-1.5">
                      <p
                        class="
                          text-sm text-slate-500
                          font-medium
                          rtl:font-semibold
                        "
                      >
                        <i
                          class="fa-regular fa-clock mr-2 rtl:mr-0 rtl:ml-2"
                        ></i>
                        {{ $t('joined_date') }}
                      </p>
                    </div>
                    <div class="w-1/2 p-1.5">
                      <p
                        class="text-sm font-medium rtl:font-semibold"
                        v-html="createTime(client.created_at)"
                      ></p>
                    </div>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </div>
      </aside>
      <main class="space-y-6 w-full lg:w-2/3">
        <tabs :link_type="'link'" :tabs="pagesTab" />
        <component :is="currentTab" :client="client" />
      </main>
    </section>
    <div class="table p-6 mx-auto" v-else>
      <spinner size="w-8 h-8" class="align-sub text-primary" />
    </div>
  </div>
</template>

<script>
import SingleCard from '@/components/basics/SingleCard.vue'
import Tabs from '@/components/basics/Tabs.vue'
import MerchandisersTable from '@/components/setup/users/MerchandisersTable.vue'
import SupervisorTable from '@/components/setup/users/SupervisorTable.vue'
import UsersTable from '@/components/setup/users/UsersTable.vue'
import TeamsTable from '@/components/setup/users/TeamsTable.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    SingleCard,
    Tabs,
    TeamsTable,
    SupervisorTable,
    MerchandisersTable,
    UsersTable,
  },
  name: 'SingleClient',
  computed: {
    clientId() {
      return this.$route.params?.id
    },
    currentTab() {
      const tab = this.$route.query.tab
      if (tab === 'merchandisers') {
        return 'merchandisers-table'
      } else if (tab === 'supervisors') {
        return 'supervisor-table'
      } else if (tab === 'teams') {
        return 'teams-table'
      } else if (tab === 'users') {
        return 'users-table'
      } else {
        return 'users-table'
      }
    },
  },
  data() {
    return {
      client: {},
      pagesTab: [],
      supervisors_count: 0,
      merchandisers_count: 0,
      users_count: 0,
      teams_count: 0,
      load: false,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
    }
  },
  async created() {
    this.pagesTab = [
      {
        url: `/setup/clients/${this.clientId}?tab=users`,
        title: 'Users',
        key: 'users',
      },
      {
        url: `/setup/clients/${this.clientId}?tab=teams`,
        title: 'Teams',
        key: 'teams',
      },
      {
        url: `/setup/clients/${this.clientId}?tab=supervisors`,
        title: 'Supervisors',
        key: 'supervisors',
      },
      {
        url: `/setup/clients/${this.clientId}?tab=merchandisers`,
        title: 'Merchandisers',
        key: 'merchandisers',
      },
    ]
    await this.getClientData()
  },
  methods: {
    ...mapMutations('customize', ['setRouteName']),
    async getClientData() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: `clients/${this.clientId}`,
        })

        const {
          client,
          merchandisers_count,
          supervisors_count,
          users_count,
          teams_count,
        } = result
        this.client = client
        this.merchandisers_count = merchandisers_count || 0
        this.supervisors_count = supervisors_count || 0
        this.users_count = users_count || 0
        this.teams_count = teams_count || 0
        this.setRouteName(client?.name)
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
        this.$router.push('/setup/clients')
      } finally {
        this.load = true
      }
    },
  },
}
</script>
