<template>
  <div>
    <SkuAudits
      v-model="pagination"
      :load="load"
      :result="items"
      :summary="summary"
      :fields="fields"
      :subCategories="subCategories"
    />
  </div>
</template>
<script>
import SkuAudits from '@/components/reports/SkuAudits.vue'
import { TBox } from '@/components/ui/table'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: { SkuAudits, TBox },
  name: 'Skus',
  data() {
    return {
      load: false,
      pagination: {
        page: 1,
        sort_key: null,
        sort_type: null,
        is_available: 'available',
      },
      items: [],
      fields: [],
      summary: {},
      subCategories: [],
    }
  },
  async created() {
    await this.getItems()
  },
  watch: {
    page(val) {
      this.pagination.page = val
      this.getItems()
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['page', 'is_called', 'table_paginate', 'search']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
  },
  methods: {
    ...mapMutations('table', [
      'changeApply',
      'changeReset',
      'changeIsCalled',
      'updateSearch',
    ]),
    getItems() {
      const { sort_key, sort_type, is_active, is_available, page, ...others } =
        this.pagination
      const concatAvailability =
        is_available || this.table_paginate['is_available']
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'reports/sku_audits',
          options: {
            page: this.page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            // is_available: 1,
            is_available: concatAvailability
              ? concatAvailability
                ? 1
                : 0
              : null,
            ...others,
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.sku_audits
          this.items = data.map((e) => {
            return {
              ...e,
              visit: {
                ...e?.visit,
                company_store: e?.visit?.company_branch?.company_store ?? {},
              },
              user: e?.visit?.user || {},
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.summary = result?.summary || {}
          this.subCategories = result.sub_categories_skus || []
          this.fields = result?.export_fields || []
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeApply(false)
          this.changeReset(false)
          this.changeIsCalled(false)
        })
    },
  },
}
</script>
