export default async function hasAccess({ next, store, to, from }) {
  const { permissions, tasks } = store.state.auth
  const permission = to.meta.permission
  const mapTasks = tasks.map((e) => e.type)

  if (!permission) return next()

  if (/\w+_audits/g.test(permission)) {
    if (new RegExp(mapTasks.join('|'), 'g').test(permission)) {
      return next()
    }
  }

  if (new RegExp(permissions.join('|'), 'g').test(`show ${permission}`)) {
    return next()
  }

  return next({
    name: 'Error',
  })
}
