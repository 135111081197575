<template>
  <div>
    <div class="px-4 space-y-6" data-type="centered-item">
      <filter-wrapper v-if="false" :loaded="load">
        <div class="w-full">
          <multiselect
            v-model="pagination.region_id"
            :options="regions"
            :placeholder="$t('regions')"
            label="name"
            track-by="id"
            id="region_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="regions.length === 0"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="
                whitespace-normal
                text-slate-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.city_id"
            :options="
              !FilterData(
                'pagination',
                'cities',
                'region',
                'region_id',
                'city_id'
              ).length
                ? cities
                : FilterData(
                    'pagination',
                    'cities',
                    'region',
                    'region_id',
                    'city_id'
                  )
            "
            :placeholder="$t('cities')"
            label="name"
            track-by="id"
            id="city_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="!cities.length"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="
                whitespace-normal
                text-slate-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.company_store_id"
            :options="stores"
            :placeholder="$t('stores')"
            label="name"
            track-by="id"
            id="company_store_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="stores.length === 0"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="
                whitespace-normal
                text-slate-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full">
          <!-- FilterData(
              'pagination',
              'branches',
              'company_store',
              'company_store_id',
              'company_branch_id'
            ).length === 0 -->
          <multiselect
            v-model="pagination.company_branch_id"
            :options="
              !FilterData(
                'pagination',
                'branches',
                'company_store',
                'company_store_id',
                'company_branch_id'
              ).length
                ? branches
                : FilterData(
                    'pagination',
                    'branches',
                    'company_store',
                    'company_store_id',
                    'company_branch_id'
                  )
            "
            :placeholder="$t('branches')"
            label="name"
            track-by="id"
            id="company_branch_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="!branches.length"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="
                whitespace-normal
                text-slate-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>

        <div class="w-full">
          <multiselect
            v-model="pagination.client_id"
            :options="clients"
            :placeholder="$t('clients')"
            label="name"
            track-by="id"
            id="client_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="clients.length === 0"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="
                whitespace-normal
                text-slate-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full col-end-[-1]">
          <div class="actions-group justify-end">
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-secondary
                  text-white
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                :disabled="apply_disabled"
                @click.prevent="applyFilter"
              >
                <spinner size="w-4 h-4 inline-block" v-if="apply_disabled" />
                {{ $t('apply') }}
              </button>
            </div>
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-slate-300
                  text-slate-600
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                :disabled="reset_disabled"
                @click.prevent="resetFilter"
              >
                <spinner size="w-4 h-4 inline-block" v-if="reset_disabled" />
                {{ $t('reset') }}
              </button>
            </div>
          </div>
        </div>
      </filter-wrapper>
      <filter-wrapper v-if="false">
        <div
          class="w-full bg-slate-200 animate-pulse h-10 rounded"
          v-for="i in 10"
          :key="i"
        ></div>
        <div class="w-full col-end-[-1] flex gap-4 justify-end">
          <div class="w-2/5 bg-slate-200 animate-pulse h-10 rounded"></div>
          <div class="w-2/5 bg-slate-200 animate-pulse h-10 rounded"></div>
        </div>
      </filter-wrapper>
      <section class="w-full space-y-4" v-if="load">
        <figure
          class="w-full bg-white rounded py-4 ring-1 ring-slate-200 hidden"
          v-for="i in Math.ceil(items.length / 10)"
          :key="i"
        >
          <apexchart
            type="bar"
            height="480"
            :options="{
              ...chartOptions,
              ...globalOptions,

              xaxis: {
                labels: {
                  show: true,
                  showDuplicates: true,
                  trim: true,
                  style: { fontFamily: 'cairo', fontWeight: 500 },
                },
                type: 'category',

                categories: items
                  .filter((e) => e.item === i)
                  .map((e) => e.name),
                tickPlacement: 'on',
                position: 'bottom',
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
            }"
            v-if="load"
            :series="[
              {
                name: $t('available'),
                data: items
                  .filter((e) => e.item === i)
                  .map((e) => e.available_skus_count),
              },
              {
                name: $t('not_available'),
                data: items
                  .filter((e) => e.item === i)
                  .map((e) => e.not_available_skus_count),
              },
            ]"
          ></apexchart>
        </figure>
        <div
          class="grid gap-4 sm:grid-cols-[repeat(auto-fill,minmax(20rem,1fr))]"
        >
          <figure
            class="
              w-full
              bg-white
              rounded
              p-4
              ring-1 ring-slate-200
              relative
              overflow-hidden
            "
            v-for="(item, index) in items"
            :key="index"
            :data-item-id="item.id"
          >
            <apexchart
              type="donut"
              height="480"
              :options="{
                ...chartOptions,
                ...globalOptions,
                title: {
                  text: item['name'],
                  margin: 20,
                  offsetX: 0,
                  offsetY: -10,
                  floating: false,
                  style: {
                    fontSize: '18px',
                    fontFamily: 'Montserrat, Cairo',
                    fontWeight: 'bold',
                    color: '#263238',
                  },
                },
                labels: [$t('available'), $t('not_available')],
              }"
              v-if="load"
              :series="[
                item.available_skus_count,
                item.not_available_skus_count,
              ]"
            ></apexchart>
            <div
              class="
                absolute
                top-0
                right-0
                w-full
                h-full
                bg-slate-800/20
                backdrop-blur-sm
                flex
                items-center
                justify-center
                p-4
              "
              v-if="disables['fetch-category-id-' + item.id]"
            >
              <spinner size="w-8 h-8 inline-block" class="shrink-0" />
            </div>
          </figure>
        </div>
      </section>
      <section class="w-full space-y-4" v-else>
        <figure
          class="w-full bg-white rounded p-4 ring-1 ring-slate-200"
          v-for="i in 2"
          :key="i"
        >
          <figcaption
            class="w-full bg-slate-200 animate-pulse h-60 rounded"
          ></figcaption>
          <blockquote class="p-4">
            <ul
              class="
                list-none
                flex
                items-center
                justify-center
                max-w-3xl
                mx-auto
                gap-3
              "
            >
              <li
                class="flex-1 bg-slate-200 animate-pulse rounded h-4"
                v-for="id in 6"
                :key="id"
              ></li>
            </ul>
          </blockquote>
        </figure>
      </section>
      <section class="w-full space-y-4" v-if="load && !items.length">
        <empty-card
          :title="$t('no_data')"
          icon="fa-solid fa-chart-column fa-xl"
        />
      </section>
    </div>
    <Modal
      :title="$t('skus')"
      v-if="skus_active"
      size="!max-w-screen-lg"
      @close="
        () => {
          skus_active = $event
          skus = []
        }
      "
    >
      <template>
        <div class="px-6 py-2">
          <Table
            :has_search="false"
            :title="$t('skus')"
            :has_paginate="false"
            :has_import="false"
            :has_export="false"
            :has_customize="false"
            :has_check="false"
          >
            <template #head>
              <th>SKU</th>
              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('barcode') }}</p>
                  </div>
                </div>
              </th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('selling_price') }}</p>
                  </div>
                </div>
              </th>
            </template>

            <template #tbody v-if="skus.length !== 0">
              <tr v-for="(item, index) in skus" :key="index">
                <td>
                  <div class="actions-group !flex-no-wrap">
                    <div>
                      <div class="w-12 h-auto">
                        <img
                          :src="
                            item.image ||
                            require('@/assets/images/placeholder.png')
                          "
                          :alt="`${item.name} image`"
                          @error="
                            (e) =>
                              (e.target.src = require('@/assets/images/placeholder.png'))
                          "
                          class="w-full max-h-full object-contain"
                        />
                      </div>
                    </div>
                    <div class="flex-1 whitespace-nowrap">
                      <p>{{ item.name }}</p>
                      <p>{{ item.alt_name }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  {{ item.barcode || '...' }}
                </td>

                <td>
                  {{ item.selling_price + ' SAR' || 'N/A' }}
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="skus.length === 0">
              <tr>
                <td :colspan="3">
                  <p
                    class="
                      text-sm text-slate-600
                      font-medium
                      rtl:font-semibold
                      text-center
                    "
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import EmptyCard from '@/components/basics/EmptyCard.vue'
import { mapGetters } from 'vuex'
import Modal from '@/components/basics/Modal.vue'
import Table from '@/components/basics/Table.vue'
export default {
  components: { FilterWrapper, EmptyCard, Modal, Table },
  name: 'OsaReports',
  data() {
    return {
      globalOptions: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat, Cairo',
            fontWeight: 'bold',
          },

          // formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          //   return w.config.series[seriesIndex].data[dataPointIndex]
          // },
        },
        tooltip: {
          y: {
            formatter: function (val, { w }) {
              return val
            },
          },

          theme: 'dark',
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat, Cairo',
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
        },
      },
      series: [
        {
          name: 'available',
          data: [],
        },
        {
          name: 'not_available',
          data: [],
        },
      ],
      // chartOptions: {
      //   chart: {
      //     type: 'bar',
      //     height: 350,
      //     stacked: true,
      //     toolbar: {
      //       show: false,
      //     },
      //   },
      //   plotOptions: {
      //     bar: {
      //       borderRadius: 10,

      //       dataLabels: {
      //         position: 'top', // top, center, bottom
      //       },
      //     },
      //   },
      //   dataLabels: {
      //     enabled: true,
      //     formatter: function (val) {
      //       return val + '%'
      //     },
      //     offsetY: -20,
      //     style: {
      //       fontSize: '12px',
      //       colors: ['#304758'],
      //     },
      //   },
      //   stroke: {
      //     show: false,
      //   },

      //   yaxis: {
      //     axisBorder: {
      //       show: false,
      //     },
      //     axisTicks: {
      //       show: false,
      //     },
      //     labels: {
      //       show: false,
      //       formatter: function (val) {
      //         return val + '%'
      //       },
      //     },
      //   },
      //   fill: {
      //     opacity: 1,
      //   },

      //   grid: {
      //     show: true,
      //     padding: {
      //       right: 0,
      //       left: 0,
      //       top: 0,
      //       bottom: 0,
      //     },
      //     borderColor: '#ced4da',
      //     strokeDashArray: 0,
      //     position: 'back',
      //     xaxis: {
      //       lines: {
      //         show: true,
      //       },
      //     },
      //     yaxis: {
      //       lines: {
      //         show: true,
      //       },
      //     },
      //   },

      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: '100%',
      //         },
      //         xaxis: {
      //           labels: {
      //             rotate: -90,
      //           },
      //         },

      //         yaxis: {
      //           labels: {
      //             show: false,
      //           },
      //         },
      //       },
      //     },
      //   ],
      //   theme: {
      //     palette: 'palette8',
      //   },
      // },
      chartOptions: {
        chart: {
          width: '100%',
          type: 'donut',
          events: {
            dataPointSelection: (event, { el }, { dataPointIndex }) => {
              const type = dataPointIndex === 0 ? 'available' : 'not_available'
              const id = el.parentElement?.dataset?.itemId
              this.$set(this.disables, 'fetch-category-id-' + id, true)
              this.getSelectedItemSKUs(type, id)
            },
          },
        },
        labels: [],
        legend: {
          // show: false,
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '12px',
          fontFamily: 'Montserrat, Cairo',
          fontWeight: 'bold',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
            },
          },
        ],
      },
      pagination: {
        region_id: [],
        city_id: [],
        company_store_id: [],
        company_branch_id: [],
        sku_id: [],
        client_id: [],
      },
      items: [],
      load: false,
      disables: {},
      skus: [],
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      apply_disabled: false,
      reset_disabled: false,
      skus_active: false,
    }
  },
  computed: {
    ...mapGetters('filters', [
      'stores',
      'branches',
      'clients',
      'regions',
      'cities',
    ]),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    columns() {
      const data =
        this.getSelectedColumns('skus') || this.getBasicColumn('skus')
      return data
    },
    FilterData() {
      return (object, data, key, parentSelectedData, origin) => {
        const selectedIds =
          this[object][parentSelectedData]?.map((e) => e.id) || []
        const filterData = this[data]?.filter((e) =>
          selectedIds.includes(e?.[key]?.id)
        )
        return filterData
      }
    },
  },
  async created() {
    Promise.all([
      //  this.getRegions(),
      // this.getSkus(),
      //  this.getCities(),
      //  this.getBranches(),
      //  this.getStores(),
      //  this.getClients(),
      this.getItems(),
    ]).finally(() => {
      this.load = true
    })
  },
  methods: {
    async getItems() {
      let paginate = {}
      for (const key in this.pagination) {
        paginate[key] = this.pagination[key]?.map((e) => e.id)
      }
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'osa_report',
          options: {
            ...paginate,
          },
        })
        const { osa } = result

        this.items = osa.map((e, i) => {
          return {
            ...e,
            item: !i ? 1 : Math.ceil(i / 10),
          }
        })
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      }
    },
    async getSelectedItemSKUs(type, id) {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'osa_report/sub_category_' + type + '_skus/' + id
        )
        this.skus = result.skus
        this.skus_active = true
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.disables = {}
      }
    },

    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination).forEach((key) =>
        this.$set(this.pagination, key, [])
      )
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
  },
}
</script>
