<template>
  <div>
    <section class="w-full space-y-6">
      <filter-box
        class="px-6"
        has-main-categories
        :has-stores="false"
        :has-branches="false"
      >
        <div class="w-full">
          <multiselect
            v-model="pagination.brand_id"
            :options="brands"
            :placeholder="$t('brand')"
            label="name"
            track-by="id"
            id="brand_id"
            :hideSelected="true"
            :clearOnSelect="true"
            style="background-color: #ffffff !important"
            :disabled="brands.length === 0"
          ></multiselect>
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.unit_id"
            :options="units"
            :placeholder="$t('units')"
            label="name"
            track-by="id"
            id="unit_id"
            :hideSelected="true"
            :clearOnSelect="true"
            style="background-color: #ffffff !important"
            :disabled="units.length === 0"
          ></multiselect>
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.company_store_id"
            :options="stores"
            :placeholder="$t('listed_stores')"
            label="name"
            track-by="id"
            id="listed_stores"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="stores.length === 0"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.company_branch_id"
            :options="filterBranches"
            :placeholder="$t('listed_branches')"
            label="name"
            track-by="id"
            id="listed_branches"
            :hideSelected="true"
            :clearOnSelect="true"
            :multiple="true"
            :closeOnSelect="false"
            :disabled="filterBranches.length === 0"
            style="background-color: #ffffff !important"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            ><span
              slot="noResult"
              class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>
        <div class="w-full">
          <t-datepicker
            :placeholder="$t('production_date')"
            v-model="pagination.prod_date"
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
        <div class="w-full">
          <t-datepicker
            :placeholder="$t('expiry_date')"
            v-model="add_data.expiry_date"
            :minDate="add_data.production_date"
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-box>
      <main class="w-full px-6">
        <Table
          :has_header="true"
          :has_check="!!items.length"
          :title="$t('skus')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :fields="fields"
          :has_import="true"
          ref="skuTable"
          @changed="checkAll"
        >
          <template #actions>
            <div>
              <router-link
                to="/setup/skus/create"
                class="py-1.5 px-3 rounded text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('add_new.sku') }}</span>
              </router-link>
            </div>
          </template>
          <template #more v-if="(add_data.sku_ids || []).length">
            <div>
              <t-dropdown
                :classes="{
                  dropdown:
                    '!origin-top-right rtl:!origin-top-left !max-w-[13rem] !bottom-auto !top-full !right-4 rtl:!right-auto rtl:!left-4',
                }"
              >
                <div
                  slot="trigger"
                  slot-scope="{
                    mousedownHandler,
                    focusHandler,
                    blurHandler,
                    keydownHandler,
                  }"
                >
                  <button
                    class="flex items-center justify-center text-slate-600 hover:bg-slate-100 font-medium rtl:font-semibold text-sm"
                    @mousedown="mousedownHandler"
                    @focus="focusHandler"
                    @blur="blurHandler"
                    @keydown="keydownHandler"
                    :class="
                      (add_data.sku_ids || []).length
                        ? 'py-1.5 px-3 rounded gap-2'
                        : 'h-8 w-8 rounded-full'
                    "
                  >
                    <i
                      class="fa-solid fa-ellipsis-vertical fa-sm align-middle"
                    ></i>
                    <span v-if="(add_data.sku_ids || []).length"
                      >{{ $t('selected') }} ({{
                        (add_data.sku_ids || []).length
                      }})</span
                    >
                  </button>
                </div>
                <div slot-scope="{ hide }">
                  <div
                    class="border border-slate-200 rounded shadow-xl shadow-slate-600/10 bg-white p-4 z-20"
                  >
                    <ul class="list-none space-y-3">
                      <li v-if="false">
                        <button
                          class="w-full flex items-center gap-3 text-sky-500"
                          @click.prevent="changeStatus()"
                          :disabled="disables[`status_all`]"
                        >
                          <span>
                            <spinner
                              size="w-4 h-4 inline-block"
                              v-if="disables[`status_all`]"
                            />
                            <i class="fa-solid fa-right-left" v-else></i>
                          </span>
                          <p
                            class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                          >
                            {{ $t('change_status') }}
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          class="w-full flex items-center gap-3 text-red-500"
                          @click.prevent="deleteItem()"
                          :disabled="disables[`delete_all`]"
                        >
                          <span>
                            <spinner
                              size="w-4 h-4 inline-block"
                              v-if="disables[`delete_all`]"
                            />
                            <i class="fa-solid fa-trash" v-else></i>
                          </span>
                          <p
                            class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                          >
                            {{ $t('delete') }}
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          class="w-full flex items-center gap-3 text-slate-500"
                          @click.prevent="
                            () => {
                              showAssignUnits()
                              hide()
                            }
                          "
                        >
                          <span>
                            <i class="fa-solid fa-dolly"></i>
                          </span>
                          <p
                            class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                          >
                            {{ $t('assign_units') }}
                          </p>
                        </button>
                      </li>
                      <li>
                        <button
                          class="w-full flex items-center gap-3 text-slate-500"
                          @click.prevent="
                            () => {
                              showAssignBranches()
                              hide()
                            }
                          "
                        >
                          <span>
                            <i class="fa-solid fa-reply-all"></i>
                          </span>
                          <p
                            class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                          >
                            {{ $t('assign_branches') }}
                          </p>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </t-dropdown>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th v-if="columns.includes('sku')">SKU</th>
            <th v-if="columns.includes('barcode')">
              <div class="sort">
                <div>
                  <p>{{ $t('barcode') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('barcode')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('categories')">
              <div class="sort">
                <div>
                  <p>{{ $t('categories') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('categories')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('sub_categories')">
              <div class="sort">
                <div>
                  <p>{{ $t('sub_categories') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('sub_categories')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('store_placements')">
              <div class="sort">
                <div>
                  <p>{{ $t('store_placements') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('store_placements')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('brand')">
              <div class="sort">
                <div>
                  <p>{{ $t('brand') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('brand_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('selling_price')">
              <div class="sort">
                <div>
                  <p>{{ $t('selling_price') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('selling_price')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('status')">
              <div class="sort">
                <div>
                  <p>{{ $t('status') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('updated_at')">
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('actions')"></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>

          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div class="form-group-with-check">
                  <div>
                    <input
                      type="checkbox"
                      :name="item.id"
                      :id="item.id"
                      :value="item.id"
                      v-model="add_data.sku_ids"
                    />
                  </div>
                </div>
              </td>
              <td v-if="columns.includes('sku')">
                <div class="actions-group !flex-no-wrap">
                  <div>
                    <div class="w-12 h-auto">
                      <img
                        :src="
                          item.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        :alt="`${item.name} image`"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                  <div class="flex-1 whitespace-nowrap">
                    <p>{{ item.name }}</p>
                    <p>{{ item.alt_name }}</p>
                  </div>
                </div>
              </td>
              <td v-if="columns.includes('barcode')">
                {{ item.barcode || '...' }}
              </td>
              <td v-if="columns.includes('categories')">
                <div class="actions-group">
                  <div
                    class="actions-group"
                    v-html="
                      item.categories
                        ? item.categories.length > 2
                          ? item.categories
                              .slice(0, 2)
                              .map(
                                (e) => `<span class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                          : item.categories
                              .map(
                                (e) => `<span
                    class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                        : '-'
                    "
                  ></div>
                  <span
                    class="status bg-slate-100"
                    v-if="item.categories.length > 2"
                    >+{{ item.categories.length - 2 }}</span
                  >
                  <span
                    class="status bg-slate-100"
                    v-if="!item.categories.length"
                    >-</span
                  >
                </div>
              </td>
              <td v-if="columns.includes('sub_categories')">
                <div class="actions-group">
                  <div
                    class="actions-group"
                    v-html="
                      item.sub_categories
                        ? item.sub_categories.length > 2
                          ? item.sub_categories
                              .slice(0, 2)
                              .map(
                                (e) => `<span
                    class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                          : item.sub_categories
                              .map(
                                (e) => `<span
                    class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                        : '-'
                    "
                  ></div>
                  <span
                    class="status bg-slate-100"
                    v-if="item.sub_categories.length > 2"
                    >+{{ item.sub_categories.length - 2 }}</span
                  >
                  <span
                    class="status bg-slate-100"
                    v-if="!item.sub_categories.length"
                    >-</span
                  >
                </div>
              </td>
              <td v-if="columns.includes('store_placements')">
                <div class="actions-group">
                  <div
                    class="actions-group"
                    v-html="
                      item.store_placements
                        ? item.store_placements.length > 2
                          ? item.store_placements
                              .slice(0, 2)
                              .map(
                                (e) => `<span
                    class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                          : item.store_placements
                              .map(
                                (e) => `<span
                    class='status bg-slate-100'
                    >${e.name}</span
                  >`
                              )
                              .join('')
                        : '-'
                    "
                  ></div>
                  <span
                    class="status bg-slate-100"
                    v-if="item.store_placements.length > 2"
                    >+{{ item.store_placements.length - 2 }}</span
                  >
                  <span
                    class="status bg-slate-100"
                    v-if="!item.store_placements.length"
                    >-</span
                  >
                </div>
              </td>
              <td v-if="columns.includes('brand')">
                {{ (item.brand || {}).name || '...' }}
              </td>

              <td v-if="columns.includes('selling_price')">
                {{ item.selling_price + ' SAR' || 'N/A' }}
              </td>
              <td v-if="columns.includes('status')">
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>

              <td
                v-if="columns.includes('updated_at')"
                v-html="createTime(item.updated_at || item.created_at)"
              ></td>
              <td
                class="table-drop-item"
                :style="`--z: ${indexes[index]}`"
                v-if="columns.includes('actions')"
              >
                <div class="relative" v-if="false">
                  <button
                    class="w-8 h-8 rounded-full flex items-center justify-center text-slate-600 hover:bg-slate-100"
                    @click.prevent="
                      $set(
                        disables,
                        `dropdown_${index}`,
                        !disables[`dropdown_${index}`]
                      )
                    "
                  >
                    <i
                      class="fa-solid fa-ellipsis-vertical fa-sm align-middle"
                    ></i>
                  </button>
                  <slide-transition>
                    <div
                      class="absolute top-[calc(100%+0.5rem)] ring-1 ring-slate-200 rounded shadow-xl shadow-slate-600/10 bg-white p-4 right-0 rtl:left-0 rtl:right-auto z-20"
                      v-if="disables[`dropdown_${index}`]"
                    >
                      <ul class="list-none space-y-3">
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-sky-500"
                            @click.prevent="changeStatus(item.id)"
                            :disabled="disables[`status_${item.id}`]"
                          >
                            <span>
                              <spinner
                                size="w-4 h-4 inline-block"
                                v-if="disables[`status_${item.id}`]"
                              />
                              <i class="fa-solid fa-right-left" v-else></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('change_status') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-red-500"
                            @click.prevent="deleteItem(item.id)"
                            :disabled="disables[`delete_${item.id}`]"
                          >
                            <span>
                              <spinner
                                size="w-4 h-4 inline-block"
                                v-if="disables[`delete_${item.id}`]"
                              />
                              <i class="fa-solid fa-trash" v-else></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('delete') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="showAssignUnits(item.id)"
                          >
                            <span>
                              <i class="fa-solid fa-dolly"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('assign_units') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="showAssignBranches(item.id)"
                          >
                            <span>
                              <i class="fa-solid fa-reply-all"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('assign_branches') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="showListedBranches(item.id)"
                          >
                            <span>
                              <i class="fa-solid fa-list-check"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('listed') }}
                            </p>
                          </button>
                        </li>

                        <li>
                          <router-link
                            class="w-full flex items-center gap-3 text-slate-500"
                            :to="`/setup/skus/${item.id}`"
                          >
                            <span>
                              <i class="fa-solid fa-pen fa-sm"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('view_update') }}
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </slide-transition>
                </div>
                <t-dropdown
                  :classes="{
                    dropdown:
                      '!origin-top-right rtl:!origin-top-left !max-w-[13rem] !bottom-auto !top-full !right-4 rtl:!right-auto rtl:!left-4',
                  }"
                >
                  <div
                    slot="trigger"
                    slot-scope="{
                      mousedownHandler,
                      focusHandler,
                      blurHandler,
                      keydownHandler,
                    }"
                  >
                    <button
                      class="w-8 h-8 rounded-full flex items-center justify-center text-slate-600 hover:bg-slate-100"
                      @mousedown="mousedownHandler"
                      @focus="focusHandler"
                      @blur="blurHandler"
                      @keydown="keydownHandler"
                    >
                      <i
                        class="fa-solid fa-ellipsis-vertical fa-sm align-middle"
                      ></i>
                    </button>
                  </div>
                  <div slot-scope="{ hide }">
                    <div
                      class="border border-slate-200 rounded shadow-xl shadow-slate-600/10 bg-white p-4 z-20"
                    >
                      <ul class="list-none space-y-3">
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-sky-500"
                            @click.prevent="changeStatus(item.id)"
                            :disabled="disables[`status_${item.id}`]"
                          >
                            <span>
                              <spinner
                                size="w-4 h-4 inline-block"
                                v-if="disables[`status_${item.id}`]"
                              />
                              <i class="fa-solid fa-right-left" v-else></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('change_status') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-red-500"
                            @click.prevent="deleteItem(item.id)"
                            :disabled="disables[`delete_${item.id}`]"
                          >
                            <span>
                              <spinner
                                size="w-4 h-4 inline-block"
                                v-if="disables[`delete_${item.id}`]"
                              />
                              <i class="fa-solid fa-trash" v-else></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('delete') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="
                              () => {
                                showAssignUnits(item.id)
                                hide()
                              }
                            "
                          >
                            <span>
                              <i class="fa-solid fa-dolly"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('assign_units') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="
                              () => {
                                showAssignBranches(item.id)
                                hide()
                              }
                            "
                          >
                            <span>
                              <i class="fa-solid fa-reply-all"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('assign_branches') }}
                            </p>
                          </button>
                        </li>
                        <li>
                          <button
                            class="w-full flex items-center gap-3 text-slate-500"
                            @click.prevent="
                              () => {
                                showListedBranches(item.id)
                                hide()
                              }
                            "
                          >
                            <span>
                              <i class="fa-solid fa-list-check"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('listed') }}
                            </p>
                          </button>
                        </li>

                        <li>
                          <router-link
                            class="w-full flex items-center gap-3 text-slate-500"
                            :to="`/setup/skus/${item.id}`"
                          >
                            <span>
                              <i class="fa-solid fa-pen fa-sm"></i>
                            </span>
                            <p
                              class="font-medium rtl:font-semibold text-sm whitespace-nowrap"
                            >
                              {{ $t('view_update') }}
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </t-dropdown>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="columns.length">
                <div class="table mx-auto my-4">
                  <router-link
                    to="/setup/skus/create"
                    class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.sku') }}</span>
                  </router-link>
                </div>
                <p
                  class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('assign_branches')"
        v-if="branches_active"
        @close="
          (e) => {
            branches_active = e
            add_data = {
              region_id: null,
              city_id: null,
              zone_id: null,
              company_branch_ids: [],
              sku_id: null,
              ...this.add_data,
            }
            add_errors = {}
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="w-full space-y-2">
              <label
                for="zone"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('zone') }}</label
              >
              <multiselect
                v-model="add_data.zone_id"
                :options="zones"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="zone_id"
                :hideSelected="true"
                :clearOnSelect="true"
                :disabled="zones.length === 0"
              ></multiselect>

              <div>
                <p
                  class="text-slate-500 font-medium rtl:font-semibold text-xs"
                  ref="zone"
                >
                  {{ GetBranchesCount('zone_id', 'zone') }}
                </p>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.zone_id"
              >
                {{ add_errors.zone_id.join(' ') }}
              </p>
            </div>
            <div class="w-full space-y-2">
              <label
                for="region"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('region') }}</label
              >
              <multiselect
                v-model="add_data.region_id"
                :options="filterRegions"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="region_id"
                :hideSelected="true"
                :disabled="filterRegions.length === 0"
              ></multiselect>
              <div>
                <p
                  class="text-slate-500 font-medium rtl:font-semibold text-xs"
                  ref="region"
                >
                  {{ GetBranchesCount('region_id', 'region') }}
                </p>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.region_id"
              >
                {{ add_errors.region_id.join(' ') }}
              </p>
            </div>
            <div class="w-full space-y-2">
              <label
                for="city"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('city') }}</label
              >
              <multiselect
                v-model="add_data.city_id"
                :options="filterCities"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="city_id"
                :hideSelected="true"
                :disabled="filterCities.length === 0"
              ></multiselect>
              <div>
                <p
                  class="text-slate-500 font-medium rtl:font-semibold text-xs"
                  ref="city"
                >
                  {{ GetBranchesCount('city_id', 'city') }}
                </p>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.city_id"
              >
                {{ add_errors.city_id.join(' ') }}
              </p>
            </div>
            <div class="w-full space-y-2">
              <label
                for="user"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('store') }}</label
              >
              <multiselect
                v-model="add_data.company_store_id"
                :options="stores"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="company_store_id"
                :hideSelected="true"
                :clearOnSelect="true"
                :closeOnSelect="true"
                :disabled="stores.length === 0"
              ></multiselect>
              <div>
                <p
                  class="text-slate-500 font-medium rtl:font-semibold text-xs"
                  ref="store"
                >
                  {{ GetBranchesCount('company_store_id', 'store') }}
                </p>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.company_store_id"
              >
                {{ add_errors.company_store_id.join(' ') }}
              </p>
            </div>
            <div class="w-full space-y-2">
              <label
                for="user"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('branches') }}</label
              >
              <multiselect
                v-model="add_data.company_branch_ids"
                :options="filterAssignBranches"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="company_branch_ids"
                :hideSelected="true"
                :clearOnSelect="true"
                :multiple="true"
                :closeOnSelect="false"
                :disabled="filterAssignBranches.length === 0"
              ></multiselect>
              <slide-transition>
                <button
                  class="shrink-0 py-1.5 px-2 text-center text-xs text-slate-600 font-medium bg-slate-200 rounded [margin-inline-start:auto]"
                  type="button"
                  @click.prevent="handleSelectAll"
                  v-if="filterAssignBranches.length"
                >
                  {{ $t('select_all') }} ({{ filterAssignBranches.length }})
                </button></slide-transition
              >
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.company_branch_ids"
              >
                {{ add_errors.company_branch_ids.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="assignBranches"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('assign_units')"
        v-if="units_active"
        @close="
          (e) => {
            units_active = e
            add_data = {
              ...add_data,
              units: [{ id: null, qty: null }],
              id: null,
            }
            add_errors = {}
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <p class="font-medium rtl:font-semibold text-sm text-slate-600">
              {{ $t('units') }}
            </p>
            <div
              class="actions-group !gap-4 !items-start"
              v-for="(item, index) in add_data.units"
              :key="index"
            >
              <div class="w-full space-y-2 flex-[1_1_120px]">
                <multiselect
                  v-model="item.id"
                  :options="filterUnits"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="units"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :show-no-results="true"
                >
                  <span
                    slot="noResult"
                    class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
                    >{{ $t('all_selected') }}</span
                  >
                </multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors[`units.${index}.id`]"
                >
                  {{ add_errors[`units.${index}.id`].join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2 flex-[1_1_120px] form-group">
                <input
                  autocomplete="off"
                  type="number"
                  :name="`unit_qty_${index}`"
                  :id="`unit_qty_${index}`"
                  :placeholder="$t('qty')"
                  v-model="item.qty"
                  min="1"
                />

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors[`units.${index}.qty`]"
                >
                  {{ add_errors[`units.${index}.qty`].join(' ') }}
                </p>
              </div>
              <div class="w-8 self-center" v-if="index !== 0">
                <button
                  class="p-1 rounded-md text-sm font-medium rtl:font-semibold bg-transparent text-red-500"
                  @click.prevent="() => add_data.units.splice(index, 1)"
                  :content="$t('delete')"
                  v-tippy
                >
                  <i class="fa-solid fa-trash fa-sm"></i>
                </button>
              </div>
            </div>
            <div class="table ml-auto">
              <button
                class="p-1 rounded-md text-sm font-medium rtl:font-semibold bg-transparent text-primary"
                @click.prevent="addNewUnit"
                :disabled="filterUnits.length === 0"
              >
                <i class="fa-solid fa-plus fa-sm"></i>
                {{ $t('add_new.new') }}
              </button>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="assignUnits"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('listed')"
        v-if="assign_active"
        @close="
          (e) => {
            assign_active = e
            assign_branches = {}
          }
        "
      >
        <template>
          <div class="w-full space-y-4 p-4">
            <!-- <header class="w-full inline-flex gap-4 flex-wrap">
              <div class="flex-[1_1_max-content] inline-flex gap-3">
                <span>
                  <i class="fa-solid fa-square-check text-teal-600"></i>
                </span>
                <div class="flex-2 self-center text-center">
                  <p
                    class="text-sm text-teal-600 font-medium rtl:font-semibold"
                  >
                    {{ $t('_listed') }}
                  </p>
                </div>
              </div>
              <div class="flex-[1_1_max-content] inline-flex gap-3">
                <span>
                  <i class="fa-solid fa-square-check text-slate-200"></i>
                </span>
                <div class="flex-2 self-center text-center">
                  <p
                    class="text-sm text-slate-600 font-medium rtl:font-semibold"
                  >
                    {{ $t('_not_listed') }}
                  </p>
                </div>
              </div>
            </header> -->
            <header class="overflow-x-auto">
              <div class="actions-group !flex-no-wrap !gap-4">
                <div>
                  <div class="w-12 h-auto">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      :alt="`${item.name} image`"
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      class="w-full max-h-full object-contain"
                    />
                  </div>
                </div>
                <div class="flex-1 whitespace-nowrap">
                  <p class="font-semibold rtl:font-bold text-slate-800">
                    {{ item.name || '-' }}
                  </p>
                  <p
                    class="font-medium rtl:font-semibold text-sm text-slate-600"
                  >
                    {{ item.barcode || '-' }}
                  </p>
                </div>
              </div>
            </header>
            <div class="py-3 border-y border-y-slate-200 space-y-2">
              <multiselect
                v-model="assign_store_id"
                :options="stores"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="units"
                :multiple="true"
                :hideSelected="true"
                :closeOnSelect="false"
                :show-no-results="true"
              >
                <span
                  slot="noResult"
                  class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
                  >{{ $t('all_selected') }}</span
                >
              </multiselect>
              <button
                class="text-sm text-slate-600 font-medium rtl:font-semibold"
                v-tippy
                :content="$t('delete')"
                v-if="
                  filterListedBranches.filter((e) => e.is_assigned).length ||
                  unlist_disabled
                "
                @click.prevent="unlistedAllBranches"
                :disabled="unlist_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="unlist_disabled" />
                {{ $t('not_list_all') }} ({{
                  filterListedBranches.filter((e) => e.is_assigned).length
                }})
              </button>
            </div>
            <ul
              class="w-full list-none overflow-y-auto max-h-[35rem] divide-y divide-y-slate-200"
            >
              <li v-if="!filterListedBranches.length">
                <p
                  class="text-sm text-slate-800 font-semibold rtl:font-bold line-clamp-2"
                >
                  {{ $t('no_data') }}
                </p>
              </li>
              <li
                class="flex items-center gap-4 p-2"
                v-for="(item, index) in filterListedBranches"
                :key="index"
                v-else
              >
                <div>
                  <i
                    class="fa-solid fa-lg"
                    :class="{
                      'fa-square-check text-teal-600': item.is_assigned,
                      'fa-square text-slate-200': !item.is_assigned,
                    }"
                  ></i>
                </div>
                <div class="flex-1 space-y-0.5">
                  <p
                    class="text-sm text-slate-800 font-semibold rtl:font-bold line-clamp-2"
                  >
                    {{ item.name || '...' }}
                  </p>
                  <p
                    class="text-sm text-slate-600 font-medium rtl:font-semibold line-clamp-1"
                  >
                    {{ (item.company_store || {}).name || '...' }}
                  </p>
                </div>
                <div v-if="item.is_assigned">
                  <button
                    class="py-1 px-2 rounded-md text-xs font-medium rtl:font-semibold text-red-500 bg-red-500/20"
                    @click.prevent="unlistedBranch(item.id)"
                  >
                    {{ $t('delete') }}
                  </button>
                </div>
              </li>
            </ul>
            <div v-if="filterListedBranches.some((e) => e.is_assigned)">
              <button
                class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                @click.prevent="updateAssigned"
                :disabled="update_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="update_disabled" />
                {{ $t('save_changes') }}
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import Modal from '@/components/basics/Modal.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import { mapGetters, mapMutations } from 'vuex'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import FilterBox from '@/components/basics/FilterBox.vue'

export default {
  name: 'SKUs',
  data() {
    return {
      load: false,
      categories: [],
      sub_categories: [],
      brands: [],
      store_placements: [],
      branches: [],
      stores: [],
      units: [],
      items: [],
      add_errors: {},
      add_disabled: false,
      units_active: false,
      branches_active: false,
      add_data: {
        units: [
          {
            id: null,
            qty: null,
          },
        ],
        company_branch_ids: [],
        sku_id: null,
        sku_ids: [],
      },
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        brand_id: null,
        unit_id: null,
        prod_date: null,
        exp_date: null,
        company_store_id: null,
        company_branch_id: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      assign_branches: {
        branches: [],
        id: null,
      },
      assign_active: false,
      indexes: [],
      fields: [],
      assign_store_id: [],
      item: {},
      update_errors: {},
      update_disabled: false,
      unlist_disabled: false,
    }
  },
  async created() {
    await Promise.all([
      this.getBrands(),
      this.getBranches(),
      this.getStores(),
      this.getUnits(),
      this.getItems(),
    ])
  },

  watch: {
    disables() {
      const lastItem = Object.keys(this.disables)
        .filter((e) => /dropdown_\d/gi.test(e))
        .at(-1)
      Object.keys(this.disables).forEach((key) => {
        if (key !== lastItem && /dropdown_\d/gi.test(key)) {
          // Delete all items except the last one
          delete this.disables[key]
        }
      })
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
    'pagination.page': {
      immediate: true,
      handler() {
        this.getItems()
      },
    },
    'add_data.zone_id'() {
      this.add_data.region_id = null
      this.add_data.city_id = null
    },
    'add_data.sku_ids'(val) {
      if (val) {
        const allSKUs = this.items.every((e) => val.includes(e?.id))
        if (!allSKUs) {
          this.$refs.skuTable.$refs.checkAll.checked = false
        } else {
          this.$refs.skuTable.$refs.checkAll.checked = true
        }
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search', 'table_paginate']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    ...mapGetters('filters', ['regions', 'cities', 'zones']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },

    filterUnits() {
      let ids = this.add_data.units.map((e) => e?.id?.id).filter((e) => e)
      return this.units.filter((e) =>
        ids.length !== 0 ? !ids.includes(e?.id) : e
      )
    },

    filterRegions() {
      this.$set(this.add_data, 'region_id', null)
      const selectedRegions = [...new Set(this.add_data.zone_id?.regions)]
      if (!selectedRegions.length) return this.regions
      return this.regions.filter((e) => selectedRegions.includes(e.id))
    },
    filterCities() {
      // this.add_data.city_id = null
      this.$set(this.add_data, 'city_id', null)
      const selectedRegion = this.add_data.region_id
      if (!selectedRegion) return this.cities
      return this.cities.filter((e) => selectedRegion?.id === e?.region?.id)
    },
    filterBranches() {
      const storeIds = this.pagination?.company_store_id?.map((e) => e?.id)
      if (!storeIds?.length) return this.branches

      return (
        this.branches.filter((e) => storeIds.includes(e?.company_store?.id)) ||
        this.branches
      )
    },
    filterAssignBranches() {
      this.$set(this.add_data, 'company_branch_ids', [])
      const storeId = this.add_data.company_store_id
      if (!storeId) return this.branches
      return this.branches.filter((e) => e.company_store?.id === storeId?.id)
    },
    filterListedBranches() {
      const storeIds = this.assign_store_id
      const assignedBranches = this.assign_branches.branches
      if (!storeIds.length) return assignedBranches
      const mapIds = storeIds.map((e) => e.id)
      return (
        assignedBranches.filter((e) => mapIds.includes(e.company_store?.id)) ||
        assignedBranches
      )
    },
    GetBranchesCount() {
      return (key, parent) => {
        const value = this.add_data[key]
        if (value) {
          ;(async () => {
            const { result } = await this.$store.dispatch('getting/getRoute', {
              name: 'company_branches',

              options: {
                [key]: value?.id,
              },
            })

            const count = result.company_branches.length
            const element = this.$refs[parent]
            if (element)
              element.innerHTML = this.$t('selected_branches').replace(
                /_COUNT_/g,
                count
              )
          })()
        }
      }
    },
  },

  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getUnits() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'units',
          options: {
            unit_type: 'sku',
          },
        })
        this.units = result.units
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },

    async getBranches() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        this.branches = result.company_branches
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },

    async getStores() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_stores'
        )
        this.stores = result.company_stores
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getBrands() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'brands'
        )
        this.brands = result.brands
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },

    getItems() {
      const {
        page,
        sort_key,
        sort_type,
        is_active,
        brand_id,
        branch_id,
        prod_date,
        exp_date,
        category_id,
        sub_category_id,
        store_placement_id,
        company_branch_id,
        company_store_id,
      } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'skus',
          options: {
     
            page,
            sort_key,
            sort_type,
            brand_id: brand_id?.id,
            branch_id: branch_id?.id,
            prod_date,
            exp_date,
            category_id: category_id?.map((e) => e.id),
            // company_branch_id: company_branch_id?.map((e) => e.id),
            // company_store_id: company_store_id?.map((e) => e.id),
            sub_category_id: sub_category_id?.map((e) => e.id),
            store_placement_id: store_placement_id?.map((e) => e.id),
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            ...this.table_paginate,
            search_key: this.search,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.skus
          this.items = data
          this.indexes = Array(data.length)
            .fill()
            .map((_, i) => i + 1)
            .sort(() => -1)
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.fields = result.export_fields
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },

    async changeStatus(id) {
      this.$set(this.disables, id ? `status_${id}` : 'status_all', true)
      try {
        const { data } = await this.axios.post(
          id ? `skus/update_is_active/${id}` : 'skus/update_is_active',
          id
            ? null
            : {
                sku_ids: this.add_data.sku_ids,
              }
        )
        this.createAlert(data.message)

        this.getItems()
        const skuIds = this.add_data.sku_ids
        if (skuIds.length && id) {
          this.$set(
            this.add_data,
            'sku_ids',
            skuIds.filter((e) => e !== id)
          )
        } else {
          this.add_data.sku_ids = []
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, id ? `delete_${id}` : 'delete_all', true)
      try {
        const { data } = await this.axios.post(
          id ? `skus/delete/${id}` : 'skus/delete',
          id
            ? null
            : {
                sku_ids: this.add_data.sku_ids,
              }
        )
        this.createAlert(data.message)
        const skuIds = this.add_data.sku_ids
        if (skuIds.length && id) {
          this.$set(
            this.add_data,
            'sku_ids',
            skuIds.filter((e) => e !== id)
          )
        } else {
          this.add_data.sku_ids = []
        }
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async assignUnits() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { units, sku_id, sku_ids } = this.add_data
        const item = {
          units: units?.map((e) => {
            return {
              id: e?.id?.id,
              qty: e.qty,
            }
          }),
        }

        const cloneItem = JSON.parse(JSON.stringify(item))
        if (!sku_id) {
          delete cloneItem.sku_id
          Object.assign(cloneItem, {
            sku_ids,
          })
        } else {
          Object.assign(cloneItem, {
            sku_id,
          })
        }

        const { data } = await this.axios.post('skus/assign_units', cloneItem)
        this.units_active = false
        this.add_data = {
          sku_ids: [],
          units: [],
          company_branch_ids: [],
        }
        this.getItems()
        const skuIds = this.add_data.sku_ids
        if (skuIds.length && sku_id) {
          this.$set(
            this.add_data,
            'sku_ids',
            skuIds.filter((e) => e !== id)
          )
        } else {
          this.add_data.sku_ids = []
        }

        this.$refs.skuTable.$refs.checkAll.checked = false
        this.createAlert(data.message)
      } catch (err) {
        const res = err?.response

        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )

          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async assignBranches() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const {
          company_branch_ids,
          region_id,
          city_id,
          zone_id,
          sku_id,
          sku_ids,
        } = this.add_data
        const item = {
          company_branch_ids: company_branch_ids?.map((e) => e.id) || null,
          region_id: region_id?.id,
          city_id: city_id?.id,
          zone_id: zone_id?.id,
          sku_id,
        }

        const cloneItem = JSON.parse(JSON.stringify(item))
        if (!sku_id) {
          delete cloneItem.sku_id
          Object.assign(cloneItem, {
            sku_ids,
          })
        } else {
          Object.assign(cloneItem, {
            sku_id,
          })
        }
        this.$refs.skuTable.$refs.checkAll.checked = false

        const { data } = await this.axios.post(
          'skus/assign_to_company_branches',
          cloneItem
        )
        this.getItems()
        const skuIds = this.add_data.sku_ids
        if (skuIds.length && sku_id) {
          this.$set(
            this.add_data,
            'sku_ids',
            skuIds.filter((e) => e !== id)
          )
        } else {
          this.add_data.sku_ids = []
        }
        this.branches_active = false
        this.add_data = {
          sku_ids: [],
          units: [],
          company_branch_ids: [],
        }
        this.createAlert(data.message)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async updateAssigned() {
      this.update_disabled = true
      this.update_errors = {}

      try {
        const { id, branches } = this.assign_branches
        const { data } = await this.axios.post(
          'skus/assign_to_company_branches',
          {
            sku_id: id,
            company_branch_ids: branches
              .filter((e) => e.is_assigned)
              .map((e) => e.id),
          }
        )
        this.getItems()
        this.assign_branches = {}
        this.assign_active = false

        this.createAlert(data.message)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.update_errors = res?.data?.message
      } finally {
        this.update_disabled = false
      }
    },
    showAssignUnits(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item && id) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.add_data = {
        ...this.add_data,

        sku_id: id,
        sku_ids: id ? [] : this.add_data.sku_ids,
        units: id
          ? item.units.length === 0
            ? [
                {
                  id: null,
                  qty: null,
                },
              ]
            : item.units.map((e) => {
                return {
                  id: e,
                  qty: e.qty,
                }
              })
          : [
              {
                id: null,
                qty: null,
              },
            ],
      }
      this.units_active = true
    },
    showAssignBranches(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item && id) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.add_data = {
        ...this.add_data,
        company_branch_ids: id ? item.company_branches : [],
        sku_id: id,
        sku_ids: id ? [] : this.add_data.sku_ids,
      }
      this.branches_active = true
    },
    showListedBranches(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      const assigned = this.branches.map((e) => {
        return {
          ...e,
          is_assigned: (item.company_branches || [])
            .map((e) => e.id)
            .includes(e.id),
        }
      })
      this.item = item
      this.assign_branches = {
        branches: assigned,
        id,
      }
      this.assign_active = true
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = item
      this.edit_active = true
    },
    addNewUnit() {
      const lastItem = this.add_data.units.at(-1)
      let error = {}

      Object.keys(lastItem).forEach((e) => {
        if (!lastItem[e]) {
          error[`units.${this.add_data.units.length - 1}.${e}`] = [
            `${e} required`,
          ]
        }
      })

      if (Object.keys(error).length !== 0) {
        this.add_errors = error
        return
      }
      this.add_errors = {}
      this.add_data.units.push({
        id: null,
        qty: null,
      })
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) =>
          [
            'search_key',
            'sort_key',
            'is_active',
            'brand_id',
            'branch_id',
            'prod_date',
            'exp_date',
            'category_id',
            'sub_category_id',
            'store_placement_id',
          ].includes(e)
        )
        .forEach((key) => {
          if (Array.isArray(this.pagination[key])) {
            this.$set(this.pagination, key, [])
          } else {
            this.$set(this.pagination, key, null)
          }
        })
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    checkAll(e) {
      if (!this.items.length) {
        this.createAlert(this.$t('no_data'), 'info')
        return
      }
      const parentData = this.add_data
      if (e.target.checked) {
        this.add_data.sku_ids = this.items.map((e) => e.id)
        return
      }
      this.add_data = {
        ...parentData,
        sku_ids: [],
        sku_id: null,
      }
    },
    unlistedBranch(id) {
      const branches = this.assign_branches.branches.map((e) => {
        return {
          ...e,
          is_assigned: e.id === id ? !e.is_assigned : e.is_assigned,
        }
      })
      this.$set(this.assign_branches, 'branches', branches)
    },

    unlistedAllBranches() {
      this.unlist_disabled = true
      const branches = this.assign_branches.branches.map((e) => {
        return {
          ...e,
          is_assigned: false,
        }
      })
      this.$set(this.assign_branches, 'branches', branches)
      this.updateAssigned().finally(() => (this.unlist_disabled = false))
    },
    handleSelectAll() {
      this.add_data.company_branch_ids = this.filterAssignBranches
    },
  },
  components: { Table, TableLoad, Modal, FilterWrapper, FilterBox },
}
</script>

<style>
.table-drop-item {
  position: relative;
  z-index: var(--z);
}
</style>
