<template>
  <div class="space-y-6">
    <section class="w-full space-y-6">
      <main
        class="w-full"
        :class="{
          'px-6': !['SingleClient'].includes($route.name),
        }"
      >
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('supervisors')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :is_loading="is_loading"
        >
          <template #actions>
            <div>
              <button
                class="
                  py-1.5
                  px-3
                  text-sm
                  flex
                  items-center
                  gap-2
                  text-slate-600
                  font-medium
                  rtl:font-semibold
                "
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('add_new.supervisor') }}</span>
              </button>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    p-4
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th v-if="columns.includes('name')">{{ $t('name') }}</th>
            <th v-if="columns.includes('username')">{{ $t('username') }}</th>

            <th v-if="columns.includes('mobile')">
              <div class="sort">
                <div>
                  <p>{{ $t('mobile') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('mobile')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('role')">
              <div class="sort">
                <div>
                  <p>{{ $t('role') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('role')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('active')">
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('updated_at')">
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('actions')"></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td v-if="columns.includes('name')">{{ item.name || '...' }}</td>
              <td v-if="columns.includes('username')">
                {{ item.username || '-' }}
              </td>
              <td v-if="columns.includes('mobile')">
                <a
                  :href="`tel:${item.mobile}`"
                  class="text-sky-500"
                  v-if="item.mobile"
                  >{{ item.mobile }}</a
                >
                <span v-else>-</span>
              </td>
              <td v-if="columns.includes('role')">
                {{ item.role_type ? $t(item.role_type) : '...' }}
              </td>

              <td v-if="columns.includes('active')">
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td
                v-if="columns.includes('updated_at')"
                v-html="createTime(item.updated_at || item.created_at)"
              ></td>

              <td v-if="columns.includes('actions')">
                <div class="actions-group">
                  <div v-if="false">
                    <button
                      class="
                        p-2
                        px-3
                        text-red-500
                        font-medium
                        rtl:font-semibold
                      "
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }} </span>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded"
                      @click.prevent="showUpdate(item.id)"
                      :disabled="!item.is_active"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="columns.length">
                <div class="table mx-auto my-4">
                  <button
                    class="
                      py-1.5
                      px-3
                      text-sm
                      flex
                      items-center
                      gap-2
                      text-slate-600
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.supervisor') }}</span>
                  </button>
                </div>
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('add_new.user')"
        v-if="add_active"
        @close="add_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="add_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <div class="flex gap-2 items-center">
                  <input
                    autocomplete="off"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    required
                    v-model="add_data.username"
                  />
                  <div class="flex py-3 px-4 rounded bg-slate-200">
                    <p
                      class="
                        text-sm
                        line-clamp-1
                        font-medium
                        rtl:font-semibold
                        text-slate-600
                      "
                    >
                      @{{
                        (client ? client.domain : null) ||
                        ($store.state.auth.user_info.company || {}).domain ||
                        'domain.com'
                      }}
                    </p>
                  </div>
                </div>
                <label for="username">{{ $t('username') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.username"
              >
                {{ add_errors.username.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  v-model="add_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.email"
              >
                {{ add_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="add_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.mobile"
              >
                {{ add_errors.mobile.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <a
                  href="javascript:void(0)"
                  @click.prevent="genPassword('password')"
                  class="
                    text-secondary text-xs
                    font-medium
                    rtl:font-semibold
                    bg-secondary/10
                    py-1
                    px-2
                    table
                    rounded
                    ml-auto
                    rtl:ml-0 rtl:mr-auto
                  "
                  >{{ $t('generate_password') }}</a
                >
                <div class="with-icon">
                  <input
                    autocomplete="off"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••"
                    required
                    v-model="add_data.password"
                    minlength="6"
                    ref="password"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    content="Show password"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>
                <label for="password">{{ $t('password') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.password"
              >
                {{ add_errors.password.join(' ') }}
              </p>
            </div>
            <div class="space-y-2">
              <div class="form-group-with-check">
                <div>
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="force_change_password"
                    id="force_change_password"
                    :true-value="1"
                    :false-value="0"
                    v-model="add_data.force_change_password"
                  />
                </div>
                <label for="force_change_password" class="flex-1">{{
                  $t('force_password')
                }}</label>
              </div>

              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.force_change_password"
              >
                {{ add_errors.force_change_password.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('update')"
        v-if="edit_active"
        @close="edit_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="edit_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <div class="flex gap-2 items-center">
                  <input
                    autocomplete="off"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    required
                    v-model="edit_data.username"
                  />
                  <div class="flex py-3 px-4 rounded bg-slate-200">
                    <p
                      class="
                        text-sm
                        line-clamp-1
                        font-medium
                        rtl:font-semibold
                        text-slate-600
                      "
                    >
                      @{{
                        (client ? client.domain : null) ||
                        ($store.state.auth.user_info.company || {}).domain ||
                        'domain.com'
                      }}
                    </p>
                  </div>
                </div>
                <label for="username">{{ $t('username') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.username"
              >
                {{ edit_errors.username.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  v-model="edit_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.email"
              >
                {{ edit_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="edit_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.mobile"
              >
                {{ edit_errors.mobile.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <a
                  href="javascript:void(0)"
                  @click.prevent="genPassword('newPassword')"
                  class="
                    text-secondary text-xs
                    font-medium
                    rtl:font-semibold
                    bg-secondary/10
                    py-1
                    px-2
                    table
                    rounded
                    ml-auto
                    rtl:ml-0 rtl:mr-auto
                  "
                  >{{ $t('generate_password') }}</a
                >
                <div class="with-icon">
                  <input
                    autocomplete="off"
                    type="password"
                    name="new_password"
                    id="new_password"
                    placeholder="••••••"
                    required
                    v-model="edit_data.new_password"
                    minlength="6"
                    ref="newPassword"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    content="Show password"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>
                <label for="new_password">{{ $t('new_password') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.new_password"
              >
                {{ edit_errors.new_password.join(' ') }}
              </p>
            </div>
            <div class="space-y-2">
              <div class="form-group-with-check">
                <div>
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="force_change_password"
                    id="force_change_password"
                    :true-value="1"
                    :false-value="0"
                    v-model="edit_data.force_change_password"
                  />
                </div>
                <label for="force_change_password" class="flex-1">{{
                  $t('force_password')
                }}</label>
              </div>

              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.force_change_password"
              >
                {{ edit_errors.force_change_password.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Supervisors',
  data() {
    return {
      load: false,
      is_loading: false,
      items: [],
      add_data: {
        role_type: 'supervisor',
        domain:
          this.client?.domain ||
          this.$store.state.auth.user_info.company?.domain,
      },
      stores: [],
      branches: [],
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        role_type: 'supervisor',
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      const name = this.$route.meta.endpoint_name
      if (['client'].includes(name)) {
        return 'supervisor'
      } else {
        return 'supervisor'
      }
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
    clientId() {
      return this.$route.params?.id
    },
  },

  watch: {
    'pagination.page'() {
      this.getItems()
    },
    'pagination.role_type'() {
      this.is_loading = true
      this.getItems().finally(() => {
        this.is_loading = false
      })
    },
    'add_data.name'(val, _) {
      if (!val) {
        this.add_data.username = null
        return
      }
      const splitValue = val.split(/\s/gi)
      if (splitValue.length > 1) {
        this.add_data.username = `${splitValue[0][0]}.${splitValue[1]}`
      }
    },
    'edit_data.name'(val, _) {
      if (!val) {
        this.edit_data.username = null
        return
      }
      const splitValue = val.split(/\s/gi)
      if (splitValue.length > 1) {
        this.edit_data.username = `${splitValue[0][0]}.${splitValue[1]}`
      }
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {
          role_type: 'supervisor',
          client_id: this.clientId,
          domain:
            this.client?.domain ||
            this.$store.state.auth.user_info.company?.domain,
        }
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },

  async created() {
    await this.getItems()
    this.clientId && this.$set(this.add_data, 'client_id', this.clientId)
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    getItems() {
      const { page, sort_key, sort_type, is_active } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'users',
          options: {
            page,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            role_type: 'supervisor',
            search_key: this.search,
            client_id: this.clientId,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.users
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data

      try {
        const { data } = await this.axios.post('users/add', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          client_id: this.clientId,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      const item = this.edit_data
      try {
        const { data } = await this.axios.post('users/update', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          client_id: this.clientId,
        })
        const { message, result } = data
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = result.user
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`users/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`users/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      let result = {}
      for (const key in item) {
        if (item[key]) {
          result[key] = item[key]
        }
      }
      this.edit_data = result
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
  components: { Table, Modal, TableLoad },
}
</script>
