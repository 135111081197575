<template>
  <section class="auth-bg !p-0">
    <main class="w-full lg:w-1/2 bg-slate-800/60 backdrop-blur-lg">
      <div class="flex flex-col h-full">
        <div class="py-4 px-6">
          <div class="[margin-inline-start:auto] table">
            <t-dropdown
              :classes="{
                dropdown: '!top-full !bottom-auto bg-white',
              }"
            >
              <div
                slot="trigger"
                slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                  isShown,
                }"
              >
                <!-- :class="{ 'border-slate-300 bg-slate-500 text-white ': isShown }" -->
                <button
                  class="
                    py-2
                    px-3
                    rounded-md
                    bg-transparent
                    text-sm
                    font-medium
                    rtl:font-semibold
                    border border-white
                    text-white
                  "
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <div class="w-full gap-3 sm:flex items-center hidden">
                    <i
                      class="
                        fa-solid fa-angle-down fa-xs
                        transition-transform
                        ease-linear
                      "
                      :class="{ 'rotate-180': isShown }"
                    ></i>
                    <span>{{ $t(`langs.${$i18n.locale}`) }}</span>
                  </div>
                  <div class="block sm:hidden">
                    <i class="fa-solid fa-globe fa-sm text-slate-600"></i>
                  </div>
                </button>
              </div>

              <div
                slot-scope="{ hide, blurHandler }"
                class="top-full bottom-auto"
              >
                <ul class="list-none overflow-hidden">
                  <li
                    v-for="(item, index) in langs"
                    :key="index"
                    class="border-b border-slate-100 last:border-0"
                  >
                    <button
                      class="
                        w-full
                        p-3
                        flex
                        items-center
                        gap-4
                        font-medium
                        rtl:font-semibold
                        text-sm
                        hover:bg-slate-100
                      "
                      @click.prevent="
                        () => {
                          changeLang(item.lang)
                          hide()
                          blurHandler()
                        }
                      "
                    >
                      <span
                        :class="`fi-${item.flag} w-5 h-4 bg-contain bg-no-repeat`"
                      ></span>
                      <span>{{ $t(`langs.${item.lang}`) }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </t-dropdown>
          </div>
        </div>
        <div class="space-y-6 p-6">
          <header class="w-full mb-10 space-y-4">
            <img
              src="@/assets/images/white-logo-test.png"
              alt="logo image"
              class="max-w-[12em] object-contain table mx-auto"
            />
            <h1 class="text-3xl text-white font-bold text-center">
              {{ $t('join_request') }}
            </h1>
            <p
              class="
                text-slate-200
                max-w-screen-md
                font-medium
                rtl:font-semibold
                text-center
                mx-auto
              "
            >
              {{ $t('join_content') }}
            </p>
          </header>
          <main
            class="
              flex-1 flex
              items-center
              justify-center
              flex-col
              max-w-2xl
              mx-auto
              w-full
              pb-10
            "
          >
            <!-- <div class="space-y-4">
              <p class="text-slate-400 font-semibold rtl:font-bold text-sm">
                {{ $t('join_subtitle') }}
              </p>
              <h1 class="text-5xl text-slate-800 font-bold uppercase">
                {{ $t('fill_form') }}
              </h1>
              <p class="text-slate-400 font-semibold rtl:font-bold text-sm">
                {{ $t('member') }}
                <router-link to="/login" class="text-primary underline">{{
                  $t('login')
                }}</router-link>
              </p>
            </div> -->
            <form class="w-full space-y-4" data-type="join-form">
              <div class="w-full gap-4 flex flex-col sm:flex-row">
                <div class="w-full space-y-2">
                  <div
                    class="
                      relative
                      bg-slate-100
                      flex flex-row-reverse
                      items-center
                      justify-center
                      gap-4
                      py-3
                      px-4
                      rounded-xl
                    "
                  >
                    <div>
                      <i class="fa-solid fa-id-badge fa-sm text-slate-500"></i>
                    </div>
                    <div class="flex-1 space-y-1">
                      <label
                        for="name"
                        class="
                          text-[10px]
                          font-semibold
                          rtl:font-bold
                          text-slate-600
                          block
                        "
                        >{{ $t('company_name') }}</label
                      >
                      <input
                        autocomplete="off"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="eg: example"
                        v-model="add_data.company_name"
                        class="
                          bg-transparent
                          placeholder:text-slate-600
                          font-medium
                          rtl:font-semibold
                          text-sm
                          block
                          w-full
                        "
                      />
                    </div>
                  </div>
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.company_name"
                  >
                    {{ add_errors.company_name.join(' ') }}
                  </p>
                </div>

                <div class="w-full space-y-2">
                  <div
                    class="
                      relative
                      bg-slate-100
                      flex flex-row-reverse
                      items-center
                      justify-center
                      gap-4
                      py-3
                      px-4
                      rounded-xl
                    "
                  >
                    <div>
                      <i class="fa-solid fa-globe fa-sm text-slate-500"></i>
                    </div>
                    <div class="flex-1 space-y-1">
                      <label
                        for="domain"
                        class="
                          text-[10px]
                          font-semibold
                          rtl:font-bold
                          text-slate-600
                          block
                        "
                        >{{ $t('domain') }}</label
                      >
                      <input
                        autocomplete="off"
                        type="text"
                        name="domain"
                        id="domain"
                        placeholder="eg: example.com"
                        v-model="add_data.domain"
                        class="
                          bg-transparent
                          placeholder:text-slate-600
                          font-medium
                          rtl:font-semibold
                          text-sm
                          block
                          w-full
                        "
                        v-ltr
                      />
                    </div>
                  </div>
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.domain"
                  >
                    {{ add_errors.domain.join(' ') }}
                  </p>
                </div>
              </div>
              <div class="w-full space-y-2">
                <div
                  class="
                    relative
                    bg-slate-100
                    flex flex-row-reverse
                    items-center
                    justify-center
                    gap-4
                    py-3
                    px-4
                    rounded-xl
                  "
                >
                  <div>
                    <i class="fa-solid fa-user-pen fa-sm text-slate-500"></i>
                  </div>
                  <div class="flex-1 space-y-1">
                    <label
                      for="contact_name"
                      class="
                        text-[10px]
                        font-semibold
                        rtl:font-bold
                        text-slate-600
                        block
                      "
                      >{{ $t('contact_name') }}</label
                    >
                    <input
                      autocomplete="off"
                      type="text"
                      name="contact_name"
                      id="contact_name"
                      placeholder="eg: abo abduallah"
                      v-model="add_data.contact_name"
                      class="
                        bg-transparent
                        placeholder:text-slate-600
                        font-medium
                        rtl:font-semibold
                        text-sm
                        block
                        w-full
                      "
                    />
                  </div>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.contact_name"
                >
                  {{ add_errors.contact_name.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <div
                  class="
                    relative
                    bg-slate-100
                    flex flex-row-reverse
                    items-center
                    justify-center
                    gap-4
                    py-3
                    px-4
                    rounded-xl
                  "
                >
                  <div>
                    <i class="fa-solid fa-mobile fa-sm text-slate-500"></i>
                  </div>
                  <div class="flex-1 space-y-1">
                    <label
                      for="contact_number"
                      class="
                        text-[10px]
                        font-semibold
                        rtl:font-bold
                        text-slate-600
                        block
                      "
                      >{{ $t('contact_mobile') }}</label
                    >
                    <input
                      autocomplete="off"
                      type="tel"
                      name="contact_number"
                      id="contact_number"
                      v-model="add_data.contact_mobile"
                      placeholder="eg: 9965XXXXXXX"
                      class="
                        bg-transparent
                        placeholder:text-slate-600
                        font-medium
                        rtl:font-semibold
                        text-sm
                        block
                        w-full
                      "
                    />
                  </div>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.contact_mobile"
                >
                  {{ add_errors.contact_mobile.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <div
                  class="
                    relative
                    bg-slate-100
                    flex flex-row-reverse
                    items-center
                    justify-center
                    gap-4
                    py-3
                    px-4
                    rounded-xl
                  "
                >
                  <div>
                    <i class="fa-solid fa-at fa-sm text-slate-500"></i>
                  </div>
                  <div class="flex-1 space-y-1">
                    <label
                      for="contact_email"
                      class="
                        text-[10px]
                        font-semibold
                        rtl:font-bold
                        text-slate-600
                        block
                      "
                      >{{ $t('contact_email') }}</label
                    >
                    <input
                      autocomplete="off"
                      type="email"
                      name="contact_email"
                      id="contact_email"
                      placeholder="eg: example@example.com"
                      v-model="add_data.contact_email"
                      class="
                        bg-transparent
                        placeholder:text-slate-600
                        font-medium
                        rtl:font-semibold
                        text-sm
                        block
                        w-full
                      "
                    />
                  </div>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.contact_email"
                >
                  {{ add_errors.contact_email.join(' ') }}
                </p>
              </div>

              <div class="w-full space-y-2">
                <div
                  class="
                    relative
                    bg-slate-100
                    flex flex-row-reverse
                    items-center
                    justify-center
                    gap-4
                    py-3
                    px-4
                    rounded-xl
                  "
                >
                  <div>
                    <i class="fa-solid fa-user-group fa-sm text-slate-500"></i>
                  </div>
                  <div class="flex-1 space-y-1">
                    <label
                      for="merchandisers_count"
                      class="
                        text-[10px]
                        font-semibold
                        rtl:font-bold
                        text-slate-600
                        block
                      "
                      >{{ $t('merchandisers_count') }}</label
                    >
                    <select
                      name="merchandisers_count"
                      id="merchandisers_count"
                      v-model="add_data.merchandisers_count"
                      class="
                        bg-transparent
                        placeholder:text-slate-600
                        font-medium
                        rtl:font-semibold
                        text-sm
                        block
                        w-full
                        appearance-none
                      "
                    >
                      <option value="" selected disabled>-- select --</option>
                      <option value="2 - 9">2 - 9</option>
                      <option value="10 - 50">10 - 50</option>
                      <option value="+50">+50</option>
                    </select>
                  </div>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.merchandisers_count"
                >
                  {{ add_errors.merchandisers_count.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <div
                  class="
                    relative
                    bg-slate-100
                    flex flex-row-reverse
                    items-center
                    justify-center
                    gap-4
                    py-3
                    px-4
                    rounded-xl
                  "
                >
                  <div class="flex-1 space-y-1">
                    <label
                      for="notes"
                      class="
                        text-[10px]
                        font-semibold
                        rtl:font-bold
                        text-slate-600
                        block
                      "
                    >
                      {{ $t('notes') }}
                    </label>
                    <textarea
                      type="email"
                      name="notes"
                      id="notes"
                      placeholder="..."
                      v-model="add_data.notes"
                      class="
                        bg-transparent
                        placeholder:text-slate-600
                        font-medium
                        rtl:font-semibold
                        text-sm
                        block
                        w-full
                        resize-y
                        min-h-[7rem]
                      "
                    ></textarea>
                  </div>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.notes"
                >
                  {{ add_errors.notes.join(' ') }}
                </p>
              </div>
              <div class="w-full inline-flex gap-3 flex-wrap !mt-8">
                <div class="flex-[1_1_max-content]">
                  <button
                    class="
                      w-full
                      text-center
                      py-3
                      px-6
                      rounded-lg
                      text-white
                      bg-secondary
                      text-sm
                      font-semibold
                      flex
                      items-center
                      gap-2
                      justify-center
                    "
                    :disabled="add_disabled"
                    @click.prevent="joinRequest"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />

                    {{ $t('join_now') }}
                  </button>
                </div>
                <div class="flex-[1_1_max-content]">
                  <router-link
                    to="/login"
                    class="
                      w-full
                      text-center
                      py-3
                      px-6
                      rounded-lg
                      text-slate-500
                      bg-slate-200
                      text-sm
                      font-semibold
                      block
                    "
                  >
                    {{ $t('login') }}
                  </router-link>
                </div>
              </div>
            </form>
          </main>
        </div>
        <div class="w-full py-4 px-6 border-t border-t-slate-700">
          <p
            class="text-white text-xs font-medium rtl:font-semibold text-center"
          >
            {{ new Date().getFullYear() }} © {{ $t('copyRight') }}
            <a
              href="https://rtibha.com"
              class="text-red-500 underline hover:no-underline"
              >Rtibha - رتبها</a
            >
          </p>
        </div>
      </div>
    </main>
    <!-- modal -->
    <div
      class="
        w-full
        fixed
        flex
        items-end
        sm:items-center
        justify-center
        pt-8
        sm:p-8
        inset-0
        z-20
        bg-slate-800/10
      "
      v-if="success_active"
    >
      <div>
        <figure
          class="w-full sm:max-w-xl bg-white rounded-t-2xl sm:rounded-2xl p-8"
        >
          <blockquote class="text-center space-y-4">
            <h1 class="text-3xl text-primary font-bold">
              {{ $t('join_success') }}
            </h1>
            <p class="text-slate-600 font-medium rtl:font-semibold">
              {{ $t('join_success_content') }}
            </p>
            <a
              href="https://gmail.com"
              class="
                w-full
                text-center
                py-3
                px-6
                rounded-lg
                text-white
                bg-secondary
                text-sm
                font-semibold
                block
                !mt-8
              "
            >
              {{ $t('go_inbox') }}
            </a>
            <button
              @click.prevent="
                () => {
                  success_active = false
                }
              "
              class="
                w-full
                text-center
                py-3
                px-6
                rounded-lg
                text-slate-600
                bg-transparent
                text-sm
                font-semibold
                block
              "
            >
              {{ $t('cancel') }}
            </button>
          </blockquote>
        </figure>
      </div>
    </div>
    <!-- modal -->
  </section>
</template>

<script>
import Modal from '@/components/basics/Modal.vue'
export default {
  components: { Modal },
  name: 'Join',
  data() {
    return {
      add_data: {
        merchandisers_count: '',
      },
      add_errors: {},
      add_disabled: false,
      success_active: false,
    }
  },
  computed: {
    langs() {
      const mapLanguages = this.$i18n.availableLocales.map((e) => {
        const iso = { ar: 'sa', en: 'us' }
        return { lang: e, flag: iso[e] }
      })
      return mapLanguages
    },
  },
  methods: {
    async joinRequest() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        await this.axios.post('https://apis.rtibha.com/website/join_request', {
          ...this.add_data,
          contact_mobile: this.add_data?.contact_mobile?.replace(
            /[+|\s|()|-]/g,
            ''
          ),
        })
        this.add_data = {}
        this.success_active = true
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
  },
}
</script>
