<template>
  <div>
    <section class="w-full space-y-6">
      <filter-box class="px-6"></filter-box>
      <main class="w-full px-6">
        <Table
          :has_header="true"
          :has_import="true"
          :has_check="false"
          :title="$t('journey_plans')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          ref="table"
          :fields="fields"
        >
          <template #actions>
            <div>
              <router-link
                class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                to="/setup/journey-plans/new"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('new_visit') }}</span>
              </router-link>
            </div>
            <!-- <div>
              <router-link
                class="
                  py-1.5
                  px-3
                  text-sm
                  flex
                  items-center
                  gap-2
                  text-slate-600
                  font-medium
                  rtl:font-semibold
                "
                to="/setup/journey-plans/smart-journey-plan"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('new_smart_journey_plan') }}</span>
              </router-link>
            </div> -->
          </template>
          <!-- <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    py-4
                    px-6
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template> -->
          <template #head>
            <th v-if="columns.includes('merchandiser')">
              {{ $t('merchandiser') }}
            </th>

            <th v-if="columns.includes('branches')">{{ $t('branches') }}</th>
            <th v-if="columns.includes('tasks')">{{ $t('tasks') }}</th>

            <th v-if="columns.includes('start_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('start_date') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('start_date')"
                  ></button>
                </div>
              </div>
            </th>
            <!-- <th v-if="columns.includes('active')">
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th> -->

            <th v-if="columns.includes('last_run')">
              <div class="sort">
                <div>
                  <p>{{ $t('last_run') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('last_run')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('updated_at')">
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('actions')"></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td v-if="columns.includes('merchandiser')">
                <p
                  class="text-sm text-primary font-medium rtl:font-semibold table"
                >
                  {{ (item.user || {}).name || '...' }}
                </p>
                <a
                  :href="`mailto:${(item.user || {}).username || '...'}`"
                  class="text-xs text-slate-400 block font-medium rtl:font-semibold truncate"
                  v-if="(item.user || {}).username"
                  >{{ (item.user || {}).username || '...' }}
                </a>
              </td>

              <td v-if="columns.includes('branches')">
                <div class="actions-group">
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-for="(branch, index) in item.company_branches.slice(0, 1)"
                    :key="index"
                    >{{ branch.name }}</span
                  >
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-if="item.company_branches.length > 1"
                    >+{{ item.company_branches.length - 1 }}</span
                  >
                  <span
                    class="text-slate-600"
                    v-if="item.company_branches.length === 0"
                    >-</span
                  >
                </div>
              </td>
              <td v-if="columns.includes('tasks')">
                <div class="actions-group">
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-for="(task, index) in item.tasks.slice(0, 1)"
                    :key="index"
                    >{{ task.name }}</span
                  >
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-if="item.tasks.length > 1"
                    >+{{ item.tasks.length - 1 }}</span
                  >
                  <span class="text-slate-600" v-if="item.tasks.length === 0"
                    >-</span
                  >
                </div>
              </td>
              <td
                v-if="columns.includes('start_date')"
                v-html="createTime(item.start_date)"
              ></td>
              <!-- <td v-if="columns.includes('active')">
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td> -->

              <td
                v-if="columns.includes('last_run')"
                v-html="createTime(item.last_run)"
              ></td>
              <td
                v-if="columns.includes('updated_at')"
                v-html="createTime(item.updated_at || item.created_at)"
              ></td>

              <td v-if="columns.includes('actions')">
                <div class="actions-group">
                  <div>
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }} </span>
                    </button>
                  </div>
                  <div v-if="false">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div v-if="false">
                    <button
                      class="w-8 h-8 bg-orange-500/10 text-orange-500 rounded"
                      @click.prevent="runJourneyPlan(item.id)"
                      :content="$t('run')"
                      v-tippy
                      :disabled="disables[`run_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`run_${item.id}`]"
                      />
                      <i class="fa-solid fa-rotate-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <router-link
                      :to="`/setup/journey-plans/${item.id}`"
                      class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                      :content="$t('view')"
                      v-tippy
                    >
                      <i class="fa-solid fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="(item.user || {}).name"
        v-if="show_active"
        @close="
          (e) => {
            item = {}
            show_active = e
          }
        "
      >
        <template>
          <div class="p-4">
            <div class="pb-4 border-b border-slate-100 space-y-4">
              <div class="actions-group">
                <div>
                  <button
                    class="py-2 px-3 flex items-center gap-2 bg-sky-500 text-white text-xs font-medium rtl:font-semibold"
                    @click.prevent="changeStatus(item.id)"
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-rotate-left" v-else></i>
                    <span>{{ $t('change_status') }}</span>
                  </button>
                </div>
                <div>
                  <button
                    class="py-2 px-3 flex items-center gap-2 bg-red-500 text-white text-xs font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <i class="fa-solid fa-trash" v-else></i>
                    <span>{{ $t('delete') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <ul class="list-none space-y-2 py-3 border-b border-b-slate-100">
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-full xsm:w-1/3 p-1.5">
                    <p
                      class="text-sm text-slate-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-tag fa-sm mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('status') }}
                    </p>
                  </div>
                  <div class="w-full xsm:w-2/3 p-1.5">
                    <p class="status capitalize" :class="item_status[1]">
                      {{ 1 === 1 ? 'Active' : 'Not active' }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-full xsm:w-1/3 p-1.5">
                    <p
                      class="text-sm text-slate-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-user fa-sm mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('assignee') }}
                    </p>
                  </div>
                  <div class="w-full xsm:w-2/3 p-1.5 text-sm">
                    <p class="text-sm font-medium rtl:font-semibold">
                      {{ (item.user || {}).name }}
                    </p>
                    <a
                      :href="`mailto:${(item.user || {}).email}`"
                      class="text-sky-500 truncate block"
                      >{{ (item.user || {}).email }}</a
                    >
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-full xsm:w-1/3 p-1.5">
                    <p
                      class="text-sm text-slate-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-tag fa-check-double mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('Tasks') }}
                    </p>
                  </div>
                  <div class="w-full xsm:w-2/3 p-1.5">
                    <div class="actions-group">
                      <span
                        class="status text-slate-600 bg-slate-100"
                        v-for="(task, index) in item.tasks.slice(0, 1)"
                        :key="index"
                        >{{ task.name }} - {{ task.alt_name }}</span
                      >
                      <a
                        href="#tasks"
                        class="status text-slate-600 bg-slate-100"
                        v-if="item.tasks.length > 1"
                        >+{{ item.tasks.length - 1 }}</a
                      >
                      <span
                        class="text-slate-600"
                        v-if="item.tasks.length === 0"
                        >-</span
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-full xsm:w-1/3 p-1.5">
                    <p
                      class="text-sm text-slate-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-tag fa-shop mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('branches') }}
                    </p>
                  </div>
                  <div class="w-full xsm:w-2/3 p-1.5">
                    <div class="actions-group">
                      <span
                        class="status text-slate-600 bg-slate-100"
                        v-for="(task, index) in item.company_branches.slice(
                          0,
                          1
                        )"
                        :key="index"
                        >{{ task.name }} - {{ task.alt_name }}</span
                      >
                      <span
                        class="status text-slate-600 bg-slate-100"
                        v-if="item.company_branches.length > 1"
                        >+{{ item.company_branches.length - 1 }}</span
                      >
                      <span
                        class="text-slate-600"
                        v-if="item.tasks.length === 0"
                        >-</span
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-full xsm:w-1/3 p-1.5">
                    <p
                      class="text-sm text-slate-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-clock fa-sm mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('start_date') }}
                    </p>
                  </div>
                  <div class="w-full xsm:w-2/3 p-1.5">
                    <p
                      class="text-sm font-medium rtl:font-semibold"
                      v-if="item.start_date"
                    >
                      {{ item.start_date | moment('h:mA, ddd mmm yyyy') }}
                    </p>
                    <span class="text-slate-600" v-else>-</span>
                  </div>
                </div>
              </li>
            </ul>

            <ul
              class="w-full list-none py-3 space-y-4 overflow-y-auto max-h-[20rem]"
              id="tasks"
            >
              <li>
                <p class="text-sm text-slate-500 font-medium rtl:font-semibold">
                  {{ $t('tasks') }}
                </p>
              </li>
              <li
                class="form-group-with-check bg-slate-50 p-4 rounded-md"
                v-for="(task, index) in item.tasks"
                :key="index"
              >
                <label
                  :for="task.name.split(' ').join('-').toLowerCase()"
                  class="flex-1"
                >
                  <div class="flex items-center gap-3">
                    <div>
                      <i
                        class="fa-solid fa-circle-check text-slate-400 fa-lg"
                      ></i>
                    </div>

                    <p class="flex-1">{{ task.name }} - {{ task.alt_name }}</p>
                  </div>
                </label>
              </li>
            </ul>
            <div class="w-full pt-4 border-t border-t-slate-100">
              <button
                class="table w-full max-w-xs py-2 px-3 rounded bg-primary text-white font-medium rtl:font-semibold mx-auto text-sm hover:shadow-xl hover:shadow-primary/10"
              >
                <i class="fa-solid fa-plus fa-sm"></i>
                {{ $t('add_new.task') }}
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'

import { mapGetters, mapMutations } from 'vuex'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import FilterBox from '@/components/basics/FilterBox.vue'

export default {
  name: 'JourneyPlans',
  data() {
    return {
      load: false,
      items: [],
      users: [],
      branches: [],
      show_active: false,
      item: {},
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        total: 0,
        user_ids: [],
        company_branch_id: [],
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      fields: [],
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },

    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search', 'table_paginate']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
  },

  async created() {
    Promise.all([
      await this.getItems(),
      await this.getUsers(),
      await this.getBranches(),
    ])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getBranches() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        this.branches = result.company_branches
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getUsers() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'users'
        )
        this.users = result.users
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getItems() {
      try {
        const {
          page,
          sort_key,
          sort_type,
          is_active,
          user_ids,
          company_branch_id,
        } = this.pagination
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'journey_plans',
          options: {
            page,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            user_ids: user_ids?.map((e) => e.id),
            company_branch_id: company_branch_id?.map((e) => e.id),
            search_key: this.search,
            ...this.table_paginate,
          },
        })
        const { data, pagination } = result.journey_plans
        this.items = data.map((e) => {
          return {
            ...e,
            image: e.image ? result.image_url + '/' + e.image : e.image, // null,
          }
        })
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
        this.fields = result.export_fields
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.load = true
        this.changeIsCalled(false)
      }
    },

    async runJourneyPlan(id) {
      this.$set(this.disables, `run_${id}`, true)

      try {
        const { isConfirmed } = await this.$swal.fire({
          title: this.$t('are-you-sure'),
          showDenyButton: true,
          confirmButtonText: this.$t('yes'),
          denyButtonText: this.$t('no'),
        })
        if (!isConfirmed) return
        const { data } = await this.axios.post(`journey_plans/run/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },

    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      this.$set(
        this.disables,
        `delete_${id}`
          .match(new RegExp(`delete_[a-zA-Z_?]+${id}`, 'gi'))
          ?.at(0),
        true
      )
      try {
        const { data } = await this.axios.post(
          `journey_plans/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },

    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)

      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        const { data } = await this.axios.post(`journey_plans/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.item = item
      this.show_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, FilterWrapper, FilterBox },
}
</script>
