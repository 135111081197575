export default {
  namespaced: true,
  state: {
    stores: [],
    branches: [],
    regions: [],
    cities: [],
    users: [],
    clients: [],
    visits: [],
    zones: [],
    main_categories: [],
    load: false,
  },
  getters: {
    stores: (state) => state.stores,
    branches: (state) => state.branches,
    regions: (state) => state.regions,
    cities: (state) => state.cities,
    zones: (state) => state.zones,
    users: (state) => state.users,
    clients: (state) => state.clients,
    main_categories: (state) => state.main_categories,
    load: (state) => state.load,
  },
  mutations: {
    STORE_DATA(state, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(state, key)) {
          const item = payload[key]
          state[key] = item
        }
      }
    },
    CHANGE_LOAD(state, payload) {
      state.load = payload
    },
  },
  actions: {
    async getRegions({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'region_groups'
        )
        commit('STORE_DATA', {
          regions: result.region_groups,
        })
      } catch (error) {}
    },
    async getCities({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', 'cities')
        commit('STORE_DATA', {
          cities: result.cities,
        })
      } catch (error) {}
    },
    async getZones({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'district_groups'
        )
        // const zones = result.zones
        commit('STORE_DATA', {
          zones: result.district_groups,
        })
      } catch (error) {}
    },
    async getBranches({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        commit('STORE_DATA', {
          branches: result.company_branches,
        })
      } catch (error) {}
    },
    async getStores({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'company_stores'
        )
        commit('STORE_DATA', {
          stores: result.company_stores,
        })
      } catch (error) {}
    },
    async getUsers({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', {
          name: 'users',
          options: {
            role_type: ['merchandiser', 'supervisor'],
          },
        })
        commit('STORE_DATA', {
          users: result.users,
        })
      } catch (error) {}
    },
    async getClients({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', 'clients')
        commit('STORE_DATA', {
          clients: result.clients,
        })
      } catch (error) {}
    },
    async getMainCategories({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', {
          name: 'main_categories',
          options: {
            is_active: 1,
          },
        })
        commit('STORE_DATA', {
          main_categories: result.main_categories,
        })
      } catch (error) {}
    },
  },
  modules: {},
}
