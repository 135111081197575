export default {
  namespaced: true,
  state: {
    is_error: false,
    cart: {},
    locale: null,
    routeName: null,

    cards: [
      {
        title: 'Total visits',
        value: 0,
        key: 'visits_count',
        translate_key: 'total_visits',
        icon: 'fa-solid fa-location-crosshairs',
        style: {
          icon: 'text-sky-600',
          parent: 'bg-sky-600/20',
        },
      },
      {
        title: 'Stores',
        value: 0,
        key: 'company_stores_count',
        translate_key: 'stores',
        icon: 'fa-solid fa-store',
        style: {
          icon: 'text-orange-600',
          parent: 'bg-orange-600/20',
        },
      },
      {
        title: 'Branches',
        value: 0,
        key: 'company_branches_count',
        translate_key: 'branches',
        icon: 'fa-solid fa-shop',
        style: {
          icon: 'text-red-600',
          parent: 'bg-red-600/20',
        },
      },
    ],
    default_cards: [
      {
        title: 'Total visits',
        value: 0,
        key: 'visits_count',
        translate_key: 'total_visits',
        icon: 'fa-solid fa-location-crosshairs',
        style: {
          icon: 'text-sky-600',
          parent: 'bg-sky-600/20',
        },
      },
      {
        title: 'Stores',
        value: 0,
        key: 'company_stores_count',
        translate_key: 'stores',
        icon: 'fa-solid fa-store',
        style: {
          icon: 'text-orange-600',
          parent: 'bg-orange-600/20',
        },
      },
      {
        title: 'Branches',
        value: 0,
        key: 'company_branches_count',
        translate_key: 'branches',
        icon: 'fa-solid fa-shop',
        style: {
          icon: 'text-red-600',
          parent: 'bg-red-600/20',
        },
      },
    ],
    tables_columns: {
      company_stores: [
        'name',
        'alt_name',
        'total_skus',
        'monthly_visits',
        'branches',
        // 'unlisted_branches',
        // 'merchandisers_count',
        'active',
        'updated_at',
        'actions',
      ],
      company_branches: [
        'name',
        'alt_name',
        'total_skus',
        'monthly_visits',
        'merchandisers_count',
        'store',
        'active',
        'updated_at',
        'actions',
      ],
      brands: ['name', 'alt_name', 'client', 'active', 'updated_at', 'actions'],
      categories: ['name', 'alt_name', 'active', 'updated_at', 'actions'],
      main_categories: ['name', 'alt_name', 'active', 'updated_at', 'actions'],
      sub_categories: [
        'name',
        'alt_name',
        'category',
        'active',
        'updated_at',
        'actions',
      ],
      competitors: [
        'name',
        'alt_name',
        'brand',
        'active',
        'updated_at',
        'actions',
      ],
      shelves: ['category', 'brand', 'active', 'updated_at', 'actions'],
      planograms: [
        'image',
        'store_placement',
        'client',
        'categories',
        'sub_categories',
        'updated_at',
        'active',
        'actions',
      ],
      merchandisers: [
        'name',
        'username',
        'clients',
        'mobile',
        'total_branches',
        'total_visits',
        'cost',
        'active',
        'activation_date',
        'actions',
      ],
      users: [
        'name',

        'mobile',
        'username',
        'role',
        'active',
        'updated_at',
        'actions',
      ],
      clients: [
        'name',
        'merchandisers_count',
        'domain',
        'tasks',
        'active',
        'updated_at',
        'actions',
      ],
      supervisor: [
        'name',
        'mobile',
        'username',
        'active',
        'updated_at',
        'actions',
      ],
      teams: ['name', 'leader', 'users', 'active', 'updated_at', 'actions'],
      skus: [
        'sku',
        'barcode',
        'categories',
        'sub_categories',
        'store_placements',
        'brand',
        'selling_price',
        'status',

        'updated_at',
        'actions',
      ],
      visit_summary: [
        'branch',
        'store',
        'user',
        'supervisor',
        'status',
        'visit_date',
        'start_date',
        'end_date',
        'actions',
      ],
      visits: [
        'client',
        'supervisor',
        'user',
        'store',
        'branch',
        'region',
        'city',
        'status',
        'visit_date',
        'start_date',
        'end_date',
        'actions',
      ],
      sku_audits: [
        'user',
        'sku',
        // 'expiry_date',
        // 'production_date',
        // 'selling_price',
        'store',
        'branch',
        'qty',
        'is_available',
        'visit_date',
        // 'store_placement',
      ],
      expiry_audits: [
        'user',
        'sku',
        'store',
        // 'qty',
        'branch',
        // 'unit',
        'visit_date',
        'dates',
        // 'production_date',
        // 'store_placement',
      ],
      return_audits: [
        'user',
        'sku',
        'store',
        'branch',
        'reason',
        'unit',
        'qty',
        'visit_date',
        'production_date',
        'expiry_date',
      ],
      stock_audits: [
        'user',
        'sku',
        'branch',
        'store',
        'door_type',
        'production_date',
        'expiry_date',
        'unit',
        'qty',
        'visit_date',
      ],
      delivery_audits: [
        'user',
        'sku',
        'branch',
        'store',
        'unit',
        'qty',
        'expiry_date',
        'visit_date',
      ],
      shelf_audits: [
        'user',
        'branch',
        'store',
        'actual_value',
        'category',
        'brand',
        'competitor',
        'shelf',
        'faces',
        'meter',
        'cm',
        'store_placement',
        'notes',
        'visit_date',
      ],
      planogram_audits: [
        'user',
        'branch',
        'store',
        'store_placement',
        'notes',
        'visit_date',
        'images_before',
        'images_after',
      ],
      journey_plans: [
        'merchandiser',
        'branches',
        'tasks',
        // 'active',
        'last_run',
        'updated_at',
        'actions',
      ],
      store_placements: ['name', 'alt_name', 'active', 'updated_at', 'actions'],
      units: ['name', 'alt_name', 'type', 'active', 'updated_at', 'actions'],
    },
    selected_columns: {},
  },
  getters: {
    currentCart: (state) => (id) => {
      return state.cart[id]
    },
    cards: (state) => state.cards,
    routeName_2: (state) => state.routeName,
    getColumns: (state) => state.tables_columns,
    selectedColumns: (state) => state.selected_columns,
    getSelectedColumns: (state) => (key) => state.selected_columns[key],
    getBasicColumn: (state) => (key) => state.tables_columns[key],
  },
  mutations: {
    SET_CART(state, data) {
      state.cart = data
    },
    STORE_CART(state, { id }) {
      const currentCart = state.cart[id]
      if (!currentCart) {
        state.cart[id] = []
        return
      }
    },
    SET_LANG(state, data) {
      state.locale = data
    },

    updateCards(state, data) {
      state.cards = []
      state.cards = [...state.default_cards, ...data]
    },
    resetCards(state, data) {
      state.cards = state.default_cards
    },
    setRouteName(state, data) {
      state.routeName = data
    },
    updatedSelectedColumns(state, { type, data }) {
      // const item = state.selected_columns
      Object.assign(state.selected_columns, {
        [type]: data,
      })
    },
  },
  actions: {
    SetCart(state, data) {
      return new Promise((resolve, reject) => {
        try {
          const { id, product } = data
          //  get cart of current user id
          const vendorCart = state.cart[id]
          if (!vendorCart) {
            state.cart[id].push(product)
            resolve(state.cart[id])
            return
          }
          const currentProduct = state.cart[id].find((e) => e.id === product.id)
          // check if current product existed or not
          if (!currentProduct) {
            state.cart[id].push(product)
          } else {
            currentProduct.qty += +product.qty
          }
          resolve(state.cart[id])
        } catch (error) {
          reject(error)
        }
      })
    },
  },
  modules: {},
}
