<template>
  <div>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter v-show="!isVisit"></reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('shelf_audits')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          :fields="fields"
          v-model="pagination.page"
        >
          <template #head>
            <th v-if="!isVisit && columns.includes('user')">
              {{ $t('user') }}
            </th>
            <th v-if="columns.includes('actual_value')">
              <div class="sort">
                <div>
                  <p>{{ $t('actual_value') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('category')">
              <div class="sort">
                <div>
                  <p>{{ $t('category') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('brand')">
              <div class="sort">
                <div>
                  <p>{{ $t('brand') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('competitor')">
              <div class="sort">
                <div>
                  <p>{{ $t('competitor') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('store_placement')">
              <div class="sort">
                <div>
                  <p>{{ $t('store_placement') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('shelf')">
              <div class="sort">
                <div>
                  <p>{{ $t('shelf') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('faces')">
              <div class="sort">
                <div>
                  <p>{{ $t('faces') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('meter')">
              <div class="sort">
                <div>
                  <p>{{ $t('meter') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('cm')">
              <div class="sort">
                <div>
                  <p>{{ $t('cm') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('notes')">{{ $t('notes') }}</th>
            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="!isVisit && columns.includes('branch')">
              {{ $t('branch') }}
            </th>
            <th v-if="!isVisit && columns.includes('store')">
              {{ $t('store') }}
            </th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && result.length !== 0">
            <tr v-for="(item, index) in result" :key="index">
              <td v-if="!isVisit && columns.includes('user')">
                <p>{{ (item.user || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.user || {}).mobile || '...' }}</a
                >
              </td>
              <td v-if="columns.includes('actual_value')">
                {{ item.actual_value || 0 }}
              </td>
              <td v-if="columns.includes('category')">
                {{ (item.category || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('brand')">
                {{ (item.brand || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('competitor')">
                {{ (item.competitor || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('store_placement')">
                {{ (item.store_placement || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('shelf')">
                {{ item.shelf }}
              </td>
              <td v-if="columns.includes('faces')">
                {{ item.faces }}
              </td>
              <td v-if="columns.includes('meter')">
                {{ item.meter }}
              </td>
              <td v-if="columns.includes('cm')">
                {{ item.cm }}
              </td>
              <td v-if="columns.includes('notes')">
                <div class="max-w-xs min-w-[10rem]">
                  {{ item.notes || '...' }}
                </div>
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="
                  createTime(
                    !isVisit ? (item.visit || {}).visit_date : item.visit_date
                  )
                "
              ></td>
              <td v-if="!isVisit && columns.includes('branch')">
                <p>{{ (item.visit.company_branch || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.visit.company_branch || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.visit.company_branch || {}).mobile || '...' }}</a
                >
              </td>

              <td v-if="!isVisit && columns.includes('store')">
                {{ (item.visit.company_store || {}).name || '...' }}
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && result.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'

export default {
  name: 'ShelfAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('customize', ['getColumns', 'getSelectedColumns']),
    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },
  mounted() {
    const data = this.cards
    this.updateCards([
      {
        title: 'Actual value',
        value: 0,
        key: null,
        icon: 'fa-solid fa-arrow-up-short-wide',
        style: {
          icon: 'text-sky-600',
          parent: 'bg-sky-600/20',
        },
      },
    ])
  },
  methods: {
    ...mapMutations('customize', ['updateCards']),
  },
  components: { Table, TableLoad, ReportsFilter, CardsContainer },
}
</script>
