<template>
  <div class="space-y-4">
    <!-- <header></header> -->
    <!-- class="grid bg-white ring-1 ring-slate-200 rounded-md"
    :style="
      'grid-template-columns: repeat(auto-fill, minmax(' + size + 'rem,1fr))'
    " -->
    <div
      class="bg-white ring-1 ring-slate-200 rounded-md"
      :style="'columns:' + size"
      v-bind="$attrs"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TBox',
  props: {
    size: {
      type: Number,
      default: 1,
    },
  },
}
</script>