<template>
  <div>
    <span v-if="!dateActive" class="date-line my-3">
      <span
        class="
          bg-white
          relative
          table
          mx-auto
          px-2
          z-[1]
          font-medium
          rlt:font-semibold
        "
        >{{ message.created_at | moment('MMMM Do YYYY') }}</span
      >
    </span>
    <div
      class="table max-w-[80%] message-item"
      :class="{
        'mr-auto rtl:mr-0 rtl:ml-auto': message.sender_type === 'user',
        'ml-auto rtl:ml-0 rtl:mr-auto': message.sender_type === 'admin',
      }"
      :data-message="message.id"
    >
      <div
        class="flex items-center gap-2 mb-2"
        :class="{
          'flex-row': message.sender_type === 'user',
          'flex-row-reverse': message.sender_type === 'admin',
        }"
        v-if="!isSame && message.sender_type === 'admin'"
      >
        <div>
          <div class="w-8 h-8">
            <img
              :src="message.sender_type === 'user' ? avatar : admin_avatar"
              class="
                w-full
                h-full
                rounded-full
                object-cover
                border border-slate-100
              "
              v-if="message.sender_type === 'admin'"
            />
          </div>
        </div>
        <div v-if="message.sender_type === 'admin'">
          <h1 class="font-bold text-sm">
            {{
              message.sender_type === 'user'
                ? message.user.name
                : message.admin.name
            }}
          </h1>
        </div>
      </div>
      <div
        :class="{
          'mr-auto rtl:mr-0 rtl:ml-auto': message.sender_type === 'user',
          'ml-auto rtl:ml-0 rtl:mr-auto pr-8 rtl:pr-0 rtl:pl-8':
            message.sender_type === 'admin',
        }"
        class="table"
      >
        <div
          class="py-2 px-4 text-sm font-medium rounded-md max-w-max"
          :class="{
            '!bg-primary !text-white mr-auto rtl:mr-0 rtl:ml-auto':
              message.sender_type === 'user',
            'bg-slate-200 text-slate-600 ml-auto rtl:ml-0 rtl:mr-auto ':
              message.sender_type === 'admin',
          }"
          @dblclick.prevent="openDetails(message.id)"
          v-html="GenerateContent(message.body, message.sender_type === 'user')"
        ></div>
        <p
          class="text-xs text-slate-500 font-medium mt-2 hidden"
          :class="{
            'text-left rtl:text-right': message.sender_type === 'user',
            'text-right rtl:text-left': message.sender_type === 'admin',
          }"
        >
          {{ message.created_at | moment('hh:mma') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SingleMessage',
  props: {
    message: {
      type: Object,
      default: {},
    },
    prev: {
      type: Array,
      default: Array,
      required: false,
    },
  },
  data() {
    return {
      isSame: false,
      avatar: require('@/assets/images/placeholder.png'),
      admin_avatar: require('@/assets/images/support-chat.png'),
      dateActive: false,
    }
  },
  created() {
    this.isSame = this.isSamePerson(this.message, this.prev)
    this.dateActive = this.isNotSameDate(this.message, this.prev)

    if (this.message.sender_type === 'user') {
      if (this.message.user?.photo) {
        this.avatar = this.message.user?.photo
      } else {
        this.avatar = require('@/assets/images/placeholder.png')
      }
    } else {
      if (this.message.admin?.photo) {
        this.admin_avatar = this.message.admin?.photo
      } else {
        this.admin_avatar = require('@/assets/images/support-chat.png')
      }
    }
  },
  computed: {
    GenerateContent() {
      return (text, type) => {
        return text.replace(
          /(?:(?:https?:\/\/)|(?:www\.))[^\s]+(?!^(.+?)([.,?!'"]*)$)/gi,
          (e) =>
            `<a href="${e.replace(/['|"]$/gi, '')}" class="${
              type ? 'text-white' : 'text-primary'
            } underline [word-break:break-word]">${e}</a>`
        )
      }
    },
  },
  methods: {
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false
      } else if (prev[0]?.sender_type === msg?.sender_type) {
        return true
      } else {
        return false
      }
    },
    isNotSameDate(msg, prev) {
      if (prev === null) {
        return false
      } else if (
        new Date(prev[0]?.created_at).getDate() ===
        new Date(msg?.created_at).getDate()
      ) {
        return true
      } else {
        return false
      }
    },
    openDetails(id) {
      const item = document.querySelector(`.message-item[data-message="${id}"]`)
      item.querySelector('p').classList.toggle('hidden')
    },
  },
}
</script>
<style lang="scss">
.date-line {
  @apply relative block w-full text-center text-slate-500 text-xs mb-3;
  &::after {
    content: '';
    @apply w-4/5 absolute h-px bg-gradient-to-tr from-slate-50 from-slate-200 to-slate-50 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
</style>
