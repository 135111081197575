import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Profile from '@/views/account/Profile'
import HomePage from '@/views/Home'
import CalendarPage from '@/views/Calendar'
import NewCalendar from '@/views/NewCalendar'
import CreateVisitPage from '@/views/reports/visits/CreateVisit'
import VisitPage from '@/views/reports/visits/index'
import SingleVisit from '@/views/reports/visits/_id'
import LoginPage from '@/views/Login'
import JoinPage from '@/views/Join'
import UsersVisits from '@/views/UsersVisits'
import MessageIndex from '@/views/messages/index'
import SingleMessage from '@/views/messages/_id'
import Brands from '@/views/setup/configuration/Brands'
import JourneyPlans from '@/views/setup/journey-plans/index'
import SingleJourneyPlane from '@/views/setup/journey-plans/_id'
import NewVisit from '@/views/setup/journey-plans/NewVisit'
import SmartJourneyPlan from '@/views/setup/journey-plans/Smart'
import Users from '@/views/setup/Users'
import Merchandisers from '@/views/setup/users/Merchandisers'
import Clients from '@/views/setup/users/clients/index'
import SingleClient from '@/views/setup/users/clients/_id'
import ConfigurationStores from '@/views/setup/configuration/Stores'
import ConfigurationBranches from '@/views/setup/configuration/Branches'
import Competitors from '@/views/setup/configuration/Competitors'
import ConfigurationRequestBranch from '@/views/setup/configuration/RequestBranch'
import Roles from '@/views/setup/Roles'
import SKUs from '@/views/setup/skus/index'
import CreateSku from '@/views/setup/skus/create'
import UpdateSku from '@/views/setup/skus/update'
import StockCount from '@/views/reports/StockCount'
import DeliveryReports from '@/views/reports/DeliveryReports'
import SkusReports from '@/views/reports/Skus'
import ReturnsReports from '@/views/reports/ReturnsReports'
import PromotionReports from '@/views/reports/PromotionReports'
import PlanogramReports from '@/views/reports/PlanogramReports'
import ShelfReports from '@/views/reports/ShelfReports'
import ExpiryReports from '@/views/reports/ExpiryReports'
import Notifications from '@/views/Notifications'
import MainCategories from '@/views/setup/configuration/MainCategories'
import Categories from '@/views/setup/configuration/Categories'
import SubCategories from '@/views/setup/configuration/SubCategories'
import StorePlacements from '@/views/setup/configuration/StorePlacements'
import Reasons from '@/views/setup/configuration/Reasons'
import Units from '@/views/setup/configuration/Units'
import Shelves from '@/views/setup/configuration/Shelves'
import Planograms from '@/views/setup/configuration/Planograms'
import Shifts from '@/views/setup/Shifts'
// import Zones from '@/views/setup/Zones'
import Cities from '@/views/setup/locations/Cities'
import RegionGroups from '@/views/setup/locations/RegionGroups'
import DistrictGroups from '@/views/setup/locations/DistrictGroup'
import EmailConfiguration from '@/views/setup/EmailConfiguration'
import OsaReports from '@/views/OsaReports'
import Error from '@/views/Error'

// middleware
import auth from '@/middleware/auth'
import hasAccess from '../middleware/hasAccess'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      middleware: [auth, hasAccess],
      title: 'dashboard',
      permission: 'dashboard',
      endpoint_name: 'visit_summary',
    },
  },
  {
    path: '/calendar',
    name: 'CalendarPage',
    component: CalendarPage,

    meta: {
      middleware: [auth, hasAccess],
      title: 'calendar',
      permission: 'calendar',
    },
  },
  // {
  //   path: '/calendar',
  //   name: 'CalendarPage',
  //   component: CalendarPage,

  //   meta: {
  //     middleware: [auth, hasAccess],
  //     title: 'calendar',
  //     permission: 'calendar',
  //   },
  // },

  {
    path: '/performance',
    name: 'UsersVisits',
    component: UsersVisits,
    meta: {
      middleware: [auth, hasAccess],
      title: 'performance',
      permission: 'performance',
      endpoint_name: 'performance_reports/users',
    },
  },

  {
    path: '/messages',
    name: 'MessageIndex',
    component: MessageIndex,
    meta: {
      middleware: [auth, hasAccess],
      title: 'messages',
      permission: 'messages',
    },
  },
  {
    path: '/messages/:id',
    name: 'SingleMessage',
    component: SingleMessage,
    meta: {
      middleware: [auth, hasAccess],
      title: 'messages',
      permission: 'messages',
    },
  },
  {
    path: '/reports/visits/urgent-visit',
    name: 'CreateVisitPage',
    component: CreateVisitPage,
    meta: {
      middleware: [auth, hasAccess],
      title: 'urgent_visit',
      permission: 'urgent_visit',
    },
  },
  {
    path: '/visits',
    name: 'VisitPage',
    component: VisitPage,
    meta: {
      middleware: [auth, hasAccess],
      title: 'visits',
      permission: 'visits',
      endpoint_name: 'visits',
    },
  },
  {
    path: '/visits/:visit_id',
    name: 'SingleVisit',
    component: SingleVisit,
    meta: {
      middleware: [auth, hasAccess],
      title: 'visit',
      permission: 'visits',
    },
  },

  {
    path: '/setup/configuration/brands',
    name: 'Brands',
    component: Brands,
    meta: {
      middleware: [auth, hasAccess],
      title: 'brands',
      permission: 'brands',
      endpoint_name: 'brands',
    },
  },
  {
    path: '/setup/journey-plans',
    name: 'JourneyPlans',
    component: JourneyPlans,
    meta: {
      middleware: [auth, hasAccess],
      title: 'journey_plans',
      permission: 'journey_plans',
      endpoint_name: 'journey_plans',
      template_name: 'journey_plans_template.xlsx',
    },
  },
  {
    path: '/setup/journey-plans/new',
    name: 'NewVisit',
    component: NewVisit,
    meta: {
      middleware: [auth, hasAccess],
      title: 'new_visit',
      permission: 'journey_plans',
    },
  },
  {
    path: '/setup/journey-plans/smart-journey-plan',
    name: 'SmartJourneyPlan',
    component: SmartJourneyPlan,
    meta: {
      middleware: [auth, hasAccess],
      title: 'new_smart_journey_plan',
      permission: 'journey_plans',
    },
  },
  {
    path: '/setup/journey-plans/:id',
    name: 'SingleJourneyPlane',
    component: SingleJourneyPlane,
    meta: {
      middleware: [auth, hasAccess],
      title: 'journey_plan',
      permission: 'journey_plans',
      endpoint_name: 'journey_plans',
    },
  },

  {
    path: '/setup/users',
    name: 'users',
    component: Users,
    meta: {
      middleware: [auth, hasAccess],
      title: 'users',
      permission: 'users',
      endpoint_name: 'users',
    },
  },
  {
    path: '/setup/merchandisers',
    name: 'Merchandisers',
    component: Merchandisers,
    meta: {
      middleware: [auth, hasAccess],
      title: 'merchandisers',
      permission: 'merchandisers',
      endpoint_name: 'merchandisers',
    },
  },
  {
    path: '/setup/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      middleware: [auth, hasAccess],
      title: 'clients',
      permission: 'clients',
      endpoint_name: 'clients',
    },
  },
  {
    path: '/setup/clients/:id',
    name: 'SingleClient',
    component: SingleClient,
    meta: {
      middleware: [auth, hasAccess],
      title: 'client',
      permission: 'clients',
      endpoint_name: 'client',
    },
  },

  {
    path: '/setup/configuration/stores',
    name: 'ConfigurationStores',
    component: ConfigurationStores,
    meta: {
      middleware: [auth, hasAccess],
      title: 'stores',
      permission: 'company_stores',
      endpoint_name: 'company_stores',
    },
  },
  {
    path: '/setup/configuration/branches',
    name: 'ConfigurationBranches',
    component: ConfigurationBranches,
    meta: {
      middleware: [auth, hasAccess],
      title: 'branches',
      permission: 'company_branches',
      endpoint_name: 'company_branches',
    },
  },
  {
    path: '/setup/configuration/competitors',
    name: 'Competitors',
    component: Competitors,
    meta: {
      middleware: [auth, hasAccess],
      title: 'competitors',
      permission: 'competitors',
      endpoint_name: 'competitors',
    },
  },
  {
    path: '/setup/configuration/branches/request-own-branch',
    name: 'ConfigurationRequestBranch',
    component: ConfigurationRequestBranch,
    meta: {
      middleware: [auth, hasAccess],
      title: 'request_branch',
      permission: 'company_branches',
      endpoint_name: 'company_branches',
    },
  },
  {
    path: '/setup/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      middleware: [auth, hasAccess],
      title: 'roles',
      permission: 'roles',
    },
  },

  {
    path: '/setup/skus',
    name: 'SKUs',
    component: SKUs,
    meta: {
      middleware: [auth, hasAccess],
      title: 'skus',
      permission: 'skus',
      endpoint_name: 'skus',
      template_name: 'skus-template.xlsx',
    },
  },
  {
    path: '/setup/skus/create',
    name: 'CreateSku',
    component: CreateSku,
    meta: {
      middleware: [auth, hasAccess],
      title: 'create_new.sku',
      permission: 'skus',
    },
  },
  {
    path: '/setup/skus/:id',
    name: 'UpdateSku',
    component: UpdateSku,
    meta: {
      middleware: [auth, hasAccess],
      title: 'sku',
      permission: 'skus',
    },
  },
  {
    path: '/reports/stock-audits',
    name: 'StockCount',
    component: StockCount,
    meta: {
      middleware: [auth, hasAccess],
      title: 'stock_audits',
      permission: 'stock_audits',
      endpoint_name: 'stock_audits',
    },
  },
  {
    path: '/reports/delivery-audits',
    name: 'DeliveryReports',
    component: DeliveryReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'delivery_audits',
      permission: 'delivery_audits',
      endpoint_name: 'delivery_audits',
    },
  },
  {
    path: '/reports/skus-audits',
    name: 'SkusReports',
    component: SkusReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'skus',
      permission: 'sku_audits',
      endpoint_name: 'sku_audits',
    },
  },

  {
    path: '/reports/promotion-audits',
    name: 'PromotionReports',
    component: PromotionReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'promotion',
      permission: 'promotion_audits',
    },
  },
  {
    path: '/reports/planogram-audits',
    name: 'PlanogramReports',
    component: PlanogramReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'planogram',
      permission: 'planogram_audits',
      endpoint_name: 'planogram_audits',
    },
  },
  {
    path: '/reports/shelf-audits',
    name: 'ShelfReports',
    component: ShelfReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'shelf_audits',
      permission: 'shelf_audits',
      endpoint_name: 'shelf_audits',
    },
  },
  {
    path: '/reports/expiry-audits',
    name: 'ExpiryReports',
    component: ExpiryReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'expiries',
      permission: 'expiry_audits',
      endpoint_name: 'expiry_audits',
    },
  },
  {
    path: '/reports/returns-audits',
    name: 'ReturnsReports',
    component: ReturnsReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'returns',
      permission: 'return_audits',
      endpoint_name: 'return_audits',
    },
  },
  {
    path: '/setup/configuration/main-categories',
    name: 'MainCategories',
    component: MainCategories,
    meta: {
      middleware: [auth, hasAccess],
      title: 'main_categories',
      permission: 'main_categories',
      endpoint_name: 'main_categories',
    },
  },
  {
    path: '/setup/configuration/categories',
    name: 'Categories',
    component: Categories,
    meta: {
      middleware: [auth, hasAccess],
      title: 'categories',
      permission: 'categories',
      endpoint_name: 'categories',
    },
  },
  {
    path: '/setup/configuration/sub-categories',
    name: 'SubCategories',
    component: SubCategories,
    meta: {
      middleware: [auth, hasAccess],
      title: 'sub_categories',
      permission: 'sub_categories',
      endpoint_name: 'sub_categories',
    },
  },
  {
    path: '/setup/configuration/shelves',
    name: 'Shelves',
    component: Shelves,
    meta: {
      middleware: [auth, hasAccess],
      title: 'shelves',
      permission: 'shelves|shelf_audits',
      endpoint_name: 'shelves',
    },
  },
  {
    path: '/setup/configuration/planograms',
    name: 'Planograms',
    component: Planograms,
    meta: {
      middleware: [auth, hasAccess],
      title: 'planograms',
      permission: 'planograms',
      endpoint_name: 'planograms',
    },
  },
  {
    path: '/setup/configuration/store-placements',
    name: 'StorePlacements',
    component: StorePlacements,
    meta: {
      middleware: [auth, hasAccess],
      title: 'store_placements',
      permission: 'store_placements',
      endpoint_name: 'store_placements',
    },
  },
  {
    path: '/setup/configuration/cancellation-reasons',
    name: 'Reasons',
    component: Reasons,
    meta: {
      middleware: [auth, hasAccess],
      title: 'c_reasons',
      permission: 'c_reasons',
      endpoint_name: 'c_reasons',
    },
  },
  {
    path: '/setup/configuration/units',
    name: 'Units',
    component: Units,
    meta: {
      middleware: [auth, hasAccess],
      title: 'units',
      permission: 'units',
      endpoint_name: 'units',
    },
  },
  {
    path: '/setup/shifts',
    name: 'Shifts',
    component: Shifts,
    meta: {
      middleware: [auth, hasAccess],
      title: 'shifts',
      permission: 'shifts',
      endpoint_name: 'shifts',
    },
  },
  // {
  //   path: '/setup/zones',
  //   name: 'Zones',
  //   component: Zones,
  //   meta: {
  //     middleware: [auth, hasAccess],
  //     title: 'zones',
  //     permission: 'zones',
  //     endpoint_name: 'zones',
  //   },
  // },
  {
    path: '/setup/locations/cities',
    name: 'Cities',
    component: Cities,
    meta: {
      middleware: [auth, hasAccess],
      title: 'cities',
      permission: 'locations',
      endpoint_name: 'cities',
    },
  },
  {
    path: '/setup/locations/region-groups',
    name: 'RegionGroups',
    component: RegionGroups,
    meta: {
      middleware: [auth, hasAccess],
      title: 'region_groups',
      permission: 'locations',
      endpoint_name: 'region_groups',
    },
  },
  {
    path: '/setup/locations/zones',
    name: 'DistrictGroups',
    component: DistrictGroups,
    meta: {
      middleware: [auth, hasAccess],
      title: 'zones',
      permission: 'locations',
      endpoint_name: 'district_groups',
    },
  },
  {
    path: '/setup/email-configurations',
    name: 'EmailConfiguration',
    component: EmailConfiguration,
    meta: {
      middleware: [auth, hasAccess],
      title: 'email_configurations',
      permission: 'email_configurations',
      // endpoint_name: 'email_configurations',
    },
  },
  {
    path: '/osa-reports',
    name: 'OsaReports',
    component: OsaReports,
    meta: {
      middleware: [auth, hasAccess],
      title: 'osa_reports',
      permission: 'osa_reports',
      endpoint_name: 'osa_reports',
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      middleware: [auth],
      title: 'notifications',
      permission: 'notifications',
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: {
      middleware: [auth],
      title: 'error',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      middleware: [auth],
      title: 'profile',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      middleware: [auth],
      title: 'login',
    },
  },
  {
    path: '/join',
    name: 'Join',
    component: JoinPage,
    meta: {
      middleware: [auth],
      title: 'join',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      to,
      store,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  } else {
    return next()
  }
})
export default router
