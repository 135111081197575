<template>
  <div>
    <section class="w-full space-y-6 px-6">
      <filter-box has-to-date has-from-date></filter-box>
      <cards-container :cards="filterCards" :load="!load" />

      <Table
        :has_header="true"
        :has_check="false"
        :has_customize="false"
        :has_export="true"
        :title="$t('performance')"
        :records="`${pagination.total || 0} ${$t('records')}`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        :fields="fields"
      >
        <template #head>
          <th>{{ $t('user') }}</th>

          <th>
            <div class="sort">
              <div>
                <p>{{ $t('visits') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('visits_count')"
                ></button>
              </div>
            </div>
          </th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('scheduled') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('scheduled_visits_count')"
                ></button>
              </div>
            </div>
          </th>

          <th>
            <div class="sort">
              <div>
                <p>{{ $t('extra_mission') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('extra_mission_visits_count')"
                ></button>
              </div>
            </div>
          </th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('extra_mission_done') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('extra_mission_done_visits_count')"
                ></button>
              </div>
            </div>
          </th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('done') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('done_visits_count')"
                ></button>
              </div>
            </div>
          </th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('skipped') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('skipped_visits_count')"
                ></button>
              </div>
            </div>
          </th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('missed') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('missed_visits_count')"
                ></button>
              </div>
            </div>
          </th>

          <!-- <th></th> -->
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="8" v-for="i in 6" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length !== 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="space-y-1">
                <p>{{ item.name || 'UNKNOWN' }}</p>
                <a
                  :href="`mailto:${item.username}`"
                  class="text-sky-500"
                  v-if="item.username"
                  >{{ item.username }}</a
                >
              </div>
            </td>
            <td>{{ item.visits_count || 0 }}</td>
            <td>{{ item.scheduled_visits_count || 0 }}</td>
            <td>{{ item.extra_mission_visits_count || 0 }}</td>
            <td>{{ item.extra_mission_done_visits_count || 0 }}</td>
            <td>{{ item.done_visits_count || 0 }}</td>
            <td>{{ item.skipped_visits_count || 0 }}</td>
            <td>{{ item.missed_visits_count || 0 }}</td>
            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showItem(item.id)"
                    :disabled="disables[`show_${item.id}`]"
                    :content="$t('view')"
                    v-tippy
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`show_${item.id}`]"
                    />
                    <i class="fa-solid fa-eye" v-else></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #tbody v-else-if="load && items.length === 0">
          <tr>
            <td :colspan="8">
              <p
                class="
                  text-sm text-slate-600
                  font-medium
                  rtl:font-semibold
                  text-center
                "
              >
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </template>
      </Table>
    </section>
    <transition name="scale">
      <Modal
        :title="`${(user_performance.user || {}).name} <br /> <a href='mailto:${
          (user_performance.user || {}).username
        }' class='text-xs table text-sky-500 font-medium'>${
          (user_performance.user || {}).username || '-'
        }</a>`"
        v-if="show_active"
        @close="
          (e) => {
            item = {}
            show_active = e
          }
        "
      >
        <template>
          <ul
            class="
              list-none
              grid
              gap-4
              grid-cols-[repeat(auto-fill,minmax(10em,auto))]
              p-4
            "
          >
            <li
              class="space-y-2 p-4 rounded bg-slate-100"
              v-for="(key, index) in performance_keys"
              :key="index"
            >
              <p
                class="
                  font-medium
                  rtl:font-semibold
                  text-slate-500 text-center text-xs
                "
              >
                {{ $t(key) }}
              </p>
              <p
                class="
                  font-bold
                  tabular-nums
                  text-slate-800 text-center text-lg
                "
              >
                {{ user_performance[key] }}
              </p>
            </li>
          </ul>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import Tabs from '@/components/basics/Tabs.vue'
import FilterBox from '@/components/basics/FilterBox.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'

export default {
  name: 'UsersVisits',
  components: { Modal, Tabs, FilterBox, CardsContainer, Table, TableLoad },
  data() {
    return {
      load: false,
      items: [],

      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        date: null,
        sort_type: 'asc',
        role_type: null,
        user_id: [],
        total: 0,
      },

      apply_disabled: false,
      reset_disabled: false,
      item_data: {},
      show_active: false,

      show_notes: false,
      note_disabled: false,
      cards: [
        {
          title: 'Done',
          value: 0,
          key: 'done_visits_count',
          translate_key: 'done',
          icon: 'fa-solid fa-check',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },

        {
          title: 'Not done',
          value: 0,
          key: 'not_done_visits_count',
          translate_key: 'not_done',
          icon: 'fa-solid fa-xmark',
          style: {
            icon: 'text-slate-600',
            parent: 'bg-slate-600/20',
          },
        },

        {
          title: 'Extra mission',
          value: 0,
          key: 'extra_mission_visits_count',
          translate_key: 'extra_mission',
          icon: 'fa-solid fa-file-circle-plus',
          style: {
            icon: 'text-green-500',
            parent: 'bg-green-500/20',
          },
        },
        {
          title: 'Merchandisers',
          value: 0,
          key: 'merchandisers_count',
          translate_key: 'merchandisers',
          icon: 'fa-solid fa-users-between-lines',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
      ],
      summary: {},
      fields: [],
      disables: {},
      performance_keys: [
        'start_time',
        'end_time',
        'ideal_time',
        'in_stores_time',
        'travel_time',
        'total_time',
        'visits_percentage',
        'done_count',
        'not_visits_count',
        'scheduled_count',
        'extra_mission_count',
        'extra_mission_done_count',
        'extra_visits_percentage',
        'missed_count',
        'skipped_count',
      ],
      user_performance: {},
    }
  },
  async created() {
    Promise.all([await this.getItems()])
  },
  computed: {
    ...mapGetters('table', ['is_called', 'table_paginate']),
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },
  watch: {
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },

  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async getItems() {
      const { page, search_key, sort_key, sort_type, role_type, user_id } =
        this.pagination
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'performance_reports/users',
          options: {
            page,
            search_key,
            sort_key,
            sort_type,
            role_type,
            user_id: user_id?.map((e) => e?.id),
            ...this.table_paginate,
          },
        })
        const { data, pagination } = result.users
        this.items = data
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
        this.fields = result.export_fields || []
        this.summary = result.summary || {}
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.load = true
        this.changeIsCalled(false)
      }
    },
    addNote() {},
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      this.$set(
        this.disables,
        `delete_${id}`
          .match(new RegExp(`delete_[a-zA-Z_?]+${id}`, 'gi'))
          ?.at(0),
        true
      )
      try {
        const { data } = await this.axios.post(`visits/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)

      try {
        const { data } = await this.axios.post(`visits/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async showItem(id) {
      this.$set(this.disables, `show_${id}`, true)
      try {
        const item = this.items.find((e) => e.id === id)
        if (!item) {
          this.createAlert('Item does not existed', 'info')
          return
        }
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          {name: `performance_reports/users/${id}`, options: {
            from: this.table_paginate.from,
            to: this.table_paginate.to,
          }}
        )
        this.user_performance = {
          ...result,
          user: item,
        }
        this.show_active = true
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }

        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) =>
          [
            'search_key',
            'sort_key',
            'role',
            'user_ids',
            'company_branch_ids',
          ].includes(e)
        )
        .forEach((key) => {
          if (Array.isArray(this.pagination[key])) {
            this.$set(this.pagination, key, [])
          } else {
            this.$set(this.pagination, key, null)
          }
        })
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'status', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
}
</script>
