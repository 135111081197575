<template>
  <div>
    <merchandisers-table />
  </div>
</template>

<script>
import MerchandisersTable from '@/components/setup/users/MerchandisersTable.vue'
export default {
  components: { MerchandisersTable },
  name: 'Merchandisers',
}
</script>
