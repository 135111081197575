<template>
  <div>
    <sub-pages-header :title="(item.user || {}).name">
      <div class="table mx-auto" v-if="!load">
        <spinner size="w-8 h-8" class="align-sub text-primary" />
      </div>
      <section class="w-full" v-else>
        <main class="w-full">
          <div class="space-y-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <figure class="bg-white ring-1 ring-slate-200 rounded-md w-full">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('merchandiser') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <ul class="list-none space-y-3">
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-user mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('name') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ user.name || '...' }}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-at mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('username') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`mailto:${user.username || '...'}`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ user.username || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-phone mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('mobile') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`tel:${user.mobile || '...'}`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ user.mobile || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </blockquote>
              </figure>
              <figure class="bg-white ring-1 ring-slate-200 rounded-md w-full">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('tasks') }}
                  </p>
                </header>
                <blockquote class="p-4">
                  <ul class="space-y-2 list-disc [padding-inline-start:1rem]">
                    <li
                      class="flex-1 list-none marker"
                      v-if="!(item.tasks || []).length"
                    >
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('no_data') }}
                      </p>
                    </li>
                    <li v-else v-for="task in item.tasks || []" :key="task.id">
                      <p
                        class="text-sm font-medium rtl:font-semibold text-slate-600"
                      >
                        {{ task.name }}
                      </p>
                      <ul
                        class="space-y-2 list-disc [padding-inline-start:1rem]"
                        v-if="(task.sub_tasks || []).length"
                      >
                        <li
                          v-for="sub_task in task.sub_tasks || []"
                          :key="sub_task.id"
                        >
                          <p
                            class="text-sm font-medium rtl:font-semibold text-slate-600"
                          >
                            {{ sub_task.name }}
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </blockquote>
              </figure>
            </div>
            <div v-for="(week, index) in weeks" :key="index">
              <div>
                <header
                  class="p-4 bg-white sticky top-[4.5rem] border border-slate-200 rounded"
                >
                  <div
                    class="flex items-center flex-wrap justify-between gap-4"
                  >
                    <div class="flex-1">
                      <p
                        class="text-sm text-primary font-medium rtl:font-semibold table truncate"
                      >
                        {{ $t(`weeks.${index + 1}`) }}
                      </p>
                    </div>
                    <div class="actions-group">
                      <div>
                        <button
                          class="w-8 h-8 rounded-full flex items-center justify-center sm:hidden"
                          @click.prevent="openCurrent"
                        >
                          <i class="fa-solid fa-angle-down fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </header>

                <slide-transition>
                  <div
                    class="w-full hidden sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 transition-all ease-linear overflow-hidden mt-4 space-y-2 sm:space-y-0 gap-2"
                    data-type="calendar"
                  >
                    <figure
                      v-for="(item, i) in week.days"
                      :key="i"
                      class="bg-white border border-slate-200 w-full rounded"
                    >
                      <figcaption
                        class="w-full border-b border-slate-200 p-3 flex gap-2"
                      >
                        <div class="flex-1">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ $t(`week_days.${item.day}`) }}
                          </p>
                        </div>
                        <div>
                          <button
                            v-tippy
                            :content="$t('delete')"
                            @click.prevent="showDeletionDay(i + 1, index + 1)"
                          >
                            <!-- :disabled="!(branch(i, index + 1) || []).length"  -->
                            <i
                              class="fa-solid fa-trash text-red-500 text-sm"
                            ></i>
                          </button>
                        </div>
                        <!-- <p
                        class="text-slate-600 text-xs font-medium rtl:font-semibold"
                      >
                        {{ item.date | moment('DD-yyyy') }}
                      </p> -->
                      </figcaption>
                      <blockquote>
                        <ul
                          class="list-none inline-flex gap-2 p-3 flex-wrap overflow-auto max-h-[17rem]"
                        >
                          <li
                            class="flex-1"
                            v-if="!(branch(i, index + 1) || []).length"
                          >
                            <p
                              class="text-xs font-medium rtl:font-semibold text-center text-slate-600"
                            >
                              {{ $t('visits_no_data') }}
                            </p>
                          </li>
                          <li
                            class="flex-[initial]"
                            v-else
                            v-for="_branch in branch(i, index + 1) || []"
                            :key="_branch.id"
                          >
                            <div
                              class="bg-slate-200 text-slate-600 rounded-md inline-flex font-medium px-2 py-1.5 text-xs gap-2"
                            >
                              <div>
                                <button
                                  v-tippy
                                  :content="$t('delete')"
                                  @click.prevent="
                                    showDeletionBranch(
                                      i + 1,
                                      index + 1,
                                      _branch
                                    )
                                  "
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                              </div>
                              <div class="flex-1">
                                <p class="">
                                  {{ _branch.name }}
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>

                        <!-- ul. -->
                      </blockquote>
                    </figure>
                  </div>
                </slide-transition>
              </div>
            </div>
          </div>
        </main>
      </section>

      <!-- deletion popup's -->
      <transition name="scale">
        <Modal
          :title="$t('delete')"
          v-if="day_active"
          @close="day_active = $event"
        >
          <template>
            <div class="p-6">
              <ul class="list-none space-y-3">
                <li>
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="radio"
                        name="dayGroup"
                        id="day-option-1"
                        value="week"
                        v-model="day_data.delete_from"
                      />
                    </div>
                    <label for="day-option-1">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('deletion_day.day') }}
                      </p>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="radio"
                        name="dayGroup"
                        id="day-option-2"
                        value="month"
                        v-model="day_data.delete_from"
                      />
                    </div>
                    <label for="day-option-2">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('deletion_day.week') }}
                      </p>
                    </label>
                  </div>
                </li>
                <li v-if="Object.keys(day_errors).length">
                  <p
                    class="text-red-500 font-medium text-xs"
                    v-html="Object.values(day_errors).join('<br />')"
                  ></p>
                </li>
              </ul>
              <div class="actions-group mt-6">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-xs font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="deleteDayFromWeek"
                    :disabled="day_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="day_disabled" />
                    {{ $t('submit') }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </Modal>
      </transition>
      <!-- ./deletion popup's -->
      <!-- deletion popup's -->
      <transition name="scale">
        <Modal
          :title="`${
            branch_item.name
          } <br /> <span class='text-sm text-slate-400 font-medium'>${
            (branch_item.company_store || {}).name || '-'
          }</span>`"
          v-if="branch_active"
          @close="branch_active = $event"
        >
          <template>
            <div class="p-6">
              <ul class="list-none space-y-3">
                <li>
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="radio"
                        name="dayGroup"
                        id="day-option-1"
                        value="day"
                        v-model="branch_data.delete_from"
                      />
                    </div>
                    <label for="day-option-1">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('deletion_branch.day') }}
                      </p>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="radio"
                        name="dayGroup"
                        id="day-option-2"
                        value="week"
                        v-model="branch_data.delete_from"
                      />
                    </div>
                    <label for="day-option-2">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('deletion_branch.week') }}
                      </p>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-group-with-check">
                    <div>
                      <input
                        type="radio"
                        name="dayGroup"
                        id="day-option-3"
                        value="month"
                        v-model="branch_data.delete_from"
                      />
                    </div>
                    <label for="day-option-3">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-slate-600"
                      >
                        {{ $t('deletion_branch.month') }}
                      </p>
                    </label>
                  </div>
                </li>
                <li v-if="Object.keys(branch_errors).length">
                  <p
                    class="text-red-500 font-medium text-xs"
                    v-html="Object.values(branch_errors).join('<br />')"
                  ></p>
                </li>
              </ul>
              <div class="actions-group mt-6">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-xs font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="deleteBranch"
                    :disabled="branch_disabled"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="branch_disabled"
                    />
                    {{ $t('submit') }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </Modal>
      </transition>
      <!-- ./deletion popup's -->
    </sub-pages-header>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Modal from '@/components/basics/Modal.vue'
import SubPagesHeader from '@/components/basics/SubPagesHeader.vue'
export default {
  name: 'SingleJourneyPlan',
  data() {
    return {
      item: {},
      user: {},
      load: false,
      branches: [],
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      days_in_week: ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'],
      day_data: {},
      branch_data: {},
      day_active: false,
      branch_active: false,
      day_disabled: false,
      branch_disabled: false,
      day_errors: {},
      branch_errors: {},
      branch_item: {},
    }
  },
  computed: {
    id() {
      return this.$route.params?.id
    },
    weeks() {
      let result = []
      const moment = this.$moment
      const days = Array(4)
        .fill(this.days_in_week)
        .reduce((prev, next) => {
          return prev + ' ' + next
        })
        .replace(/\s/g, ',')
        .split(',')
        .map((e, i) => {
          return {
            day: e,
            id: i - 1,
            date: moment()
              .startOf('month')
              .clone()
              .weekday(i - 1)
              .format('DD-MM-YYYY'),
          }
        })
      // this.item?.recurring_type === 'monthly' ? 4 : 1
      result = Array(4)
        .fill('')
        .map((e, i) => {
          return {
            title: `Week ${i + 1}`,
            id: i + 1,
            days: days.slice(i > 0 ? i * 7 : i, (i + 1) * 7),
          }
        })
      return result
    },
    branch() {
      return (i, week) =>
        this.branches.filter((e) => e.day === i + 1 && e.week === week)
    },
  },
  watch: {
    day_active(val) {
      if (!val) {
        this.day_data = {}
        this.day_errors = {}
      }
    },
    branch_active(val) {
      if (!val) {
        this.branch_data = {}
        this.branch_errors = {}
      }
    },
  },
  async created() {
    await this.getItem()
  },
  methods: {
    ...mapMutations('customize', ['setRouteName']),
    async getItem() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          `/journey_plans/${this.id}`
        )
        const { journey_plan } = result
        this.item = journey_plan ?? {}
        this.user = journey_plan?.user ?? {}
        this.branches = journey_plan?.company_branches ?? []
        // this.setRouteName(journey_plan?.user?.name)
        // this.visit_tabs_data = anotherItems
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
        this.$router.push('/setup/journey-plans')
      } finally {
        this.load = true
      }
    },
    openCurrent(e) {
      const parElement =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .parentElement
      const calendarParent = parElement.querySelector('[data-type="calendar"]')
      let height = 0
      for (let i = 0; i < calendarParent.children.length; i++) {
        const childHeight =
          calendarParent.children[i].getBoundingClientRect().height
        height += childHeight
      }
      if (calendarParent.classList.contains('hidden')) {
        calendarParent.classList.remove('hidden')
        calendarParent.style.cssText = `--height: ${height}px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-down', 'fa-angle-up')
      } else {
        calendarParent.classList.add('hidden')
        calendarParent.style.cssText = `--height: 0px;`
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-angle-up', 'fa-angle-down')
      }
    },
    async deleteDayFromWeek() {
      this.day_disabled = true
      this.day_errors = {}

      try {
        const { data } = await this.axios.post('journey_plans/delete_day', {
          ...this.day_data,
        })
        const { message } = data
        this.getItem()
        this.createAlert(message)
        this.day_active = false
        this.day_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.day_error =
            'There is something went wrong, please try again later.'
          return
        }
        const error = res?.data?.message
        if (typeof error !== 'string') {
          this.day_errors = error
        } else {
          this.createAlert(error, 'error')
        }
      } finally {
        this.day_disabled = false
      }
    },
    async deleteBranch() {
      this.branch_disabled = true
      this.branch_errors = {}

      try {
        const { data } = await this.axios.post(
          'journey_plans/delete_company_branch',
          {
            ...this.branch_data,
          }
        )
        const { message } = data
        this.getItem()
        this.createAlert(message)
        this.branch_active = false
        this.branch_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.branch_error =
            'There is something went wrong, please try again later.'
          return
        }
        const error = res?.data?.message
        if (typeof error !== 'string') {
          this.branch_errors = error
        } else {
          this.createAlert(error, 'error')
        }
      } finally {
        this.branch_disabled = false
      }
    },
    showDeletionDay(day, week) {
      this.day_data = {
        day,
        week,
        journey_plan_id: this.id,
        delete_from: 'week',
      }
      this.day_active = true
    },
    showDeletionBranch(day, week, item) {
      this.branch_data = {
        day,
        week,
        company_branch_id: item.id,
        journey_plan_id: this.id,
        delete_from: 'day',
      }
      this.branch_item = item
      this.branch_active = true
    },
  },
  components: { Modal, SubPagesHeader },
}
</script>
