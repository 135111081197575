<template>
  <div>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter v-show="!isVisit" ref="reportsFilter" has-main-categories>
      </reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('expiries')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          :fields="fields"
          v-model="pagination.page"
        >
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    py-4
                    px-6
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.notify === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[item]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[item]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th v-if="!isVisit && columns.includes('user')">
              {{ $t('user') }}
            </th>
            <th v-if="columns.includes('sku')">{{ $t('sku') }}</th>
            <th v-if="!isVisit && columns.includes('store')">
              {{ $t('store') }}
            </th>
            <th v-if="!isVisit && columns.includes('branch')">
              {{ $t('branch') }}
            </th>
            <!-- <th v-if="columns.includes('unit')">
              <div class="sort">
                <div>
                  <p>{{ $t('unit') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th> -->
            <!-- <th v-if="columns.includes('qty')">
              <div class="sort">
                <div>
                  <p>{{ $t('qty') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th> -->
            <!-- <th v-if="columns.includes('store_placement')">
              <div class="sort">
                <div>
                  <p>{{ $t('store_placement') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th> -->

            <th v-if="columns.includes('dates')">
              <div class="sort">
                <div>
                  <p>{{ $t('dates') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && result.length !== 0">
            <tr v-for="(item, index) in result" :key="index">
              <td v-if="!isVisit && columns.includes('user')">
                <p>{{ (item.user || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.user || {}).mobile || '...' }}</a
                >
              </td>
              <td v-if="columns.includes('sku')">
                <div class="actions-group !flex-no-wrap">
                  <div>
                    <div class="w-12 h-auto">
                      <img
                        :src="
                          (item.sku || {}).image ||
                          require('@/assets/images/placeholder.png')
                        "
                        :alt="`${(item.sku || {}).name} image`"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                  <div class="flex-1 whitespace-nowrap">
                    <p class="text-sky-500">
                      {{ (item.sku || {}).barcode || '...' }}
                    </p>
                    <p class="text-slate-600">
                      {{ (item.sku || {}).name || '...' }}
                    </p>
                  </div>
                </div>
              </td>
              <td v-if="!isVisit && columns.includes('store')">
                {{ (item.visit.company_store || {}).name || '...' }}
              </td>
              <td v-if="!isVisit && columns.includes('branch')">
                <p>{{ (item.visit.company_branch || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.visit.company_branch || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.visit.company_branch || {}).mobile || '...' }}</a
                >
              </td>

              <!-- <td v-if="columns.includes('unit')">
                {{ (item.unit || {}).name || '...' }}
              </td> -->
              <!-- <td v-if="columns.includes('qty')">{{ item.qty || 0 }}</td> -->
              <!-- <td v-if="columns.includes('store_placement')">
                {{ (item.store_placement || {}).name || '...' }}
              </td> -->

              <td v-if="columns.includes('dates')">
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showDates(item)"
                    :content="$t('dates')"
                    v-tippy
                  >
                    <i class="fa-solid fa-eye"></i>
                  </button>
                </div>
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="
                  createTime(
                    !isVisit ? (item.visit || {}).visit_date : item.visit_date
                  )
                "
              ></td>
            </tr>
          </template>
          <template #tbody v-else-if="load && result.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <!-- dates -->
    <Modal
      :title="$t('dates')"
      @close="
        (e) => {
          show_dates = e
          dates = []
        }
      "
      v-if="show_dates"
    >
      <template>
        <nav class="p-2">
          <ul class="divide-y divide-slate-100">
            <li
              class="flex gap-4 p-4"
              v-for="(date, index) in dates"
              :key="index"
            >
              <img
                class="shrink-0 w-10 h-10"
                :src="date.image || require('@/assets/images/placeholder.png')"
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
              />
              <div class="flex-1 space-y-1">
                <p class="text-sm font-semibold">
                  {{
                    (date.unit || {})[
                      $i18n.locale !== 'ar' ? 'name' : 'alt_name'
                    ]
                  }}
                </p>
                <p class="text-sm font-semibold">
                  {{ $t('expiry_date') }} :
                  {{ date.exp_date | moment('yyyy-MM-DD') }}
                </p>
                <p class="text-sm font-semibold">
                  {{ $t('production_date') }} :
                  {{ date.prod_date | moment('yyyy-MM-DD') }}
                </p>
                <p class="text-sm font-semibold">
                  {{ $t('qty') }} : {{ date.qty || 0 }}
                </p>
              </div>
            </li>
          </ul>
        </nav>
      </template>
    </Modal>
    <!-- dates -->
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'
import Modal from '../basics/Modal.vue'
export default {
  name: 'ExpiryAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      disables: {},
      status: ['all', 'alert_1', 'alert_2', 'alert_3'],
      dates: [],
      show_dates: false,
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Total Expired',
          value: 0,
          key: 'expiries_count',
          translate_key: 'expiries',
          icon: 'fa-solid fa-arrow-trend-down',
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },

        // {
        //   title: 'Total SKUs',
        //   value: 0,
        //   key: 'skus_count',
        //   translate_key: 'skus',
        //   icon: 'fa-solid fa-basket-shopping',
        //   style: {
        //     icon: 'text-yellow-600',
        //     parent: 'bg-yellow-600/20',
        //   },
        // },
      ],
    }
  },
  watch: {
    is_called() {
      if (!this.is_called) {
        this.disables = {}
      }
    },
  },
  computed: {
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    ...mapGetters('table', ['is_called']),

    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },
  methods: {
    showDates(item) {
      const dates_data = item.items
      if (!dates_data || !dates_data.length)
        return this.createAlert(this.$t('no_data'))
      this.dates = dates_data
      this.show_dates = true
    },
    changeFilterStatus(item) {
      this.$set(this.disables, item, true)
      this.$set(this.pagination, 'notify', item)
      // this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination()
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        this.pagination
      )
    },
  },

  components: { Table, TableLoad, ReportsFilter, CardsContainer, Modal },
}
</script>
