import ClickOutside from 'vue-click-outside'
import directive from '@/helper/directive'
export default {
  data() {
    return {}
  },
  created() {
    const locale = this.$cookies.get('locale')
    if (!locale) return
    this.appendLangElement(locale || 'en')
  },
  mounted() {},
  directives: {
    ClickOutside,
    ...directive,
  },
  methods: {
    multiAngle(icons) {
      icons.forEach((icon) => {
        const regex = /fa-angle-(right|left)/g
        if (regex.test(icon.className)) {
          icon.className = icon.className.replace(regex, 'fa-angle-down')
        } else {
          icon.className = icon.className.replace(
            'fa-angle-down',
            `fa-angle-${
              icon.classList.contains('rtl:block') ? 'left' : 'right'
            }`
          )
        }
      })
    },
    createImage(file) {
      return URL.createObjectURL(file)
    },
    handleOpen(ref) {
      setTimeout(() => {
        this.$refs[ref].$el.querySelector(
          '.vti__dropdown-list'
        ).style.width = `${this.$refs[ref].$el.offsetWidth}px`
      }, 5)
    },
    refresh() {
      // Object.keys(this).forEach((e) => {
      //   const functions = e.match(/\bget/gi)
      //   if (functions) {
      //     this.$store.commit('SET_LOAD', true)
      //     this[e]().finally(() => this.$store.commit('SET_LOAD', false))
      //   }
      // })
    },
    openFilter(e) {
      const filterBox = this.$refs.filter
      const filterIcon = this.$refs.filterIcon
      if (filterBox.classList.contains('hidden')) {
        filterBox.classList.replace('hidden', 'grid')
        filterIcon.classList.replace('fa-angle-down', 'fa-angle-up')
      } else {
        filterBox.classList.replace('grid', 'hidden')
        filterIcon.classList.replace('fa-angle-up', 'fa-angle-down')
      }
    },
    getCurrentDate(_day, idx) {
      const date = new Date()
      let anotherDate = this.addDays(date, idx === 0 ? -1 : idx - 1)
      // console.log(anotherDate)
      return `${anotherDate.getDate()}-${anotherDate.getFullYear()}`
    },
    addDays(date, days) {
      var result = new Date(date)
      result.setDate(date.getDate() + days)
      return result
    },
    changeLimitText(count, _content) {
      return `+ ${count}`
    },
    // countdown transform
    transformTimer(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value

        props[key] = `${digits}`
      })

      return props
    },
    // create alert with options
    createAlert(content, type) {
      // this.$noty.show(content).setType((type ??= 'success'))
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        },
      })

      Toast.fire({
        icon: (type ??= 'success'),
        title: content,
      })
    },
    changeWidth(e) {
      let type = localStorage.getItem('window')
      const containers = document.querySelectorAll('.aside-move')

      containers.forEach((elem) => {
        if (!type) {
          localStorage.setItem('window', 'full')
          return
        }
        if (type === 'full') {
          localStorage.setItem('window', 'center')
          elem.classList.add('xl:container')
          this.$store.commit('SET_WIDTH', 'center')
        } else {
          localStorage.setItem('window', 'full')
          elem.classList.remove('xl:container')
          this.$store.commit('SET_WIDTH', 'full')
        }
      })
    },
    textPasswordType(e) {
      e.preventDefault()
      let input = e.currentTarget.parentElement.querySelector('input')
      if (input.type === 'password') {
        input.setAttribute('type', 'text')
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-eye', 'fa-eye-slash')
      } else {
        input.setAttribute('type', 'password')
        e.currentTarget
          .querySelector('i')
          .classList.replace('fa-eye-slash', 'fa-eye')
      }
    },
    genPassword(el) {
      var chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      var passwordLength = 12
      var password = ''
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }
      this.$refs[el].value = password
    },
    convertImage(file) {
      try {
        const url = URL.createObjectURL(file)

        return url // return blob file preview :)
      } catch (error) {
        this.createAlert(
          'There is something went wrong while converting current file :/',
          'error'
        )
      }
    },
    changeLang(lang) {
      const locale = this.$cookies.get('locale')
      this.$cookies.set('locale', lang || locale || 'en')
      this.appendLangElement(lang || locale || 'en')
    },
    appendLangElement(lang) {
      this.$i18n.locale = lang
      const html = document.documentElement,
        htmlAttrs = {
          lang: lang,
          dir: this.$i18n.locale === 'ar' ? 'rtl' : 'ltr',
        }
      Object.entries(htmlAttrs).forEach(([key, value]) => {
        html.setAttribute(key, value)
      })
    },
    logout() {
      this.$cookies.remove('t')
      location.reload()
    },
    createTime(time) {
      const date = this.$moment(time).format('DD-MM-YYYY')
      return time && date && date !== 'Invalid date'
        ? `
       <p class="text-sm text-slate-600 font-semibold">${date}</p>`
        : `<p class="text-sm text-slate-600 font-semibold">N/A</p>`
    },
    deleteAlert() {
      return this.$swal.fire({
        title: this.$t('deletion.title'),
        text: this.$t('deletion.content'),
        showDenyButton: true,
        confirmButtonText: this.$t('delete'),
        denyButtonText: this.$t('no'),
      })
    },
  },
}
