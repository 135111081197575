<template>
  <div>
    <v-tour name="myTour" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template slot="content">
              <div class="p-3">
                <p
                  class="text-sm font-medium rtl:font-semibold text-slate-600"
                  v-html="tour.steps[tour.currentStep].content"
                ></p>
              </div>
            </template>
            <template>
              <div
                slot="actions"
                class="actions-group p-3 border-t border-t-slate-100"
              >
                <button
                  @click="tour.previousStep"
                  class="
                    py-2
                    px-3
                    rounded
                    text-xs
                    font-medium
                    rtl:font-semibold
                    text-center
                    bg-slate-100
                    text-slate-600
                  "
                >
                  {{ $t('prev') }}
                </button>
                <!-- v-if="tour.isLast" -->
                <button
                  @click="finishFunction(tour)"
                  class="
                    py-2
                    px-3
                    rounded
                    text-xs
                    font-medium
                    rtl:font-semibold
                    text-center
                    bg-primary
                    text-white
                  "
                >
                  {{ $t('finish') }}
                </button>
                <button
                  @click="tour.nextStep"
                  class="
                    py-2
                    px-3
                    rounded
                    text-xs
                    font-medium
                    rtl:font-semibold
                    text-center
                    bg-primary
                    text-white
                  "
                >
                  {{ $t('next') }}
                </button>
                <p
                  class="
                    text-xs
                    font-medium
                    rtl:font-semibold
                    text-slate-600
                    flex-1
                    text-right
                    rtl:text-left
                  "
                >
                  {{ tour.currentStep + 1 }} -
                  {{ tour.steps.length }}
                </p>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
export default {
  name: 'VueTour',
  data() {
    return {
      steps: [
        {
          target: '[data-tour-name="language"]',
          header: {
            title: 'Portal language',
          },
          content: 'Choose your primary language',
        },
        {
          target: '[data-tour-name="messages"]',
          header: {
            title: 'Messages',
          },
          content:
            'All merchant messages here can see more from the messages page',
        },
        {
          target: '[data-tour-name="profile"]',
          header: {
            title: 'Update profile',
          },
          content: `Click <a href="/profile" class="text-primary underline hover:no-underline">here</a> to be directed to your introductory file page to update the data as you want`,
        },
        {
          target: '[data-tour-name="support"]',
          header: {
            title: 'Live support',
          },
          content:
            "We're 24hr online get in touch with us anytime we're available for you.",
        },
      ],
      options: {},
    }
  },
  methods: {
    finishFunction(tour) {
      tour.finish()
      localStorage.setItem('tour-enabled', true)
    },
  },
}
</script>

<style lang="scss">
.v-step {
  .v-step__header {
    background-color: #f3f4f6 !important;
    color: #1e293b !important;
    margin: 0px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding: 10px !important;
    @apply text-left rtl:text-right font-semibold rtl:font-bold text-sm border-b border-b-slate-200;
  }
  background-color: #ffffff !important;
  box-shadow: none !important;
  padding: 0px !important;
  //   max-width: 480px !important;
  border-radius: 10px !important;
  @apply border border-slate-200;
  .v-step__arrow {
    &::before {
      background-color: #f1f5f9 !important;
      border-top: 1px solid #e5e7eb;
      border-left: 1px solid #e5e7eb;
    }
  }
}
</style>
