var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full grid xs:grid-cols-1 grid-cols-[repeat(auto-fill,minmax(17em,auto))] gap-4 items-stretch"},_vm._l((_vm.result),function(image,index){return _c('single-image-gallery-card',{key:index,attrs:{"item":image},on:{"open":function (ref) {
          var show = ref.show;
          var item = ref.item;

          _vm.preview_active = show
          _vm.preview = item
        }}})}),1),(!_vm.result.length)?_c('div',[_c('empty-card',{attrs:{"title":_vm.$t('no_images'),"icon":"fa-solid fa-images fa-xl"}})],1):_vm._e(),_c('transition',{attrs:{"name":"scale"}},[(_vm.preview_active)?_c('modal',{attrs:{"title":"Image preview","size":'!max-w-screen-sm'},on:{"close":function () {
          _vm.preview_active = false
          _vm.preview = {}
        }}},[[_c('div',{staticClass:"w-full space-y-4 p-4"},[_c('img',{staticClass:"w-full h-auto object-cover",attrs:{"src":_vm.preview.image || require('@/assets/images/placeholder.png'),"alt":"visit image","title":"image"},on:{"error":function (e) { return (e.target.src = require('@/assets/images/placeholder.png')); }}}),_c('div',{staticClass:"space-y-1"},[_c('p',{staticClass:"text-sm font-semibold rtl:font-bold"},[_vm._v(" "+_vm._s(_vm.preview.desc)+" ")])])])]],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }