var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.load)?_c('section',{staticClass:"w-full space-y-4"},[_vm._l((Math.ceil(_vm.items.length / 10)),function(i){return _c('figure',{key:i,staticClass:"w-full bg-white rounded py-4 ring-1 ring-slate-200 hidden"},[(_vm.load)?_c('apexchart',{attrs:{"type":"bar","height":"480","options":Object.assign({}, _vm.chartOptions,
          _vm.globalOptions,

          {xaxis: {
            labels: {
              show: true,
              showDuplicates: true,
              trim: true,
              style: { fontFamily: 'cairo', fontWeight: 500 },
            },
            type: 'category',

            categories: _vm.items.filter(function (e) { return e.item === i; }).map(function (e) { return e.name; }),
            tickPlacement: 'on',
            position: 'bottom',
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          }}),"series":[
          {
            name: _vm.$t('available'),
            data: _vm.items
              .filter(function (e) { return e.item === i; })
              .map(function (e) { return e.available_skus_count; }),
          },
          {
            name: _vm.$t('not_available'),
            data: _vm.items
              .filter(function (e) { return e.item === i; })
              .map(function (e) { return e.not_available_skus_count; }),
          } ]}}):_vm._e()],1)}),_c('div',{staticClass:"grid gap-4 sm:grid-cols-[repeat(auto-fill,minmax(20rem,1fr))]"},_vm._l((_vm.items),function(item,index){return _c('figure',{key:index,staticClass:"\n          w-full\n          bg-white\n          rounded\n          p-4\n          ring-1 ring-slate-200\n          relative\n          overflow-hidden\n        ",attrs:{"data-item-id":item.id}},[(_vm.load)?_c('apexchart',{attrs:{"type":"donut","height":"480","options":Object.assign({}, _vm.chartOptions,
            _vm.globalOptions,
            {title: {
              text: item['name'],
              margin: 20,
              offsetX: 0,
              offsetY: -10,
              floating: false,
              style: {
                fontSize: '18px',
                fontFamily: 'Montserrat, Cairo',
                fontWeight: 'bold',
                color: '#263238',
              },
            },
            labels: [_vm.$t('available'), _vm.$t('not_available')]}),"series":[item.available_skus_count, item.not_available_skus_count]}}):_vm._e(),(_vm.disables['fetch-category-id-' + item.id])?_c('div',{staticClass:"\n            absolute\n            top-0\n            right-0\n            w-full\n            h-full\n            bg-slate-800/20\n            backdrop-blur-sm\n            flex\n            items-center\n            justify-center\n            p-4\n          "},[_c('spinner',{staticClass:"shrink-0",attrs:{"size":"w-8 h-8 inline-block"}})],1):_vm._e()],1)}),0)],2):_c('section',{staticClass:"w-full space-y-4"},_vm._l((2),function(i){return _c('figure',{key:i,staticClass:"w-full bg-white rounded p-4 ring-1 ring-slate-200"},[_c('figcaption',{staticClass:"w-full bg-slate-200 animate-pulse h-60 rounded"}),_c('blockquote',{staticClass:"p-4"},[_c('ul',{staticClass:"\n            list-none\n            flex\n            items-center\n            justify-center\n            max-w-3xl\n            mx-auto\n            gap-3\n          "},_vm._l((6),function(id){return _c('li',{key:id,staticClass:"flex-1 bg-slate-200 animate-pulse rounded h-4"})}),0)])])}),0),(_vm.skus_active)?_c('Modal',{attrs:{"title":_vm.$t('skus'),"size":"!max-w-screen-lg"},on:{"close":function () {
        _vm.skus_active = _vm.$event
        _vm.skus = []
      }}},[[_c('div',{staticClass:"px-6 py-2"},[_c('Table',{attrs:{"has_search":false,"title":_vm.$t('skus'),"has_paginate":false,"has_import":false,"has_export":false,"has_customize":false,"has_check":false},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('th',[_vm._v("SKU")]),_c('th',[_c('div',{staticClass:"sort"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('barcode')))])])])]),_c('th',[_c('div',{staticClass:"sort"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('selling_price')))])])])])]},proxy:true},(_vm.skus.length !== 0)?{key:"tbody",fn:function(){return _vm._l((_vm.skus),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"actions-group !flex-no-wrap"},[_c('div',[_c('div',{staticClass:"w-12 h-auto"},[_c('img',{staticClass:"w-full max-h-full object-contain",attrs:{"src":item.image ||
                          require('@/assets/images/placeholder.png'),"alt":((item.name) + " image")},on:{"error":function (e) { return (e.target.src = require('@/assets/images/placeholder.png')); }}})])]),_c('div',{staticClass:"flex-1 whitespace-nowrap"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.alt_name))])])])]),_c('td',[_vm._v(" "+_vm._s(item.barcode || '...')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.selling_price + ' SAR' || 'N/A')+" ")])])})},proxy:true}:(_vm.skus.length === 0)?{key:"tbody",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":3}},[_c('p',{staticClass:"\n                    text-sm text-slate-600\n                    font-medium\n                    rtl:font-semibold\n                    text-center\n                  "},[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])])])]},proxy:true}:null],null,true)})],1)]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }