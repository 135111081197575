<template>
  <div>
    <header class="w-full overflow-x-auto">
      <ul class="flex items-center w-full gap-6" v-if="link_type === 'button'">
        <li v-for="(item, index) in filterTabs" :key="index">
          <button
            class="py-2 text-center font-medium rtl:font-semibold text-sm capitalize whitespace-nowrap"
            :class="{
              'active-tab-pills': component_name === item.name,
            }"
            @click.prevent="$emit('action', item.name)"
          >
            {{ item.key ? $t(item.key) : item.title }}
          </button>
        </li>
      </ul>
      <ul class="flex items-center w-full gap-6" v-if="link_type === 'link'">
        <li v-for="(item, index) in filterTabs" :key="index">
          <router-link
            class="py-2 text-center font-medium rtl:font-semibold text-sm text-slate-600 border-transparent block whitespace-nowrap"
            :to="item.url"
            exact-active-class="active-tab-pills"
            :class="{
              'active-tab-pills': queryKey ? checkTab(item.url) : null,
            }"
          >
            {{ item.key ? $t(item.key) : item.title }}
          </router-link>
        </li>
      </ul>
    </header>
  </div>
</template>
<script>
export default {
  name: 'TabsHeader',
  props: {
    link_type: String,
    component_name: [String, Number, Boolean],
    tabs: Array,
    hasPermission: {
      type: Boolean,
      default: false,
    },
    queryKey: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    filterTabs() {
      const permissions = this.$store.getters['auth/permissions']
      const tasks = this.$store.getters['auth/tasks']
      const pages = this.tabs

      return pages.filter((e) => {
        const permission = e.permission

        // return all tabs in case tabs without permissions
        if (!this.hasPermission) return e

        // check first if current permission has audits
        if (/\w+_audits/gi.test(permission)) {
          // filter tabs to return only matched
          const taskTypes = tasks.map((_e) => _e.type)
          console.log(permission, taskTypes)

          if (
            taskTypes.some((_e) => new RegExp(permission, 'gi').test(_e)) ||
            ['visits', 'images'].includes(permission)
          ) {
            return e
          }
          return
        }

        if (permissions?.length) {
          if (
            permissions.some((_e) =>
              new RegExp(`^(show|edit|delete|update) ${permission}`, 'gi').test(
                _e
              )
            )
          ) {
            return e
          }
        } else {
          return e
        }
      })
    },
    checkTab() {
      return (path) => {
        const url = new URL(window.location.origin + path)
        const search = new URLSearchParams(url.search)
        return this.$route.query[this.queryKey] === search.get(this.queryKey)
      }
    },
  },
}
</script>
