import Vue from 'vue'
import store from '@/store/index'

import SlideTransition from '@/components/basics/SlideTransition.vue'
Vue.component('slide-transition', SlideTransition)

// plugins
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// apexCharts
import VueApexCharts from 'vue-apexcharts'
Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)

// // Multiselect
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// // register globally
Vue.component('multiselect', Multiselect)

// moment;
const moment = require('moment')
Vue.use(require('vue-moment'), { moment })

// light book
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)

// vuejs-paginate
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// vue tel input
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput, {
  onlyCountries: ['sa', 'bh', 'ae', 'jo', 'kw', 'qa', 'eg'],
  mode: 'international',
  defaultCountry: 'SA',
  enabledFlags: true,
  // customValidate: true,
  dropdownOptions: {
    showDialCodeInSelection: false,
    showFlags: true,
  },
  styleClasses: 'custom-tel-input',
  inputOptions: {
    placeholder: 'Mobile number',
    required: true,
  },
})

import VueSweetalert2 from 'vue-sweetalert2'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
import '@sweetalert2/theme-borderless/borderless.scss'

Vue.use(VueSweetalert2)

import VueCustomSpinner from 'vue-custom-spinner'
Vue.component('spinner', VueCustomSpinner)

// VueCountdown
import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)

// import vuejs-google-map
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: store.state.map_api_key,
    libraries: 'places',
  },
})

import Geocoder from '@pderas/vue2-geocoder'

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: 'ar', // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: store.state.map_api_key,
})
import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy, {
  animation: 'shift-away',
})

Vue.component('tippy', TippyComponent)
//
import 'tom-select/dist/css/tom-select.css'

import EmptyCard from '@/components/basics/EmptyCard'
Vue.component('EmptyCard', EmptyCard)

import VueTagsInput from '@johmun/vue-tags-input'
Vue.component('v-tags', VueTagsInput)

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

import VueTailwind from 'vue-tailwind'
import { TDatepicker, TDropdown } from 'vue-tailwind/dist/components'

const settings = {
  't-datepicker': {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-full',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input:
          'block w-full px-3 py-2.5 transition duration-100 ease-in-out border rounded disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton:
          'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3',
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown:
          'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-1 inline-flex',
        inputWrapper: '',
        input: 'text-black placeholder-slate-400 border-slate-200',
        clearButton:
          'hover:bg-slate-100 rounded transition duration-100 ease-in-out text-slate-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-2 px-3',
        navigatorViewButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-slate-100',
        navigatorViewButtonIcon: 'fill-current text-slate-400',
        navigatorViewButtonBackIcon: 'fill-current text-slate-400',
        navigatorViewButtonMonth: 'text-slate-700 font-semibold',
        navigatorViewButtonYear: 'text-slate-500 ml-1',
        navigatorViewButtonYearRange: 'text-slate-500 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-slate-700 font-semibold',
        navigatorLabelYear: 'text-slate-500 ml-1',
        navigatorPrevButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-slate-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-slate-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-slate-400',
        navigatorNextButtonIcon: 'text-slate-400',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay:
          'uppercase text-xs text-slate-500 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay:
          'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-slate-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay:
          'text-sm bg-blue-500 text-white w-full h-8 rounded-l-full',
        inRangeLastDay:
          'text-sm bg-blue-500 text-white w-full h-8 rounded-r-full',
        inRangeDay:
          'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay:
          'text-sm rounded-full w-8 h-8 mx-auto bg-blue-500 text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay:
          'text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay:
          'text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today:
          'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500',
        monthWrapper: 'px-3 pt-2',
        selectedMonth:
          'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
        activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
        month: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        yearWrapper: 'px-3 pt-2',
        year: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        selectedYear:
          'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
        activeYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
      },
      variants: {
        danger: {
          input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
          clearButton: 'hover:bg-red-200 text-red-500',
        },
      },
    },
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button:
          'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown:
          'origin-bottom-left absolute right-0 rtl:left-0 rtl:right-auto w-screen max-w-[10rem] rounded shadow mb-1 bottom-full overflow-hidden',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
      },
      classes: {
        button: 'bg-blue-500 hover:bg-blue-600',
        dropdown: 'bg-white',
      },
      variants: {
        danger: {
          button: 'bg-red-500 hover:bg-red-600',
          dropdown: 'bg-red-50',
        },
      },
    },
  },
}

Vue.use(VueTailwind, settings)
