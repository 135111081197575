<template>
  <div>
    <Modal
      v-if="value"
      @close="$emit('input', $event)"
      size="!max-w-screen-md"
      :title="
        item.visit
          ? (item.visit.company_branch.company_store || {}).name ||
            (item.visit.company_branch || {}).name
          : $t('images')
      "
    >
      <template>
        <div class="w-full space-y-4 p-6">
          <div class="pb-4 border-b border-b-slate-100">
            <div class="relative mt-4">
              <Swiper
                class="w-full"
                :options="{
                  loopedSlides: 5,
                  slidesPerView: 1,
                  loop: false,
                  navigation: {
                    nextEl: 'button[data-type=\'navigation-btn-next\']',
                    prevEl: 'button[data-type=\'navigation-btn-prev\']',
                  },
                }"
                ref="swiperTop"
              >
                <SwiperSlide v-for="(image, index) in item.images" :key="index">
                  <figure class="space-y-6">
                    <figcaption>
                      <img
                        :src="
                          image || require('@/assets/images/placeholder.png')
                        "
                        alt="visit image"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="
                          w-full
                          max-h-[25rem]
                          h-auto
                          object-contain
                          mx-auto
                          aspect-square
                        "
                      />
                    </figcaption>
                    <blockquote>
                      <p
                        class="
                          font-medium
                          rtl:font-semibold
                          text-center text-slate-600 text-xs
                          shrink-0
                          flex-[max-content]
                        "
                      >
                        {{
                          item.images_before.includes(image)
                            ? $t('images_before')
                            : $t('images_after')
                        }}
                      </p>
                    </blockquote>
                  </figure>
                </SwiperSlide>
              </Swiper>
              <div
                class="
                  navigation-swiper
                  w-full
                  absolute
                  top-1/2
                  -translate-y-1/2
                  left-1/2
                  -translate-x-1/2
                  flex
                  justify-between
                  z-[5]
                "
              >
                <button
                  class="
                    flex
                    items-center
                    justify-center
                    gap-2
                    font-semibold
                    text-slate-600 text-sm
                    w-10
                    h-10
                    ring-1 ring-slate-200
                    rounded-full
                    bg-transparent
                    hover:bg-slate-200
                    shrink-0
                  "
                  data-type="navigation-btn-prev"
                >
                  <i class="fa-solid fa-angle-left block rtl:hidden"></i>
                  <i class="fa-solid fa-angle-right hidden rtl:block"></i>
                </button>
                <button
                  class="
                    flex
                    items-center
                    justify-center
                    gap-2
                    font-semibold
                    text-slate-600 text-sm
                    w-10
                    h-10
                    ring-1 ring-slate-200
                    rounded-full
                    bg-transparent
                    hover:bg-slate-200
                    shrink-0
                  "
                  data-type="navigation-btn-next"
                >
                  <i class="fa-solid fa-angle-right block rtl:hidden"></i>
                  <i class="fa-solid fa-angle-left hidden rtl:block"></i>
                </button>
              </div>
            </div>

            <Swiper
              class="w-full my-8"
              :options="{
                watchSlidesProgress: true,
                loop: false,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true,
              }"
              ref="swiperThumbs"
            >
              <SwiperSlide
                v-for="(image, index) in item.images"
                :key="index"
                class="
                  w-24
                  h-auto
                  slide-bottom
                  rounded
                  p-2
                  ring-1 ring-slate-200
                "
              >
                <figure class="space-y-4">
                  <figcaption class="table mx-auto">
                    <img
                      :src="image || require('@/assets/images/placeholder.png')"
                      alt="visit image"
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      class="max-w-full max-h-[12rem] object-contain"
                    />
                  </figcaption>
                  <!-- <blockquote>
                    <p
                      class="
                        font-medium
                        rtl:font-semibold
                        text-center text-slate-600 text-xs
                      "
                    >
                      {{
                        item.images_before.includes(image)
                          ? $t('images_before')
                          : $t('images_after')
                      }}
                    </p>
                  </blockquote> -->
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
          <div class="flex" v-if="!isVisit">
            <div class="space-y-1 flex-1">
              <div class="table">
                <div class="grid">
                  <p
                    class="
                      text-xs text-slate-600
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{
                      (item.visit.company_branch.company_store || {}).name ||
                      '...'
                    }}
                  </p>
                  <p
                    class="
                      text-xs text-slate-400
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{ (item.visit.company_branch || {}).name || '...' }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex-1">
              <span class="text-xs text-slate-400 font-semibold"
                >{{ item.images_count }} {{ $t('image') }}</span
              >
            </div>
          </div>
          <div class="flex">
            <div class="space-y-1 flex-1">
              <div class="table">
                <div class="grid">
                  <p
                    class="
                      text-xs text-slate-600
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{ $t('store_placement') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex-1">
              <div class="grid">
                <div class="actions-group">
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-for="(store, index) in (item.items || []).slice(0, 1)"
                    :key="index"
                    >{{ (store.store_placement || {}).name }}</span
                  >
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-if="(item.items || []).length > 1"
                    >+{{ (item.items || []).length - 1 }}</span
                  >
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-if="(item.items || []).length === 0"
                    >-</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="hidden" v-if="!isVisit">
            <div class="flex-1">
              <span class="text-xs text-slate-400 font-semibold">
                {{ $t('user') }}
              </span>
            </div>
            <div class="space-y-1 flex-1">
              <div class="table">
                <div class="grid">
                  <div class="actions-group">
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-for="(user, index) in (item.items || []).slice(0, 1)"
                      :key="index"
                      >{{ (user.user || {}).name }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.items || []).length > 1"
                      >+{{ (item.items || []).length - 1 }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.items || []).length === 0"
                      >-</span
                    >
                  </div>
                  <!-- <p
                    class="
                      text-xs text-slate-600
                      block
                      font-medium
                      rtl:font-semibold
                      truncate
                    "
                  >
                    {{ (item.visit.user || {}).name || '...' }}
                  </p>

                  <a
                    :href="`tel:${(item.visit.user || {})['mobile']}`"
                    class="
                      text-sm text-primary
                      font-medium
                      rtl:font-semibold
                      table
                      truncate
                    "
                  >
                    {{ (item.visit.user || {})['mobile'] }}
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/basics/Modal.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// Import Swiper styles
import 'swiper/css/swiper.min.css'
export default {
  name: 'PlangogramModal',
  components: { Modal, Swiper, SwiperSlide },
  props: {
    item: {
      type: Object,
      default: {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
  },
  watch: {
    value(val) {
      if (val) {
        setTimeout(() => {
          this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop?.$swiper
            const swiperThumbs = this.$refs.swiperThumbs?.$swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
          })
        }, 25)
      }
    },
  },
  data() {
    return {
      add_data: {
        images: [],
      },
      export_disabled: false,
      active_images: false,
      images: [],
    }
  },
  methods: {
    async ExportImages() {
      this.export_disabled = true
      try {
        const { data } = await this.axios.post(
          'reports/planogram_audits/export_pdf',
          { images: this.add_data.images?.map((e) => e.split('/').pop()) },
          {
            responseType: 'blob',
            headers: {
              'Content-type': 'blob',
            },
          }
        )
        let blob = new Blob([data])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `planogram-images-${new Date().getTime()}.pdf`
        link.click()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        // this.createAlert(res?.data?.message, 'error')
        this.createAlert(
          'You cannot export current table at that moment, please try again or contact us through live chat.',
          'error'
        )
      } finally {
        this.export_disabled = false
      }
    },
    handleChange({ target: { checked } }, image) {
      console.log(checked)
      if (checked) {
        this.add_data.images = [...new Set([...this.add_data.images, image])]
      } else {
        this.$set(
          this.add_data,
          'images',
          this.add_data.images.filter((e) => e !== image)
        )
      }
    },
    handleClose() {
      this.add_data.images = []
      this.active_images = false
      this.images = []
    },
    displayImages() {
      this.images = this.item.images
      this.active_images = true
    },
  },
}
</script>

<style >
.slide-bottom {
  width: 25%;
  opacity: 1;
}
.slide-bottom:not(.swiper-slide-active) {
  opacity: 0.4;
}
</style>