<template>
  <a
    href="javascript:void(0)"
    class="h-full"
    @click.prevent="
      () => {
        $emit('open', { show: true, item })
      }
    "
  >
    <figure
      class="
        w-full
        ring-1 ring-slate-200
        bg-white
        rounded
        overflow-hidden
        h-full
      "
    >
      <figcaption
        class="
          flex-1
          h-auto
          sm:h-full sm:min-h-[20rem] sm:max-h-[25rem]
          relative
        "
      >
        <img
          :src="item.image || require('@/assets/images/placeholder.png')"
          alt="visit image"
          @error="
            (e) => (e.target.src = require('@/assets/images/placeholder.png'))
          "
          class="w-full h-full object-cover"
          title="image"
        />
        <div
          class="
            w-full
            h-full
            absolute
            inset-0
            bg-gradient-to-t
            from-black/70
            space-y-1
            p-4
            flex flex-col
            justify-end
            group
            hover:justify-center
          "
        >
          <div class="space-y-1 group-hover:hidden">
            <p
              class="
                text-white
                line-clamp-1
                text-sm
                font-semibold
                rtl:font-bold
              "
            >
              {{ item.desc }}
            </p>
          </div>
          <div
            class="
              w-12
              h-12
              rounded-full
              hidden
              group-hover:flex
              items-center
              justify-center
              self-center
              justify-self-center
              bg-black/50
            "
          >
            <i class="fa-solid fa-expand text-white"></i>
          </div>
        </div>
      </figcaption>
    </figure>
  </a>
</template>

<script>
export default {
  name: 'SingleImageGalleryCard',
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
}
</script>
