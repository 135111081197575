<template>
  <div>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter v-show="!isVisit"> </reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('delivery_audits')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          :fields="fields"
          v-model="pagination.page"
        >
          <template #head>
            <th v-if="!isVisit && columns.includes('user')">
              {{ $t('user') }}
            </th>
            <th v-if="columns.includes('sku')">{{ $t('sku') }}</th>

            <th v-if="columns.includes('qty')">
              <div class="sort">
                <div>
                  <p>{{ $t('qty') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('unit')">
              <div class="sort">
                <div>
                  <p>{{ $t('unit') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="!isVisit && columns.includes('branch')">
              {{ $t('branch') }}
            </th>
            <th v-if="!isVisit && columns.includes('store')">
              {{ $t('store') }}
            </th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && result.length !== 0">
            <tr v-for="(item, index) in result" :key="index">
              <td v-if="!isVisit && columns.includes('user')">
                <p>{{ (item.user || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.user || {}).mobile || '...' }}</a
                >
              </td>
              <td v-if="columns.includes('sku')">
                <div class="actions-group !flex-no-wrap">
                  <div>
                    <div class="w-12 h-auto">
                      <img
                        :src="
                          (item.sku || {}).image ||
                          require('@/assets/images/placeholder.png')
                        "
                        :alt="`${(item.sku || {}).name} image`"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                  <div class="flex-1 whitespace-nowrap">
                    <p class="text-sky-500">
                      {{ (item.sku || {}).barcode || '...' }}
                    </p>
                    <p class="text-slate-600">
                      {{ (item.sku || {}).name || '...' }}
                    </p>
                  </div>
                </div>
              </td>

              <td v-if="columns.includes('qty')">{{ item.qty || 0 }}</td>
              <td v-if="columns.includes('unit')">
                {{ (item.unit || {}).name || '...' }}
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="
                  createTime(
                    !isVisit ? (item.visit || {}).visit_date : item.visit_date
                  )
                "
              ></td>

              <td v-if="!isVisit && columns.includes('branch')">
                <p>{{ (item.visit.company_branch || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.visit.company_branch || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.visit.company_branch || {}).mobile || '...' }}</a
                >
              </td>

              <td v-if="!isVisit && columns.includes('store')">
                {{ (item.visit.company_store || {}).name || '...' }}
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && result.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReturnsReports from '@/views/reports/ReturnsReports.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'

export default {
  name: 'DeliveryAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      add_data: {
        unit_id: null,
      },
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('customize', ['getColumns', 'getSelectedColumns']),
    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },

  components: {
    Table,
    TableLoad,
    ReturnsReports,
    ReportsFilter,
    CardsContainer,
  },
}
</script>
