<template>
  <div class="space-y-6">
    <div class="px-6">
      <Tabs :link_type="'link'" :tabs="pagesTab" has-permission />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from '@/components/basics/Tabs.vue'
export default {
  name: 'ConfigurationWrapper',
  data() {
    return {
      pagesTab: [
        {
          permission: 'brands',
          url: '/setup/configuration/brands',

          title: 'Brands',
          key: 'brands',
        },
        {
          permission: 'company_stores',
          url: '/setup/configuration/stores',
          title: 'Stores',
          key: 'stores',
        },
        {
          permission: 'company_branches',
          url: '/setup/configuration/branches',

          title: 'Branches',
          key: 'branches',
        },

        {
          permission: 'main_categories',
          url: '/setup/configuration/main-categories',

          title: 'Main categories',
          key: 'main_categories',
        },
        {
          permission: 'categories',
          url: '/setup/configuration/categories',

          title: 'Categories',
          key: 'categories',
        },
        {
          permission: 'sub_categories',
          url: '/setup/configuration/sub-categories',

          title: 'Sub categories',
          key: 'sub_categories',
        },
        {
          permission: 'planograms',
          url: '/setup/configuration/planograms',

          title: 'Planograms',
          key: 'planograms',
        },
        {
          permission: 'shelves|shelf_audits',
          url: '/setup/configuration/shelves',

          title: 'Shelves',
          key: 'shelves',
        },
        {
          permission: 'store_placements',
          url: '/setup/configuration/store-placements',

          title: 'Store placements',
          key: 'store_placements',
        },
        {
          permission: 'units',
          url: '/setup/configuration/units',

          title: 'Units',
          key: 'units',
        },
        {
          permission: 'c_reasons',
          url: '/setup/configuration/cancellation-reasons',

          title: 'Cancellation reasons',
          key: 'c_reasons',
        },
        {
          permission: 'competitor_promotion_audits',
          url: '/setup/configuration/competitors',
          title: 'Competitors',
          key: 'competitors',
        },
      ],
    }
  },
  components: {
    Tabs,
  },
}
</script>
