<template>
  <div>
    <div
      class="w-full grid xs:grid-cols-1 grid-cols-[repeat(auto-fill,minmax(17em,auto))] gap-4 items-stretch"
    >
      <single-image-gallery-card
        v-for="(image, index) in result"
        :key="index"
        :item="image"
        @open="
          ({ show, item }) => {
            preview_active = show
            preview = item
          }
        "
      />
    </div>
    <div v-if="!result.length">
      <empty-card :title="$t('no_images')" icon="fa-solid fa-images fa-xl" />
    </div>
    <transition name="scale">
      <modal
        title="Image preview"
        :size="'!max-w-screen-sm'"
        v-if="preview_active"
        @close="
          () => {
            preview_active = false
            preview = {}
          }
        "
      >
        <template>
          <div class="w-full space-y-4 p-4">
            <img
              :src="preview.image || require('@/assets/images/placeholder.png')"
              alt="visit image"
              @error="
                (e) =>
                  (e.target.src = require('@/assets/images/placeholder.png'))
              "
              class="w-full h-auto object-cover"
              title="image"
            />
            <div class="space-y-1">
              <p class="text-sm font-semibold rtl:font-bold">
                {{ preview.desc }}
              </p>
            </div>
          </div>
        </template>
      </modal>
    </transition>
  </div>
</template>

<script>
import EmptyCard from '../basics/EmptyCard.vue'
import Modal from '../basics/Modal.vue'
import SingleImageGalleryCard from './components/SingleImageGalleryCard.vue'
export default {
  components: { SingleImageGalleryCard, Modal, EmptyCard },
  name: 'ImagesAudits',
  data() {
    return {
      preview_active: false,
    }
  },
  props: {
    result: {
      type: Array,
      default: Array,
    },
  },
}
</script>
