<template>
  <div>
    <sub-pages-header>
      <main>
        <form class="space-y-6">
          <figure
            class="w-full p-6 sm:p-10 rounded-md ring-1 ring-slate-200 bg-white space-y-4"
          >
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3 self-end">
                <label for="name">{{ $t('image') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <figure class="space-y-4">
                  <figcaption v-if="add_data.image" class="relative table">
                    <img
                      :src="
                        add_data.image
                          ? convertImage(add_data.image)
                          : add_data.image ||
                            require('@/assets/images/placeholder.png')
                      "
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      alt="product image"
                      class="w-auto max-h-[12rem] ring-1 ring-slate-200 object-contain"
                    />
                    <button
                      class="w-6 h-6 rounded-full bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                      @click.prevent="
                        () => {
                          ;($refs.skuImage.value = null),
                            $set(add_data, 'image', null)
                        }
                      "
                    >
                      <i class="fa-solid fa-times fa-sm align-middle"></i>
                    </button>
                  </figcaption>
                  <blockquote class="space-y-2">
                    <label class="block">
                      <span class="sr-only">{{ $t('choose_image') }}</span>
                      <input
                        autocomplete="off"
                        type="file"
                        accept="image/*"
                        class="custom-file"
                        @change="
                          (e) => $set(add_data, 'image', e.target.files[0])
                        "
                        ref="skuImage"
                      />
                    </label>
                    <p
                      class="text-red-500 font-medium rtl:font-semibold text-sm"
                      v-if="add_errors.image"
                    >
                      {{ add_errors.image.join(' ') }}
                    </p>
                  </blockquote>
                </figure>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="name">{{ $t('name') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Name"
                  v-ltr
                  v-model="add_data.name"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name"
                >
                  {{ add_errors.name.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <input
                  autocomplete="off"
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  required
                  placeholder="الاسم"
                  v-rtl
                  v-model="add_data.alt_name"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alt_name"
                >
                  {{ add_errors.alt_name.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="sku">{{ $t('sku') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <input
                  autocomplete="off"
                  type="text"
                  name="sku"
                  id="sku"
                  required
                  placeholder="eg: abc"
                  v-model="add_data.sku"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.sku"
                >
                  {{ add_errors.sku.join(' ') }}
                </p>
              </div>
            </div>
          </figure>
          <figure
            class="w-full p-6 sm:p-10 rounded-md ring-1 ring-slate-200 bg-white space-y-4"
          >
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="categories">{{ $t('categories') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <multiselect
                  v-model="add_data.category_ids"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  :options="categories"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="categories.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.category_ids"
                >
                  {{ add_errors.category_ids.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="sub_categories">{{ $t('sub_categories') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <multiselect
                  v-model="add_data.sub_category_ids"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  :options="subCategories"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="subCategories.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.sub_category_ids"
                >
                  {{ add_errors.sub_category_ids.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="brands">{{ $t('brand') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <multiselect
                  v-model="add_data.brand_id"
                  :options="brands"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="brand_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="brands.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.brand_id"
                >
                  {{ add_errors.brand_id.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="store_placements">{{
                  $t('store_placements')
                }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <multiselect
                  v-model="add_data.store_placement_ids"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  :options="store_placements"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="store_placements.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.store_placement_ids"
                >
                  {{ add_errors.store_placement_ids.join(' ') }}
                </p>
              </div>
            </div>
          </figure>
          <figure
            class="w-full p-6 sm:p-10 rounded-md ring-1 ring-slate-200 bg-white space-y-4"
          >
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="barcode">{{ $t('barcode') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <input
                  autocomplete="off"
                  type="text"
                  name="barcode"
                  id="barcode"
                  required
                  placeholder="eg: 123456"
                  v-model="add_data.barcode"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.barcode"
                >
                  {{ add_errors.barcode.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3">
                <label for="selling_price">{{ $t('selling_price') }}</label>
              </div>
              <div class="w-full sm:w-2/3 space-y-2">
                <input
                  autocomplete="off"
                  type="number"
                  name="selling_price"
                  min="1"
                  id="selling_price"
                  required
                  :placeholder="$t('not_available')"
                  v-model="add_data.selling_price"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.selling_price"
                >
                  {{ add_errors.selling_price.join(' ') }}
                </p>
              </div>
            </div>
            <div
              class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            >
              <div class="w-full sm:w-1/3"></div>
              <div class="w-full sm:w-2/3 space-y-2">
                <div class="form-group-with-check">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    name="has_exp_date"
                    id="has_exp_date"
                    v-model="add_data.has_exp_date"
                    :true-value="1"
                    :false-value="0"
                  />
                  <label for="has_exp_date">Has expiry date?</label>
                </div>
              </div>
            </div>

            <slide-transition>
              <div
                class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
                v-if="!add_data.has_exp_date"
              >
                <div class="w-full sm:w-1/3">
                  <label for="duration">{{ $t('duration') }}</label>
                </div>
                <div class="w-full sm:w-2/3 space-y-2">
                  <select
                    class="custom-select"
                    id="exp_dur_type"
                    v-model="add_data.exp_dur_type"
                  >
                    <option value="" disabled selected>-- select --</option>
                    <option value="day">{{ $t('day') }}</option>
                    <option value="month">{{ $t('month') }}</option>
                    <option value="year">{{ $t('year') }}</option>
                  </select>
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.exp_dur_type"
                  >
                    {{ add_errors.exp_dur_type.join(' ') }}
                  </p>
                </div>
              </div>
            </slide-transition>
            <slide-transition>
              <div
                class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
                v-if="!add_data.has_exp_date"
              >
                <div class="w-full sm:w-1/3"></div>
                <div class="w-full sm:w-2/3 space-y-2">
                  <input
                    autocomplete="off"
                    type="number"
                    name="exp_dur"
                    id="exp_dur"
                    required
                    v-model="add_data.exp_dur"
                    min="1"
                    placeholder="6"
                  />
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.exp_dur"
                  >
                    {{ add_errors.exp_dur.join(' ') }}
                  </p>
                </div>
              </div>
            </slide-transition>
          </figure>
          <figure
            class="w-full p-6 sm:p-10 rounded-md ring-1 ring-slate-200 bg-white space-y-4"
          >
            <div class="w-full flex gap-3 group">
              <div>
                <i
                  class="fa-solid fa-bell group-hover:-rotate-45 transition-transform animate-bell"
                ></i>
              </div>
              <div class="flex-1">
                <p class="font-semibold rtl:font-bold text-slate-600 text-sm">
                  {{ $t('notify.title') }}
                </p>
              </div>
            </div>

            <div class="w-full form-group">
              <div class="w-full space-y-2">
                <input
                  autocomplete="off"
                  type="number"
                  name="alert_1_days"
                  id="alert_1_days"
                  min="0"
                  v-model="add_data.alert_1_days"
                  :placeholder="$t('notify.notify_2')"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alert_1_days"
                >
                  {{ add_errors.alert_1_days.join(' ') }}
                </p>
              </div>
            </div>
            <div class="w-full form-group">
              <div class="w-full space-y-2">
                <input
                  autocomplete="off"
                  type="number"
                  name="alert_2_days"
                  id="alert_2_days"
                  min="0"
                  v-model="add_data.alert_2_days"
                  :placeholder="$t('notify.notify_1')"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alert_2_days"
                >
                  {{ add_errors.alert_2_days.join(' ') }}
                </p>
              </div>
            </div>
            <div class="w-full form-group">
              <div class="w-full space-y-2">
                <input
                  autocomplete="off"
                  type="number"
                  name="alert_3_days"
                  id="alert_3_days"
                  min="0"
                  v-model="add_data.alert_3_days"
                  :placeholder="$t('notify.notify_0')"
                />
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alert_3_days"
                >
                  {{ add_errors.alert_3_days.join(' ') }}
                </p>
              </div>
            </div>
          </figure>
        </form>
      </main>
      <template #action>
        <button
          class="py-3 px-6 rounded-md bg-secondary text-white text-xs font-medium rtl:font-semibold"
          :disabled="add_disabled"
          @click.prevent="addItem"
        >
          <spinner
            size="w-4 h-4"
            class="inline-block align-sub"
            v-if="add_disabled"
          />
          {{ $t('submit') }}
        </button>
      </template>
    </sub-pages-header>
  </div>
</template>
<script>
import SubPagesHeader from '@/components/basics/SubPagesHeader.vue'
export default {
  components: { SubPagesHeader },
  name: 'CreateSku',
  data() {
    return {
      categories: [],
      sub_categories: [],
      brands: [],
      store_placements: [],
      add_disabled: false,
      add_errors: {},
      add_data: {
        category_ids: [],
        sub_category_ids: [],
        store_placement_ids: [],
        brand_id: null,
        image: null,
        exp_dur_type: 'month',
        has_exp_date: 0,
      },
    }
  },
  async created() {
    await Promise.all([
      this.getCategories(),
      this.getSubCategories(),
      this.getBrands(),
      this.getStorePlacement(),
    ])
  },
  computed: {
    subCategories() {
      return this.sub_categories.filter((e) => {
        const id = e.category?.id
        const category = this.add_data.category_ids.find((c) => c?.id === id)
        if (id === category?.id) {
          return e
        }
      })
    },
  },
  watch: {
    'add_data.has_exp_date'(val, _) {
      if (!val) {
        this.add_data.exp_dur_type = 'month'
      }
    },
  },
  methods: {
    async getStorePlacement() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'store_placements'
        )
        this.store_placements = result.store_placements
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getCategories() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'categories'
        )
        this.categories = result.categories
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getSubCategories() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'sub_categories'
        )
        this.sub_categories = result.sub_categories
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getBrands() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'brands'
        )
        this.brands = result.brands
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      let fd = new FormData()

      for (const key in this.add_data) {
        if (this.add_data.hasOwnProperty(key)) {
          const propValue = this.add_data[key]
          if (propValue || propValue === 0) {
            if (
              [
                'category_ids',
                'sub_category_ids',
                'store_placement_ids',
              ].includes(key)
            ) {
              propValue.forEach((item, idx) => {
                fd.append(`${key}[${idx}]`, propValue[idx]?.id)
              })
            } else if (key === 'image') {
              if (propValue) {
                fd.append(key, propValue, propValue.name)
              }
            } else if (['brand_id'].includes(key)) {
              fd.append(key, propValue?.id)
            } else {
              fd.append(key, propValue)
            }
          }
        }
      }

      // console.log(fd)
      try {
        const { data } = await this.axios.post('skus/add', fd)
        this.createAlert(data.message)
        this.add_data = {
          category_ids: [],
          sub_category_ids: [],
          store_placement_ids: [],
          brand_id: null,
          image: null,
        }
        this.$refs.skuImage.value = null
        this.$router.push('/setup/skus')
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
  },
}
</script>
<style lang="scss">
.animate-bell {
  transform-origin: top;
  // transform-box: ;
  animation: bell 1.5s infinite linear;
}
@keyframes bell {
  0%,
  100% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(-25deg);
  }
}
</style>
