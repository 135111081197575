<template>
  <div class="p-6">
    <figure
      class="ring-1 ring-slate-200 bg-white rounded max-w-screen-sm mx-auto"
    >
      <figcaption class="border-b border-b-slate-200 p-4">
        <p class="font-medium rtl:font-semibold text-sm">
          {{ $t('request_branch') }}
        </p>
      </figcaption>
      <blockquote class="p-4">
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name"
                v-ltr
              />
              <label for="name">{{ $t('name') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.name"
            >
              {{ add_errors.name.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <input
                type="text"
                name="alt_name"
                id="alt_name"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.alt_name"
                v-rtl
              />
              <label for="alt_name">{{ $t('alt_name') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.alt_name"
            >
              {{ add_errors.alt_name.join(' ') }}
            </p>
          </div>

          <div class="form-group">
            <div class="with-label">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                required
                autocomplete="off"
                v-model="add_data.email"
                v-email
              />
              <label for="email">{{ $t('email') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.email"
            >
              {{ add_errors.email.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <vue-tel-input
                id="mobile"
                ref="branchAddMobile"
                @open="handleOpen('branchAddMobile')"
                v-model="add_data.mobile"
              />
              <label for="mobile">{{ $t('mobile') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.mobile"
            >
              {{ add_errors.mobile.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <multiselect
                v-model="add_data.store_id"
                :options="stores"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="store_id"
              ></multiselect>
              <label for="store_id">{{ $t('store') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.store_id"
            >
              {{ add_errors.store_id.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <multiselect
                v-model="add_data.country_id"
                :options="countries"
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="country_id"
              ></multiselect>
              <label for="country_id">{{ $t('country') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.country_id"
            >
              {{ add_errors.country_id.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <multiselect
                v-model="add_data.region_id"
                :options="
                  FilterData(
                    'add_data',
                    'regions',
                    'country',
                    'country_id',
                    'region_id'
                  )
                "
                :disabled="
                  FilterData(
                    'add_data',
                    'regions',
                    'country',
                    'country_id',
                    'region_id'
                  ).length === 0
                "
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="region_id"
              ></multiselect>
              <label for="region_id">{{ $t('region') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.region_id"
            >
              {{ add_errors.region_id.join(' ') }}
            </p>
          </div>
          <div class="form-group">
            <div class="with-label">
              <multiselect
                v-model="add_data.city_id"
                :options="
                  FilterData(
                    'add_data',
                    'cities',
                    'region',
                    'region_id',
                    'city_id'
                  )
                "
                :disabled="
                  FilterData(
                    'add_data',
                    'cities',
                    'region',
                    'region_id',
                    'city_id'
                  ).length === 0
                "
                :placeholder="$t('search')"
                label="name"
                track-by="id"
                id="city_id"
              ></multiselect>
              <label for="city_id">{{ $t('city') }}</label>
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.city_id"
            >
              {{ add_errors.city_id.join(' ') }}
            </p>
          </div>
          <div class="space-y-2">
            <Map v-model="add_data.location" />
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="add_errors.address"
            >
              {{ add_errors.address.join(' ') }}
            </p>
          </div>

          <div class="actions-group">
            <div>
              <button
                class="
                  py-2.5
                  px-6
                  rounded-md
                  text-sm
                  font-medium
                  rtl:font-semibold
                  text-white
                  bg-primary
                  table
                "
                @click.prevent="addItem"
                :disabled="add_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                {{ $t('add') }}
              </button>
            </div>
          </div>
        </form>
      </blockquote>
    </figure>
  </div>
</template>

<script>
import Map from '@/components/basics/Map.vue'

export default {
  name: 'RequestBranch',
  data() {
    return {
      load: false,
      add_data: {
        location: {},
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      stores: [],
      countries: [],
      regions: [],
      cities: [],
    }
  },
  computed: {
    FilterData() {
      return (type, key, itemKey, neededKey, current_id) => {
        const items = this[key].filter(
          (e) => e?.[itemKey]?.id === this[type]?.[neededKey]?.id
        )
        if (items.length === 0) {
          this.$set(this[type], current_id, null)
        }
        return items ?? []
      }
    },
  },
  watch: {
    'add_data.branch_id'() {
      if (this.add_data.branch_id?.id) {
        const item = JSON.parse(
          JSON.stringify({
            ...this.add_data.branch_id,
            address: [],
          })
        )
        // filter current object and add new key
        Object.keys(item).forEach((key) => {
          const existed = item[key]
          if (['country', 'region', 'city'].includes(key)) {
            if (existed) {
              item.address.push(existed?.name)
            }
          }
          // add some keys as default
          if (['mobile', 'email', 'name'].includes(key)) {
            if (existed) {
              this.$set(this.add_data, key, existed)
            }
          }
        })

        // store new object
        this.item = item
      }
    },
  },
  async created() {
    await Promise.all([
      this.getStores(),
      this.getCountries(),
      this.getRegions(),
      this.getCities(),
    ]).finally(() => (this.load = true))
  },
  methods: {
    getStores() {
      this.$store.dispatch('getting/getRoute', 'stores').then(({ result }) => {
        this.stores = result.stores
      })
    },
    getCountries() {
      this.$store
        .dispatch('getting/getRoute', 'countries')
        .then(({ result }) => {
          this.countries = result.countries
        })
    },
    getRegions() {
      this.$store.dispatch('getting/getRoute', 'regions').then(({ result }) => {
        this.regions = result.regions
      })
    },
    getCities() {
      this.$store.dispatch('getting/getRoute', 'cities').then(({ result }) => {
        this.cities = result.cities
      })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data

      try {
        const { data } = await this.axios.post('branches/add', {
          ...item,
          ...item?.location,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          country_id: item?.country_id?.id,
          region_id: item?.region_id?.id,
          city_id: item?.city_id?.id,
          store_id: item?.store_id?.id,
        })
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
        this.$router.push({
          name: 'ConfigurationBranches',
        })
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
  },
  components: { Map },
}
</script>