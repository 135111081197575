<template>
  <div>
    <ConfigurationWrapper>
      <section class="w-full space-y-6 px-6">
        <filter-box :has-branches="false" />
        <main class="w-full">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('branches')"
            :records="`${pagination.total || 0} ${$t('records')}`"
            :pagination="pagination"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
            :fields="fields"
          >
            <template #actions>
              <div>
                <button
                  class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.branch') }}</span>
                </button>
              </div>
              <div>
                <router-link
                  to="/setup/configuration/branches/request-own-branch"
                  class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('request_branch') }}</span>
                </router-link>
              </div>
            </template>
            <template #filter>
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, '_')) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th v-if="columns.includes('name')">{{ $t('name') }}</th>
              <th v-if="columns.includes('alt_name')">{{ $t('alt_name') }}</th>

              <th v-if="columns.includes('total_skus')">
                <div class="sort">
                  <div>
                    <p>{{ $t('total_skus') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('total_skus')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('monthly_visits')">
                <div class="sort">
                  <div>
                    <p>{{ $t('monthly_visits') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('month_visits')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('merchandisers_count')">
                <div class="sort">
                  <div>
                    <p>{{ $t('merchandisers_count') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('merchandisers_count')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('store')">{{ $t('store') }}</th>
              <th v-if="columns.includes('users')">
                <div class="sort">
                  <div>
                    <p>{{ $t('users') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('users')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('active')">
                <div class="sort">
                  <div>
                    <p>{{ $t('active') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('is_active')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('updated_at')">
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>

              <th v-if="columns.includes('actions')"></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="columns.includes('name')">
                  {{ item.name || '...' }}
                </td>
                <td v-if="columns.includes('alt_name')">
                  {{ item.alt_name || '...' }}
                </td>
                <td v-if="columns.includes('total_skus')">
                  <button
                    class="py-2 px-3 rounded border border-gray-300 bg-white text-gray-600 font-semibold text-center text-xs inline-flex gap-2 items-center"
                    @click.prevent="getSelectedItemSkus(item.id)"
                    :disabled="!item.skus_count || disables[`skus-${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`skus-${item.id}`]"
                    />
                    <i class="fa-solid fa-eye" v-else></i>
                    <span>{{ $t('view') }} ( {{ item.skus_count || 0 }} )</span>
                  </button>
                </td>
                <td v-if="columns.includes('monthly_visits')">
                  {{ item.month_visits_count || 0 }}
                </td>
                <td v-if="columns.includes('merchandisers_count')">
                  {{ item.merchandisers_count || 0 }}
                </td>
                <td v-if="columns.includes('store')">
                  {{ (item.company_store || {}).name || '...' }}
                </td>
                <td v-if="columns.includes('users')">
                  <div class="actions-group">
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-for="(task, index) in (item.users || []).slice(0, 1)"
                      :key="index"
                      >{{ task.name }}
                    </span>
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.users || []).length > 1"
                      >+{{ (item.users || []).length - 1 }}</span
                    >
                    <span
                      class="text-slate-600"
                      v-if="(item.users || []).length === 0"
                      >-</span
                    >
                  </div>
                </td>
                <td v-if="columns.includes('active')">
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                </td>
                <td
                  v-if="columns.includes('updated_at')"
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td v-if="columns.includes('actions')">
                  <div class="actions-group">
                    <div v-if="false">
                      <button
                        class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                        @click.prevent="deleteItem(item.id)"
                        :disabled="disables[`delete_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${item.id}`]"
                        />
                        <span v-else> {{ $t('delete') }} </span>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                        @click.prevent="changeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                        :disabled="disables[`status_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${item.id}`]"
                        />
                        <i class="fa-solid fa-right-left" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded"
                        @click.prevent="showUpdate(item.id)"
                        :disabled="!item.is_active"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td :colspan="columns.length">
                  <div class="table mx-auto my-4">
                    <button
                      class="py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold"
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.branch') }}</span>
                    </button>
                  </div>
                  <p
                    class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.branch')"
          v-if="add_active"
          @close="
            (e) => {
              add_active = e
              add_data = {
                branch_id: null,
              }
              add_errors = {}
              item = {}
              show_detail = false
            }
          "
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="add_data.company_store_id"
                    :options="stores"
                    :placeholder="$t('search')"
                    clear-selected
                    label="name"
                    track-by="id"
                    id="company_store_id_add"
                  ></multiselect>
                  <label for="company_store_id_add">{{ $t('store') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.company_store_id"
                >
                  {{ add_errors.company_store_id.join(' ') }}
                </p>
              </div>
              <slide-transition>
                <div v-if="add_data.company_store_id">
                  <div class="table [margin-inline-start:auto]">
                    <button
                      class="py-1 px-2 rounded text-xs font-medium rtl:font-semibold text-slate-600"
                      @click.prevent="selectAllBranches"
                    >
                      {{ $t('select_all') }} ({{
                        branches.filter(
                          (e) =>
                            e.store.id ===
                            (add_data.company_store_id.store || {}).id
                        ).length
                      }})
                    </button>
                  </div>
                  <div class="form-group">
                    <div class="with-label">
                      <multiselect
                        v-model="add_data.branch_id"
                        :options="addBranchesFilter"
                        :disabled="!addBranchesFilter.length"
                        :multiple="true"
                        :closeOnSelect="false"
                        :taggable="true"
                        :hideSelected="true"
                        :clearOnSelect="true"
                        :placeholder="$t('search')"
                        label="name"
                        track-by="id"
                        id="branch_id"
                      ></multiselect>
                      <label for="branch_id">{{ $t('branches') }}</label>
                    </div>
                    <p
                      class="text-red-500 font-medium text-xs mt-2"
                      v-if="add_errors.branch_id"
                    >
                      {{ add_errors.branch_id.join(' ') }}
                    </p>
                  </div>

                  <div
                    class="form-group-with-check my-4"
                    v-if="add_data.branch_id"
                  >
                    <div>
                      <input
                        type="checkbox"
                        name="selectSKU"
                        id="selectSKU"
                        :true-value="1"
                        :false-value="0"
                        v-model="add_data.assign_to_sku"
                      />
                    </div>
                    <div>
                      <label for="selectSKU">
                        {{ $t('assign_to_skus') }}
                      </label>
                    </div>
                  </div>
                  <slide-transition>
                    <div class="space-y-4" v-if="add_data.assign_to_sku">
                      <div class="form-group">
                        <div class="with-label">
                          <multiselect
                            v-model="add_data.client_id"
                            :options="clients"
                            :placeholder="$t('search')"
                            clear-selected
                            label="name"
                            track-by="id"
                            id="client_id"
                          ></multiselect>
                          <label for="client_id">{{ $t('client') }}</label>
                        </div>
                        <p
                          class="text-red-500 font-medium text-xs mt-2"
                          v-if="add_errors.client_id"
                        >
                          {{ add_errors.client_id.join(' ') }}
                        </p>
                      </div>
                      <div class="form-group">
                        <div class="with-label">
                          <multiselect
                            v-model="add_data.brand_id"
                            :options="filterBrands"
                            :disabled="!filterBrands.length"
                            :multiple="true"
                            :closeOnSelect="false"
                            :taggable="true"
                            :hideSelected="true"
                            :clearOnSelect="true"
                            :placeholder="$t('search')"
                            label="name"
                            track-by="id"
                            id="brand_id"
                          ></multiselect>
                          <label for="brand_id">{{ $t('brands') }}</label>
                        </div>
                        <p
                          class="text-red-500 font-medium text-xs mt-2"
                          v-if="add_errors.brand_id"
                        >
                          {{ add_errors.brand_id.join(' ') }}
                        </p>
                      </div>
                      <div class="table [margin-inline-start:auto] !mt-1">
                        <button
                          class="py-1 px-2 rounded text-xs font-medium rtl:font-semibold text-slate-600"
                          @click.prevent="selectAllBrands"
                          :disabled="!filterBrands.length"
                        >
                          {{ $t('select_all') }} ({{ filterBrands.length }})
                        </button>
                      </div>
                      <div class="form-group">
                        <div class="with-label">
                          <multiselect
                            v-model="add_data.sku_ids"
                            :options="filterSkus"
                            :disabled="!filterSkus.length"
                            :multiple="true"
                            :closeOnSelect="false"
                            :taggable="true"
                            :hideSelected="true"
                            :clearOnSelect="true"
                            :placeholder="$t('search')"
                            label="name"
                            track-by="id"
                            id="sku_ids"
                          ></multiselect>
                          <label for="sku_ids">{{ $t('skus') }}</label>
                        </div>
                        <p
                          class="text-red-500 font-medium text-xs mt-2"
                          v-if="add_errors.sku_ids"
                        >
                          {{ add_errors.sku_ids.join(' ') }}
                        </p>
                      </div>
                      <div class="table [margin-inline-start:auto] !mt-1">
                        <button
                          class="py-1 px-2 rounded text-xs font-medium rtl:font-semibold text-slate-600"
                          @click.prevent="selectAllSkus"
                          :disabled="!filterSkus.length"
                        >
                          {{ $t('select_all') }} ({{ filterSkus.length }})
                        </button>
                      </div>
                    </div>
                  </slide-transition>
                </div>
              </slide-transition>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-4 rounded-md text-xs font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t('add') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="
            (e) => {
              edit_active = e
              edit_data = {}
              edit_errors = {}
              item = {}
              show_detail = false
            }
          "
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    v-model="edit_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name"
                >
                  {{ edit_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    v-model="edit_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_name"
                >
                  {{ edit_errors.alt_name.join(' ') }}
                </p>
              </div>

              <div class="form-group">
                <div class="with-label">
                  <input
                    autocomplete="off"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@example.com"
                    required
                    v-model="edit_data.email"
                    v-email
                  />
                  <label for="email">{{ $t('email') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.email"
                >
                  {{ edit_errors.email.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <vue-tel-input
                    id="mobile"
                    ref="branchAddMobile"
                    @open="handleOpen('branchAddMobile')"
                    v-model="edit_data.mobile"
                  />
                  <label for="mobile">{{ $t('manager_mobile') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.mobile"
                >
                  {{ edit_errors.mobile.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
    </ConfigurationWrapper>
    <Modal
      :title="$t('skus')"
      size="!max-w-screen-xl"
      v-if="skus_active"
      @close="skus_active = $event"
    >
      <template>
        <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
          <li class="sm:col-span-2 md:col-span-3 space-y-3">
            <input
              type="text"
              :placeholder="$t('search')"
              class="w-full py-3 px-4 rounded bg-white border border-gray-200 text-sm font-medium"
              v-model="sku_search"
            />
            <slide-transition>
              <div
                class="flex items-center justify-end gap-4"
                v-if="(skus_data.sku_ids || []).length"
              >
                <button
                  class="py-2 px-3 rounded bg-red-500 text-white text-center font-medium inline-flex items-center text-sm gap-3"
                  type="button"
                  @click.prevent="DeleteSelectedSkus"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="skus_disabled" />
                  <i class="fa-solid fa-trash fa-sm" v-else></i>
                  <span
                    >{{ $t('delete') }} ({{ (skus_data.sku_ids || []).length }})
                  </span>
                </button>
              </div>
            </slide-transition>
          </li>
          <slide-transition>
            <li
              class="sm:col-span-2 md:col-span-3"
              v-if="!filterSelectedSkus.length"
            >
              <p class="text-sm font-medium text-center text-gray-600">
                {{ $t('no_data') }}
              </p>
            </li>
          </slide-transition>
          <li
            class="p-3 rounded-lg border border-gray-200"
            v-for="(item, index) in filterSelectedSkus"
            :key="index"
          >
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :name="'skus-' + index"
                :id="'skus-' + index"
                :value="item.id"
                v-model="skus_data.sku_ids"
              />
              <label :for="'skus-' + index">
                <div class="flex gap-4 items-start">
                  <div class="flex-1 space-y-0.5">
                    <p class="font-semibold text-gray-800 line-clamp-1">
                      {{ item.name || 'N/A' }}
                    </p>
                    <p class="text-sm text-gray-500 font-medium">
                      {{ item.barcode || 'N/A' }}
                    </p>
                  </div>
                  <!-- <button
                    class="shrink-0 w-8 h-8 rounded-full bg-red-100 text-red-500 flex items-center justify-center"
                    type="button"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                  </button> -->
                </div>
              </label>
            </div>
          </li>
        </ul>
      </template></Modal
    >
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import ConfigurationWrapper from '@/components/setup/configuration/ConfigurationWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'
import FilterBox from '@/components/basics/FilterBox.vue'

export default {
  name: 'Branches',
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        company_store_id: null,
        branch_id: null,
        multi_branches: 1,
        assign_to_sku: 0,
        brand_id: null,
        client_id: null,
        sku_ids: null,
      },
      stores: [],
      branches: [],
      add_active: false,
      add_errors: {},
      show_detail: false,
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        total: 0,
        company_store_ids: null,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      item: {},
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      fields: [],
      skus: [],
      brands: [],
      skus_active: false,
      skus_disabled: false,
      skus_errors: {},
      skus_error: {},
      skus_data: {
        sku_ids: [],
        company_branch_id: null,
      },
      selected_skus: [],
      sku_search: null,
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {
          company_store_id: null,
          branch_id: null,
          multi_branches: 1,
          assign_to_sku: 0,
          brand_id: null,
          client_id: null,
          sku_ids: null,
        }
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    skus_active() {
      if (!this.skus_active) {
        this.skus_data = {
          sku_ids: [],
          company_store_id: null,
        }
        this.skus_errors = {}
        this.skus_disabled = false
        this.selected_skus = []
      }
    },

    'add_data.multi_branches'(newVal, _) {
      if (newVal) return
      const storeKeys = this.add_data.branch_id

      if (Array.isArray(storeKeys)) {
        const firstItem = JSON.parse(JSON.stringify(storeKeys[0] || {}))
        this.add_data.branch_id = firstItem
      }
    },

    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },

    'add_data.client_id'(val) {
      if (val) {
        this.$set(this.add_data, 'sku_ids', null)
        this.$set(this.add_data, 'brand_id', null)
      }
    },
    'add_data.assign_to_sku'(val) {
      if (!val) {
        this.$set(this.add_data, 'sku_ids', null)
        this.$set(this.add_data, 'brand_id', null)
        this.$set(this.add_data, 'client_id', null)
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search', 'table_paginate']),
    ...mapGetters('filters', ['clients']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
    addBranchesFilter() {
      const store = this.add_data.company_store_id
      this.$set(this.add_data, 'branch_id', null)
      if (!store) return this.branches
      return this.branches.filter((e) => e.store?.id === store?.store?.id)
    },

    filterBrands() {
      const client = this.add_data.client_id

      if (!client?.id || !client) return this.brands
      return this.brands.filter((e) => e.client?.id === client?.id)
    },
    filterSkus() {
      const brands = this.add_data.brand_id
      if (!brands || !brands.length) return this.skus
      const mapBrandIds = brands.map((e) => e.id)
      return this.skus.filter((e) => mapBrandIds.includes(e.brand?.id))
    },
    filterSelectedSkus() {
      return this.selected_skus
        ?.filter((item) => {
          return this.sku_search
            ? new RegExp('^' + this.sku_search, 'gi').test(item?.name)
            : true
        })
        .map((item, index, arr) => {
          const ids = arr
            .filter((e) => e.id !== item.id && item.barcode === e.barcode)
            .map((e) => e.barcode)

          if (ids.includes(item.barcode)) return { ...item, duplicated: true }
          return item
        })
    },
  },

  async created() {
    await Promise.all([
      this.getStores(),
      this.getBranches(),
      this.getSkus(),
      this.getBrands(),
      this.getItems(),
    ])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getSkus() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'skus',
          options: {
            is_active: 1,
          },
        })
        this.skus = result.skus
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getSelectedItemSkus(id) {
      try {
        this.$set(this.disables, 'skus-' + id, true)
        this.$set(this.skus_data, 'company_branch_id', id)
        this.selected_skus = []
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'skus',
          options: {
            company_branch_id: id,
          },
        })
        this.selected_skus = result.skus
        this.skus_active = true
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.disables = {}
      }
    },
    async getBrands() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'brands',
          options: {
            is_active: 1,
          },
        })
        this.brands = result.brands
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getStores() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'company_stores',
          options: {
            is_active: 1,
          },
        })
        this.stores = result.company_stores
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getBranches() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'branches',
          options: {
            is_active: 1,
            not_listed_stores: 1,
          },
        })
        this.branches = result.branches
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    getItems() {
      const { page, sort_key, sort_type, is_active, company_store_ids } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'company_branches',
          options: {
            page,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            search_key: this.search,
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.company_branches
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.fields = result.export_fields
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data
      let dataResult = {}

      for (const key in item) {
        if (item.multi_branches) {
          if (['company_store_id', 'branch_id', 'sku_ids'].includes(key)) {
            dataResult[key] = item[key]
          }
        } else {
          dataResult[key] = item[key]
        }
      }

      try {
        const { data } = await this.axios.post('company_branches/add', {
          ...dataResult,
          mobile: dataResult?.mobile?.replace(/[+|\s|()|-]/g, ''),
          company_store_id: dataResult?.company_store_id?.id,
          branch_id: Array.isArray(dataResult.branch_id)
            ? dataResult?.branch_id?.map((e) => e.id)
            : dataResult?.branch_id?.id,
          sku_ids: dataResult.sku_ids?.map((e) => e.id),
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {
          company_store_id: null,
          branch_id: null,
          multi_branches: 1,
          assign_to_sku: 0,
          brand_id: null,
          client_id: null,
          sku_ids: null,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      const item = this.edit_data
      try {
        const { data } = await this.axios.post('company_branches/update', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          company_store_id: item?.company_store_id?.id,
          branch_id: item?.branch_id?.id,
        })
        const { message, result } = data
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = result.company_branch
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `company_branches/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`company_branches/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async DeleteSelectedSkus() {
      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        this.skus_disabled = true
        const { data } = await this.axios.post(
          `skus/delete_from_company_branch`,
          this.skus_data
        )
        this.createAlert(data.message)
        this.getItems()
        this.skus = []
        this.skus_active = false
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.skus_disabled = false
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        company_store_id: item?.company_store,
        branch_id: item?.branch,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    selectAllBranches() {
      const data = this.branches.filter(
        (e) => e.store?.id === this.add_data.company_store_id?.store?.id
      )

      this.add_data.branch_id = data
    },
    selectAllBrands() {
      this.add_data.brand_id = this.filterBrands
    },
    selectAllSkus() {
      this.add_data.sku_ids = this.filterSkus
    },
  },
  components: {
    Table,
    Modal,
    TableLoad,
    ConfigurationWrapper,
    FilterBox,
  },
}
</script>
