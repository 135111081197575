import moment from 'moment'

export default {
  namespaced: true,
  state: {
    page: 1,
    apply_disabled: false,
    reset_disabled: false,
    is_called: false,
    search_key: null,
    pagination: {},
    original_pagination: {},
  },
  getters: {
    page: (state) => state.page,
    apply: (state) => state.apply_disabled,
    reset: (state) => state.reset_disabled,
    is_called: (state) => state.is_called,
    original_pagination: (state) => state.original_pagination,
    table_paginate: (state) => {
      const {...other } = state.pagination
      return {
        ...other,
        // from: !from
        //   ? moment(new Date()).add(-1, 'days').format('yyyy-MM-DD')
        //   : from,
        // to: !to ? moment(new Date()).add(-1, 'days').format('yyyy-MM-DD') : to,
      }
    },
    search: (state) => state.search_key,
  },
  mutations: {
    setPage(state, payload) {
      state.page = payload
    },
    changeApply(state, payload) {
      state.apply_disabled = payload
    },
    changeReset(state, payload) {
      state.reset_disabled = payload
    },
    changeIsCalled(state, payload) {
      state.is_called = payload
      if (!payload) {
        state.apply_disabled = payload
        state.reset_disabled = payload
      }
    },
    changePagination(state, payload) {
      const {
        company_store_id,
        company_branch_id,
        country_id,
        region_id,
        city_id,
        user_id,
        client_id,
        zone_id,
        supervisor_id,
        is_active,
        // is_available,
        main_category_id,
        ...others
      } = payload
      state.pagination = {
        ...others,
        company_store_id: company_store_id?.map((e) => e.id),
        company_branch_id: company_branch_id?.map((e) => e.id),
        country_id: country_id?.map((e) => e.id),
        region_id: new Array().concat(
          ...region_id?.map((e) => [...e.regions?.map((r) => r.id)])
        ),
        city_id: city_id?.map((e) => e.id),
        user_id: user_id?.map((e) => e.id),
        client_id: client_id?.map((e) => e.id),
        supervisor_id: supervisor_id?.map((e) => e.id),
        zone_id: new Array().concat(
          ...zone_id?.map((e) => [...e.districts?.map((r) => r.id)])
        ),
        main_category_id: main_category_id?.map((e) => e.id),
        is_active: is_active ? (is_active === 'active' ? 1 : 0) : null,
        // is_available: is_available
        //   ? is_available === 'available'
        //     ? 1
        //     : 0
        //   : null,
      }
      state.original_pagination = payload
    },
    updatePagination() {
      const item = JSON.parse(JSON.stringify(state.pagination))
      Object.assign(item, payload)
      state.pagination = item
    },
    resetPagination(state) {
      // state.pagination = {
      //   from: moment().add(-1, 'days').format('yyyy-MM-DD'),
      //   to: moment().add(-1, 'days').format('yyyy-MM-DD'),
      // }
      state.original_pagination = state.pagination
    },
    updateSearch(state, payload) {
      state.search_key = payload
    },
  },
  actions: {
    setNewPage({ commit }, pageCount) {
      commit('setPage', pageCount)
    },
  },
}
