<template>
  <div class="space-y-6">
    <section class="w-full space-y-6">
      <main class="w-full px-6">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('clients')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :is_loading="is_loading"
        >
          <template #actions>
            <div>
              <button
                class="
                  py-1.5
                  px-3
                  text-sm
                  flex
                  items-center
                  gap-2
                  text-slate-600
                  font-medium
                  rtl:font-semibold
                "
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('add_new.client') }}</span>
              </button>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    p-4
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th v-if="columns.includes('name')">{{ $t('name') }}</th>
            <th v-if="columns.includes('email')">{{ $t('email') }}</th>
            <th v-if="columns.includes('domain')">{{ $t('domain') }}</th>

            <th v-if="columns.includes('merchandisers_count')">
              <div class="sort">
                <div>
                  <p>{{ $t('merchandisers_count') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('merchandisers_count')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('visits_count')">
              <div class="sort">
                <div>
                  <p>{{ $t('visits') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('visits_count')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('tasks')">
              <div class="sort">
                <div>
                  <p>{{ $t('tasks') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('tasks')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('active')">
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('updated_at')">
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('actions')"></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td v-if="columns.includes('name')">{{ item.name || '...' }}</td>
              <td v-if="columns.includes('domain')">
                <a
                  :href="`${item.domain}`"
                  class="text-sky-500"
                  v-if="item.domain"
                  >{{ item.domain }}</a
                >
                <span v-else>-</span>
              </td>

              <td v-if="columns.includes('merchandisers_count')">
                {{ item.merchandisers_count || 0 }}
              </td>
              <td v-if="columns.includes('visits_count')">
                {{ item.visits_count || 0 }}
              </td>
              <td v-if="columns.includes('tasks')">
                <div class="actions-group">
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-for="(task, index) in (item.tasks || []).slice(0, 1)"
                    :key="index"
                    >{{ task.name }}
                  </span>
                  <span
                    class="status text-slate-600 bg-slate-100"
                    v-if="(item.tasks || []).length > 1"
                    >+{{ (item.tasks || []).length - 1 }}</span
                  >
                  <span
                    class="text-slate-600"
                    v-if="(item.tasks || []).length === 0"
                    >-</span
                  >
                </div>
              </td>

              <td v-if="columns.includes('active')">
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td
                v-if="columns.includes('updated_at')"
                v-html="createTime(item.updated_at || item.created_at)"
              ></td>

              <td v-if="columns.includes('actions')">
                <div class="actions-group">
                  <div>
                    <router-link
                      :to="`/setup/clients/${item.id}?tab=users`"
                      class="p-1.5 text-sky-500 font-medium rtl:font-semibold"
                    >
                      {{ $t('view') }}
                    </router-link>
                  </div>
                  <div v-if="item.is_active">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded"
                      @click.prevent="showUpdate(item.id)"
                      :disabled="!item.is_active"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="columns.length">
                <div class="table mx-auto my-4">
                  <button
                    class="
                      py-1.5
                      px-3
                      text-sm
                      flex
                      items-center
                      gap-2
                      text-slate-600
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.client') }}</span>
                  </button>
                </div>
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('add_new.client')"
        v-if="add_active"
        @close="add_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="add_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="domain"
                  id="domain"
                  placeholder="domain.com"
                  required
                  v-model="add_data.domain"
                />
                <label for="domain">{{ $t('domain') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.domain"
              >
                {{ add_errors.domain.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  v-model="add_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.email"
              >
                {{ add_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="add_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.mobile"
              >
                {{ add_errors.mobile.join(' ') }}
              </p>
            </div>

            <div class="w-full space-y-2">
              <label
                for="tasks"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('tasks') }}</label
              >
              <ul
                class="
                  w-full
                  list-none
                  space-y-4
                  mt-4
                  p-2
                  max-h-[20rem]
                  overflow-y-auto
                "
              >
                <li v-for="(item, index) in tasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="add_data.task_ids"
                            @change.prevent="
                              handleParentItem($event, item.id, 'add_data')
                            "
                            v-if="!item.sub_tasks.length"
                          />
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="
                      w-full
                      list-none
                      space-y-4
                      mt-4
                      [padding-inline-start:1.5rem]
                    "
                    v-if="item.sub_tasks.length"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="add_data.task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id, 'add_data')
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.task_ids"
              >
                {{ add_errors.task_ids.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('update')"
        v-if="edit_active"
        @close="edit_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="edit_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  v-model="edit_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.email"
              >
                {{ edit_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="edit_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.mobile"
              >
                {{ edit_errors.mobile.join(' ') }}
              </p>
            </div>

            <div class="w-full space-y-2">
              <label
                for="tasks"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('tasks') }}</label
              >
              <ul
                class="
                  w-full
                  list-none
                  space-y-4
                  mt-4
                  p-2
                  max-h-[20rem]
                  overflow-y-auto
                "
              >
                <li v-for="(item, index) in tasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="edit_data.task_ids"
                            @change.prevent="
                              handleParentItem($event, item.id, 'edit_data')
                            "
                            v-if="!item.sub_tasks.length"
                          />
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="
                      w-full
                      list-none
                      space-y-4
                      mt-4
                      [padding-inline-start:1.5rem]
                    "
                    v-if="item.sub_tasks.length"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="edit_data.task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id, 'edit_data')
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>

              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.task_ids"
              >
                {{ edit_errors.task_ids.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal v-if="success_active">
        <template>
          <div class="py-10 px-6">
            <div class="flex items-center justify-center flex-col gap-6">
              <div>
                <div
                  class="
                    w-20
                    h-20
                    flex
                    items-center
                    justify-center
                    bg-primary/10
                    rounded-full
                    text-primary
                  "
                >
                  <i class="fa-solid fa-user-plus fa-xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-4 text-center">
                <h1 class="text-xl text-primary font-bold">
                  {{ $t('client_added_title') }}
                </h1>
                <p
                  class="
                    text-slate-400
                    max-w-2xl
                    font-medium
                    rtl:font-semibold
                    text-sm
                  "
                >
                  {{ $t('client_added_content') }}
                </p>
                <div class="!mt-8 space-y-2">
                  <router-link
                    to="/setup/configuration/brands"
                    class="
                      w-full
                      max-w-sm
                      text-center
                      py-3
                      px-6
                      rounded-full
                      text-white
                      bg-secondary
                      text-xs
                      font-medium
                      rtl:font-semibold
                      block
                      mx-auto
                    "
                  >
                    {{ $t('add_new.brand') }}
                  </router-link>
                  <router-link
                    :to="`/setup/clients/${id}`"
                    class="
                      w-full
                      max-w-sm
                      text-center
                      py-3
                      px-6
                      rounded-full
                      text-secondary
                      bg-transparent
                      border border-secondary
                      text-xs
                      font-medium
                      rtl:font-semibold
                      block
                      mx-auto
                    "
                  >
                    {{ $t('add_new.user') }}
                  </router-link>
                  <button
                    type="button"
                    @click.prevent="
                      () => {
                        success_active = false
                        id = null
                      }
                    "
                    class="
                      w-full
                      max-w-sm
                      text-center
                      py-3
                      px-6
                      rounded-full
                      text-slate-600
                      bg-transparent
                      text-xs
                      font-medium
                      rtl:font-semibold
                      block
                      mx-auto
                    "
                  >
                    {{ $t('cancel') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Clients',
  data() {
    return {
      load: false,
      is_loading: false,
      items: [],
      add_data: {
        role_type: 'client',

        task_ids: [],
      },
      global_tasks: [],
      stores: [],
      branches: [],
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',

        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      status: ['all', 'active', 'not active'],
      apply_disabled: false,
      reset_disabled: false,
      success_active: false,
      id: null,
      disables: {},
      clients: [],
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },

    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {
          task_ids: [],
        }
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
    tasks() {
      const companyTasks = this.$store.getters['auth/getUser']?.company.tasks
      // console.log(companyTasks)
      return this.global_tasks
    },
  },

  async created() {
    Promise.all([await this.getItems(), await this.getTasks()])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),
    async getTasks() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'tasks'
        )
        this.global_tasks = result.tasks
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    getItems() {
      const { page, sort_key, sort_type, is_active } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'clients',
          options: {
            page,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            search_key: this.search,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.clients
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data

      try {
        const { data } = await this.axios.post('clients/add', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.success_active = true
        this.id = data?.result?.client?.id
        this.add_data = {
          task_ids: [],
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      const item = this.edit_data
      try {
        const { data } = await this.axios.post('clients/update', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
        })
        const { message, result } = data
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...result.user,
          task_ids: result.user?.tasks,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`clients/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`clients/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      let result = {}
      // get all items inside tasks then assign sub_tasks with their object
      const concatTasks = this.tasks.map((e) =>
        new Array().concat(e, e.sub_tasks)
      )

      const finalResult = new Array().concat(...concatTasks)

      Object.assign(item, {
        task_ids: item?.tasks
          ?.filter((e) => finalResult.map((task) => task.id).includes(e.id))
          ?.map((e) => e.id),
      })

      for (const key in item) {
        if (item[key]) {
          result[key] = item[key]
        }
      }

      this.edit_data = result
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    handleParentItem(e, id, type) {
      const tasks = this.tasks
      const task = tasks.find((e) => e.id === id)
      const isChecked = e.target.checked

      if (!task?.sub_tasks?.length) return
      const taskIds = task?.sub_tasks?.map((e) => e.id)
      if (isChecked) {
        this.$set(
          this[type],
          'task_ids',
          new Array().concat(this[type]?.task_ids, taskIds)
        )
      } else {
        this.$set(
          this[type],
          'task_ids',
          this[type]?.task_ids?.filter(
            (e) => !new Array().concat(id, taskIds).includes(e)
          )
        )
      }
    },
    handleChildItem(e, id, type) {
      const isChecked = e.target.checked
      const selectedTasks = this[type]?.task_ids

      if (isChecked) {
        if (selectedTasks.includes(id)) return
        this.$set(this[type], 'task_ids', new Array().concat(selectedTasks))
      } else {
        const parentSubTasks = this.tasks.find((e) => e.id === id)
        const siblingIds = parentSubTasks.sub_tasks.map((e) => e.id)
        const hasSiblingIds = siblingIds.some((e) =>
          new RegExp(selectedTasks.join('|')).test(e)
        )

        if (hasSiblingIds) return
        this.$set(
          this[type],
          'task_ids',
          selectedTasks.filter((e) => e !== id)
        )
      }
    },
  },
  components: { Table, Modal, TableLoad },
}
</script>
