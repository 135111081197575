<template>
  <div class="space-y-6 !mb-6">
    <filter-wrapper v-if="load" :loaded="load">
      <div class="w-full" v-if="hasClients && !isVisit">
        <multiselect
          v-model="add_data.client_id"
          :placeholder="$t('clients')"
          label="name"
          track-by="id"
          :options="clients"
          :multiple="true"
          :closeOnSelect="false"
          :taggable="true"
          :clearOnSelect="true"
          id="filterClients"
          :disabled="getUser.role_type !== 'super_user' || !clients.length"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          style="background-color: #ffffff !important"
        >
          <span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasRegions">
        <multiselect
          v-model="add_data.region_id"
          :options="regions"
          :placeholder="$t('regions')"
          label="name"
          track-by="id"
          id="region_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="regions.length === 0"
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasCities">
        <multiselect
          v-model="add_data.city_id"
          :options="filterCities"
          :placeholder="$t('cities')"
          label="name"
          track-by="id"
          id="city_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="!filterCities.length"
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasZones">
        <multiselect
          v-model="add_data.zone_id"
          :options="FilterZones"
          :placeholder="$t('zones')"
          label="name"
          track-by="id"
          id="zone_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="!FilterZones.length"
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>

      <div class="w-full" v-if="hasStores && !isVisit">
        <multiselect
          v-model="add_data.company_store_id"
          :options="stores"
          :placeholder="$t('stores')"
          label="name"
          track-by="id"
          id="company_store_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="stores.length === 0"
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasBranches && !isVisit">
        <!-- FilterData(
              'add_data',
              'branches',
              'company_store',
              'company_store_id',
              'company_branch_id'
            ).length === 0 -->
        <multiselect
          v-model="add_data.company_branch_id"
          :options="
            !FilterData(
              'add_data',
              'branches',
              'company_store',
              'company_store_id',
              'company_branch_id'
            ).length
              ? branches
              : FilterData(
                  'add_data',
                  'branches',
                  'company_store',
                  'company_store_id',
                  'company_branch_id'
                )
          "
          :placeholder="$t('branches')"
          label="name"
          track-by="id"
          id="company_branch_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="!branches.length"
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasSupervisors && !isVisit">
        <multiselect
          v-model="add_data.supervisor_id"
          :options="users.filter((e) => e.role_type === 'supervisor')"
          :placeholder="$t('supervisors')"
          label="name"
          track-by="id"
          id="supervisor_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="
            users.filter((e) => e.role_type === 'supervisor').length === 0
          "
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasMerchandisers && !isVisit">
        <multiselect
          v-model="add_data.user_id"
          :options="users.filter((e) => e.role_type === 'merchandiser')"
          :placeholder="$t('merchandisers')"
          label="name"
          track-by="id"
          id="user_id"
          :clearOnSelect="true"
          :multiple="true"
          :closeOnSelect="false"
          :disabled="
            users.filter((e) => e.role_type === 'merchandiser').length === 0
          "
          style="background-color: #ffffff !important"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          ><span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <div class="w-full" v-if="hasMainCategories && !isVisit">
        <multiselect
          v-model="add_data.main_category_id"
          :placeholder="$t('main_categories')"
          label="name"
          track-by="id"
          :options="main_categories"
          :closeOnSelect="false"
          :taggable="true"
          :clearOnSelect="true"
          id="filterMainCategory"
          :disabled="!main_categories.length"
          :limit="1"
          :limit-text="(e) => `+${e}`"
          :show-no-results="true"
          style="background-color: #ffffff !important"
          multiple
        >
          <span
            slot="noResult"
            class="whitespace-normal text-slate-600 text-sm font-medium rtl:font-semibold"
            >{{ $t('no_data') }}</span
          ></multiselect
        >
      </div>
      <!-- children goes here -->
      <slot></slot>
      <!-- ./children goes here -->
      <div class="w-full sm:col-span-2" v-if="isWeekly">
        <div class="w-full bg-white ring-1 ring-slate-200 rounded">
          <ul class="flex justify-between items-center py-0.5">
            <li>
              <button
                class="w-9 h-9 text-sm text-slate-600"
                v-tippy
                :content="$t('prev')"
                @click.prevent="changePrevWeek"
              >
                <i class="fa-solid fa-angle-left"></i>
              </button>
            </li>
            <li class="flex-1">
              <div class="grid w-full">
                <div class="flex gap-3 text-center items-center">
                  <p
                    class="text-sm line-clamp-1 text-slate-600 font-medium flex-1"
                  >
                    {{ from }}
                  </p>
                  <i class="fa-solid fa-right-left text-xs text-slate-400"></i>
                  <p
                    class="text-sm line-clamp-1 text-slate-600 font-medium flex-1"
                  >
                    {{ to }}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <button
                class="w-9 h-9 text-sm text-slate-600"
                v-tippy
                :content="$t('next')"
                @click.prevent="changeNextWeek"
              >
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full" v-if="hasFromDate && !isWeekly">
        <t-datepicker
          :placeholder="$t('from')"
          v-model="add_data.from"
          :clearable="false"
          userFormat="Y-m-d"
          :weekStart="6"
        />
      </div>
      <div class="w-full" v-if="hasToDate && !isWeekly">
        <t-datepicker
          :placeholder="$t('to')"
          v-model="add_data.to"
          :minDate="add_data.from"
          :clearable="false"
          userFormat="Y-m-d"
          :weekStart="6"
        />
      </div>

      <div class="w-full col-end-[-1]">
        <div class="actions-group justify-end">
          <div>
            <button
              class="w-full h-auto bg-secondary text-white font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
              :disabled="apply"
              @click.prevent="applyFilter"
            >
              <spinner size="w-4 h-4 inline-block" v-if="apply" />
              {{ $t('apply') }}
            </button>
          </div>
          <div>
            <button
              class="w-full h-auto bg-slate-300 text-slate-600 font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
              :disabled="reset"
              @click.prevent="resetFilter"
            >
              <spinner size="w-4 h-4 inline-block" v-if="reset" />
              {{ $t('reset') }}
            </button>
          </div>
        </div>
      </div>
    </filter-wrapper>
    <filter-wrapper v-else :loaded="load">
      <div
        class="w-full bg-slate-200 animate-pulse h-10 rounded"
        v-for="i in 10"
        :key="i"
      ></div>
      <div class="w-full col-end-[-1] flex gap-4 justify-end">
        <div class="w-2/5 bg-slate-200 animate-pulse h-10 rounded"></div>
        <div class="w-2/5 bg-slate-200 animate-pulse h-10 rounded"></div>
      </div>
    </filter-wrapper>
  </div>
</template>

<script>
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'FilterBox',
  components: { FilterWrapper },
  props: {
    hasCards: {
      type: Boolean,
      default: true,
    },
    hasStores: {
      type: Boolean,
      default: true,
    },
    hasBranches: {
      type: Boolean,
      default: true,
    },
    hasClients: {
      type: Boolean,
      default: true,
    },
    hasRegions: {
      type: Boolean,
      default: true,
    },
    hasZones: {
      type: Boolean,
      default: true,
    },
    hasCities: {
      type: Boolean,
      default: true,
    },
    hasMainCategories: {
      type: Boolean,
      default: false,
    },
    hasMerchandisers: {
      type: Boolean,
      default: true,
    },
    hasSupervisors: {
      type: Boolean,
      default: true,
    },
    hasFromDate: {
      type: Boolean,
      default: false,
    },
    hasToDate: {
      type: Boolean,
      default: false,
    },
    isWeekly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.generateWeek()
  },
  data() {
    return {
      add_data: {
        ...(this.$store.getters['table/original_pagination']
          ? this.$store.getters['table/original_pagination']
          : {}),
        company_store_id: [],
        company_branch_id: [],
        main_category_id: [],
        country_id: [],
        region_id: [],
        zone_id: [],
        city_id: [],
        categories: [],
        sub_categories: [],
        search_key: null,
        from: this.$moment(new Date()).format('yyyy-MM-DD'),
        to: null,
        client_id:
          this.$store.state.auth.user_info?.role_type !== 'super_user'
            ? [this.$store.state.auth.user_info?.client]
            : [],
      },
    }
  },
  computed: {
    ...mapGetters('table', ['reset', 'apply', 'search', 'original_pagination']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('filters', [
      'stores',
      'branches',
      'regions',
      'cities',
      'users',
      'zones',
      'clients',
      'load',
      'main_categories',
    ]),
    FilterData() {
      return (object, data, key, parentSelectedData, origin) => {
        const selectedIds =
          this[object][parentSelectedData]?.map((e) => e.id) || []
        const filterData = this[data]?.filter((e) =>
          selectedIds.includes(e?.[key]?.id)
        )
        return filterData
      }
    },

    filterCities() {
      const selectedCities =
        new Array().concat(
          ...this.add_data.region_id?.map((e) => [
            ...e.regions?.map((r) => r.id),
          ])
        ) || []
      if (!selectedCities.length) return this.cities
      return this.cities.filter((e) => selectedCities.includes(e.region?.id))
    },
    FilterZones() {
      const mapCities = this.add_data.city_id?.map((e) => e.id) || []
      if (!mapCities.length) return []
      const filterZones = this.zones.filter((e) =>
        e.districts?.some((d) => mapCities.includes(d.city?.id))
      )
      return filterZones
    },

    isVisit() {
      return this.$route.name === 'SingleVisit'
    },

    from() {
      return this.$moment(this.add_data.from).format('ddd DD, MMM YYYY')
    },
    to() {
      return this.$moment(this.add_data.to).format('ddd DD, MMM YYYY')
      // return null;
    },
  },

  watch: {
    search(newVal) {
      if (newVal) {
        this.$set(this.add_data, 'search_key', newVal)
      }
    },
    is_called() {
      this.apply = false
      this.reset = false
    },

    // original_pagination(newVal, oldVal) {
    //   console.log(newVal, oldVal)
    // },
  },
  methods: {
    ...mapMutations('table', [
      'changeApply',
      'changeReset',
      'changeIsCalled',
      'changePagination',
      'resetPagination',
    ]),
    generateDate(str) {
      const date = str || new Date().toISOString().substring(0, 10)
      const day = new Date(date).getDay()
      const newDate = this.$moment(date)
        .add(day === 6 ? 1 : 0, 'day')
        .toDate()
      const someday = this.$moment(newDate)

      const format = 'yyyy-MM-DD'
      const start = someday
        .clone()
        .startOf('week')
        .add(-1, 'day')
        .format(format)
      const end = someday.clone().endOf('week').add(-2, 'day').format(format)
      const today = someday.clone().format(format)
      return {
        start,
        end,
        today,
      }
    },
    generateWeek() {
      const { start, end, today } = this.generateDate()

      if (this.isWeekly) {
        this.$set(this.add_data, 'from', start)
        this.$set(this.add_data, 'to', end)
        return
      }

      this.$set(this.add_data, 'from', today)
      this.$set(this.add_data, 'to', today)

      this.changePagination(this.add_data)
      this.changeIsCalled(true)
    },
    changeNextWeek() {
      const lastDay = this.add_data.to
      const date = this.$moment(lastDay).add(2, 'days').format('yyyy-MM-DD')
      const { start, end, today } = this.generateDate(date)

      this.$set(this.add_data, 'from', start)
      this.$set(this.add_data, 'to', end)

      
    },
    changePrevWeek() {
      const startDay = this.add_data.from
      const date = this.$moment(startDay).add(-1, 'days').format('yyyy-MM-DD')
      const { start, end, today } = this.generateDate(date)

      this.$set(this.add_data, 'from', start)
      this.$set(this.add_data, 'to', end)
    },
    applyFilter() {
      this.changeApply(true)
      this.handleApplyPagination()
    },
    handleApplyPagination(items) {
      this.changeIsCalled(true)
      const children = this.$slots.default
      const data = { ...this.handleChildrenData(children), ...(items || {}) }
      // const data = items
      // console.log(data)

      this.changePagination({
        ...data,

        is_available: data.is_available
          ? data.is_available === 'available'
            ? 1
            : 0
          : data.is_available,
      })
    },
    handleChildrenData(items) {
      if (!items) return this.add_data
      let result = {}
      result = this.add_data
      for (let i = 0; i < items?.length; i++) {
        const child = items[i]
        if (child) {
          const childData = child.context?.pagination ?? child.context?.add_data
          Object.assign(result, childData)
        }
      }

      return result
    },
    resetFilter() {
      this.changeReset(true)
      Object.keys(this.add_data).forEach((e) => {
        if (['from', 'to'].includes(e)) {
          if (['CalendarPage'].includes(this.$route.name)) {
            this.generateWeek()
          } else {
            this.$set(this.add_data, e, this.$moment().format('yyyy-MM-DD'))
          }
        } else if (e === 'client_id') {
          this.$set(
            this.add_data,
            e,
            this.$store.state.auth.user_info?.role_type !== 'super_user'
              ? [this.$store.state.auth.user_info?.client]
              : []
          )
        } else {
          this.$set(this.add_data, e, null)
        }
      })
      this.resetChildrenData(this.$slots.default)
      this.generateWeek()
      this.resetPagination()
      this.changeIsCalled(true)
    },
    resetChildrenData(items) {
      if (!items?.length) return {}
      for (let i = 0; i < items.length; i++) {
        const child = items[i]
        if (child) {
          const childData = child.context?.pagination || child.context?.add_data
          const currentChild = child.context
          Object.keys(childData).forEach((e) => {
            if (['from', 'to'].includes(e)) {
              this.$set(
                childData,
                e,
                this.$moment(new Date()).format('yyyy-MM-DD')
              )
            } else if (e === 'page') {
              this.$set(childData, e, 1)
            } else {
              this.$set(childData, e, null)
            }
          })
        }
      }
    },
    handleChange(e) {
      console.log(e)
    },
  },
}
</script>
