<template>
  <div class="space-y-6">
    <div class="inline">
      <div
        class="p-4 pb-0 rounded bg-white ring-1 ring-slate-200 md:sticky md:top-20 flex gap-4 justify-between mx-6 z-[5]"
      >
        <div v-if="false">
          <button
            class="flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm py-2 px-4 rounded bg-transparent hover:bg-slate-200"
            @click.prevent="goBack"
          >
            <i class="fa-solid fa-angle-left block rtl:hidden"></i>
            <i class="fa-solid fa-angle-right hidden rtl:block"></i>
            <span>{{ $t('back') }}</span>
          </button>
        </div>
        <div>
          <Tabs
            :link_type="'link'"
            :tabs="pagesTab"
            has-permission
            class="container-tabs"
            query-key="tab"
          />
        </div>
        <div v-if="false">
          <button
            class="flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm w-8 h-8 rounded bg-transparent hover:bg-slate-200"
            @click.prevent="goToVisit"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from '@/components/basics/Tabs.vue'
export default {
  name: 'ContainerWrapper',
  computed: {
    visitId() {
      return this.$route.params?.visit_id
    },
    pagesTab() {
      const pages = [
        {
          url: `/visits/${this.visitId}`,
          title: 'Details',
          key: 'details',
          permission: 'visits',
        },
        {
          url: `/visits/${this.visitId}?tab=sku-audits`,
          title: 'Sku audits',
          key: 'skus',
          permission: 'sku_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=expiry-audits`,
          title: 'Expiry',
          key: 'expiries',
          permission: 'expiry_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=stock-audits`,
          title: 'Stock',
          key: 'stock_audits',
          permission: 'stock_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=delivery-audits`,
          title: 'Delivery',
          key: 'delivery_audits',
          permission: 'delivery_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=shelf-audits`,
          title: 'Shelf audits',
          key: 'shelf_audits',
          permission: 'shelf_audits',
        },

        {
          url: `/visits/${this.visitId}?tab=return-audits`,
          title: 'Returns',
          key: 'returns',
          permission: 'return_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=promotion-audits`,
          title: 'Promotion',
          key: 'promotion',
          permission: 'promotion_audits',
        },
        {
          url: `/visits/${this.visitId}?tab=planogram-audits`,
          title: 'Planogram',
          key: 'planogram',
          permission: 'planogram_audits',
        },

        // {
        //   url: `/visits/${this.visitId}?tab=images`,
        //   title: 'Images',
        //   key: 'images',
        //   permission: 'planogram_audits|promotion_audits',
        // },
      ]

      // return pages.filter(
      //   (e) =>
      //     this.reports[e.permission]?.data?.length > 0 ||
      //     ['visits', 'images'].includes(e.permission)
      // )
      return pages
    },
  },
  props: {
    reports: {
      type: Object,
      default: Object,
    },
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    },
    goToVisit() {
      return this.$router.push('/visits')
    },
  },
  components: {
    Tabs,
  },
}
</script>
<style lang="scss">
.container-tabs {
  @apply grid;
}
.container-tabs ul {
  @apply mb-4;
}
</style>
