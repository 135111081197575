<template>
  <div class="space-y-4">
    <div class="actions-group justify-end">
      <slot name="actions" v-if="$slots.actions"></slot>
      <div v-if="has_customize">
        <button
          class="py-2.5 sm:py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold ring-1 ring-slate-200 bg-white"
          @click.prevent="showCustomizeModal"
        >
          <i class="fa-solid fa-filter"></i>

          <span class="hidden sm:inline">{{ $t('customize') }}</span>
        </button>
      </div>
      <div v-if="has_export && endpointName">
        <button
          class="py-2.5 sm:py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold ring-1 ring-slate-200 bg-white"
          @click.prevent="ExportItems"
          :disabled="export_disabled"
        >
          <spinner size="w-4 h-4 inline-block" v-if="export_disabled" />
          <i class="fa-solid fa-cloud-arrow-down" v-else></i>

          <span class="hidden sm:inline">{{ $t('download') }}</span>
        </button>
      </div>
      <div v-if="has_import">
        <button
          class="py-2.5 sm:py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold ring-1 ring-slate-200 bg-white"
          @click.prevent="showImport"
        >
          <i class="fa-solid fa-cloud-arrow-up"></i>
          <span class="hidden sm:inline">{{ $t('import') }}</span>
        </button>
      </div>
      <div>
        <button
          class="py-2.5 sm:py-1.5 px-3 rounded text-sm flex items-center gap-2 text-white bg-primary font-medium rtl:font-semibold"
          @click.prevent="showEmailModal"
          v-if="fields.length"
        >
          <i class="fa-solid fa-envelope-open-text"></i>
          <span class="hidden sm:inline">{{ $t('send_email') }}</span>
        </button>
      </div>
    </div>
    <div v-if="$slots.customTabs">
      <slot name="customTabs"></slot>
    </div>
    <div class="bg-white rounded-md ring-1 ring-slate-200">
      <div v-if="has_header">
        <header class="w-full p-4 border-b border-slate-200">
          <div class="flex flex-wrap gap-4 items-center">
            <div class="space-y-1 w-full flex-1">
              <h1 class="text-xl font-bold text-slate-800 capitalize">
                {{ title || '...' }}
                <spinner size="w-4 h-4 inline-block" v-if="is_loading" />
              </h1>
              <p
                class="text-xs font-medium rtl:font-semibold text-slate-600"
                v-if="records"
              >
                {{ records }}
              </p>
            </div>
            <div v-if="has_search">
              <div class="form-group">
                <div class="with-icon">
                  <input
                    autocomplete="off"
                    type="text"
                    name="search"
                    id="search"
                    :placeholder="$t('search')"
                    class="!bg-transparent !py-2 ring-1 ring-slate-200 max-w-sm !rounded"
                    @input="
                      (e) => {
                        updateSearch(e.target.value.trim())
                      }
                    "
                    @keyup.enter.stop="
                      (e) => {
                        changeIsCalled(true)
                      }
                    "
                  />
                  <span class="input-icon">
                    <spinner size="w-4 h-4 inline-block" v-if="is_called" />
                    <i class="fa-solid fa-search" v-else></i>
                  </span>
                </div>
              </div>
            </div>
            <slot name="more"></slot>
          </div>
        </header>
      </div>
      <div
        class="w-full grid border-b border-slate-200 overflow-x-auto whitespace-nowrap"
        v-if="$slots.filter"
      >
        <slot name="filter"></slot>
      </div>
      <div class="overflow-x-auto pb-4 w-full">
        <table
          class="styled-table"
          :class="{
            'has-filter': $slots.filter || !has_head,
          }"
        >
          <thead v-if="$slots.head">
            <tr v-if="has_head">
              <th v-if="has_check">
                <div class="form-group-with-check">
                  <div>
                    <input
                      autocomplete="off"
                      type="checkbox"
                      name="all"
                      id="all"
                      ref="checkAll"
                      @change="(e) => $emit('changed', e)"
                    />
                  </div>
                </div>
              </th>
              <slot name="head"> </slot>
            </tr>
            <slot name="head" v-else> </slot>
          </thead>
          <tbody v-if="$slots.tbody">
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </div>
      <div
        class="table mx-auto !py-6 px-4"
        v-if="has_paginate && total_pages > 1"
      >
        <paginate
          v-model="current_page"
          :page-count="total_pages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="$t('prev')"
          :next-text="$t('next')"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :click-handler="handleClickToSendPage"
        >
        </paginate>
      </div>
    </div>
    <transition name="scale">
      <!-- v-if="show_active"
        @close="
          (e) => {
            item = {}
            show_active = e
          }
        " -->
      <Modal
        :title="$t('send_email')"
        v-if="show_active"
        @close="
          (e) => {
            add_data = {
              emails: [],
              fields: fields,
            }
            show_active = e
            add_errors = {}
          }
        "
      >
        <template>
          <form class="w-full space-y-8 p-6">
            <div class="form-group space-y-2">
              <label for="emails">{{ $t('emails') }}</label>
              <v-tags
                v-model="email"
                :tags="add_data.emails"
                placeholder="eg: example@example.com"
                class="v-tags"
                @tags-changed="(newTags) => (add_data.emails = newTags)"
              />
              <!-- :show-remove-all="false" -->
              <ul
                class="space-y-1"
                v-if="Object.keys(add_errors).some((e) => /emails/gi.test(e))"
              >
                <li
                  v-for="(item, index) in Object.keys(add_errors)
                    .filter((e) => /emails/gi.test(e))
                    .map((e) => add_errors[e]) || []"
                  :key="index"
                >
                  <p class="text-red-500 font-medium text-xs">
                    {{ (item || []).join(' ') }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="w-full form-group space-y-2">
              <label for="all">{{ $t('customize_excel') }}</label>
              <ul
                class="list-none grid grid-cols-[repeat(auto-fill,minmax(8em,auto))] gap-2"
              >
                <li v-for="(item, index) in fields" :key="index" class="block">
                  <div class="form-group-with-check">
                    <div>
                      <input
                        autocomplete="off"
                        type="checkbox"
                        :name="item"
                        :id="item"
                        :value="item"
                        v-model="add_data.fields"
                      />
                    </div>
                    <label :for="item" class="capitalize">
                      {{ item.replace(/(_|-)/g, ' ') }}
                    </label>
                  </div>
                </li>
              </ul>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.fields"
              >
                {{ add_errors.fields.join(' ') }}
              </p>
            </div>
            <div class="w-full">
              <VueEditor v-model="emailBody" :editor-toolbar="customToolbar" />
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.body"
              >
                {{ add_errors.body.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  @click.prevent="SendItemsByEmail"
                  :disabled="add_disabled"
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('submit') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <customize-table ref="customizeTableRef" />
    <import-file
      v-if="has_import"
      @click-event="importData"
      @uploaded-file="updateImportData"
      :disabled="import_disabled"
      :errors="import_errors"
      ref="importModal"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/basics/Modal.vue'
import { VueEditor } from 'vue2-editor'
import CustomizeTable from './table/CustomizeTable.vue'
import ImportFile from './table/ImportFile.vue'

export default {
  name: 'TableComponent',
  components: { Modal, VueEditor, CustomizeTable, ImportFile },
  props: {
    has_check: {
      type: Boolean,
      default: true,
    },
    has_header: {
      type: Boolean,
      default: true,
    },
    has_paginate: {
      type: Boolean,
      default: true,
    },
    has_search: {
      type: Boolean,
      default: true,
    },
    has_export: {
      type: Boolean,
      default: true,
    },
    has_import: {
      type: Boolean,
      default: false,
    },
    has_customize: {
      type: Boolean,
      default: true,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    records: {
      type: String,
      default: null,
    },
    total_pages: {
      type: Number,
      default: 0,
    },
    fields: {
      type: Array,
      default: Array,
    },
    pagination: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('table', ['table_paginate', 'is_called', 'search']),
    ...mapGetters('customize', ['table_cols', 'table_cols_of_endpoints']),
    isJourneyPlans() {
      return this.$route.name === 'JourneyPlans'
    },
    has_head() {
      return this.$slots?.head?.findIndex((e) => e.tag !== 'tr') !== -1
    },
    emailBody: {
      get: function () {
        this.add_data.emails = [{ text: this.getUser?.email }]
        return `Dears,\n\nKindly find the ${this.title} ${
          /reports/gi.test(this.$route.path)
            ? `report from ${
                this.table_paginate?.from ?? new Date().toDateString()
              } to ${
                this.table_paginate?.to ?? new Date().toDateString()
              }\n\n\nRegards,\n${this.getUser?.name}`
            : ''
        }\n\n${
          this.getUser?.mobile
            ? `<p>tel: <a href="tel:${this.getUser?.mobile}">${this.getUser?.mobile}</a></p>`
            : `\n`
        }${
          this.getUser?.email
            ? `<p>email: <a href="mailto:${this.getUser?.email}">${this.getUser?.email}</a></p>`
            : `\n`
        }
        `
      },
      set: function (e) {
        this.add_data.body = e
      },
    },
    endpointName() {
      const route = this.$route
      const name = route.meta.endpoint_name
      if (['clients', 'merchandisers', 'client'].includes(name)) {
        return 'users'
      } else {
        if (/reports/gi.test(route.path)) {
          return `reports/${name}`
        }
        return name
      }
    },
    generateParamsToExport() {
      const pagination = Object.keys(this.table_paginate).length
        ? this.table_paginate
        : this.pagination

      if (!Object.keys(pagination).length) return
      Object.assign(pagination, {
        search_key: this.search,
      })

      let result = {}
      for (const key in pagination) {
        const item = pagination[key]
        if (!['total', 'total_pages'].includes(key)) {
          if (
            [
              'company_store_id',
              'company_branch_id',
              'city_id',
              'region_id',
              'store_placement_id',
              'unit_id',
              'user_id',
              'leader_id',
              'category_id',
              'sub_category_id',
              'brand_id',
            ].includes(key)
          ) {
            if (Array.isArray(item)) {
              result[key] = item?.map((e) => e.id || e)
            } else {
              result[key] = item?.id
            }
          } else {
            result[key] = item
          }
        }
      }

      return result
    },
  },

  data() {
    return {
      current_page: 1,
      add_data: {
        emails: [],
        body: null,
        fields: [...this.fields],
      },
      email: '',
      show_active: false,
      add_errors: {},
      file: null,
      run_tomorrow: 0,
      delete_current_journey_plan: 0,
      task_ids: null,
      show_import: false,
      import_disabled: false,
      import_errors: {},
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        [{ direction: 'rtl' }],
      ],
      add_disabled: false,
      export_disabled: false,
    }
  },
  watch: {
    current_page() {
      this.$emit('input', this.current_page)
    },
  },
  methods: {
    ...mapActions('table', ['setNewPage']),
    ...mapMutations('table', ['updateSearch', 'changeIsCalled']),
    handleClickToSendPage(page) {
      this.setNewPage(this.current_page)
    },
    showEmailModal() {
      this.show_active = true
    },
    hideEmailModal() {
      this.show_active = true
    },
    async ExportItems() {
      this.export_disabled = true
      try {
        const { data } = await this.axios.get(
          `${this.endpointName}/export_excel`,
          {
            params: {
              ...this.generateParamsToExport,
            },
            responseType: 'blob',
            headers: {
              'Content-type': 'blob',
            },
          }
        )
        let blob = new Blob([data])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${
          this.$route.meta.endpoint_name
        }-${new Date().getTime()}.${
          this.$route.name == 'SKUs' ? 'xlsx' : 'csv'
        }`
        link.click()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        // this.createAlert(res?.data?.message, 'error')
        this.createAlert(
          'You cannot export current table at that moment, please try again or contact us through live chat.',
          'error'
        )
      } finally {
        this.export_disabled = false
      }
    },
    async SendItemsByEmail() {
      this.add_disabled = true
      this.add_errors = {}
      try {
        const { data } = await this.axios.post(
          `${this.endpointName}/send_excel`,
          {
            ...this.add_data,
            emails: this.add_data?.emails?.map((e) => e.text),
          },
          {
            params: {
              ...this.generateParamsToExport,
            },
          }
        )
        this.createAlert(data.message)
      } catch (error) {
        const res = error?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        const message = res?.data?.message
        if (typeof message === 'object') {
          this.add_errors = message
          return
        }
        this.createAlert(message, 'error')
      } finally {
        this.add_disabled = false
      }
    },
    showImport() {
      this.$refs.importModal.show()
    },
    async importData() {
      this.import_disabled = true

      try {
        let fd = new FormData()
        if (!this.file) {
          this.createAlert('The file has not yet been chosen', 'error')
          return
        }

        fd.append('file', this.file, this.file.name)
        if (this.isJourneyPlans) {
          if (typeof this.run_tomorrow === 'number') {
            fd.append('run_tomorrow', this.run_tomorrow)
          }
          if (typeof this.delete_current_journey_plan === 'number') {
            fd.append(
              'delete_current_journey_plan',
              this.delete_current_journey_plan
            )
          }

          if (Array.isArray(this.task_ids)) {
            for (const task in this.task_ids) {
              fd.append(`task_ids[${task}]`, this.task_ids[task])
            }
          }
        }
        const { data } = await this.axios.post(
          `${this.endpointName}/import`,
          fd
        )

        if (data.result) {
          this.import_errors = data.result
          return
        }
        this.$refs.importModal.hide()
        this.file = null
        this.run_tomorrow = 0
        this.delete_current_journey_plan = 0
        this.task_ids = null
        this.changeIsCalled(true)
        this.createAlert(data.message)
      } catch (error) {
        const res = error?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        const message = res?.data?.message
        if (typeof message === 'object') {
          this.import_errors = message
          return
        }
        this.createAlert(message, 'error')
      } finally {
        this.import_disabled = false
      }
    },
    showCustomizeModal() {
      this.$refs.customizeTableRef?.showModal()
    },
    updateImportData(e) {
      this.file = e.file
      this.run_tomorrow = e.run_tomorrow
      this.delete_current_journey_plan = e.delete_current_journey_plan
      this.task_ids = e.task_ids || []
    },
  },
}
</script>

<style lang="scss">
.v-tags {
  max-width: 100% !important;
  .ti-input {
    @apply rounded-md;
    padding: 0.5rem 0.7rem !important;
    border-color: #e2e8f0 !important;
  }
  .ti-tags {
    @apply items-center;
    .ti-tag {
      @apply px-2 py-1.5;
    }
  }
  input {
    background-color: transparent !important;
    padding: 0px !important;
  }
}
</style>
