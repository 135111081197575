<template>
  <div>
    <sub-pages-header>
      <div class="w-full">
        <form class="w-full space-y-4">
          <figure class="bg-white border border-slate-200 rounded">
            <header class="border-b border-slate-100 p-4">
              <p class="font-medium rtl:font-semibold text-sm">
                {{ $t('create_new.visit') }}
              </p>
            </header>
            <blockquote class="w-full space-y-3 p-6">
              <div
                class="w-full space-y-2"
                v-if="getUser.role_type === 'super_user'"
              >
                <label
                  for="client"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('client') }}</label
                >
                <multiselect
                  v-model="add_data.client_id"
                  :options="clients"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="client_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="clients.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.client_id"
                >
                  {{ add_errors.client_id.join(' ') }}
                </p>
              </div>

              <div class="w-full space-y-2">
                <label
                  for="region"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('region') }}</label
                >
                <multiselect
                  v-model="add_data.region_id"
                  :options="regions"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="region_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="regions.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.region_id"
                >
                  {{ add_errors.region_id.join(' ') }}
                </p>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class="w-full space-y-2">
                  <label
                    for="city"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('city') }}</label
                  >
                  <multiselect
                    v-model="add_data.city_id"
                    :options="filterCities"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="city_id"
                    :hideSelected="true"
                    :clearOnSelect="false"
                    :disabled="filterCities.length === 0"
                  ></multiselect>

                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.city_id"
                  >
                    {{ add_errors.city_id.join(' ') }}
                  </p>
                </div>
                <div class="w-full space-y-2">
                  <label
                    for="zone"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('zone') }}</label
                  >
                  <multiselect
                    v-model="add_data.zone_id"
                    :options="filterZones"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="zone_id"
                    :hideSelected="true"
                    :clearOnSelect="false"
                    :disabled="filterZones.length === 0"
                  ></multiselect>

                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.zone_id"
                  >
                    {{ add_errors.zone_id.join(' ') }}
                  </p>
                </div>

                <div class="w-full space-y-2">
                  <label
                    for="supervisor"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('supervisor') }}</label
                  >
                  <multiselect
                    v-model="add_data.supervisor_id"
                    :options="filterSupervisors"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="supervisor_id"
                    :hideSelected="true"
                    :clearOnSelect="false"
                    :disabled="filterSupervisors.length === 0"
                  ></multiselect>

                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.supervisor_id"
                  >
                    {{ add_errors.supervisor_id.join(' ') }}
                  </p>
                </div>

                <div class="w-full space-y-2">
                  <label
                    for="merchandiser"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('merchandiser') }}</label
                  >
                  <multiselect
                    v-model="add_data.user_id"
                    :options="filterMerchandisers"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    id="user_id"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    :disabled="filterMerchandisers.length === 0"
                  ></multiselect>

                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.user_id"
                  >
                    {{ add_errors.user_id.join(' ') }}
                  </p>
                </div>

                <div class="w-full space-y-2">
                  <label
                    for="stores"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('store') }}</label
                  >
                  <multiselect
                    v-model="add_data.company_store_id"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    :options="filterStores"
                    :taggable="true"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    id="stores"
                    :disabled="filterStores.length === 0"
                  ></multiselect>
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.company_store_id"
                  >
                    {{ add_errors.company_store_id.join(' ') }}
                  </p>
                </div>
                <div class="w-full space-y-2">
                  <label
                    for="branches"
                    class="table font-medium rtl:font-semibold text-sm"
                    >{{ $t('branches') }}</label
                  >
                  <multiselect
                    v-model="add_data.company_branch_ids"
                    :placeholder="$t('search')"
                    label="name"
                    track-by="id"
                    :options="filterBranches"
                    :multiple="true"
                    :closeOnSelect="false"
                    :taggable="true"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    id="branches"
                    :disabled="filterBranches.length === 0"
                  ></multiselect>
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-if="add_errors.company_branch_ids"
                  >
                    {{ add_errors.company_branch_ids.join(' ') }}
                  </p>
                </div>
              </div>
            </blockquote>
          </figure>
          <figure class="bg-white border border-slate-200 rounded">
            <header class="border-b border-slate-100 p-4">
              <p class="font-medium rtl:font-semibold text-sm">
                {{ $t('required_tasks') }}
              </p>
            </header>
            <blockquote class="w-full p-4">
              <ul class="list-none space-y-4" v-if="!load">
                <li v-for="i in 4" :key="i" class="w-full">
                  <div class="actions-group !flex-nowrap !gap-4">
                    <div
                      class="w-10 h-10 rounded bg-slate-200 animate-pulse"
                    ></div>
                    <div class="space-y-2 flex-1">
                      <div
                        class="w-1/3 h-4 rounded bg-slate-200 animate-pulse"
                      ></div>
                      <div
                        class="w-2/3 h-3 rounded bg-slate-200 animate-pulse"
                      ></div>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                class="text-center space-y-2 py-8 px-2"
                v-if="load && tasks.length === 0"
              >
                <i class="fa-solid fa-calendar-xmark fa-2xl text-slate-400"></i>
                <h1 class="font-semibold rtl:font-bold !mt-6">
                  There are no tasks available
                </h1>
                <p
                  class="text-sm text-slate-600 max-w-2xl font-medium rtl:font-semibold"
                >
                  You can contact us to find out when new tasks will be
                  available to complete your work
                </p>
              </div>
              <ul
                class="w-full list-none space-y-4"
                v-if="load && filterTasks.length !== 0"
              >
                <li v-for="(item, index) in filterTasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="add_data.task_ids"
                            @change.prevent="
                              handleParentItem($event, item.id, 'add_data')
                            "
                            v-if="!item.sub_tasks.length"
                          />
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="w-full list-none space-y-4 mt-4 [padding-inline-start:1.5rem]"
                    v-if="item.sub_tasks.length"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="add_data.task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id, 'add_data')
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.task_ids"
              >
                {{ add_errors.task_ids.join(' ') }}
              </p>
            </blockquote>
          </figure>
        </form>
      </div>

      <template #action>
        <button
          class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
          @click.prevent="addItem"
          :disabled="add_disabled"
        >
          <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
          {{ $t('create_new.visit') }}
        </button>
      </template>
    </sub-pages-header>
  </div>
</template>
<script>
import SubPagesHeader from '@/components/basics/SubPagesHeader.vue'
import { mapGetters } from 'vuex'
export default {
  components: { SubPagesHeader },
  name: 'CreateVisitPage',
  data() {
    return {
      tasks: [],
      times: ['today', 'weekly', 'monthly'],
      days: ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'],
      add_data: {
        week_days: [],
        task_ids: [],
        company_branch_ids: [],
        month_days: [],
        today_visit: 1,
        user_id: null,
      },
      times_data: [],
      users: [],
      branches: [],
      stores: [],
      clients: [],
      add_disabled: false,
      add_errors: {},
      load: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('filters', ['regions', 'cities', 'zones']),
    filterMerchandisers() {
      let _merchandisers = []
      const clientId = this.add_data.client_id
      const merchandisers = this.users.filter(
        (e) => e.role_type === 'merchandiser'
      )
      if (this.getUser?.role_type === 'super_user') {
        _merchandisers = clientId
          ? merchandisers.filter((merchandiser) => {
              const merchandiserClient = merchandiser?.clients?.map(
                (e) => clientId?.id === e?.id
              )
              if (!merchandiserClient.length) return false
              return merchandiser
            })
          : merchandisers
      }

      if (_merchandisers.length === 1)
        this.add_data['user_id'] = _merchandisers[0]
      return _merchandisers
    },
    filterSupervisors() {
      const clientId = this.add_data.client_id
      const supervisors = this.users.filter((e) => e.role_type === 'supervisor')

      if (this.getUser?.role_type === 'super_user') {
        const _supervisors = clientId
          ? supervisors.filter((e) => e.client?.id === clientId?.id)
          : supervisors
        if (_supervisors.length === 1)
          this.add_data['supervisor_id'] = _supervisors[0]
        return _supervisors
      }

      if (supervisors.length === 1)
        this.add_data['supervisor_id'] = supervisors[0]
      return supervisors
    },
    filterBranches() {
      const storeId = this.add_data?.company_store_id
      if (!storeId || !storeId?.id) return this.branches
      this.$set(
        this.add_data,
        'company_branch_ids',
        this.add_data.company_branch_ids?.filter(
          (e) => e.company_store?.id === storeId?.id
        )
      )
      return (
        this.branches.filter((e) => e?.company_store?.id === storeId?.id) ||
        this.branches
      )
    },
    filterCities() {
      const selectedCities = this.add_data.region_id?.regions?.map((e) => e.id)
      if (!selectedCities?.length) return this.cities
      return this.cities.filter((e) => selectedCities.includes(e.region?.id))
    },
    filterZones() {
      const selectedCity = this.add_data.city_id?.id
      const filterZones = this.zones.filter((e) =>
        e.districts?.some((d) => selectedCity === d.city?.id)
      )
      return selectedCity ? filterZones : []
    },
    filterTasks() {
      let _tasks = []
      const clientId = this.add_data.client_id
      const tasks = this.tasks

      if (this.getUser?.role_type === 'super_user') {
        _tasks = clientId
          ? tasks.filter(
              (e) =>
                e.client_ids?.includes(clientId?.id) ||
                e.sub_tasks?.some((s) => s.client_ids.includes(clientId?.id))
            )
          : tasks
      } else if (
        ['client_user', 'supervisor'].includes(this.getUser.role_type)
      ) {
        _tasks = tasks.filter(
          (e) =>
            e.client_ids?.includes(this.getUser?.client?.id) ||
            e.sub_tasks?.some((s) => s.client_ids.includes(clientId?.id))
        )
      }

      this.add_data['task_ids'] = new Array().concat(
        _tasks.map((e) =>
          e.sub_tasks?.length ? e.sub_tasks.map((s) => s.id) : e.id
        )
      )
      return _tasks
    },
    filterStores() {
      let _stores = []
      const clientId = this.add_data.client_id
      const stores = this.stores
      this.$set(this.add_data, 'company_store_id', null)
      this.$set(this.add_data, 'company_branch_ids', [])

      if (this.getUser?.role_type === 'super_user') {
        _stores = clientId
          ? stores.filter((e) => e.client_ids.includes(clientId?.id))
          : stores
        // if (!clientId) return stores
        // return stores.filter((e) => e.client_ids.includes(clientId?.id))
      } else if (
        ['client_user', 'supervisor'].includes(this.getUser.role_type)
      ) {
        _stores = stores.filter((e) =>
          e.client_ids.includes(this.getUser?.client?.id)
        )
      }

      if (_stores.length === 1) this.add_data['company_store_id'] = _stores[0]
      return _stores
    },
  },
  watch: {
    'add_data.region_id'(val) {
      if (val) {
        this.$set(this.add_data, 'zone_id', null)
        this.$set(this.add_data, 'city_id', null)
      }
    },
    'add_data.client_id'(val) {
      if (val) {
        this.$set(this.add_data, 'supervisor_id', null)
        this.$set(this.add_data, 'user_id', null)
        this.$set(this.add_data, 'store_id', null)
      }
    },
  },
  async created() {
    await Promise.all([
      this.getUsers(),
      this.getClients(),
      this.getBranches(),
      this.getStores(),
      this.getTasks(),
    ])
  },
  methods: {
    async getTasks() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'tasks'
        )
        this.tasks = result.tasks
      } catch ({ result: result_1 }) {
        this.createAlert(result_1.message, 'error')
      } finally {
        this.load = true
      }
    },
    async getBranches() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        this.branches = result.company_branches
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getStores() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_stores'
        )
        this.stores = result.company_stores
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getUsers() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'users',
          options: {
            role_type: ['merchandiser', 'supervisor'],
          },
        })
        this.users = result.users
        this.$set(
          this.add_data,
          'user_id',
          result.users.find((e) => e.id === parseInt(this.$route.query.user))
        )
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getClients() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'clients'
        )
        const _clients = result.clients
        if (_clients.length === 1) this.add_data['client_id'] = _clients[0]
        this.clients = _clients
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      let fd = new FormData()

      for (const key in this.add_data) {
        if (this.add_data.hasOwnProperty(key)) {
          const propValue = this.add_data[key]
          if (propValue) {
            if (
              [
                'month_days',
                'week_days',
                'task_ids',
                'company_branch_ids',
              ].includes(key)
            ) {
              if (propValue.length !== 0) {
                propValue.forEach((_, idx) => {
                  fd.append(
                    `${key}[${idx}]`,
                    propValue[idx]?.id || propValue[idx]
                  )
                })
              }
            } else if (
              typeof propValue === 'object' &&
              !Array.isArray(propValue)
            ) {
              fd.append(key, propValue?.id)
            } else {
              fd.append(key, propValue)
            }
          }
        }
      }

      // console.log(fd)
      try {
        const { data } = await this.axios.post('visits/add', fd)
        this.createAlert(data.message)
        this.add_data = {
          month_days: [],
          week_days: [],
          task_ids: [],
          company_branch_ids: [],
          client_id: this.clients[0],
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    handleParentItem(e, id, type) {
      const tasks = this.tasks
      const task = tasks.find((e) => e.id === id)
      const isChecked = e.target.checked

      if (!task?.sub_tasks?.length) return
      const taskIds = task?.sub_tasks?.map((e) => e.id)
      if (isChecked) {
        this.$set(
          this[type],
          'task_ids',
          new Array().concat(this[type]?.task_ids, taskIds)
        )
      } else {
        this.$set(
          this[type],
          'task_ids',
          this[type]?.task_ids?.filter(
            (e) => !new Array().concat(id, taskIds).includes(e)
          )
        )
      }
    },
    handleChildItem(e, id, type) {
      const isChecked = e.target.checked
      const selectedTasks = this[type]?.task_ids

      if (isChecked) {
        if (selectedTasks.includes(id)) return
        this.$set(this[type], 'task_ids', new Array().concat(selectedTasks))
      } else {
        const parentSubTasks = this.tasks.find((e) => e.id === id)
        const siblingIds = parentSubTasks.sub_tasks.map((e) => e.id)
        const hasSiblingIds = siblingIds.some((e) =>
          new RegExp(selectedTasks.join('|')).test(e)
        )

        if (hasSiblingIds) return
        this.$set(
          this[type],
          'task_ids',
          selectedTasks.filter((e) => e !== id)
        )
      }
    },
  },
}
</script>
<style lang="scss">
.circle-progress {
  @apply transition-all ease-in-out;
  stroke-dasharray: 345;
  stroke-dashoffset: calc(345 - (345 * var(--i)) / 100);
  stroke-linecap: round;
}
</style>
