var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"w-full py-4 px-6"},[_c('div',{staticClass:"w-full flex justify-between items-center gap-4",attrs:{"data-type":"centered-item"}},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-slate-600 text-xs font-medium rtl:font-semibold"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © "+_vm._s(_vm.$t('copyRight'))+" "),_c('a',{staticClass:"text-primary underline hover:no-underline",attrs:{"href":"https://rtibha.com"}},[_vm._v("Rtibha - رتبها")])])]),(['Login', 'Join'].includes(_vm.$route.name))?_c('div',[_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var mousedownHandler = ref.mousedownHandler;
          var focusHandler = ref.focusHandler;
          var blurHandler = ref.blurHandler;
          var keydownHandler = ref.keydownHandler;
          var isShown = ref.isShown;
return _c('div',{},[_c('button',{staticClass:"\n              py-2\n              px-3\n              rounded-md\n              bg-slate-50\n              hover:bg-slate-100\n              text-sm\n              font-medium\n              rtl:font-semibold\n            ",class:{ 'border-slate-300 bg-slate-500 text-white ': isShown },on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('div',{staticClass:"w-full gap-3 sm:flex items-center hidden"},[_c('i',{staticClass:"\n                  fa-solid fa-angle-down fa-xs\n                  transition-transform\n                  ease-linear\n                ",class:{ 'rotate-180': isShown }}),_c('span',[_vm._v(_vm._s(_vm.$t(("langs." + (_vm.$i18n.locale)))))])]),_c('div',{staticClass:"block sm:hidden"},[_c('i',{staticClass:"fa-solid fa-globe fa-sm text-slate-600"})])])])}},{key:"default",fn:function(ref){
          var hide = ref.hide;
          var blurHandler = ref.blurHandler;
return _c('div',{},[_c('ul',{staticClass:"list-none overflow-hidden"},_vm._l((_vm.langs),function(item,index){return _c('li',{key:index,staticClass:"border-b border-slate-100 last:border-0"},[_c('button',{staticClass:"\n                  w-full\n                  p-3\n                  flex\n                  items-center\n                  gap-4\n                  font-medium\n                  rtl:font-semibold\n                  text-sm\n                  hover:bg-slate-100\n                ",on:{"click":function($event){$event.preventDefault();return (function () {
                    _vm.changeLang(item.lang)
                    hide()
                    blurHandler()
                  }).apply(null, arguments)}}},[_c('span',{class:("fi-" + (item.flag) + " w-5 h-4 bg-contain bg-no-repeat")}),_c('span',[_vm._v(_vm._s(_vm.$t(("langs." + (item.lang)))))])])])}),0)])}}],null,false,1027009031)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }