<template>
  <div>
    <section class="w-full px-6">
      <Table
        :has_header="true"
        :has_check="false"
        :has_customize="false"
        :title="$t('roles')"
        :records="`${pagination.total || 0} ${$t('records')}`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div>
            <button
              class="
                py-1.5
                px-3
                text-sm
                flex
                items-center
                gap-2
                text-slate-600
                font-medium
                rtl:font-semibold
              "
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>{{ $t('add_new.role') }}</span>
            </button>
          </div>
        </template>
        <template #head>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('alt_name') }}</th>
          <th>{{ $t('permissions') }}</th>

          <th></th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 6" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length !== 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name || '...' }}</td>
            <td>{{ item.alt_name || '...' }}</td>
            <td>{{ (item.permissions || []).length }}</td>
            <td>
              <div class="actions-group">
                <div v-if="false">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> Delete </span>
                  </button>
                </div>

                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    content="Update"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #tbody v-else-if="load && items.length === 0">
          <tr>
            <td :colspan="5">
              <div class="table mx-auto my-4">
                <button
                  class="
                    py-1.5
                    px-3
                    text-sm
                    flex
                    items-center
                    gap-2
                    text-slate-600
                    font-medium
                    rtl:font-semibold
                  "
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.role') }}</span>
                </button>
              </div>
              <p
                class="
                  text-sm text-slate-600
                  font-medium
                  rtl:font-semibold
                  text-center
                "
              >
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </template>
      </Table>
      <!-- <TableLoad  /> -->
    </section>
    <transition name="scale">
      <modal
        :title="$t('add_new.role')"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e
          }
        "
      >
        <template>
          <form class="p-4 space-y-6">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="add_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  v-model="add_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.alt_name"
              >
                {{ add_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <p class="text-slate-600 font-medium rtl:font-semibold text-sm">
                {{ $t('permissions') }}
              </p>
              <div class="w-full overflow-auto max-h-[25rem] !mt-4">
                <table class="styled-table w-full without-bg">
                  <tbody>
                    <tr v-for="(item, i) in permissions" :key="i">
                      <td :colspan="4">
                        <!-- {{
                          item.translate_key
                            ? $t(item.translate_key)
                            : item.name
                        }} -->
                        {{ item.name }}
                      </td>
                      <td v-if="item.access">
                        <div
                          class="form-group-with-check flex-col-reverse !gap-2"
                        >
                          <div>
                            <input
                              autocomplete="off"
                              type="checkbox"
                              :name="`item_${i}`"
                              :id="`item_${i}`"
                              data-type="switch"
                              :value="`show ${item.key}`"
                              v-model="add_data.permissions"
                              @change.prevent="
                                handlePermissionOnChange($event, 'add_data')
                              "
                            />
                          </div>
                          <div class="flex-1">
                            <label
                              :for="`item_${i}`"
                              class="capitalize text-slate-600"
                              >{{ $t('show') }}
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.permissions"
              >
                {{ add_errors.permissions.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </modal>
    </transition>
    <transition name="scale">
      <modal
        :title="$t('update')"
        v-if="edit_active"
        @close="
          (e) => {
            edit_active = e
          }
        "
      >
        <template>
          <form class="p-4 space-y-6">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="edit_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  v-model="edit_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.alt_name"
              >
                {{ edit_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <p class="text-slate-600 font-medium rtl:font-semibold text-sm">
                {{ $t('permissions') }}
              </p>
              <div class="w-full overflow-auto max-h-[25rem] !mt-4">
                <table class="styled-table w-full without-bg">
                  <tbody>
                    <tr v-for="(item, i) in permissions" :key="i">
                      <td :colspan="4">
                        <!-- {{
                          item.translate_key
                            ? $t(item.translate_key)
                            : item.name
                        }} -->
                        {{ item.name }}
                      </td>
                      <td v-if="item.access">
                        <div
                          class="form-group-with-check flex-col-reverse !gap-2"
                        >
                          <div>
                            <input
                              autocomplete="off"
                              type="checkbox"
                              :name="`item_${i}`"
                              :id="`item_${i}`"
                              data-type="switch"
                              :value="`show ${item.key}`"
                              v-model="edit_data.permissions"
                              @change.prevent="
                                handlePermissionOnChange($event, 'edit_data')
                              "
                            />
                          </div>
                          <div class="flex-1">
                            <label
                              :for="`item_${i}`"
                              class="capitalize text-slate-600"
                              >{{ $t('show') }}
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.permissions"
              >
                {{ edit_errors.permissions.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </modal>
    </transition>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
export default {
  components: { Modal, Table, TableLoad },
  name: 'Access',
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        permissions: [],
      },
      add_disabled: false,
      add_errors: {},
      add_active: false,
      edit_data: {
        permissions: [],
      },
      edit_disabled: false,
      edit_errors: {},
      edit_active: false,
      permissions: [
        {
          name: 'Select all',
          key: 'all',
          translate_key: 'select_all',
          access: ['show'],
        },

        {
          name: 'Performance',
          key: 'performance',
          translate_key: 'performance',
          access: ['show'],
        },
        {
          name: 'Calendar',
          key: 'calendar',
          translate_key: 'calendar',
          access: ['show'],
        },
        {
          name: 'OSA reports',
          key: 'osa_reports',
          translate_key: 'osa_reports',
          access: ['show'],
        },
        {
          name: 'Urgent visits',
          key: 'urgent_visit',
          translate_key: 'urgent_visit',
          access: ['show'],
        },
        {
          name: 'Reports',
          translate_key: 'reports',
        },
        {
          name: 'Visits',
          key: 'visits',
          translate_key: 'visits',
          access: ['show'],
        },
        {
          name: 'Sku audits',
          key: 'sku_audits',
          translate_key: 'skus',
          access: ['show'],
        },
        {
          name: 'Expiries',
          key: 'expiry_audits',
          translate_key: 'expiries',
          access: ['show'],
        },
        {
          name: 'Stock audits',
          key: 'stock_audits',
          translate_key: 'stock_audits',
          access: ['show'],
        },
        {
          name: 'Shelf audits',
          key: 'shelf_audits',
          translate_key: 'shelf_audits',
          access: ['show'],
        },
        {
          name: 'Delivery audits',
          key: 'delivery_audits',
          translate_key: 'delivery_audits',
          access: ['show'],
        },
        {
          name: 'Returns',
          key: 'return_audits',
          translate_key: 'returns',
          access: ['show'],
        },
        {
          name: 'Promotion',
          key: 'promotion_audits',
          translate_key: 'promotion',
          access: ['show'],
        },
        {
          name: 'Planogram',
          key: 'planogram_audits',
          translate_key: 'planogram',
          access: ['show'],
        },
        {
          name: 'Setup',
          translate_key: 'setup',
        },
        {
          name: 'Journey planes',
          key: 'journey_plans',
          translate_key: 'journey_plans',
          access: ['show'],
        },
        {
          name: 'SKUs',
          key: 'skus',
          translate_key: 'skus',
          access: ['show'],
        },
        {
          name: 'Shifts',
          key: 'shifts',
          translate_key: 'shifts',
          access: ['show'],
        },
        {
          name: 'Zones',
          key: 'zones',
          translate_key: 'zones',
          access: ['show'],
        },
        {
          name: 'Email configurations',
          key: 'email_configurations',
          translate_key: 'email_configurations',
          access: ['show'],
        },
        {
          name: 'Configuration',
          translate_key: 'configuration',
        },
        {
          name: 'Stores',
          key: 'company_stores',
          translate_key: 'stores',
          access: ['show'],
        },
        {
          name: 'Branches',
          key: 'company_branches',
          translate_key: 'branches',
          access: ['show'],
        },
        {
          name: 'Brands',
          key: 'brands',
          translate_key: 'brands',
          access: ['show'],
        },
        {
          name: 'Main categories',
          key: 'main_categories',
          translate_key: 'main_categories',
          access: ['show'],
        },
        {
          name: 'Categories',
          key: 'categories',
          translate_key: 'categories',
          access: ['show'],
        },
        {
          name: 'Sub categories',
          key: 'sub_categories',
          translate_key: 'sub_categories',
          access: ['show'],
        },
        {
          name: 'Store placements',
          key: 'store_placements',
          translate_key: 'store_placements',
          access: ['show'],
        },
        {
          name: 'Units',
          key: 'units',
          translate_key: 'units',
          access: ['show'],
        },

        {
          name: 'Competitors',
          key: 'competitors',
          translate_key: 'competitors',
          access: ['show'],
        },
        {
          name: 'Shelves',
          key: 'shelves',
          translate_key: 'shelves',
          access: ['show'],
        },
        {
          name: 'Planograms',
          key: 'planograms',
          translate_key: 'planograms',
          access: ['show'],
        },
        {
          name: 'Cancellation reasons',
          key: 'c_reasons',
          translate_key: 'c_reasons',
          access: ['show'],
        },

        {
          name: 'Locations',
          key: 'locations',
          translate_key: 'locations',
          access: ['show'],
        },
        {
          name: 'Users',
          key: 'users',
          translate_key: 'users',
          access: ['show'],
        },
        {
          name: 'Clients',
          key: 'clients',
          translate_key: 'clients',
          access: ['show'],
        },
        {
          name: 'Merchandisers',
          key: 'merchandisers',
          translate_key: 'merchandisers',
          access: ['show'],
        },
        {
          name: 'Supervisors',
          key: 'supervisors',
          translate_key: 'supervisors',
          access: ['show'],
        },
        {
          name: 'Roles',
          key: 'roles',
          translate_key: 'roles',
          access: ['show'],
        },
       
      ],
      pagination: {
        page: 1,
      },
      access: ['show', 'delete', 'edit', 'add'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {
          permissions: [],
        }
        this.add_errors = {}
        this.add_disabled = false
      }
    },
  },
  async created() {
    await this.getItems()
  },
  methods: {
    async getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'roles',
          options: {
            page,
            search_key,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
          },
        })
        const { data, pagination } = result.roles
        this.items = data.map((e) => {
          return {
            ...e,
          }
        })
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.load = true
      }
      // .finally(() => {
      //   this.load = true
      // })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { data } = await this.axios.post('roles/add', {
          ...this.add_data,
          permissions: this.add_data.permissions.includes('show dashboard')
            ? this.add_data.permissions
            : ['show dashboard', ...this.add_data.permissions],
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
        window.location.reload()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('roles/update', {
          ...this.edit_data,
          permissions: this.edit_data.permissions.includes('show dashboard')
            ? this.edit_data.permissions
            : ['show dashboard', ...this.edit_data.permissions],
        })
        const { message, result } = data
        const finalResult = result.role ?? {}
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...finalResult,
        }
        window.location.reload()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },

    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`roles/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    handlePermissionOnChange(e, type) {
      const value = e.target.value
      const isChecked = e.target.checked

      if (!value) return
      const splitValue = value.split(/\s/gi)

      if (splitValue[1] === 'all') {
        // filter permissions by access key to ignore items if that key did not existed
        const filterPermissions = this.permissions.filter(
          (e) => e.access && e.key !== 'all'
        )

        for (const item of filterPermissions) {
          const itemAccess = item.access
          if (itemAccess.includes(splitValue[0])) {
            const accessKeyToPush = `${splitValue[0]} ${item.key}`
            if (this[type].permissions.includes(accessKeyToPush)) {
              this[type].permissions.splice(
                this[type].permissions.indexOf(accessKeyToPush),
                1
              )
              // return
            }
            if (isChecked) {
              this[type].permissions.push(accessKeyToPush)
            } else {
              this[type].permissions.splice(
                this[type].permissions.indexOf(accessKeyToPush),
                1
              )
            }
          }
        }
        // filter permissions from parent
        this.$set(
          this[type],
          'permissions',
          this[type].permissions.filter((e) => !/\w+\sall/gi.test(e))
        )
      }
    },
  },
}
</script>
