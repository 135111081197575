<template>
  <div>
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
    <section
      class="w-full space-y-6"
      :class="{
        'px-6': !isVisit,
      }"
    >
      <reports-filter v-show="!isVisit" ref="reportsFilter"></reports-filter>
      <cards-container :cards="filterCards" :load="!load" v-if="!isVisit" />
      <Tabs
        :link_type="'button'"
        :tabs="filterTabs"
        :component_name="component_name"
        @action="
          (e) => {
            component_name = e
            handlePagination({
              ...pagination,
              is_competitor: e ? (e === 'promotion' ? 0 : 1) : null,
            })
          }
        "
      />
      <main class="w-full space-y-3">
        <div
          class="w-full grid xs:grid-cols-1 grid-cols-[repeat(auto-fill,minmax(17em,auto))] gap-4 items-stretch"
          v-if="(generateResult || []).length !== 0"
        >
          <figure
            class="bg-white border border-slate-200"
            v-for="(item, index) in generateResult"
            :key="index"
          >
            <figcaption class="p-2 relative">
              <img
                :src="
                  (item.images || [])[0] ||
                  require('@/assets/images/placeholder.png')
                "
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
                alt="image"
                class="w-full h-full min-h-[12rem] max-h-[15rem] object-cover"
              />
              <button
                class="absolute top-4 right-4 rtl:right-auto rtl:left-4 w-9 h-9 rounded-full bg-white shadow-lg flex items-center justify-center"
                @click="openLightBox(item)"
                v-if="item.images.length >= 1"
              >
                <i class="fa-solid fa-expand fa-sm text-slate-600"></i>
              </button>
            </figcaption>
            <blockquote class="w-full pt-2 p-4 space-y-1" v-if="!isVisit">
              <div class="flex">
                <div class="space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                      >
                        {{
                          (item.visit.company_branch.company_store || {})
                            .name || '...'
                        }}
                      </p>
                      <p
                        class="text-xs text-slate-400 block font-medium rtl:font-semibold truncate"
                      >
                        {{ (item.visit.company_branch || {}).name || '...' }}
                      </p>
                      <!-- <a
                        :href="`tel:${
                          (item.visit.company_branch || {})['mobile']
                        }`"
                        class="
                          text-sm text-primary
                          font-medium
                          rtl:font-semibold
                          table
                          truncate
                        "
                      >
                        {{ (item.visit.company_branch || {})['mobile'] }}
                      </a> -->
                    </div>
                  </div>
                </div>
                <div class="flex-1">
                  <span class="text-xs text-slate-400 font-semibold"
                    >{{ item.images_count }} {{ $t('image') }}</span
                  >
                </div>
              </div>
              <div class="hidden">
                <div class="flex-1">
                  <span class="text-xs text-slate-400 font-semibold">
                    {{ $t('user') }}
                  </span>
                </div>
                <div class="space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                      >
                        {{ (item.visit.user || {}).name || '...' }}
                      </p>

                      <a
                        :href="`tel:${(item.visit.user || {})['mobile']}`"
                        class="text-sm text-primary font-medium rtl:font-semibold table truncate"
                      >
                        {{ (item.visit.user || {})['mobile'] }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </blockquote>
          </figure>
        </div>
        <div v-if="generateResult.length === 0 || !load">
          <EmptyCard :title="$t('no_data')" icon="fa-solid fa-images fa-xl" />
        </div>
        <div
          class="table mx-auto !py-6 px-4"
          v-if="load && pagination.total_pages > 1"
        >
          <paginate
            v-model="pagination.page"
            :page-count="pagination.total_pages"
            :page-range="5"
            :margin-pages="2"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :click-handler="handlePagination"
          >
          </paginate>
        </div>
      </main>
    </section>
    <Modal
      v-if="images_active"
      @close="
        (e) => {
          images_active = e
          current_index = 0
        }
      "
      size="!max-w-screen-md"
      :title="$t('images')"
    >
      <template>
        <div class="w-full space-y-4 p-6">
          <div class="pb-4 border-b border-b-slate-100">
            <div class="relative">
              <Swiper
                class="w-full"
                :options="{
                  loopedSlides: 5,
                  slidesPerView: 1,
                  loop: false,
                  navigation: {
                    nextEl: 'button[data-type=\'navigation-btn-next\']',
                    prevEl: 'button[data-type=\'navigation-btn-prev\']',
                  },
                }"
                ref="swiperTop"
                @slide-change="onSwiperChange"
              >
                <SwiperSlide v-for="(image, index) in items" :key="index">
                  <figure class="space-y-6">
                    <figcaption>
                      <img
                        :src="
                          image || require('@/assets/images/placeholder.png')
                        "
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="w-full max-h-[28rem] h-auto object-contain mx-auto aspect-square"
                      />
                    </figcaption>
                  </figure>
                </SwiperSlide>
              </Swiper>
              <slide-transition>
                <div
                  class="navigation-swiper w-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex justify-between z-[5]"
                  v-if="(items || []).length > 1"
                >
                  <button
                    class="flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm w-10 h-10 ring-1 ring-slate-200 rounded-full bg-transparent hover:bg-slate-200 shrink-0"
                    data-type="navigation-btn-prev"
                  >
                    <i class="fa-solid fa-angle-left block rtl:hidden"></i>
                    <i class="fa-solid fa-angle-right hidden rtl:block"></i>
                  </button>
                  <button
                    class="flex items-center justify-center gap-2 font-semibold text-slate-600 text-sm w-10 h-10 ring-1 ring-slate-200 rounded-full bg-transparent hover:bg-slate-200 shrink-0"
                    data-type="navigation-btn-next"
                  >
                    <i class="fa-solid fa-angle-right block rtl:hidden"></i>
                    <i class="fa-solid fa-angle-left hidden rtl:block"></i>
                  </button>
                </div>
              </slide-transition>
            </div>
            <slide-transition>
              <Swiper
                class="w-full my-8"
                :options="{
                  watchSlidesProgress: true,
                  loop: false,
                  loopedSlides: 5, // looped slides should be the same
                  spaceBetween: 10,
                  centeredSlides: true,
                  slidesPerView: 'auto',
                  touchRatio: 0.2,
                  slideToClickedSlide: true,
                }"
                ref="swiperThumbs"
                v-if="(items || []).length > 1"
              >
                <SwiperSlide
                  v-for="(image, index) in items"
                  :key="index"
                  class="w-24 h-auto slide-bottom rounded p-2 ring-1 ring-slate-200"
                >
                  <figure class="space-y-4">
                    <figcaption class="table mx-auto">
                      <img
                        :src="
                          image || require('@/assets/images/placeholder.png')
                        "
                        alt="visit image"
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        class="max-w-full max-h-[12rem] object-contain"
                      />
                    </figcaption>
                    <!-- <blockquote>
                      <p
                        class="
                          font-medium
                          rtl:font-semibold
                          text-center text-slate-600 text-xs
                        "
                      >
                        {{
                          item.images_before.includes(image)
                            ? $t('images_before')
                            : $t('images_after')
                        }}
                      </p>
                    </blockquote> -->
                  </figure>
                </SwiperSlide>
              </Swiper>
            </slide-transition>
          </div>
          <div class="space-y-3 flex-1">
            <div class="flex">
              <div class="space-y-1 flex-1">
                <div class="table">
                  <div class="grid">
                    <p
                      class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                    >
                      {{ $t('store_placement') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="grid">
                  <p
                    class="text-xs text-slate-600 block font-medium rtl:font-semibold"
                  >
                    {{ (generateItem.store_placement || {}).name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="space-y-1 flex-1">
                <div class="table">
                  <div class="grid">
                    <p
                      class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                    >
                      {{ $t('brand') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="grid">
                  <p
                    class="text-xs text-slate-600 block font-medium rtl:font-semibold"
                  >
                    {{ (generateItem.brand || {}).name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex" v-if="generateItem.competitor">
              <div class="space-y-1 flex-1">
                <div class="table">
                  <div class="grid">
                    <p
                      class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                    >
                      {{ $t('competitor') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="grid">
                  <p
                    class="text-xs text-slate-600 block font-medium rtl:font-semibold"
                  >
                    {{ (generateItem.competitor || {}).name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="space-y-1 flex-1">
                <div class="table">
                  <div class="grid">
                    <p
                      class="text-xs text-slate-600 block font-medium rtl:font-semibold truncate"
                    >
                      {{ $t('notes') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="grid">
                  <p
                    class="text-xs text-slate-600 block font-medium rtl:font-semibold"
                  >
                    {{ generateItem.notes || '-' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import CardsContainer from './components/CardsContainer.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import Tabs from '../basics/Tabs.vue'
import { mapGetters, mapMutations } from 'vuex'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { v4 as uuidv4 } from 'uuid'

// Import Swiper styles
import 'swiper/css/swiper.min.css'
import Modal from '../basics/Modal.vue'
export default {
  components: {
    ReportsFilter,
    CardsContainer,
    Tabs,
    Swiper,
    SwiperSlide,
    Modal,
  },
  name: 'PromotionAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      pagination: this.value,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      items: [],
      item: {},
      images_active: false,
      index: null,
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'My promotions',
          value: 0,
          key: 'my_promotions_count',
          translate_key: 'my_promotions',
          icon: 'fa-solid fa-hashtag',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'competitors',
          value: 0,
          key: 'competitors_count',
          translate_key: 'competitors',
          icon: 'fa-solid fa-user-group',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
      ],
      component_name: null,
      tabs: [
        {
          name: null,
          title: 'All',
          key: 'all',
        },
        {
          name: 'promotion',
          title: 'My promotions',
          key: 'my_promotions',
        },
        {
          name: 'competitors',
          title: 'Competitors',
          key: 'competitors',
        },
      ],
      current_index: 0,
    }
  },
  watch: {
    images_active(val) {
      if (val) {
        setTimeout(() => {
          this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop?.$swiper
            const swiperThumbs = this.$refs.swiperThumbs?.$swiper
            if (this.items.length > 1) {
              swiperTop.controller.control = swiperThumbs
              swiperThumbs.controller.control = swiperTop
            }
          })
        }, 25)
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['tasks']),
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      // return this.cards.map((e) => ({
      //   ...e,
      //   value: this.summary[e.key] || 0,
      // }))
      const tasks = this.$store.getters['auth/tasks']
      const filterPromotions = this.cards.filter((card) => {
        return tasks?.some((task) =>
          new RegExp('(' + task.type?.split('_')?.join('|') + ')', 'gi').test(
            card.key
          )
        )
          ? card
          : false
      })
      const items = this.cards.filter(
        (card) => !/promotion|competitors/gi.test(card.key)
      )

      return new Array().concat(items, filterPromotions).map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
    filterTabs() {
      const _tasks = this.tasks
      const filters = this.tabs.filter((e) => {
        return (
          _tasks.some((task) => new RegExp(e.name).test(task.type)) || !e.name
        )
      })

      return filters.some((e) => /competitors/gi.test(e.name)) ? filters : []
    },
    // generateResult() {
    //   const items = this.result || []
    //   return items.map((e) => {
    //     const imagesBefore = new Array().concat(
    //       ...e.items?.map((item) => item.images_before)
    //     )
    //     const imagesAfter = new Array().concat(
    //       ...e.items?.map((item) => item.images_after)
    //     )
    //     const images = new Array().concat(
    //       ...e.items?.map((item) => item.images)
    //     )
    //     return {
    //       ...e,
    //       // images_before: imagesBefore,
    //       // images_after: imagesAfter,
    //       // images_count: new Array().concat(imagesBefore, imagesAfter).length,
    //       // images: new Array().concat(imagesBefore, imagesAfter),
    //       images_count: images.length,
    //       images,
    //       id: uuidv4(),
    //     },

    // })
    // },
    generateResult() {
      const items = this.result || []
      return items.map((e) => {
        const images = new Array().concat(
          ...(this.isVisit ? e?.images : e.items?.map((item) => item.images))
        )

        return {
          ...e,
          images_count: images.length,
          images,
          id: uuidv4(),
          ...(e.items
            ? { items: e.items }
            : {
                items: [
                  {
                    ...e,
                    images_count: images.length,
                    images,
                    id: uuidv4(),
                  },
                ],
              }),
        }
      })
    },
    generateItem() {
      const currentImage = this.items[this.current_index]
      return this.item?.items?.find((e) => e.images?.includes(currentImage))
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async openLightBox(ev) {
      // const loadImages = async () => {
      //   let result = []

      //   for (let i = 0; i < images.length; i++) {
      //     const item = images[i]
      //     const img = new Image()
      //     img.src = item
      //     const error = await this.handleImgLoad(img)

      //     if (error) {
      //       result.push(require('@/assets/images/placeholder.png'))
      //     } else {
      //       result.push(item)
      //     }
      //   }
      //   return result
      // }

      // this.items = await loadImages()
      this.items = ev.images
      this.item = ev
      this.images_active = true
    },
    handleImgLoad(item) {
      return new Promise((resolve) =>
        item.addEventListener('error', () => resolve(true))
      )
    },
    handlePagination(params) {
      // this.changeIsCalled(true);
      this.$refs.reportsFilter?.$refs?.filterBox?.handleApplyPagination(
        typeof params === 'object' ? params : this.pagination
      )
    },
    onSwiperChange() {
      const swiper = this.$refs.swiperTop?.$swiper
      if (swiper) {
        this.current_index = swiper?.realIndex
      }
    },
  },
}
</script>
