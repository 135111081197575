<template>
  <div>
    <container-wrapper>
      <section class="w-full space-y-6 px-6">
        <filter-wrapper>
          <div class="w-full">
            <multiselect
              v-model="pagination.region_id"
              :options="filtersRegions"
              :placeholder="$t('regions')"
              id="region_id"
              style="background-color: #ffffff !important"
              :hideSelected="true"
              :clearOnSelect="true"
              label="name"
              track-by="name"
              :disabled="filtersRegions.length === 0"
            ></multiselect>
          </div>
          <div class="w-full">
            <div class="actions-group">
              <div>
                <button
                  class="
                    w-full
                    h-auto
                    bg-secondary
                    text-white
                    font-medium
                    rtl:font-semibold
                    text-sm
                    rounded-md
                    text-center
                    py-2.5
                    px-4
                  "
                  @click.prevent="applyFilter"
                  :disabled="apply_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="apply_disabled" />
                  {{ $t('apply') }}
                </button>
              </div>
              <div>
                <button
                  class="
                    w-full
                    h-auto
                    bg-gray-300
                    text-gray-600
                    font-medium
                    rtl:font-semibold
                    text-sm
                    rounded-md
                    text-center
                    py-2.5
                    px-4
                  "
                  @click.prevent="resetFilter"
                  :disabled="reset_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="reset_disabled" />
                  {{ $t('reset') }}
                </button>
              </div>
            </div>
          </div>
        </filter-wrapper>
        <main class="w-full">
          <Table
            :has_header="true"
            :has_check="false"
            :has_customize="false"
            :has_export="false"
            :title="$t('cities')"
            :records="`${pagination.total} ${$t('records')}`"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
          >
            <template #head>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('alt_name') }}</th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('country') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('country_id')"
                    ></button>
                  </div>
                </div>
              </th>
              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('region') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('region_id')"
                    ></button>
                  </div>
                </div>
              </th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>
              <th></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="7" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.name || '-' }}</td>
                <td>{{ item.alt_name || '-' }}</td>
                <td>{{ (item.country || {}).name || '-' }}</td>
                <!-- <td>{{ (item.region || {}).name || '-' }}</td> -->
                <td>
                  <div class="actions-group">
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-for="(region, index) in (
                        item.region_groups || []
                      ).slice(0, 1)"
                      :key="index"
                      >{{ region.name }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.region_groups || []).length > 1"
                      >+{{ (item.region_groups || []).length - 1 }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.region_groups || []).length === 0"
                      >-</span
                    >
                  </div>
                </td>

                <td
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td>
                  <!-- <div class="actions-group">
                   
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                        @click.prevent="changeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                        :disabled="disables[`status_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${item.id}`]"
                        />
                        <i class="fa-solid fa-right-left" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                        @click.prevent="showUpdate(item.id)"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div> -->
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td colspan="6">
                  <!-- <div class="table mx-auto my-4">
                    <button
                      class="
                        py-1.5
                        px-3
                        rounded-sm
                        text-sm
                        flex
                        items-center
                        gap-2
                        text-gray-600
                        ring-1 ring-gray-300
                        font-medium
                        rtl:font-semibold
                      "
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.city') }}</span>
                    </button>
                  </div> -->
                  <p
                    class="
                      text-sm text-gray-600
                      font-medium
                      rtl:font-semibold
                      text-center
                    "
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.city')"
          v-if="add_active"
          @close="add_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="add_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name"
                >
                  {{ add_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="add_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alt_name"
                >
                  {{ add_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="add_data.country_id"
                    :options="countries"
                    :placeholder="$t('select')"
                    id="add_data_country_id"
                    label="name"
                    track-by="name"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    :disabled="countries.length === 0"
                  ></multiselect>
                  <label for="add_data_country_id">{{ $t('country') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.country_id"
                >
                  {{ add_errors.country_id.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="add_data.region_id"
                    :options="FilterRegions('add_data')"
                    :placeholder="$t('select')"
                    id="add_data_region_id"
                    label="name"
                    track-by="name"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    :disabled="FilterRegions('add_data').length === 0"
                  ></multiselect>
                  <label for="add_data_region_id">{{ $t('region') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.region_id"
                >
                  {{ add_errors.region_id.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="
                      py-2.5
                      px-6
                      rounded-md
                      text-sm
                      font-medium
                      rtl:font-semibold
                      text-white
                      bg-primary
                      table
                    "
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t('add') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="edit_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="edit_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name"
                >
                  {{ edit_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="edit_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_name"
                >
                  {{ edit_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="edit_data.country_id"
                    :options="countries"
                    :placeholder="$t('select')"
                    id="edit_data_country_id"
                    label="name"
                    track-by="name"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    :disabled="countries.length === 0"
                  ></multiselect>
                  <label for="edit_data_country_id">{{ $t('country') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.country_id"
                >
                  {{ edit_errors.country_id.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <multiselect
                    v-model="edit_data.region_id"
                    :options="FilterRegions('edit_data')"
                    :placeholder="$t('select')"
                    id="edit_data_region_id"
                    label="name"
                    track-by="name"
                    :hideSelected="true"
                    :clearOnSelect="true"
                    :disabled="FilterRegions('edit_data').length === 0"
                  ></multiselect>
                  <label for="edit_data_region_id">{{ $t('region') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.region_id"
                >
                  {{ edit_errors.region_id.join(' ') }}
                </p>
              </div>
              <div class="actions-group">
                <div>
                  <button
                    class="
                      py-2.5
                      px-6
                      rounded-md
                      text-sm
                      font-medium
                      rtl:font-semibold
                      text-white
                      bg-primary
                      table
                    "
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
    </container-wrapper>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import ContainerWrapper from '@/components/setup/locations/ContainerWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Cities',
  data() {
    return {
      load: false,
      items: [],
      countries: [],
      regions: [],
      add_data: {},
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        country_id: null,
        region_id: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called(val) {
      if (val) this.getItems()
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
    ...mapGetters({
      filtersRegions: 'filters/regions',
    }),
    FilterRegions() {
      return (type) =>
        this.regions.filter(
          (e) => e?.country?.id === this[type]?.country_id?.id
        ) ?? []
    },
  },
  created() {
    this.getItems()
    this.getCountries()
    this.getRegions()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getCountries() {
      this.$store
        .dispatch('getting/getRoute', 'countries')
        .then(({ result }) => {
          this.countries = result.countries
        })
    },
    getRegions() {
      this.$store.dispatch('getting/getRoute', 'regions').then(({ result }) => {
        this.regions = result.regions
      })
    },
    getItems() {
      const {
        page,
        search_key,
        sort_key,
        sort_type,
        is_active,
        country_id,
        region_id,
      } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'cities',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            country_id: country_id?.id,
            region_id: region_id?.id,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.cities
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { data } = await this.axios.post('cities/add', {
          ...this.add_data,
          country_id: this.add_data?.country_id?.id,
          region_id: this.add_data?.region_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('cities/update', {
          ...this.edit_data,
          country_id: this.edit_data?.country_id?.id,
          region_id: this.edit_data?.region_id?.id,
        })
        const { message, result } = data
        const finalResult = result.region ?? {}
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        this.edit_data = {
          ...finalResult,
          country_id: finalResult?.country,
          region_id: finalResult?.region,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`cities/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`cities/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        country_id: item?.country ?? {},
        region_id: item?.region ?? {},
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) =>
          ['status', 'search_key', 'country_id', 'region_id'].includes(e)
        )
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, FilterWrapper, ContainerWrapper },
}
</script>
