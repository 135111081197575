<template>
  <div>
    <configuration-wrapper>
      <section class="w-full space-y-6">
        <filter-box class="px-6"> </filter-box>
        <main class="w-full px-6">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('planograms')"
            :records="`${pagination.total || 0} ${$t('records')}`"
            :pagination="pagination"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
          >
            <template #actions>
              <div>
                <button
                  class="
                    py-1.5
                    px-3
                    text-sm
                    flex
                    items-center
                    gap-2
                    text-slate-600
                    font-medium
                    rtl:font-semibold
                  "
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.planogram') }}</span>
                </button>
              </div>
            </template>
            <template #filter>
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="
                      py-4
                      px-6
                      text-center
                      font-medium
                      rtl:font-semibold
                      text-sm
                      capitalize
                    "
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, '_')) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th v-if="columns.includes('image')">{{ $t('image') }}</th>
              <th v-if="columns.includes('client')">
                <div class="sort">
                  <div>
                    <p>{{ $t('client') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('client_id')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('store_placement')">
                <div class="sort">
                  <div>
                    <p>{{ $t('store_placement') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('store_placement_id')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('categories')">
                <div class="sort">
                  <div>
                    <p>{{ $t('categories') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('categories')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('sub_categories')">
                <div class="sort">
                  <div>
                    <p>{{ $t('sub_categories') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('sub_categories')"
                    ></button>
                  </div>
                </div>
              </th>
              <th v-if="columns.includes('active')">
                <div class="sort">
                  <div>
                    <p>{{ $t('active') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('active')"
                    ></button>
                  </div>
                </div>
              </th>

              <th v-if="columns.includes('updated_at')">
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>

              <th v-if="columns.includes('actions')"></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="columns.includes('image')">
                  <div class="w-12 h-auto">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      :alt="`${item.name} image`"
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      class="w-full max-h-full object-contain"
                    />
                  </div>
                </td>
                <td v-if="columns.includes('client')">
                  <p>{{ (item.client || {}).name || '-' }}</p>
                  <a
                    :href="`tel:${(item.client || {}).mobile}`"
                    class="text-sky-500"
                    v-if="(item.client || {}).mobile"
                    >{{ (item.client || {}).mobile }}</a
                  >
                  <p v-else>-</p>
                </td>
                <td v-if="columns.includes('store_placement')">
                  {{ (item.store_placement || {}).name || '...' }}
                </td>

                <td v-if="columns.includes('categories')">
                  <div class="actions-group">
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-for="(category, index) in (item.categories || []).slice(
                        0,
                        1
                      )"
                      :key="index"
                      >{{ category.name }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.categories || []).length > 1"
                      >+{{ (item.categories || []).length - 1 }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.categories || []).length === 0"
                      >-</span
                    >
                  </div>
                </td>
                <td v-if="columns.includes('sub_categories')">
                  <div class="actions-group">
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-for="(category, index) in (
                        item.sub_categories || []
                      ).slice(0, 1)"
                      :key="index"
                      >{{ category.name }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.sub_categories || []).length > 1"
                      >+{{ (item.sub_categories || []).length - 1 }}</span
                    >
                    <span
                      class="status text-slate-600 bg-slate-100"
                      v-if="(item.sub_categories || []).length === 0"
                      >-</span
                    >
                  </div>
                </td>
                <td v-if="columns.includes('active')">
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                </td>
                <td
                  v-if="columns.includes('updated_at')"
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td v-if="columns.includes('actions')">
                  <div class="actions-group">
                    <div v-if="false">
                      <button
                        class="
                          p-2
                          px-3
                          text-red-500
                          font-medium
                          rtl:font-semibold
                        "
                        @click.prevent="deleteItem(item.id)"
                        :disabled="disables[`delete_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${item.id}`]"
                        />
                        <span v-else> {{ $t('delete') }} </span>
                      </button>
                    </div>

                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded"
                        @click.prevent="showUpdate(item.id)"
                        :disabled="!item.is_active"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td :colspan="columns.length">
                  <div class="table mx-auto my-4">
                    <button
                      class="
                        py-1.5
                        px-3
                        text-sm
                        flex
                        items-center
                        gap-2
                        text-slate-600
                        font-medium
                        rtl:font-semibold
                      "
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.planogram') }}</span>
                    </button>
                  </div>
                  <p
                    class="
                      text-sm text-slate-600
                      font-medium
                      rtl:font-semibold
                      text-center
                    "
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
    </configuration-wrapper>

    <transition name="scale">
      <Modal
        :title="$t('add_new.planogram')"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e
            add_data = {}
            add_data.image = null
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <figure class="space-y-4">
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? convertImage(add_data.image)
                      : add_data.image ||
                        require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="product image"
                  class="
                    w-auto
                    max-h-[12rem]
                    ring-1 ring-slate-200
                    object-contain
                  "
                />
                <button
                  class="
                    w-6
                    h-6
                    rounded-full
                    bg-red-500
                    text-white
                    absolute
                    -top-2
                    -right-2
                    rtl:right-auto rtl:-left-2
                    ring-2 ring-white
                  "
                  @click.prevent="
                    () => {
                      ;($refs.addImage.value = null),
                        $set(add_data, 'image', null)
                    }
                  "
                >
                  <i class="fa-solid fa-times fa-sm align-middle"></i>
                </button>
              </figcaption>
              <blockquote class="space-y-2">
                <label class="block">
                  <span class="sr-only">{{ $t('choose_image') }}</span>
                  <input
                    autocomplete="off"
                    type="file"
                    accept="image/*"
                    class="custom-file"
                    @change="(e) => $set(add_data, 'image', e.target.files[0])"
                    ref="addImage"
                  />
                </label>
                <p
                  class="text-red-500 font-medium rtl:font-semibold text-sm"
                  v-if="add_errors.image"
                >
                  {{ add_errors.image.join(' ') }}
                </p>
              </blockquote>
            </figure>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.client_id"
                  :options="clients"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="client_id"
                ></multiselect>
                <label for="client_id">{{ $t('client') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.client_id"
              >
                {{ add_errors.client_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.store_placement_id"
                  :options="stores"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="store_placement_id"
                ></multiselect>
                <label for="store_placement_id">{{
                  $t('store_placement')
                }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.store_placement_id"
              >
                {{ add_errors.store_placement_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.category_ids"
                  :options="categories"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="category_ids"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  style="background-color: #ffffff !important"
                  :disabled="categories.length === 0"
                  multiple
                ></multiselect>
                <label for="category_ids">{{ $t('categories') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.category_ids"
              >
                {{ add_errors.category_ids.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.sub_category_ids"
                  :options="filterAddSubCategories"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="sub_category_ids"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  style="background-color: #ffffff !important"
                  :disabled="filterAddSubCategories.length === 0"
                  multiple
                ></multiselect>
                <label for="sub_category_ids">{{ $t('sub_categories') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.sub_category_ids"
              >
                {{ add_errors.sub_category_ids.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal :title="`Update`" v-if="edit_active" @close="edit_active = $event">
        <template>
          <form class="p-6 space-y-4">
            <figure class="space-y-4">
              <figcaption v-if="edit_data.image" class="relative table">
                <img
                  :src="
                    edit_data.image && typeof edit_data.image === 'object'
                      ? convertImage(edit_data.image)
                      : edit_data.image ||
                        require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="product image"
                  class="
                    w-auto
                    max-h-[12rem]
                    ring-1 ring-slate-200
                    object-contain
                  "
                />
                <button
                  class="
                    w-6
                    h-6
                    rounded-full
                    bg-red-500
                    text-white
                    absolute
                    -top-2
                    -right-2
                    rtl:right-auto rtl:-left-2
                    ring-2 ring-white
                  "
                  @click.prevent="
                    () => {
                      ;($refs.skuImage.value = null),
                        $set(edit_data, 'image', null)
                    }
                  "
                  v-if="typeof edit_data.image === 'object'"
                >
                  <i class="fa-solid fa-times fa-sm align-middle"></i>
                </button>
              </figcaption>
              <blockquote class="space-y-2">
                <label class="block">
                  <span class="sr-only">{{ $t('choose_image') }}</span>
                  <input
                    autocomplete="off"
                    type="file"
                    accept="image/*"
                    class="custom-file"
                    @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                    ref="skuImage"
                  />
                </label>
                <p
                  class="text-red-500 font-medium rtl:font-semibold text-sm"
                  v-if="edit_errors.image"
                >
                  {{ edit_errors.image.join(' ') }}
                </p>
              </blockquote>
            </figure>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.client_id"
                  :options="clients"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="client_id"
                ></multiselect>
                <label for="client_id">{{ $t('client') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.client_id"
              >
                {{ edit_errors.client_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.store_placement_id"
                  :options="stores"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="store_placement_id"
                ></multiselect>
                <label for="store_placement_id">{{
                  $t('store_placement')
                }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.store_placement_id"
              >
                {{ edit_errors.store_placement_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.category_ids"
                  :options="categories"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="category_ids"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  style="background-color: #ffffff !important"
                  :disabled="categories.length === 0"
                  multiple
                ></multiselect>
                <label for="category_ids">{{ $t('categories') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.category_ids"
              >
                {{ edit_errors.category_ids.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.sub_category_ids"
                  :options="filterEditSubCategories"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="sub_category_ids"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  style="background-color: #ffffff !important"
                  :disabled="filterEditSubCategories.length === 0"
                  multiple
                ></multiselect>
                <label for="sub_category_ids">{{ $t('sub_categories') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.sub_category_ids"
              >
                {{ edit_errors.sub_category_ids.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'
import FilterBox from '@/components/basics/FilterBox.vue'
import ConfigurationWrapper from '@/components/setup/configuration/ConfigurationWrapper.vue'

export default {
  name: 'Planograms',
  data() {
    return {
      load: false,
      items: [],
      stores: [],
      categories: [],
      sub_categories: [],
      add_data: {},
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        sort_key: null,
        sort_type: 'asc',
        is_active: 'active',
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
      clients: [],
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },

    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'search', 'table_paginate']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },
    filterAddSubCategories() {
      const selectedIds = this.add_data?.category_ids?.map((e) => e.id) || []
      return this.sub_categories.filter((e) =>
        selectedIds.includes(e.category.id)
      )
    },
    filterEditSubCategories() {
      const selectedIds = this.edit_data?.category_ids?.map((e) => e.id) || []
      return this.sub_categories.filter((e) =>
        selectedIds.includes(e.category.id)
      )
    },
    filterSubCategories() {
      const selectedIds = this.pagination?.category_ids?.map((e) => e.id) || []
      return this.sub_categories.filter((e) =>
        selectedIds.includes(e.category.id)
      )
    },
  },
  async created() {
    Promise.all([
      await this.getItems(),
      await this.getStorePlacements(),
      await this.getCategories(),
      await this.getSubCategories(),
      await this.getClients(),
    ])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),

    async getStorePlacements() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'store_placements'
        )
        this.stores = result.store_placements
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getCategories() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'categories'
        )
        this.categories = result.categories
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getClients() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'clients'
        )
        this.clients = result.clients
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getSubCategories() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'sub_categories'
        )
        this.sub_categories = result.sub_categories
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    getItems() {
      const {
        page,

        sort_key,
        sort_type,
        is_active,
        category_ids,
        sub_category_ids,
        store_placement_ids,
      } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'planograms',
          options: {
            page,
            search_key: this.search,

            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            category_ids: category_ids?.map((e) => e.id),
            sub_category_ids: sub_category_ids?.map((e) => e.id),
            store_placement_ids: store_placement_ids?.map((e) => e.id),
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.planograms
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      let fd = new FormData()

      for (const key in this.add_data) {
        const item = this.add_data[key]

        if (['category_ids', 'sub_category_ids'].includes(key)) {
          item.forEach(({ id }, index) => {
            fd.append(`${key}[${index}]`, id)
          })
        } else if (['store_placement_id', 'client_id'].includes(key)) {
          fd.append(key, item.id)
        } else if (key === 'image') {
          fd.append(key, item, item.name)
        } else {
          fd.append(key, item)
        }
      }

      try {
        const { data } = await this.axios.post('planograms/add', fd)
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      let fd = new FormData()

      for (const key in this.edit_data) {
        const item = this.edit_data[key]

        if (['category_ids', 'sub_category_ids'].includes(key)) {
          item.forEach(({ id }, index) => {
            fd.append(`${key}[${index}]`, id)
          })
        } else if (['store_placement_id', 'client_id'].includes(key)) {
          fd.append(key, item.id)
        } else if (key === 'image') {
          if (typeof item === 'object') {
            fd.append(key, item, item.name)
          } else {
            fd.delete(key)
          }
        } else {
          fd.append(key, item)
        }
      }
      try {
        const { data } = await this.axios.post('planograms/update', fd)
        const { message, result } = data
        const finalResult = result.planogram ?? {}
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...finalResult,
          store_placement_id: finalResult?.store_placement,
          category_ids: finalResult?.categories,
          sub_category_ids: finalResult?.sub_categories,
          client_id: finalResult?.client,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },

    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`planograms/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        store_placement_id: item?.store_placement,
        category_ids: item?.categories,
        sub_category_ids: item?.sub_categories,
        client_id: item?.client,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, FilterBox, ConfigurationWrapper },
}
</script>
