<template>
  <div>
    <section class="w-full px-6">
      <div class="table m-auto py-20">
        <div class="flex items-center justify-center flex-col gap-6">
          <div>
            <div
              class="
                w-28
                h-28
                flex
                items-center
                justify-center
                bg-white
                rounded-full
                text-slate-500
              "
            >
              <i class="fa-solid fa-bell-slash fa-2xl"></i>
            </div>
          </div>
          <div class="flex-1 space-y-2 text-center">
            <h1 class="text-lg text-primary font-semibold rtl:font-bold">
              There are no notifications
            </h1>
            <p
              class="
                text-sm text-slate-400
                max-w-xl
                font-medium
                rtl:font-semibold
              "
            >
              There are no notifications in that account, it may be the new
              account so you have not received any notification yet, you can add
              your users to receive all notifications, click on the button below
              to add a new user or add a new task if you already have users
            </p>
            <router-link
              to="/setup/users"
              class="
                w-full
                max-w-xs
                text-center
                py-3
                px-6
                rounded-full
                text-white
                bg-secondary
                text-xs
                font-medium
                rtl:font-semibold
                shadow-xl shadow-secondary/30
                !mt-8
                block
                mx-auto
              "
            >
              Add new user
            </router-link>
            <router-link
              to="/reports/visits/new-task"
              class="
                w-full
                max-w-xs
                text-center
                py-3
                px-6
                rounded-full
                text-slate-600
                block
                text-xs
                font-medium
                rtl:font-semibold
                mx-auto
              "
            >
              Add a new task
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Notifications',
}
</script>
