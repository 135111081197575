<template>
  <div class="space-y-6">
    <div class="px-6 flex gap-4">
      <!-- <div>
        <button
          class="
            flex
            items-center
            justify-center
            gap-2
            font-semibold
            text-slate-600 text-sm
            py-2
            px-4
            rounded
            bg-transparent
            hover:bg-slate-200
          "
          @click.prevent="goBack"
        >
          <i class="fa-solid fa-angle-left block rtl:hidden"></i>
          <i class="fa-solid fa-angle-right hidden rtl:block"></i>
          <span>{{ $t('back') }}</span>
        </button>
      </div> -->
      <Tabs
        :link_type="'link'"
        :tabs="pagesTab"
        has-permission
        class="grid flex-1"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from '@/components/basics/Tabs.vue'
export default {
  name: 'ContainerWrapper',
  computed: {
    pagesTab() {
      const pages = [
        {
          url: '/setup/locations/region-groups',
          title: 'Region groups',
          key: 'regions',
          permission: 'locations',
        },
        {
          url: '/setup/locations/cities',
          title: 'Cities',
          key: 'cities',
          permission: 'locations',
        },
        {
          url: '/setup/locations/zones',
          title: 'Zones',
          key: 'zones',
          permission: 'locations',
        },
      ]

      return pages
    },
  },
  props: {
    reports: {
      type: Object,
      default: Object,
    },
  },

  components: {
    Tabs,
  },
}
</script>
