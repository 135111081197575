<template>
  <div>
    <transition name="scale">
      <modal
        :title="$t('customize_table')"
        v-if="show"
        @close="
          () => {
            show = false
          }
        "
      >
        <template>
          <div class="p-6">
            <ul
              class="
                list-none
                grid grid-cols-[repeat(auto-fill,minmax(8em,auto))]
                gap-4
              "
            >
              <li v-for="(item, index) in columns" :key="index" class="block">
                <div class="form-group-with-check">
                  <div>
                    <input
                      autocomplete="off"
                      type="checkbox"
                      :name="item"
                      :id="item"
                      :value="item"
                      v-model="fields"
                    />
                  </div>
                  <label :for="item" class="capitalize">
                    <!-- {{ item.replace(/(_|-)/g, ' ') }} -->
                    {{ $t(item) }}
                  </label>
                </div>
              </li>
            </ul>
            <button
              class="
                flex
                items-start
                gap-2
                mt-6
                w-full
                text-left
                rtl:text-right
                text-slate-600
                group
              "
              v-if="columns.length"
              @click.prevent="reloadPage"
            >
              <div>
                <i
                  class="
                    fa-solid fa-arrow-rotate-left
                    group-hover:-rotate-[360deg]
                    transition-transform
                  "
                ></i>
              </div>
              <div class="flex-1">
                <p class="font-medium rtl:font-semibold text-sm">
                  {{ $t('reload') }}
                </p>
              </div>
            </button>
            <div v-if="!columns.length">
              <div class="flex items-center justify-center flex-col gap-6">
                <div>
                  <div
                    class="
                      w-16
                      h-16
                      flex
                      items-center
                      justify-center
                      bg-slate-200
                      rounded-full
                      text-slate-500
                    "
                  >
                    <i class="fa-solid fa-braille"></i>
                  </div>
                </div>
                <div class="flex-1 space-y-4 text-center">
                  <h1
                    class="text-slate-600 font-medium rtl:font-semibold text-sm"
                  >
                    {{ $t('columns_no_data') }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  components: { Modal },
  name: 'CustomizeTable',
  data() {
    return {
      fields: [],
      show: false,
    }
  },
  computed: {
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      const name =
        this.$route.meta?.endpoint_name ??
        this.$route.query?.tab?.replace(/(-)/gi, '_') ??
        null
      if (['client'].includes(name)) {
        const tab = this.$route.query?.tab?.replace(/(-)/gi, '_')
        return tab ?? 'merchandisers'
      } else {
        return name
      }
      console.log(name)
      return name
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    columns() {
      let columnName = this.endpointName

      if (!columnName) return []

      const data = !this.isVisit
        ? this.getBasicColumn(columnName)
        : this.getBasicColumn(columnName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []

      return data
    },
  },
  watch: {
    fields(newVal, _) {
      this.updatedSelectedColumns({
        type: this.endpointName,
        data: newVal,
      })
    },
  },
  mounted() {
    this.fields =
      this.getSelectedColumns(this.endpointName) ||
      this.getBasicColumn(this.endpointName) ||
      []
  },
  methods: {
    ...mapMutations('customize', ['updatedSelectedColumns']),
    showModal() {
      this.show = true
    },
    hideModal() {
      this.show = false
    },
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>
