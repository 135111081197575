export default {
  dashboard: 'لوحة التحكم',
  calendar: 'التقويم',
  users: 'المستخدمون',
  user_visits: 'مستخدم الزيارات',
  user: 'المستخدم',
  new_visit: 'زيارة جديدة',
  reports: 'التقارير',
  visits: 'الزيارات',
  visit: 'زيارة',
  skus: 'SKUs',
  expiry: 'منتهية الصلاحية',
  expiries: 'انتهاء الصلاحية',
  stock_audits: 'عمليات تدقيق الأسهم',
  shelf_audits: 'تدقيق الرف',
  delivery_audits: 'التوصيل',

  shelf: 'رفوف',
  returns: 'المسترجعات',
  promotion: 'ترقية وظيفية',
  planogram: 'المخطط',
  setup: 'الاعدادات',
  journey_plans: 'الرحلات',
  configuration: 'المخازن',
  shelves: 'أرفف',
  planograms: 'المخططات',
  teams: 'الفرق',
  permissions: 'الصلاحيات',
  stores: 'السلاسل',
  store: 'سلسلة',
  branches: 'الاسواق',
  branch: 'سوق',
  brands: 'العلامات التجارية',
  brand: 'ماركة',
  categories: 'التصنيفات',
  category: 'التصنيف',
  sub_categories: 'التصنيفات الفرعية',
  sub_category: 'التصنيف فرعي',
  store_placements: 'مواضع السوق',
  store_placement: 'وضع التخزين',
  units: 'الوحدات',
  unit: 'وحدة',
  notifications: 'الاشعارات',
  messages: 'الرسائل',
  regions: 'المناطق',
  region: 'المنطقة',
  cities: 'المدن',
  city: 'المدينة',
  expiry_skus: 'SKUs انتهاء الصلاحية',
  out_of_stock: 'إنتهى من المخزن',
  supervisors: 'المشرفين',
  supervisor: 'مشرف',
  leaders: 'القادة',
  leader: 'قائد',
  sku: 'SKU',
  current_week_visits: 'إجمالي زيارات الأسبوع',
  today_visits_per_status: 'زيارات اليوم لكل حالة',
  visits_per_day: 'الزيارات اليومية',
  total_visits: 'اجمالي الزيارات',
  total_branches: 'اجمالي الاسواق',
  visit_summary: 'ملخص الزيارات',
  recent_visits: 'الزيارات الأخيرة',
  done: 'منتهيه',
  not_done: 'غير منتهيه',
  missed: 'مفقوده',
  pending: 'قيد الانتظار',
  extra_mission: 'مهمة إضافية',
  extra_mission_done: 'مهمة إضافية منتهيه',
  added_by_user: 'اضيفت بواسطة مستخدم',
  skipped: 'تخطي',
  cancelled: 'ألغيت',
  scheduled: 'مجدول',
  active: 'نشيط',
  not_active: 'غير نشيط',
  available: 'متوفر',
  not_available: 'غير متوفر',
  yes: 'نعم',
  no: 'لا',
  customize: 'تخصيص',
  download: 'تحميل',
  send_email: 'أرسل عبر البريد الإلكتروني',
  filter: 'تصفيه',
  add_new: {
    category: 'تصنيف جديد',
    sub_category: 'تصنيف فرعي جديد',
    store: 'سلسلة جديدة',
    branch: 'سوق جديد',
    brand: 'العلامة التجارية الجديدة',
    merchandiser: 'تاجر جديد',
    team: 'فريق جديد',
    user: 'موظف جديد',
    client: 'عميل جديد',
    visit: 'زيارة جديدة',
    sku: 'SKU جديد',
    shelf: 'رف جديد',
    planogram: 'رسم تخطيطي جديد',
    new: 'اضف جديد',
    now: 'اضف الان',
    role: 'مجموعة جديدة',
    shift: 'اضافة دوام جديد',
    unit: 'وحدة جديدة',
    store_placement: 'موضع سوق جديد',
    zone: 'منطقة جديدة',
    reason: 'سبب جديد',
    city: 'مدينة جديدة',
    group: 'مجموعة جديدة',
    competitor: 'منافس جديد',
  },
  update: 'تحديث',
  assign_branches: 'تعيين للفروع',
  assign_units: 'تعيين الوحدات',
  assign_info: 'بيانات المعين',
  assignee: 'المعين',
  client_info: 'بيانات العميل',
  delete: 'حذف',
  add: 'اضافة',
  save: 'حفظ',
  save_changes: 'حفظ التغييرات',
  submit: 'ارسال',
  cancel: 'الغاء',
  name: 'الاسم',
  alt_name: 'اسم بديل',
  email: 'عنوان البريد الالكترونى',
  mobile: 'رقم الجوال',
  barcode: 'الرمز الشريطي',
  selling_price: 'سعر البيع',
  value: 'القيمة',
  actual_value: 'القيمة الفعلية',
  tasks: 'المهام',
  required_tasks: 'المهام المطلوبة',
  visit_date: 'زيارة التاريخ',
  created_at: 'تاريخ الإنشاء',
  updated_at: 'اخر تحديث',
  start_date: 'تسجيل الوصول',
  production_date: 'تاريخ الإنتاج',
  end_date: 'تسجيل الانتهاء',
  return_date: 'تاريخ الاسترجاع',
  expiry_date: 'تاريخ انتهاء الصلاحية',
  joined_date: 'تاريخ الانضمام',
  qty: 'الكمية',
  in_location: 'في الموقع',
  out_location: ' خارج موقع',
  search: 'بحث...',
  status: 'الحالة',
  create_new: {
    category: 'إنشاء تصنيف جديد',
    sub_category: 'إنشاء تصنيف فرعي جديد',
    store: 'إنشاء سلسلة جديدة',
    branch: 'إنشاء سوق جديد',
    brand: 'إنشاء علامة تجارية جديدة',
    team: 'إنشاء فريق جديد',
    user: 'إنشاء موظف جديد',
    visit: 'إنشاء زيارة جديدة',
    sku: 'إنشاء SKU جديد',
    shelf: 'إنشاء رف جديد',
    planogram: 'إنشاء مخطط جديد',
  },
  currency: {
    sar: 'ريال سعودي',
  },
  next: 'التالي',
  prev: 'السابق',
  view_details: 'عرض التفاصيل',
  notes: 'ملحوظات',
  reason: 'السبب',
  apply: 'تطبيق',
  reset: 'الغاء',
  image: 'صورة',
  images: 'الصور',
  images_before: 'الصور من قبل',
  images_after: 'الصور بعد',
  type: 'النوع',
  visit_type: 'نوع الزيارة',
  change_status: 'تغيير الحالة',
  view: 'عرض',
  view_update: 'عرض وتحديث',
  listed: 'الاسواق المدرجة',
  manager_mobile: 'رقم مدير السوق',
  address: 'العنوان',
  lat: 'خط العرض',
  lng: 'خط الطول',
  lat_lng: 'خط العرض وخط الطول',
  logout: 'تسجيل خروج',
  edit: 'تعديل',
  edit_profile: 'تعديل الملف الشخصي',
  window_width: 'عرض النافذة',
  view_all: 'عرض الكل',
  all: 'الكل',
  langs: {
    ar: 'عربي',
    en: 'إنجليزي',
  },
  emails: 'عناوين البريد الإلكتروني',
  customize_excel: 'تخصيص أعمدة Excel',
  customize_table: 'تخصيص الجدول',
  total_skus: 'اجمالي المنتجات',
  total_visits: 'اجمالي الزيارات',
  records: 'السجلات',
  password: 'كلمة المرور',
  new_password: 'كلمة السر الجديدة',
  current_password: 'كلمة المرور الحالية',
  generate_password: 'إنشاء كلمة المرور',
  force_password: 'يجب تغيير كلمة المرور',
  multi_client: 'متعدد العملاء',
  no_data: 'لا توجد بيانات لعرضها',
  role: 'الوظيفة',
  username: 'اسم المستخدم',
  merchandiser: 'التاجر',
  merchandisers: 'التجار',
  supervisor: 'مشرف',
  super_user: 'مستخدم متميز',
  client: 'عميل',
  clients: 'العملاء',
  administration: 'الادارة',
  week_days: {
    SAT: 'السبت',
    SUN: 'الاحد',
    MON: 'الاثنين',
    TUE: 'الثلاثاء',
    WED: 'الاربعاء',
    THU: 'الخميس',
    FRI: 'الجمعة',
  },
  visit_time: {
    today: 'اليوم',
    tomorrow: 'غدا',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
  },
  weeks: {
    1: 'الأسبوع الأول',
    2: 'الأسبوع الثاني',
    3: 'الأسبوع الثالث',
    4: 'الأسبوع الرابع',
  },
  choose_image: 'اختر صورة',
  choose_photo: 'اختر صورة',
  profile_photo: 'صورة الملف الشخصي',
  all_selected: 'جميع العناصر مختاره بالفعل',
  email_note: 'سيتم تسجيل الخروج من الحساب إذا قمت بتغيير البريد الإلكتروني',
  visits_no_data: 'لا توجد زيارات في ذلك اليوم',
  user_no: 'لا يوجد مستخدمون لعرضهم',
  user_no_content: `لا يوجد مستخدمون لعرض زياراتهم ، بحيث يمكنك إضافة ملف
                  زيارة جديدة واختر السوق المطلوب ثم حدد
                  زيارات ، انقر فوق الزر أدناه للانتقال إلى صفحة الزيارة الجديدةديدة`,
  completed: 'مكتمل',
  login: 'تسجيل دخول',
  copyRight: 'جميع الحقوق محفوظة لدى',
  cost: 'التكلفة',
  add_info_visit: 'هل تريد اضافة زيارة جديدة',
  location_empty: 'لم يتم تحديد الموقع من قبل المستخدم حتى الآن',
  select_all: 'تحديد الكل',
  roles: 'المجموعات',
  role: 'مجموعة',
  images: 'الصور',
  visit_details: 'تفاصيل الزيارة',
  details: 'التفاصيل',
  no_images: 'ليس هناك صور بعد',
  profile: 'الملف التعريفي',
  skus_amount: 'سعر الـ SKUs',
  expiry_amount: 'سعر منهية الصلاحية',
  returns_amount: 'سعر المرتجعات',
  nearly_expired: 'على وشك الانتهاء',
  near_expiring_amount: 'مبالغ على وشك الانتهاء',
  from: 'من',
  to: 'الى',
  allow_camera: 'السماح بالوصول إلى المعرض',
  allow_add_branch: 'السماح بإضافة اسواق',
  client_added_title: 'تم اضافة عميل بنجاح',
  client_added_content: `تمت إضافة عميل جديد بنجاح ، يجب إضافة تاجر ومشرف واحد على الأقل بالإضافة إلى إضافة العلامات التجارية الخاصة بك لاستخدامها في إضافة المنتجات بنجاح ، يمكنك إضافة العلامات التجارية من خلال النقر على زر إضافة العلامات التجارية في الأسفل أو النقر فوق إضافة مستخدمين أو قم بإلغائها لإضافتها لاحقًا`,
  listed_stores: 'السلاسل المدرجة',
  not_listed_stores: 'السلاسل الغير المدرجة',
  listed_branches: 'الاسواق المدرجة',
  not_listed_branches: ' الاسواق الغير المدرجة',
  not_list_all: 'الغاء الكل',
  move_visit: 'نقل الزيارة',
  temporary: 'مؤقت',
  permanent: 'دائم',
  move_type: 'نوع النقل',
  not_member: 'ليست عضو؟',
  member: 'عضو بالفعل؟',
  join_request: 'طلب الانضمام',
  join_content:
    'مرحبًا ، يسعدنا انضمامك إلينا مع أحد أفضل عملائنا ، يمكنك ملء البيانات في النموذج التالي لإرسال الطلب بنجاح وسيتم إعلامك عبر البريد الإلكتروني بالقبول من قبل أحد الدعم الفني لدينا ، شكرا لك',
  join_subtitle: 'ابداء مجاناً',
  fill_form: 'املئ النموذج التالي',
  company_name: 'اسم الشركة',
  contact_name: 'اسم جهة الاتصال',
  contact_mobile: 'رقم التواصل',
  contact_email: 'بريد التواصل الالكتروني',
  merchandisers_count: 'عدد التجار',
  join_now: 'انضم الان',
  join_success: 'تم إرسال طلب الانضمام بنجاح',
  join_success_content: `تم إرسال طلب الانضمام بنجاح. يمكنك متابعة صندوق الوارد الخاص بك لتلقي بريد إلكتروني من أحد الدعم الفني لدينا مع بيانات تسجيل الدخول الخاصة بك في أقرب وقت ممكن. شكرا لك على هذه الثقة والانضمام إلينا.`,
  go_inbox: 'انتقل إلى البريد الوارد',
  columns_no_data:
    'لا توجد أعمدة لعرضها في تلك الصفحة أو لم يتم تحديد العناصر المناسبة التي سيتم وضعها هنا',
  actions: 'الاجراءات',
  shifts: 'اوقات العمل',
  osa_reports: 'تقارير OSA',
  urgent_visit: 'زيارة طارئه',
  journey_plan: 'رحلة',
  reload: 'أعد تحميل الصفحة الحالية إذا كان تخصيص الجدول لا يعمل',
  multi_stores: 'اسواق متعددة',
  multi_branches: 'الاسواق متعددة',
  show: 'عرض',
  hide: 'اخفاء',
  notify: {
    title: 'Notify me a few days before the expiration date',
    notify_2: 'نبهني قبل يومين',
    notify_1: 'نبهني قبل يوم واحد',
    notify_0: 'نبهني قبل الانتهاء فورا',
  },
  back: 'العودة',
  zones: 'المناطق',
  zone: 'المنطقة',
  duration: 'مدة الصلاحية',
  has_exp_date: 'لديه تاريخ انتهاء الصلاحية؟',
  day: 'يوم',
  month: 'شهر',
  year: 'سنة',
  domain: 'اسم النطاق',
  import: 'تصدير',
  activation_date: 'وقت التسجيل',
  _listed: 'مدرج',
  _not_listed: 'غير مدرج',
  shared: 'مشترك',
  finish: 'إنهاء',
  selected: 'تم تحديد',
  my_promotions: 'عروضي',
  competitors: 'المنافسين',
  go_website: 'الانتقال للموقع',
  performance: 'الاداء',
  add_urgent_visit: 'اضافه زياره طارئة',
  visit_from_diff_location: 'فتح و اغلاق الزياره من موقع اخر',
  email_configurations: 'اعدادات البريد الالكتروني',
  logs: 'الانشطة',
  daily: 'يومي',
  weekly: 'اسبوعي',
  monthly: 'شهري',
  email_setup: 'اعداد البريد الالكتروني',
  client_user: 'مستخدم عميل',
  super_user: 'مستخدم متميز',
  c_reasons: 'الاسباب',
  export_pdf: 'تصدير PDF',
  done_count: 'تمت الزيارات',
  end_time: 'وقت الانتهاء',
  extra_mission_count: 'مهمة اضافية',
  extra_mission_done_count: 'تم إنجاز مهمة إضافية',
  extra_visits_percentage: 'نسبة الزيارات الاضافية',
  ideal_time: 'الوقت المثالي',
  in_stores_time: 'الوقت في السلاسل',
  missed_count: 'افتقد',
  not_visits_count: 'لم تتم زيارتها',
  scheduled_count: 'المجدول',
  skipped_count: 'تم التخطي',
  start_time: 'وقت البدء',
  total_time: 'الوقت الكلي',
  travel_time: 'وقت الزيارة',
  visits_percentage: 'نسبة الزيارات',
  return: 'مسترجع',
  statuses: 'الحالات',
  select: '-- اختار --',
  alert_1: 'التنبيه الاول',
  alert_2: 'التنبيه الثاني',
  alert_3: 'التنبيه الثالث',
  deletion_modal: {
    title: 'هل انت متاكد؟',
    content:
      'إذا كنت لا تزال ترغب في حذف هذا السوق ، فهذا يعني أنه سيتم حذف الاسواق المرتبطة بهذا السوق والزيارات و SKUs ، إذا كنت متأكدًا بالفعل ، فاكتب اسم السوق في الحلق أدناه لتأكيد الحذف الناجح',
  },
  today_visits: 'زيارات اليوم',
  deletion_branch: {
    day: 'حذف السوق من ذلك اليوم فقط.',
    week: 'حذف السوق من الاسابيع من نفس اليوم.',
    month: 'حذف السوق من الشهر كاملاً.',
  },
  deletion_day: {
    day: 'احذف ذلك اليوم فقط.',
    week: 'احذف اليوم من الاسابيع من نفس اليوم.',
  },
  assign_to_skus: 'تعيين الاسواق للـ SKUs',
  selected_branches: 'يوجد _COUNT_ سوق(اسواق) في العنصر المحدد',
  run_tomorrow: 'تشغيل غدا',
  run: 'تشغيل',
  are_you_sure: 'هل انت متاكد؟',
  last_run: 'اخر تشغيل',
  request_branch: 'اطلب سوقك الخاص',
  country: 'الدولة',
  door_type: 'مكان المنتج',
  main: 'معروض',
  stock: 'مخزن',
  monthly_visits: 'الزيارات الشهرية',
  latest_visits: 'اخر الزيارات',
  visits_count: 'اجمالي الزيارات',
  dates: 'التواريخ',
  main_categories: 'الاقسام الرئيسية',
  main_category: 'القسم الرئيسي',
  clear: 'مسح المحدد',
  logs: 'Logs',
  new_smart_journey_plan: 'خطة رحلة ذكية جديدة',
  desc: 'الوصف',
  locations: 'المواقع',
  region_groups: 'مجموعات المناطق',
  district_groups: 'مجموعات الاحياء',
  districts: 'الاحياء',
  clear_all: 'مسح الكل',
  'export-image': 'تصدير الصورة',
  'export-images': 'تصدير الصور',
  'export-selected-images': 'تصدير الصور المحددة',
  'view-images': 'عرض الصور',
  'are-you-sure': 'هل انت متاكد؟',

  deletion: {
    title: 'هل أنت متأكد أنك تريد حذف ذلك؟',
    content:
      'إذا كنت لا تزال ترغب في حذفه ، فلن يكون لديك الصلاحية لإعادة الوصول إليه ، وسيتم حذف كل ما يتعلق به',
    code: 'الرجاء إدخال الرمز المرسل عبر البريد الإلكتروني ، وسيتم تأكيد عملية الحذف',
  },
  competitors: 'المنافسين',
  shelf: 'رف',
  faces: 'الوجوه',
  meter: 'متر',
  cm: 'سم',
  sync_skus: 'مزامنة SKUs',
  delete_replace: 'حذف واستبدال',
  add_all_skus: 'اضافة جميع الـ SKUs',
  delete_current_journey_plan: 'احذف خطة الرحلة الحالية',

  // {{ $t(item.replace(/\s/g, '_')) }}
}
