<template>
  <div>
    <section class="w-full space-y-6 px-6">
      <filter-box> </filter-box>
      <!-- <div
        class="w-full grid grid-cols-[repeat(auto-fill,minmax(15em,auto))] gap-4 items-stretch"
      >
        <single-card
          v-for="(card, index) in cards"
          :key="index"
          :item="card"
          class="h-full"
          :load="!load"
        />
      </div> -->
      <cards-container :cards="cards" :load="!load" />
      <div class="w-full">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <figure class="w-full rounded bg-white ring-1 ring-slate-200">
            <header class="w-full p-4 border-b border-slate-100">
              <h4 class="font-semibold rtl:font-bold text-slate-600">
                {{ $t('current_week_visits') }}
              </h4>
              <p class="font-semibold rtl:font-bold text-slate-500 text-xs">
                {{ $t('total_visits') }}: {{ week_visits_count }}
              </p>
            </header>
            <div id="chart-per-day">
              <apexchart
                type="bar"
                height="380"
                :options="{
                  ...chartOptions,
                  colors: ['#cbd5e1', '#0d9488', '#22c55e', '#0ea5e9'],
                  ...globalOptions,
                }"
                :series="series"
                v-if="load"
              ></apexchart>
              <div class="table p-6 mx-auto" v-if="!load">
                <spinner size="w-8 h-8" class="align-sub text-primary" />
              </div>
              <div
                v-if="series.every((e) => e.data.length === 0) && load"
                class="space-y-4"
              >
                <div
                  class="w-12 h-12 flex items-center justify-center bg-slate-200 rounded-full text-slate-600 mx-auto"
                >
                  <i class="fa-solid fa-chart-pie"></i>
                </div>
                <p
                  class="text-center text-slate-600 text-sm font-medium rtl:font-semibold"
                >
                  {{ $t('no_data') }}
                </p>
              </div>
            </div>
          </figure>
          <figure class="w-full bg-white ring-1 ring-slate-200 rounded">
            <header class="w-full p-4 border-b border-slate-100">
              <h4 class="font-semibold rtl:font-bold text-slate-600">
                {{ $t('today_visits_per_status') }}
              </h4>
              <p class="font-semibold rtl:font-bold text-slate-500 text-xs">
                {{ $t('total_visits') }}: {{ visits_count }}
              </p>
            </header>

            <blockquote class="flex items-center justify-center flex-1 p-4">
              <div id="chart">
                <apexchart
                  type="pie"
                  width="380"
                  :options="{
                    ...pie_chartOptions,
                    colors: status_colors,
                    tooltip: globalOptions.tooltip,
                  }"
                  :series="pie_series"
                  v-if="pie_start && !pie_series.every((e) => !e)"
                ></apexchart>
                <div class="table p-6 mx-auto" v-if="!pie_start">
                  <spinner size="w-8 h-8" class="align-sub text-primary" />
                </div>
                <div
                  v-if="pie_series.every((e) => !e) && pie_start"
                  class="space-y-4 py-20"
                >
                  <div
                    class="w-12 h-12 flex items-center justify-center bg-slate-200 rounded-full text-slate-600 mx-auto"
                  >
                    <i class="fa-solid fa-chart-pie"></i>
                  </div>
                  <p
                    class="text-center text-slate-600 text-sm font-medium rtl:font-semibold"
                  >
                    {{ $t('no_data') }}
                  </p>
                </div>
              </div>
            </blockquote>
          </figure>
        </div>
      </div>
      <!-- <OSAReport /> -->
      <main>
        <Table
          :has_header="true"
          :has_check="false"
          :has_search="false"
          :has_export="false"
          :title="$t('latest_visits')"
          :records="`${items.length || 0} ${$t('records')}`"
        >
          <template #head>
            <th v-if="columns.includes('branch')">
              <div class="sort">
                <div>
                  <p>{{ $t('branch') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('company_branch_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('store')">
              <div class="sort">
                <div>
                  <p>{{ $t('store') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('company_store_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('user')">
              <div class="sort">
                <div>
                  <p>{{ $t('user') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('user_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('supervisor')">
              <div class="sort">
                <div>
                  <p>{{ $t('supervisor') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('supervisor_id')"
                  ></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('status')">
              <div class="sort">
                <div>
                  <p>{{ $t('status') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('status')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('visit_date')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('start_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('start_date') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('check_in_time')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('end_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('end_date') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('check_out_time')"
                  ></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('actions')"></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td v-if="columns.includes('branch')">
                {{ (item.company_branch || {}).name || '...' }}
                <a
                  :href="`tel:${(item.company_branch || {}).mobile}`"
                  class="text-sky-500 block"
                  v-if="(item.company_branch || {}).mobile"
                  >{{ (item.company_branch || {}).mobile }}</a
                >
              </td>
              <td v-if="columns.includes('store')">
                {{ (item.company_store || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('user')">
                {{ (item.user || {}).name || '...' }}
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-sky-500 block"
                  v-if="(item.user || {}).mobile"
                  >{{ (item.user || {}).mobile }}</a
                >
              </td>
              <td v-if="columns.includes('supervisor')">
                {{ (item.supervisor || {}).name || '...' }}
                <a
                  :href="`tel:${(item.supervisor || {}).mobile}`"
                  class="text-sky-500 block"
                  v-if="(item.supervisor || {}).mobile"
                  >{{ (item.supervisor || {}).mobile }}</a
                >
              </td>

              <td v-if="columns.includes('status')">
                <p class="status capitalize" :class="styles[item.status]">
                  {{
                    ['missed', 'skipped'].includes(item.status)
                      ? item.is_scheduled
                        ? $t('scheduled') + ' - '
                        : $t('extra_mission') + ' - '
                      : null
                  }}
                  {{ item.status ? $t(item.status) : '...' }}
                </p>
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="createTime(item.visit_date)"
              ></td>
              <td v-if="columns.includes('start_date')">
                {{ item.check_in_time || '--:--' }}
              </td>
              <td v-if="columns.includes('end_date')">
                {{ item.check_out_time || '--:--' }}
              </td>

              <td v-if="columns.includes('actions')">
                <div class="actions-group">
                  <div>
                    <router-link
                      :to="`/visits/${item.id}`"
                      class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                      content="View details"
                      v-tippy
                    >
                      <i class="fa-solid fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import SingleCard from '@/components/basics/SingleCard.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import { mapGetters, mapMutations } from 'vuex'
import FilterBox from '@/components/basics/FilterBox.vue'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'
// import OSAReport from '@/components/reports/OSAReport.vue'
export default {
  name: 'HomePage',
  data() {
    return {
      series: [
        {
          name: 'not_done_visits_count',
          data: [],
        },
        {
          name: 'done_visits_count',
          data: [],
        },
        {
          name: 'extra_mission_done_visits_count',
          data: [],
        },
        // {
        //   name: 'visits_count',
        //   data: [],
        // },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            // endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontFamily: 'cairo',
              fontWeight: 500,
              cssClass: 'text-center text-gray-600',
            },
            formatter: (value) => {
              return `${value.day} \n ( ${value.visits_count} )`
            },
          },
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },

        grid: {
          show: true,
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          show: false,
        },
      },
      globalOptions: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat, Cairo',
            fontWeight: 'bold',
          },

          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex]
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: (series) =>
                this.$t(series.replace(/_visits_count/gi, '')),
            },
          },

          theme: 'dark',
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat, Cairo',
          },
          x: { show: false },
          z: {
            title: 'Size: ',
          },
          marker: {
            show: false,
          },
        },
      },
      per_status_series: [],
      per_status_chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },

        stroke: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },

        grid: {
          show: false,
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          formatter: function (val, { w }) {
            return val.replace(/_visits_count$/gi, '').replace(/(-|_)/g, ' ')
          },
        },
      },
      status_colors: [
        '#16a34a', // 1
        '#ef4444', // 2
        '#0ea5e9', // 3
        '#eab308', // 4
        '#ca8a04', // 5
        '#ef4444', // 6
        '#cbd5e1', // 7
        '#9d174d', // 8
      ],
      pie_series: [],
      pie_chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
            },
          },
        ],
      },
      pie_start: false,
      value: '',
      select: ['list', 'of', 'options'],
      options: [],

      cards: [
        {
          title: 'Today visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'today_visits',
          icon: 'fa-solid fa-location-crosshairs',
          path: {
            name: 'VisitPage',
          },
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'SKUs',
          value: 0,
          key: 'skus_count',
          translate_key: 'skus',
          icon: 'fa-solid fa-basket-shopping',
          path: {
            name: 'SKUs',
          },
          style: {
            icon: 'text-yellow-600',
            parent: 'bg-yellow-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          path: {
            name: 'ConfigurationStores',
          },
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          path: {
            name: 'ConfigurationBranchs',
          },
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },

        {
          title: 'Brands',
          value: 0,
          key: 'brands_count',
          translate_key: 'brands',
          icon: 'fa-solid fa-hashtag',
          path: {
            name: 'Brands',
          },
          style: {
            icon: 'text-indigo-600',
            parent: 'bg-indigo-600/20',
          },
        },
        {
          title: 'Merchandisers',
          value: 0,
          key: 'merchandisers_count',
          translate_key: 'merchandisers',
          icon: 'fa-solid fa-users-between-lines',
          path: {
            name: 'Merchandisers',
          },
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        {
          title: 'Supervisors',
          value: 0,
          key: 'supervisors_count',
          translate_key: 'supervisors',
          icon: 'fa-solid fa-user-group',

          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },

        // {
        //   title: 'Zones',
        //   value: 0,
        //   key: 'zones_count',
        //   translate_key: 'zones',
        //   icon: 'fa-solid fa-magnifying-glass-location',
        //   style: {
        //     icon: 'text-green-600',
        //     parent: 'bg-green-600/20',
        //   },
        // },
        {
          title: 'Cities',
          value: 0,
          key: 'cities_count',
          translate_key: 'cities',
          icon: 'fa-solid fa-compass',
          style: {
            icon: 'text-green-400',
            parent: 'bg-green-400/20',
          },
        },
        {
          title: '1st alert',
          value: 0,
          key: 'alert_1_skus_count',
          translate_key: 'alert_1',
          icon: 'fa-solid fa-bell',
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
        {
          title: '2th alert',
          value: 0,
          key: 'alert_2_skus_count',
          translate_key: 'alert_2',
          icon: 'fa-solid fa-bell',
          style: {
            icon: 'text-orange-500',
            parent: 'bg-orange-500/20',
          },
        },
        {
          title: '3th alert',
          value: 0,
          key: 'alert_3_skus_count',
          translate_key: 'alert_3',
          icon: 'fa-solid fa-bell',
          style: {
            icon: 'text-yellow-500',
            parent: 'bg-yellow-500/20',
          },
        },
        {
          title: 'Out of stock',
          value: 0,
          key: 'out_of_stock_count',
          translate_key: 'out_of_stock',
          icon: 'fa-solid fa-arrow-trend-down',
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
      ],
      day_visits_count: {
        date: this.$moment(new Date().toLocaleDateString()).format(
          'DD-MM-YYYY'
        ),
      },
      day_visits: [],
      load: false,
      week_visits_count: 0,
      visits_count: 0,
      week_visits_status_count: 0,
      items: [],
      status: [
        'done',
        'missed',
        'pending',
        'extra mission',
        'extra mission done',
        'added by user',
        'skipped',
      ],
      styles: {
        done: 'bg-sky-500 text-white',
        missed: 'bg-red-500 text-white',
        pending: 'bg-slate-400 text-white',
        extra_mission: 'bg-green-600 text-white',
        extra_mission_done: 'bg-yellow-500 text-white',
        added_by_user: 'bg-green-800 text-white',
        skipped: 'bg-pink-800 text-white',
        scheduled: 'bg-slate-200 text-slate-600',
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
    }
  },
  async created() {
    Promise.all([await this.getDashboard(), await this.getVisitsByDate()])
  },

  computed: {
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    ...mapGetters('table', ['is_called', 'table_paginate', 'search']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data = this.getSelectedColumns(this.endpointName)
      return data
    },
  },
  watch: {
    'day_visits_count.date'() {
      this.getVisitsByDate()
    },
    async is_called() {
      if (this.is_called) {
        await this.getDashboard()
      }
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async getVisitsByDate() {
      this.pie_start = false
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'dashboard/day_visits_count',
          options: {
            ...this.day_visits_count,
          },
        })
        const { day_visits_count } = result
        this.visits_count = day_visits_count.visits_count
        const { series, labels } = await this.displayDataPerStatus(
          day_visits_count
        )

        this.pie_series = series
        this.$set(this.pie_chartOptions, 'labels', labels)
        this.visits_count = day_visits_count.visits_count
      } catch (error) {
        // alert(JSON.stringify(error))
      } finally {
        this.$nextTick(() => {
          this.pie_start = true
        })
      }
    },
    async getDashboard() {
      this.load = false
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'dashboard',
          options: {
            ...this.table_paginate,
          },
        })
        const { summary, week_visits_count, week_visits_status_count, visits } =
          result
        this.displaySummaryData(summary)
        this.displayWeekVisits(week_visits_count)
        this.displayWeekVisitsPerStatus(week_visits_status_count)
        this.items = visits.map((e) => {
          return {
            ...e,
            company_store: e?.company_branch?.company_store ?? {},
          }
        })
      } catch (error) {
      } finally {
        this.load = true
        this.changeIsCalled(false)
      }
    },

    displaySummaryData(summary) {
      const summaryKeys = Object.keys(summary)
      for (let sIndx = 0; sIndx < summaryKeys.length; sIndx++) {
        const item = summaryKeys[sIndx]
        const cardItem = this.cards.find((e) => e.key === item)
        if (cardItem) {
          cardItem.value = summary[item]
          this.$set(
            this.cards,
            this.cards.findIndex((e) => e.key === cardItem.key),
            cardItem
          )
        }
      }
    },

    async displayWeekVisits(visits) {
      this.week_visits_count = await this.calcCounts(visits)
      const filterVisits = visits.filter((e) => e.visits_count)

      this.$set(this.chartOptions?.xaxis, 'categories', filterVisits)
      for (const item of this.series) {
        const index = this.series.findIndex((e) => e.name === item.name)
        this.$set(this.series, index, {
          ...item,
          data: filterVisits.map((visit) => visit[item.name]),
        })
      }
      // this.$set(
      //   this.series[0],
      //   'data',
      //   visits.map((e) => e.not_done_visits_count)
      // )
      // this.$set(
      //   this.series[1],
      //   'data',
      //   visits.map((e) => e.done_visits_count)
      // )
      // this.$set(
      //   this.series[2],
      //   'data',
      //   visits.map((e) => e.extra_mission_done_visits_count)
      // )
      // this.$set(
      //   this.series[3],
      //   'data',
      //   visits.map((e) => e.visits_count)
      // )
    },
    async displayWeekVisitsPerStatus(visits) {
      this.week_visits_status_count = await this.calcCounts(visits)
      let items = []
      let obj = {}
      for (let i = 0; i < visits.length; i++) {
        const item = visits[i]
        const { labels } = await this.displayDataPerStatus(item)

        obj.labels = labels
        labels.forEach((label, index) => {
          if (!obj[label]) {
            obj[label] = []
          }
          Object.assign(obj, {
            [label]: [...obj[label], item[label]],
          })
        })
      }

      for (const key in obj) {
        let finalObj = {}
        if (!/labels/g.test(key)) {
          finalObj.data = obj[key]
          finalObj.name = key
          items.push(finalObj)
        }
      }

      this.per_status_series = items
    },

    displayDataPerStatus(items) {
      return new Promise((resolve) => {
        const visitsKeys = Object.keys(items)
        let series = [],
          labels = []
        for (let i = 0; i < visitsKeys.sort().length; i++) {
          const searchRegex = /_visits_count/g
          const visitStatus = visitsKeys[i]
          if (searchRegex.test(visitStatus)) {
            series = [...series, items[visitStatus]]
            labels = [...labels, visitStatus]
          }
        }

        resolve({
          series,
          labels,
        })
      })
    },

    calcCounts(items) {
      let total = 0
      return new Promise((resolve) => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          total += item.visits_count
        }

        resolve(total)
      })
    },
    sortTable() {},
  },
  components: {
    Table,
    SingleCard,
    TableLoad,
    FilterBox,
    CardsContainer,
    // OSAReport,
  },
}
</script>
