<template>
  <div class="space-y-6 px-6">
    <reports-filter></reports-filter>
    <cards-container :cards="filterCards" :load="!load" />
    <header class="w-full my-6" v-if="false">
      <div class="grid gap-3">
        <div class="w-full flex gap-3 flex-wrap">
          <label
            :key="index"
            class="py-1.5 px-4 rounded-md flex gap-2 items-center cursor-pointer"
            v-for="(item, index) in status"
            :class="styles[item.replace(/\s/g, '_')]"
            :for="`${item}_${index}`"
          >
            <spinner
              size="w-4 h-4"
              class="align-sub"
              v-if="disables[`${item}_${index}`]"
            />
            <p class="text-sm font-medium rtl:font-semibold capitalize">
              {{ $t(item.replace(/\s/g, '_')) }}
            </p>
            <input
              autocomplete="off"
              type="checkbox"
              :name="`${item}_${index}`"
              :id="`${item}_${index}`"
              :value="item.replace(/\s/g, '_')"
              v-model="pagination.status"
              hidden
              class="hidden"
              aria-hidden="true"
              :disabled="disables[item]"
              @change.prevent="changeFilterStatus(`${item}_${index}`)"
            />
          </label>
        </div>
        <p
          class="text-xs font-medium rtl:font-semibold text-slate-600"
          v-if="pagination.status.length !== 0"
        >
          {{ $t('status') }}:
          <span class="text-sky-600">{{ pagination.status.join(', ') }}</span>
        </p>
      </div>
    </header>
    <main class="w-full">
      <Table
        :has_header="true"
        :has_check="false"
        :title="$t('visits')"
        :records="`${pagination.total || 0} ${$t('records')}`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        :fields="fields"
        v-model="pagination.page"
      >
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[item]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[item]"
                />
                {{ $t(item.replace(/\s/g, '_')) }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th v-if="columns.includes('client')">
            <div class="sort">
              <div>
                <p>{{ $t('client') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('client_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('supervisor')">
            <div class="sort">
              <div>
                <p>{{ $t('supervisor') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('supervisor_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('user')">
            <div class="sort">
              <div>
                <p>{{ $t('user') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('user_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('store')">
            <div class="sort">
              <div>
                <p>{{ $t('store') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('company_store_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('branch')">
            <div class="sort">
              <div>
                <p>{{ $t('branch') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('company_branch_id')"
                ></button>
              </div>
            </div>
          </th>

          <th v-if="columns.includes('region')">
            <div class="sort">
              <div>
                <p>{{ $t('region') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('region_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('city')">
            <div class="sort">
              <div>
                <p>{{ $t('city') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('city_id')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('status')">
            <div class="sort">
              <div>
                <p>{{ $t('status') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('status')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('visit_date')">
            <div class="sort">
              <div>
                <p>{{ $t('visit_date') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('visit_date')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('start_date')">
            <div class="sort">
              <div>
                <p>{{ $t('start_date') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('check_in_time')"
                ></button>
              </div>
            </div>
          </th>
          <th v-if="columns.includes('end_date')">
            <div class="sort">
              <div>
                <p>{{ $t('end_date') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('check_out_time')"
                ></button>
              </div>
            </div>
          </th>

          <th v-if="columns.includes('actions')"></th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length !== 0">
          <tr v-for="(item, index) in items" :key="index">
            <td v-if="columns.includes('client')">
              {{ (item.client || {}).name || '...' }}
              <a
                :href="`tel:${(item.client || {}).mobile}`"
                class="text-sky-500 block"
                v-if="(item.client || {}).mobile"
                >{{ (item.client || {}).mobile }}</a
              >
            </td>

            <td v-if="columns.includes('supervisor')">
              {{ (item.supervisor || {}).name || '...' }}
              <a
                :href="`mailto:${(item.supervisor || {}).username}`"
                class="text-sky-500 block"
                v-if="(item.supervisor || {}).username"
                >{{ (item.supervisor || {}).username }}</a
              >
            </td>
            <td v-if="columns.includes('user')">
              {{ (item.user || {}).name || '...' }}
              <a
                :href="`mailto:${(item.user || {}).username}`"
                class="text-sky-500 block"
                v-if="(item.user || {}).username"
                >{{ (item.user || {}).username }}</a
              >
            </td>
            <td v-if="columns.includes('store')">
              {{ (item.company_store || {}).name || '...' }}
            </td>
            <td v-if="columns.includes('branch')">
              {{ (item.company_branch || {}).name || '...' }}
              <a
                :href="`tel:${(item.company_branch || {}).mobile}`"
                class="text-sky-500 block"
                v-if="(item.company_branch || {}).mobile"
                >{{ (item.company_branch || {}).mobile }}</a
              >
            </td>
            <td v-if="columns.includes('region')">
              {{ (item.region || {}).name || '...' }}
            </td>
            <td v-if="columns.includes('city')">
              {{ (item.city || {}).name || '...' }}
            </td>

            <td v-if="columns.includes('status')">
              <p class="status capitalize" :class="styles[item.status]">
                {{
                  ['missed', 'skipped'].includes(item.status)
                    ? item.is_scheduled
                      ? $t('scheduled') + ' - '
                      : $t('extra_mission') + ' - '
                    : null
                }}
                {{ item.status ? $t(item.status) : '...' }}
              </p>
            </td>
            <td
              v-if="columns.includes('visit_date')"
              v-html="createTime(item.visit_date)"
            ></td>
            <td v-if="columns.includes('start_date')">
              {{ item.check_in_time || '--:--' }}
            </td>
            <td v-if="columns.includes('end_date')">
              {{ item.check_out_time || '--:--' }}
            </td>

            <td v-if="columns.includes('actions')">
              <div class="actions-group">
                <div v-if="['extra_mission'].includes(item.status)">
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> {{ $t('delete') }} </span>
                  </button>
                </div>
                <div v-if="false">
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="$t('change_status')"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="showMove(item.id)"
                    :content="$t('move_visit')"
                    v-tippy
                  >
                    <i class="fa-solid fa-right-left"></i>
                  </button>
                </div>
                <div>
                  <router-link
                    :to="`/visits/${item.id}`"
                    class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                    :content="$t('view_details')"
                    v-tippy
                  >
                    <i class="fa-solid fa-eye"></i>
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #tbody v-else-if="load && items.length === 0">
          <tr>
            <td :colspan="columns.length">
              <p
                class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
              >
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </template>
      </Table>
    </main>
    <transition name="scale">
      <Modal
        :title="$t('move_visit')"
        v-if="move_active"
        @close="
          (e) => {
            move_active = e
            move_errors = {}
            move_data = {
              to_user_id: null,
            }
          }
        "
      >
        <template>
          <div class="p-4">
            <form class="w-full space-y-4">
              <div class="w-full space-y-2">
                <label
                  for="user"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('user') }}</label
                >
                <multiselect
                  v-model="move_data.to_user_id"
                  :options="users"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="to_user_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="users.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="move_errors.to_user_id"
                >
                  {{ move_errors.to_user_id.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <p class="text-slate-600 font-semibold rtl:font-bold text-sm">
                  {{ $t('move_type') }}
                </p>
                <ul class="list-none flex flex-wrap gap-6">
                  <li
                    v-for="(item, index) in move_types"
                    :key="index"
                    class="block"
                  >
                    <div class="form-group-with-check">
                      <div>
                        <input
                          autocomplete="off"
                          type="radio"
                          name="moveTypeGroup"
                          :id="`move_${index}`"
                          :value="item"
                          v-model="move_data.move_type"
                        />
                      </div>
                      <label :for="`move_${index}`" class="capitalize">
                        {{ $t(item) }}
                      </label>
                    </div>
                  </li>
                </ul>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="move_errors.to_user_id"
                >
                  {{ move_errors.to_user_id.join(' ') }}
                </p>
              </div>
              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="moveVisit"
                    :disabled="move_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="move_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'
import ReportsFilter from '@/components/reports/components/ReportsFilter.vue'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'

export default {
  components: {
    Table,
    TableLoad,
    Modal,
    ReportsFilter,
    CardsContainer,
  },
  name: 'VisitPage',
  data() {
    return {
      load: false,
      status: ['all', 'done', 'not_done'],
      styles: {
        done: 'bg-sky-500 text-white',
        missed: 'bg-red-500 text-white',
        pending: 'bg-slate-400 text-white',
        extra_mission: 'bg-green-600 text-white',
        extra_mission_done: 'bg-yellow-500 text-white',
        added_by_user: 'bg-green-800 text-white',
        skipped: 'bg-pink-800 text-white',
        cancelled: 'bg-red-600 text-white',
        scheduled: 'bg-slate-200 text-slate-600',
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      items: [],
      pagination: {
        status: 'done',
        from: null,
        company_branch_id: [],
        user_id: [],
        page: 1,
        total: 0,
        total_pages: 0,
      },
      move_data: {
        to_user_id: null,
      },
      move_types: ['temporary', 'permanent'],
      move_errors: {},
      move_disabled: false,
      move_active: false,
      disables: {},
      apply_disabled: false,
      reset_disabled: false,
      item: {},
      show_active: false,
      store_placements: [],
      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Merchandisers',
          value: 0,
          key: 'merchandisers_count',
          translate_key: 'merchandisers',
          icon: 'fa-solid fa-users-between-lines',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        // {
        //   title: 'Supervisors',
        //   value: 0,
        //   key: 'supervisors_count',
        //   translate_key: 'supervisors',
        //   icon: 'fa-solid fa-user-group',
        //   style: {
        //     icon: 'text-blue-400',
        //     parent: 'bg-blue-400/20',
        //   },
        // },

        {
          title: 'Scheduled',
          value: 0,
          key: 'scheduled_visits_count',
          translate_key: 'scheduled',
          icon: 'fa-solid fa-list-ol',
          style: {
            icon: 'text-slate-600',
            parent: 'bg-slate-600/20',
          },
        },

        // {
        //   title: 'Missed',
        //   value: 0,
        //   key: 'missed_visits_count',
        //   translate_key: 'missed',
        //   icon: 'fa-solid fa-arrow-down-short-wide',
        //   style: {
        //     icon: 'text-red-500',
        //     parent: 'bg-red-500/20',
        //   },
        // },

        {
          title: 'Extra mission',
          value: 0,
          key: 'extra_mission_visits_count',
          translate_key: 'extra_mission',
          icon: 'fa-solid fa-file-circle-plus',
          style: {
            icon: 'text-green-500',
            parent: 'bg-green-500/20',
          },
        },
        {
          title: 'Extra mission done',
          value: 0,
          key: 'extra_mission_done_visits_count',
          translate_key: 'extra_mission_done',
          icon: 'fa-solid fa-check-double',
          style: {
            icon: 'text-yellow-500',
            parent: 'bg-yellow-500/20',
          },
        },
        {
          title: 'done',
          value: 0,
          key: 'done_visits_count',
          translate_key: 'done',
          icon: 'fa-solid fa-check',
          style: {
            icon: 'text-sky-500',
            parent: 'bg-sky-500/20',
          },
        },
        {
          title: 'Not done',
          value: 0,
          key: 'not_done_count',
          translate_key: 'not_done',
          icon: 'fa-solid fa-xmark',
          style: {
            icon: 'text-pink-800',
            parent: 'bg-pink-800/20',
          },
        },
        // {
        //   title: 'Skipped',
        //   value: 0,
        //   key: 'skipped_visits_count',
        //   translate_key: 'skipped',
        //   icon: 'fa-solid fa-forward',
        //   style: {
        //     icon: 'text-pink-800',
        //     parent: 'bg-pink-800/20',
        //   },
        // },
      ],
      summary: {},
      users: [],
      fields: [],
    }
  },
  async created() {
    Promise.all([
      await this.getItems(),
      // await this.getUsers(),
      // await this.getStorePlacements(),
    ])
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['is_called', 'table_paginate', 'search']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data =
        this.getSelectedColumns(this.endpointName) ||
        this.getBasicColumn(this.endpointName)
      return data
    },

    filterUsers() {
      const selectedClient = this.table_paginate?.client_id
      const data = this.users?.filter((e) => e.role_type === 'merchandiser')

      if (this.getUser?.role_type === 'super_user') {
        if (!selectedClient?.length) return []
        const clientIds = selectedClient?.map((e) => e.id)
        if (!data.length) return []

        return data.filter((merchandiser) => {
          const merchandiserClient = merchandiser?.clients?.map((e) =>
            [clientIds].includes(e?.id)
          )
          if (!merchandiserClient.length) return false
          return merchandiser
        })
      }
      return data
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled', 'updateSearch']),
    async getStorePlacements() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'store_placements'
        )
        this.store_placements = result.store_placements
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getUsers() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'users',
          options: {
            role_type: ['merchandiser'],
          },
        })
        this.users = result.users
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    getItems() {
      const { status, from, company_branch_id, user_id, page, client_id } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'visits',
          options: {
            status: status
              ? status === 'done'
                ? ['done', 'extra_mission_done']
                : ['missed', 'skipped', 'scheduled', 'extra_mission']
              : null,
            from,
            page,
            company_branch_id: company_branch_id?.map((e) => e.id),
            user_id: user_id?.map((e) => e.id),
            client_id: client_id?.map((e) => e.id),
            search_key: this.search,
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.visits

          this.items = data.map((e) => {
            return {
              ...e,
              company_store: e?.company_branch?.company_store ?? {},
              city: e?.company_branch?.branch?.city ?? {},
              region: e?.company_branch?.branch?.region ?? {},
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.summary = result?.summary || {}
          this.fields = result?.export_fields || []
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`visits/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        const { data } = await this.axios.post(`visits/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async moveVisit() {
      this.move_disabled = true
      this.move_errors = {}

      try {
        const { data } = await this.axios.post('visits/move', {
          ...this.move_data,
          to_user_id: this.move_data?.to_user_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.move_active = false
        this.move_data = {
          to_user_id: null,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.move_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.move_errors = res?.data?.message
      } finally {
        this.move_disabled = false
      }
    },
    showMove(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.move_data = {
        visit_id: id,
      }
      this.move_active = true
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination).forEach((key) => {
        if (!['page', 'total_pages', 'total'].includes(key)) {
          if (Array.isArray(this.pagination[key])) {
            this.$set(this.pagination, key, [])
          } else {
            this.$set(this.pagination, key, null)
          }
        }
      })
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    showItem(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.item = {
        ...item,
      }
      this.show_active = true
    },
    changeFilterStatus(item) {
      this.$set(this.disables, item, true)
      this.$set(this.pagination, 'status', item)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
}
</script>
