import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import axios from '@/plugins/axios'
import i18n from '@/plugins/i18n'
// css
import '@/assets/styles/main.css'
import '@/assets/styles/main.scss'
import '@/assets/styles/custom.scss'
import 'flag-icons/sass/flag-icons.scss'

import '@/lib/index'

Vue.mixin(require('@/mixins/index.js').default)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
