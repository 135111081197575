<template>
  <div>
    <ReturnAudits
      v-model="pagination"
      :load="load"
      :result="items"
      :summary="summary"
      :fields="fields"
    />
  </div>
</template>
<script>
import ReturnAudits from '@/components/reports/ReturnAudits.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ReturnAudits },
  name: 'ReturnsReports',
  data() {
    return {
      load: false,
      pagination: {
        page: 1,
        sort_key: null,
        sort_type: null,
      },
      items: [],
      fields: [],
      summary: {},
    }
  },
  async created() {
    await this.getItems()
  },
  watch: {
    page() {
      this.getItems()
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
  },
  computed: {
    ...mapGetters('table', ['page', 'is_called', 'table_paginate', 'search']),
  },
  methods: {
    ...mapMutations('table', [
      'changeApply',
      'changeReset',
      'changeIsCalled',
      'updateSearch',
    ]),
    getItems() {
      const { sort_key, sort_type, is_active } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'reports/return_audits',
          options: {
            page: this.page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            ...this.table_paginate,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.return_audits
          this.items = data.map((e) => {
            return {
              ...e,
              visit: {
                ...e?.visit,
                company_store: e?.visit?.company_branch?.company_store ?? {},
              },
              user: e?.visit?.user || {},
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.summary = result?.summary || {}
          this.fields = result?.export_fields || []
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeApply(false)
          this.changeReset(false)
          this.changeIsCalled(false)
        })
    },
  },
}
</script>
