<template>
  <div class="px-6 pb-10">
    <section
      class="
        flex flex-col
        lg:flex-row
        gap-6
        max-w-screen-xl
        mx-auto
        align-start
      "
      v-if="load"
    >
      <aside class="lg:w-1/3">
        <form class="w-full space-y-4">
          <figure class="bg-white border border-slate-200 rounded">
            <header class="border-b border-slate-100 p-4">
              <p class="font-medium rtl:font-semibold text-sm">
                {{ $t('create_new.visit') }}
              </p>
            </header>
            <blockquote class="w-full space-y-3 p-6">
              <div
                class="w-full space-y-2"
                v-if="getUser.role_type === 'super_user'"
              >
                <label
                  for="client"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('client') }}</label
                >
                <multiselect
                  v-model="add_data.client_id"
                  :options="clients"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="client_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="clients.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.client_id"
                >
                  {{ add_errors.client_id.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <label
                  for="zone"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('zone') }}</label
                >
                <multiselect
                  v-model="add_data.zone_id"
                  :options="zones"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="zone_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="zones.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.zone_id"
                >
                  {{ add_errors.zone_id.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <label
                  for="region"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('region') }}</label
                >
                <multiselect
                  v-model="add_data.region_id"
                  :options="filterRegions"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="region_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="filterRegions.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.region_id"
                >
                  {{ add_errors.region_id.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <label
                  for="city"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('city') }}</label
                >
                <multiselect
                  v-model="add_data.city_id"
                  :options="filterCities"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="city_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="filterCities.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.city_id"
                >
                  {{ add_errors.city_id.join(' ') }}
                </p>
              </div>

              <div class="w-full space-y-2">
                <label
                  for="supervisor"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('supervisor') }}</label
                >
                <multiselect
                  v-model="add_data.supervisor_id"
                  :options="filterSupervisors"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="supervisor_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="filterSupervisors.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.supervisor_id"
                >
                  {{ add_errors.supervisor_id.join(' ') }}
                </p>
              </div>

              <div class="w-full space-y-2">
                <label
                  for="merchandiser"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('merchandiser') }}</label
                >
                <multiselect
                  v-model="add_data.user_id"
                  :options="filterMerchandisers"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="user_id"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="filterMerchandisers.length === 0"
                ></multiselect>

                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.user_id"
                >
                  {{ add_errors.user_id.join(' ') }}
                </p>
              </div>

              <div class="w-full space-y-2">
                <label
                  for="stores"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('store') }}</label
                >
                <multiselect
                  v-model="add_data.company_store_id"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  :options="filterStores"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  id="stores"
                  :disabled="filterStores.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.company_store_id"
                >
                  {{ add_errors.company_store_id.join(' ') }}
                </p>
              </div>
              <div class="w-full space-y-2">
                <label
                  for="branches"
                  class="table font-medium rtl:font-semibold text-sm"
                  >{{ $t('branches') }}</label
                >
                <multiselect
                  v-model="add_data.company_branch_ids"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  :options="filterBranches"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  id="branches"
                  :disabled="filterBranches.length === 0"
                ></multiselect>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.company_branch_ids"
                >
                  {{ add_errors.company_branch_ids.join(' ') }}
                </p>
              </div>
            </blockquote>
          </figure>
          <figure class="bg-white border border-slate-200 rounded">
            <header class="border-b border-slate-100 p-4">
              <p class="font-medium rtl:font-semibold text-sm">
                {{ $t('required_tasks') }}
              </p>
            </header>
            <blockquote class="w-full p-4">
              <ul class="list-none space-y-4" v-if="!load">
                <li v-for="i in 4" :key="i" class="w-full">
                  <div class="actions-group !flex-nowrap !gap-4">
                    <div
                      class="w-10 h-10 rounded bg-slate-200 animate-pulse"
                    ></div>
                    <div class="space-y-2 flex-1">
                      <div
                        class="w-1/3 h-4 rounded bg-slate-200 animate-pulse"
                      ></div>
                      <div
                        class="w-2/3 h-3 rounded bg-slate-200 animate-pulse"
                      ></div>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                class="text-center space-y-2 py-8 px-2"
                v-if="load && filterTasks.length === 0"
              >
                <i class="fa-solid fa-calendar-xmark fa-2xl text-slate-400"></i>
                <h1 class="font-semibold rtl:font-bold !mt-6">
                  There are no tasks available
                </h1>
                <p
                  class="
                    text-sm text-slate-600
                    max-w-2xl
                    font-medium
                    rtl:font-semibold
                  "
                >
                  You can contact us to find out when new tasks will be
                  available to complete your work
                </p>
              </div>
              <ul
                class="w-full list-none space-y-4"
                v-if="load && filterTasks.length !== 0"
              >
                <li v-for="(item, index) in filterTasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="add_data.task_ids"
                            @change.prevent="
                              handleParentItem($event, item.id, 'add_data')
                            "
                            v-if="!item.sub_tasks.length"
                          />
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="
                      w-full
                      list-none
                      space-y-4
                      mt-4
                      [padding-inline-start:1.5rem]
                    "
                    v-if="item.sub_tasks.length"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="add_data.task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id, 'add_data')
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.task_ids"
              >
                {{ add_errors.task_ids.join(' ') }}
              </p>
            </blockquote>
          </figure>
          <figure class="bg-white border border-slate-200 rounded">
            <header class="border-b border-slate-100 p-4">
              <p class="font-medium rtl:font-semibold text-sm">
                {{ $t('visit_date') }}
              </p>
            </header>
            <blockquote class="p-4 space-y-6">
              <ul class="list-none space-y-6">
                <li
                  v-for="(item, index) in times"
                  :key="index"
                  class="block space-y-6"
                >
                  <div class="form-group-with-check">
                    <div>
                      <input
                        autocomplete="off"
                        type="radio"
                        name="taskTime"
                        :id="item"
                        :value="item"
                        v-model="times_data"
                      />
                    </div>
                    <label :for="item" class="capitalize">
                      {{ $t(`visit_time.${item}`) }}
                    </label>
                  </div>
                  <ul
                    class="
                      list-none
                      flex flex-wrap
                      gap-6
                      pl-8
                      rtl:pl-0 rtl:pr-8
                    "
                    v-if="
                      times_data.indexOf('weekly') !== -1 && item === 'weekly'
                    "
                  >
                    <li v-for="(day, index) in days" :key="index" class="block">
                      <div class="form-group-with-check">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="day"
                            :id="day"
                            :value="index + 1"
                            v-model="add_data.week_days"
                          />
                        </div>
                        <label :for="day" class="capitalize">
                          {{ $t(`week_days.${day}`) }}
                        </label>
                      </div>
                    </li>
                  </ul>
                  <ul
                    class="
                      list-none
                      flex flex-wrap
                      gap-6
                      pl-8
                      rtl:pl-0 rtl:pr-8
                    "
                    v-if="
                      times_data.indexOf('monthly') !== -1 && item === 'monthly'
                    "
                  >
                    <li
                      v-for="(item, i) in generateTaskTime"
                      :key="i"
                      class="space-y-3"
                    >
                      <p class="font-medium rtl:font-semibold text-sm">
                        {{ $t(`weeks.${i + 1}`) }}
                      </p>
                      <ul class="list-none flex flex-wrap gap-6">
                        <li
                          v-for="(day, index) in item.days"
                          :key="index"
                          class="block"
                        >
                          <div class="form-group-with-check">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="day.id"
                                :id="day.id"
                                :data-id="day.id"
                                :value="day.id"
                                v-model="add_data.month_days"
                              />
                            </div>
                            <label :for="day.id" class="capitalize">
                              {{ $t(`week_days.${day.name}`) }}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    Object.keys(add_errors).some((e) =>
                      [
                        'week_days',
                        'today_visit',
                        'month_days',
                        'run_tomorrow',
                      ].includes(e)
                    )
                  "
                >
                  <p
                    class="text-red-500 font-medium text-xs mt-2"
                    v-html="
                      Object.keys(add_errors)
                        .filter((key) =>
                          [
                            'week_days',
                            'today_visit',
                            'month_days',
                            'run_tomorrow',
                          ].includes(key)
                        )
                        .map((e) => add_errors[e])
                        .join('<br />')
                    "
                  ></p>
                </li>
                <li class="block space-y-6">
                  <div class="form-group-with-check">
                    <div>
                      <input
                        autocomplete="off"
                        type="checkbox"
                        name="RunTomorrow"
                        id="tomorrow"
                        :true-value="1"
                        :false-value="0"
                        v-model="add_data.run_tomorrow"
                      />
                    </div>
                    <label for="tomorrow" class="capitalize">
                      {{ $t('run_tomorrow') }}
                    </label>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <button
            class="
              py-2.5
              px-6
              rounded-md
              text-sm
              font-medium
              rtl:font-semibold
              text-white
              bg-primary
              table
            "
            @click.prevent="addItem"
            :disabled="add_disabled"
          >
            <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
            {{ $t('create_new.visit') }}
          </button>
        </form>
      </aside>
      <main class="lg:w-2/3">
        <figure
          class="
            bg-white
            ring-1 ring-slate-200
            rounded
            overflow-hidden
            sticky
            top-20
          "
        >
          <figcaption class="border-b border-b-slate-100 p-4">
            <p class="text-slate-600 text-sm font-medium rtl:font-semibold">
              Locations
            </p>
          </figcaption>
          <blockquote>
            <GmapMap
              :cluster="{ options: { styles: map.clusterStyle } }"
              :zoom="8"
              :center="{
                lat: Number(generateLocationFromStores[0].lat),
                lng: Number(generateLocationFromStores[0].lng),
              }"
              map-type-id="terrain"
              :options="{
                fullscreenControl: false,
                styles: map.mapStyle,
                mapTypeControl: false,
              }"
              style="height: calc(100vh - 200px); min-height: 350px"
              ref="mapComponent"
            >
              <GmapMarker
                v-for="(location, index) in generateLocationFromStores"
                :key="index"
                :position="{
                  lat: Number(location.lat),
                  lng: Number(location.lng),
                }"
                :draggable="false"
                @mouseover="handleMarkerWhenHover($event, location)"
                :title="location.name"
                :ref="'map-marker-' + location.id"
                />
                <!-- :label="{
                  text: location.name,
                  className: 'text-white py-1.5 px-2 rounded bg-red-500 -mt-12',
                  fontFamily: '\'Montserrat\', \'Cairo\', sans-serif',
                  fontSize: '12px',
                  color: '#ffffff',
                }" -->
            </GmapMap>
          </blockquote>
        </figure>
      </main>
    </section>
    <spinner class="w-8 h-8 block mx-auto" v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SmartJourneyPlan',
  data() {
    return {
      tasks: [],
      times: ['weekly', 'monthly'],
      days: ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'],
      add_data: {
        week_days: [],
        task_ids: [],
        company_branch_ids: [],
        month_days: [],
        today_visit: 0,
        run_tomorrow: 0,
        user_id: null,
      },
      times_data: [],
      clients: [],
      users: [],
      branches: [],
      stores: [],
      add_disabled: false,
      add_errors: {},
      load: false,
      locations: [
        {
          lat: 40.0,
          lng: 50.0,
        },
        {
          lat: 85.0,
          lng: 85.0,
        },
        {
          lat: 80.0,
          lng: 90.0,
        },
      ],
      map: {
        clusterStyle: [
          {
            url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
            width: 56,
            height: 56,
            textColor: '#fff',
          },
        ],
        mapStyle: [
          {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#3e606f',
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: '#313536',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
              {
                color: '#44a688',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#13876c',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#f5e4e4',
              },
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
              {
                lightness: '14',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#13876c',
              },
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#067372',
              },
              {
                lightness: '-20',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
              {
                color: '#357374',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#004757',
              },
            ],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('filters', ['regions', 'cities', 'zones']),
    filterRegions() {
      this.$set(this.add_data, 'region_id', null)
      const selectedRegions = [...new Set(this.add_data.zone_id?.regions)]
      if (!selectedRegions.length) return this.regions
      return this.regions.filter((e) => selectedRegions.includes(e.id))
    },
    filterCities() {
      // this.add_data.city_id = null
      this.$set(this.add_data, 'city_id', null)
      const selectedRegion = this.add_data.region_id
      if (!selectedRegion) return this.cities
      return this.cities.filter((e) => selectedRegion?.id === e?.region?.id)
    },
    generateTaskTime() {
      let result = []
      const days = Array(4)
        .fill(this.days)
        .reduce((prev, next) => {
          return prev + ' ' + next
        })
        .replace(/\s/g, ',')
        .split(',')
        .map((e, i) => {
          return {
            name: e,
            id: i + 1,
          }
        })

      result = Array(4)
        .fill('')
        .map((e, i) => {
          return {
            title: `Week ${i + 1}`,
            days: days.slice(i > 0 ? i * 7 : i, (i + 1) * 7),
          }
        })
      return result
    },
    filterMerchandisers() {
      const clientId = this.add_data.client_id

      if (this.getUser?.role_type === 'super_user') {
        if (!clientId)
          return this.users.filter((e) => e.role_type === 'merchandiser')
        return this.users
          .filter((e) => e.role_type === 'merchandiser')
          .filter((merchandiser) => {
            const merchandiserClient = merchandiser?.clients?.map(
              (e) => clientId?.id === e?.id
            )
            if (!merchandiserClient.length) return false
            return merchandiser
          })
      }
      return this.users
    },
    filterSupervisors() {
      const clientId = this.add_data.client_id
      const supervisors = this.users.filter((e) => e.role_type === 'supervisor')

      if (this.getUser?.role_type === 'super_user') {
        if (!clientId) return supervisors
        return supervisors.filter((e) => e.client?.id === clientId?.id)
      }
      return supervisors
    },
    filterBranches() {
      const storeId = this.add_data?.company_store_id
      if (!storeId || !storeId?.id) return this.branches
      this.$set(
        this.add_data,
        'company_branch_ids',
        this.add_data.company_branch_ids?.filter(
          (e) => e.company_store?.id === storeId?.id
        )
      )
      return (
        this.branches.filter((e) => e?.company_store?.id === storeId?.id) ||
        this.branches
      )
    },
    filterStores() {
      const clientId = this.add_data.client_id
      const stores = this.stores
      this.$set(this.add_data, 'company_store_id', null)
      this.$set(this.add_data, 'company_branch_ids', [])

      if (this.getUser?.role_type === 'super_user') {
        if (!clientId) return stores
        return stores.filter((e) => e.client_ids.includes(clientId?.id))
      } else if (
        ['client_user', 'supervisor'].includes(this.getUser.role_type)
      ) {
        return stores.filter((e) =>
          e.client_ids.includes(this.getUser?.client?.id)
        )
      }
      return stores
    },
    filterTasks() {
      const clientId = this.add_data.client_id
      const tasks = this.tasks

      if (this.getUser?.role_type === 'super_user') {
        if (!clientId) return tasks
        return tasks.filter((e) => e.client_ids.includes(clientId?.id))
      } else if (
        ['client_user', 'supervisor'].includes(this.getUser.role_type)
      ) {
        return tasks.filter((e) =>
          e.client_ids.includes(this.getUser?.client?.id)
        )
      }
      return tasks
    },
    generateLocationFromStores() {
      let result = []
      const branches = this.add_data.company_branch_ids
      const filterBranches = this.filterBranches

      if (branches.length) result = branches
      else result = filterBranches

      return result
        .map((e, i) => ({
          ...e,
          // lat: Math.floor(Math.random() * (i + 1 * 1000)),
          lat: e.branch?.lat,
          // lng: Math.floor(Math.random() * (i + 1 * 1000)),
          lng: e.branch?.lng,
        }))
        .filter((e) => e.lat && e.lng)
    },
  },

  watch: {
    times_data() {
      // filter object to get required keys
      const splitKeys = Object.keys(this.add_data)
        .filter((e) => ['week_days', 'month_days'].includes(e))
        .map((e) => {
          let item = e.split(/[week|month]/gi).join('')
          return {
            [e]: e.replace(item, 'ly'),
          }
        })

      // Filter all the elements to get the value and the key required to empty the value of that key if it is not checked
      splitKeys.forEach((e) => {
        Object.entries(e).forEach(([key, value]) => {
          if (!this.times_data.includes(value)) {
            this.$set(this.add_data, key, [])
          }
        })
      })
    },
    'add_data.zone_id'(val) {
      if (val) {
        this.$set(this.add_data, 'region_id', null)
        this.$set(this.add_data, 'city_id', null)
      }
    },
    'add_data.client_id'(val) {
      if (val) {
        this.$set(this.add_data, 'supervisor_id', null)
        this.$set(this.add_data, 'user_id', null)
        this.$set(this.add_data, 'store_id', null)
      }
    },
  },
  async created() {
    Promise.all([
      this.getUsers(),
      this.getClients(),
      this.getBranches(),
      this.getStores(),
      this.getTasks(),
    ]).finally(() => (this.load = true))
  },
  methods: {
    async getTasks() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'tasks'
        )
        this.tasks = result.tasks
      } catch ({ result: result_1 }) {
        this.createAlert(result_1.message, 'error')
      }
    },
    async getBranches() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        this.branches = result.company_branches
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getStores() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'company_stores'
        )
        this.stores = result.company_stores
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getUsers() {
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'users',
          options: {
            role_type: ['merchandiser', 'supervisor'],
          },
        })
        this.users = result.users
        this.$set(
          this.add_data,
          'user_id',
          result.users.find((e) => e.id === parseInt(this.$route.query.user))
        )
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async getClients() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'clients'
        )
        this.clients = result.clients
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      }
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      let fd = new FormData()

      for (const key in this.add_data) {
        if (this.add_data.hasOwnProperty(key)) {
          const propValue = this.add_data[key]
          if (propValue) {
            if (
              [
                'month_days',
                'week_days',
                'task_ids',
                'company_branch_ids',
              ].includes(key)
            ) {
              if (propValue.length !== 0) {
                propValue.forEach((_, idx) => {
                  fd.append(
                    `${key}[${idx}]`,
                    propValue[idx]?.id || propValue[idx]
                  )
                })
              }
            } else if (
              typeof propValue === 'object' &&
              !Array.isArray(propValue)
            ) {
              fd.append(key, propValue?.id)
            } else {
              fd.append(key, propValue)
            }
          }
        }
      }

      // console.log(fd)
      try {
        const { data } = await this.axios.post('visits/add', fd)
        this.createAlert(data.message)
        this.add_data = {
          month_days: [],
          week_days: [],
          task_ids: [],
          company_branch_ids: [],
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    handleParentItem(e, id, type) {
      const tasks = this.tasks
      const task = tasks.find((e) => e.id === id)
      const isChecked = e.target.checked

      if (!task?.sub_tasks?.length) return
      const taskIds = task?.sub_tasks?.map((e) => e.id)
      if (isChecked) {
        this.$set(
          this[type],
          'task_ids',
          new Array().concat(this[type]?.task_ids, taskIds)
        )
      } else {
        this.$set(
          this[type],
          'task_ids',
          this[type]?.task_ids?.filter(
            (e) => !new Array().concat(id, taskIds).includes(e)
          )
        )
      }
    },
    handleChildItem(e, id, type) {
      const isChecked = e.target.checked
      const selectedTasks = this[type]?.task_ids

      if (isChecked) {
        if (selectedTasks.includes(id)) return
        this.$set(this[type], 'task_ids', new Array().concat(selectedTasks))
      } else {
        const parentSubTasks = this.tasks.find((e) => e.id === id)
        const siblingIds = parentSubTasks.sub_tasks.map((e) => e.id)
        const hasSiblingIds = siblingIds.some((e) =>
          new RegExp(selectedTasks.join('|')).test(e)
        )

        if (hasSiblingIds) return
        this.$set(
          this[type],
          'task_ids',
          selectedTasks.filter((e) => e !== id)
        )
      }
    },
    handleMarkerWhenHover(e, item) {
      console.log(e)
    },
  },
}
</script>
<style lang="scss">
.circle-progress {
  @apply transition-all ease-in-out;
  stroke-dasharray: 345;
  stroke-dashoffset: calc(345 - (345 * var(--i)) / 100);
  stroke-linecap: round;
}
</style>
