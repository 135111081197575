<template>
  <div>
    <section class="w-full px-6">
      <Table
        :has_header="true"
        :has_check="false"
        :has_customize="false"
        :title="$t('shifts')"
        :records="`${pagination.total || 0} ${$t('records')}`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div>
            <button
              class="
                py-1.5
                px-3
                text-sm
                flex
                items-center
                gap-2
                text-slate-600
                font-medium
                rtl:font-semibold
              "
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>{{ $t('add_new.shift') }}</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="
                  py-4
                  px-6
                  text-center
                  font-medium
                  rtl:font-semibold
                  text-sm
                  capitalize
                "
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                {{ $t(item.replace(/\s/g, '_')) }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('alt_name') }}</th>
          <th>{{ $t('shifts') }}</th>
          <th>
            <div class="sort">
              <div>
                <p>{{ $t('active') }}</p>
              </div>
              <div>
                <button
                  class="sort-btn"
                  @click.prevent="sortTable('is_active')"
                ></button>
              </div>
            </div>
          </th>
          <th></th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="4" v-for="i in 6" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length !== 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name || '...' }}</td>
            <td>{{ item.alt_name || '...' }}</td>
            <td>
              <div class="actions-group !flex-wrap min-w-[15rem]">
                <span
                  class="status text-slate-600 bg-slate-100"
                  v-for="(shift, index) in item.shifts"
                  :key="index"
                  >{{ $t(`week_days.${shift}`) }}</span
                >
                <!-- <span
                  class="status text-slate-600 bg-slate-100"
                  v-if="item.shifts.length > 1"
                  >+{{ item.shifts.length - 1 }}</span
                > -->
                <span class="text-slate-600" v-if="!item.shifts.length">-</span>
              </div>
            </td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
              </p>
            </td>
            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="$t('change_status')"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :disabled="!item.is_active"
                    :content="$t('update')"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #tbody v-else-if="load && items.length === 0">
          <tr>
            <td :colspan="4">
              <div class="table mx-auto my-4">
                <button
                  class="
                    py-1.5
                    px-3
                    text-sm
                    flex
                    items-center
                    gap-2
                    text-slate-600
                    font-medium
                    rtl:font-semibold
                  "
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.shift') }}</span>
                </button>
              </div>
              <p
                class="
                  text-sm text-slate-600
                  font-medium
                  rtl:font-semibold
                  text-center
                "
              >
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </template>
      </Table>
      <!-- <TableLoad  /> -->
    </section>
    <transition name="scale">
      <modal
        :title="$t('add_new.shift')"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e
          }
        "
      >
        <template>
          <form class="p-4 space-y-6">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="add_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  v-model="add_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.alt_name"
              >
                {{ add_errors.alt_name.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <p class="text-slate-600 font-medium rtl:font-semibold text-sm">
                {{ $t('shifts') }}
              </p>
              <ul class="list-none space-y-4 mt-4">
                <li
                  class="flex gap-4 flex-wrap"
                  v-for="(item, index) in days"
                  :key="index"
                >
                  <div class="form-group flex-1 space-y-2">
                    <div class="with-label">
                      <input
                        autocomplete="off"
                        type="time"
                        :name="`${item}_from`"
                        :id="`${item}_from`"
                        v-model="add_data[`day_${index + 1}_from`]"
                      />
                      <label :for="`${item}_from`"
                        >{{ $t(`week_days.${item}`) }} | {{ $t('from') }}</label
                      >
                    </div>
                    <p
                      class="text-red-500 font-medium text-xs mt-2"
                      v-if="add_errors[`day_${index + 1}_from`]"
                    >
                      {{ add_errors[`day_${index + 1}_from`].join(' ') }}
                    </p>
                  </div>
                  <div class="form-group flex-1 space-y-2">
                    <div class="with-label">
                      <input
                        autocomplete="off"
                        type="time"
                        :name="`${item}_to`"
                        :id="`${item}_to`"
                        v-model="add_data[`day_${index + 1}_to`]"
                      />
                      <label :for="`${item}_to`"
                        >{{ $t(`week_days.${item}`) }} | {{ $t('to') }}</label
                      >
                    </div>
                    <p
                      class="text-red-500 font-medium text-xs mt-2"
                      v-if="add_errors[`day_${index + 1}_to`]"
                    >
                      {{ add_errors[`day_${index + 1}_to`].join(' ') }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </modal>
    </transition>
    <transition name="scale">
      <modal
        :title="$t('update')"
        v-if="edit_active"
        @close="
          (e) => {
            edit_active = e
          }
        "
      >
        <template>
          <form class="p-4 space-y-6">
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  v-model="edit_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  autocomplete="off"
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  v-model="edit_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.alt_name"
              >
                {{ edit_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <p class="text-slate-600 font-medium rtl:font-semibold text-sm">
                {{ $t('shifts') }}
              </p>
              <ul class="list-none space-y-4 mt-4">
                <li
                  class="flex gap-4 flex-wrap"
                  v-for="(item, index) in days"
                  :key="index"
                >
                  <div class="form-group flex-1 space-y-2">
                    <div class="with-label">
                      <input
                        autocomplete="off"
                        type="time"
                        :name="`${item}_from`"
                        :id="`${item}_from`"
                        v-model="edit_data[`day_${index + 1}_from`]"
                      />
                      <label :for="`${item}_from`"
                        >{{ $t(`week_days.${item}`) }} | {{ $t('from') }}</label
                      >
                    </div>
                    <p
                      class="text-red-500 font-medium text-xs mt-2"
                      v-if="edit_errors[`day_${index + 1}_from`]"
                    >
                      {{ edit_errors[`day_${index + 1}_from`].join(' ') }}
                    </p>
                  </div>
                  <div class="form-group flex-1 space-y-2">
                    <div class="with-label">
                      <input
                        autocomplete="off"
                        type="time"
                        :name="`${item}_to`"
                        :id="`${item}_to`"
                        v-model="edit_data[`day_${index + 1}_to`]"
                      />
                      <label :for="`${item}_to`"
                        >{{ $t(`week_days.${item}`) }} | {{ $t('to') }}</label
                      >
                    </div>
                    <p
                      class="text-red-500 font-medium text-xs mt-2"
                      v-if="edit_errors[`day_${index + 1}_to`]"
                    >
                      {{ edit_errors[`day_${index + 1}_to`].join(' ') }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </modal>
    </transition>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
export default {
  components: { Modal, Table, TableLoad },
  name: 'Shifts',
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      add_disabled: false,
      add_errors: {},
      add_active: false,
      edit_data: {},
      edit_disabled: false,
      edit_errors: {},
      edit_active: false,
      days: ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'],
      pagination: {
        page: 1,
        is_active: 'active',
        sort_type: null,
        sort_key: null,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
  },
  async created() {
    await this.getItems()
  },
  methods: {
    async getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'shifts',
          options: {
            page,
            search_key,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
          },
        })
        const { data, pagination } = result.shifts
        const generateDays = data.map((e) => {
          const days = Object.entries(e)
            .filter(([key]) => /day_\w+_from/gi.test(key))
            .filter(([_, value]) => value)

          return {
            ...e,
            shifts_count: days.length,
            shifts: days.map(
              ([key]) => this.days[parseInt(key.match(/\d/gi).join('')) - 1]
            ),
          }
        })
        this.items = generateDays.map((e) => {
          return {
            ...e,
          }
        })
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.load = true
      }
      // .finally(() => {
      //   this.load = true
      // })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      let item = this.add_data
      let result = {}

      for (const key in item) {
        if (/day_\w+/gi.test(key)) {
          if (item[key]) {
            result[key] = item[key] + ':00'
          }
        } else {
          result[key] = item[key]
        }
      }
      try {
        const { data } = await this.axios.post('shifts/add', {
          ...result,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      let item = this.edit_data
      let editResult = {}

      for (const key in item) {
        if (/day_\w+/gi.test(key)) {
          if (item[key]) {
            if (item[key].split(':').length !== 3) {
              editResult[key] = item[key] + ':00'
            } else {
              editResult[key] = item[key]
            }
          }
        } else {
          editResult[key] = item[key]
        }
      }

      try {
        const { data } = await this.axios.post('shifts/update', {
          ...editResult,
        })
        const { message, result } = data
        const finalResult = result.shift ?? {}
        this.getItems()
        this.createAlert(message)
        this.edit_active = false
        this.edit_data = {
          ...finalResult,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },

    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { data } = await this.axios.post(`shifts/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`shifts/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
}
</script>
