<template>
  <div>
    <sub-pages-header :title="(item.company_branch || {}).name">
      <div class="table p-6 mx-auto" v-if="!load">
        <spinner size="w-8 h-8" class="align-sub text-primary" />
      </div>
      <ContainerWrapper :reports="visit_tabs_data" v-else>
        <section class="w-full px-6">
          <div
            class="w-full flex flex-col-reverse lg:flex-row gap-4"
            v-if="!componentTab"
          >
            <aside class="w-full lg:w-1/3 space-y-4">
              <div class="w-full grid gap-4 grid-cols-2">
                <figure
                  class="w-full bg-white ring-1 ring-slate-200 rounded-md text-center p-6 border-inline-start"
                  :class="
                    item.check_in_other_location
                      ? 'border-red-600'
                      : 'border-sky-600'
                  "
                >
                  <h1 class="text-lg text-primary font-bold">
                    {{ item.check_in_time || '--:--' }}
                  </h1>
                  <p class="text-sm text-slate-600 font-semibold rtl:font-bold">
                    {{ $t('start_date') }}
                  </p>
                </figure>
                <figure
                  class="w-full bg-white ring-1 ring-slate-200 rounded-md text-center p-6 border-inline-start"
                  :class="
                    item.check_out_other_location
                      ? 'border-red-600'
                      : 'border-sky-600'
                  "
                >
                  <h1 class="text-lg text-primary font-bold">
                    {{ item.check_out_time || '--:--' }}
                  </h1>
                  <p class="text-sm text-slate-600 font-semibold rtl:font-bold">
                    {{ $t('end_date') }}
                  </p>
                </figure>
              </div>
              <figure class="w-full bg-white ring-1 ring-slate-200 rounded-md">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('merchandiser') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <ul class="list-none space-y-3">
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-user mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('name') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ (item.user || {}).name || '...' }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-at mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('username') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`mailto:${
                              (item.user || {}).username || '...'
                            }`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ (item.user || {}).username || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-phone mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('mobile') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`tel:${(item.user || {}).mobile || '...'}`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ (item.user || {}).mobile || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </blockquote>
              </figure>
              <figure class="w-full bg-white ring-1 ring-slate-200 rounded-md">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('supervisor') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <ul class="list-none space-y-3">
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-user mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('name') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ (item.supervisor || {}).name || '...' }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-at mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('username') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`mailto:${
                              (item.supervisor || {}).username || '...'
                            }`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ (item.supervisor || {}).username || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full">
                        <div class="w-1/2 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-phone mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('mobile') }}
                          </p>
                        </div>
                        <div class="w-1/2 p-1.5">
                          <a
                            :href="`tel:${
                              (item.supervisor || {}).mobile || '...'
                            }`"
                            class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                            >{{ (item.supervisor || {}).mobile || '...' }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </blockquote>
              </figure>
            </aside>
            <main class="w-full lg:w-2/3 space-y-4">
              <cards-container :cards="filterCards" />

              <div class="table [margin-inline-start:auto]">
                <ul class="actions-group">
                  <li>
                    <button
                      class="py-2.5 sm:py-1.5 px-3 text-sm flex items-center gap-2 text-slate-600 font-medium rtl:font-semibold ring-1 ring-slate-200 bg-white"
                    >
                      <i class="fa-solid fa-cloud-arrow-down"></i>
                      <span class="hidden sm:inline">{{
                        $t('export_pdf')
                      }}</span>
                    </button>
                  </li>
                  <li>
                    <button
                      class="py-2.5 sm:py-1.5 px-3 rounded text-sm flex items-center gap-2 text-white bg-primary font-medium rtl:font-semibold"
                    >
                      <i class="fa-solid fa-envelope-open-text"></i>
                      <span class="hidden sm:inline">{{
                        $t('send_email')
                      }}</span>
                    </button>
                  </li>
                </ul>
              </div>
              <figure class="w-full bg-white ring-1 ring-slate-200 rounded-md">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('visit_details') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <ul class="list-none space-y-3">
                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-right-left mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('status') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5">
                          <p
                            class="status capitalize"
                            :class="styles[item.status]"
                          >
                            {{
                              ['missed', 'skipped'].includes(item.status)
                                ? item.is_scheduled
                                  ? $t('scheduled') + ' - '
                                  : $t('extra_mission') + ' - '
                                : null
                            }}
                            {{ item.status ? $t(item.status) : '...' }}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-store mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('store') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5 text-sm">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ (item.company_store || {}).name }} -
                            {{ (item.company_store || {}).alt_name }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-shop mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('branch') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5 text-sm">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ (item.company_branch || {}).name }} -
                            {{ (item.company_branch || {}).alt_name }}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-location-dot mr-2 rtl:mr-0 rtl:ml-2"
                              :class="
                                item.check_in_other_location
                                  ? 'text-red-500'
                                  : 'text-sky-600'
                              "
                            ></i>
                            {{ $t('in_location') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5">
                          <a
                            :href="`https://maps.google.com/maps/?q=${item.check_in_lat},${item.check_in_lng}`"
                            target="_blank"
                            rel="noopener noreferrer"
                            v-if="item.check_in_lat || item.check_in_lng"
                            class="0 underline text-sm"
                            :class="
                              item.check_in_other_location
                                ? 'text-red-500'
                                : 'text-sky-600'
                            "
                          >
                            {{ item.check_in_lat }} - {{ item.check_in_lng }}
                          </a>
                          <p
                            class="text-xs text-slate-600 italic font-medium rtl:font-semibold"
                            v-else
                          >
                            {{ $t('location_empty') }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-location-dot mr-2 rtl:mr-0 rtl:ml-2"
                              :class="
                                item.check_out_other_location
                                  ? 'text-red-500'
                                  : 'text-sky-600'
                              "
                            ></i>
                            {{ $t('out_location') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5">
                          <a
                            :href="`https://maps.google.com/maps/?q=${item.check_out_lat},${item.check_out_lng}`"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="underline text-sm"
                            :class="
                              item.check_out_other_location
                                ? 'text-red-500'
                                : 'text-sky-600'
                            "
                            v-if="item.check_out_lat || item.check_out_lng"
                          >
                            {{ item.check_out_lat }} - {{ item.check_out_lng }}
                          </a>
                          <p
                            class="text-xs text-slate-600 italic font-medium rtl:font-semibold"
                            v-else
                          >
                            {{ $t('location_empty') }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="w-full">
                      <div class="flex w-full flex-wrap">
                        <div class="w-full xsm:w-1/3 p-1.5">
                          <p
                            class="text-sm text-slate-500 font-medium rtl:font-semibold"
                          >
                            <i
                              class="fa-solid fa-clock mr-2 rtl:mr-0 rtl:ml-2"
                            ></i>
                            {{ $t('visit_date') }}
                          </p>
                        </div>
                        <div class="w-full xsm:w-2/3 p-1.5">
                          <p class="text-sm font-medium rtl:font-semibold">
                            {{ item.visit_date }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </blockquote>
              </figure>
              <figure class="w-full bg-white ring-1 ring-slate-200 rounded-md">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('required_tasks') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <ul
                    class="w-full list-none space-y-4 overflow-y-auto max-h-[20rem]"
                    id="tasks"
                  >
                    <li
                      class="form-group-with-check bg-slate-50 p-4 rounded-md"
                      v-for="(task, index) in item.tasks"
                      :key="index"
                    >
                      <label
                        :for="task.name.split(' ').join('-').toLowerCase()"
                        class="flex-1"
                      >
                        <div class="flex items-center gap-3">
                          <div>
                            <i
                              class="fa-solid fa-circle-check text-slate-400 fa-lg"
                            ></i>
                          </div>
                          <!-- <div>
                        <input autocomplete="off"
                          type="checkbox"
                          :name="task.name.split(' ').join('-').toLowerCase()"
                          :id="task.name.split(' ').join('-').toLowerCase()"
                          :value="task.id"
                          v-model="add_data.task_ids"
                        />
                      </div> -->
                          <p class="flex-1">
                            {{ task.name }} - {{ task.alt_name }}
                          </p>
                        </div>
                      </label>
                    </li>
                    <li v-if="(item.tasks || []).length === 0">
                      <div
                        class="flex items-center justify-center flex-col gap-6"
                      >
                        <div>
                          <div
                            class="w-20 h-20 flex items-center justify-center bg-slate-100 rounded-full text-slate-500"
                          >
                            <i class="fa-solid fa-list-ol fa-xl"></i>
                          </div>
                        </div>
                        <div class="flex-1 space-y-4 text-center">
                          <h1 class="text-slate-600 font-bold">
                            {{ $t('no_data') }}
                          </h1>
                        </div>
                      </div>
                    </li>
                  </ul>
                </blockquote>
              </figure>
              <figure class="w-full bg-white ring-1 ring-slate-200 rounded-md">
                <header class="w-full border-b border-slate-100 p-4">
                  <p class="text-sm font-semibold rtl:font-bold text-slate-600">
                    {{ $t('notes') }}
                  </p>
                </header>
                <blockquote class="p-6">
                  <p
                    class="text-sm font-semibold rtl:font-bold text-slate-600"
                    v-if="item.notes"
                  >
                    {{ item.notes }}
                  </p>
                  <div
                    class="flex items-center justify-center flex-col gap-6"
                    v-else
                  >
                    <div>
                      <div
                        class="w-20 h-20 flex items-center justify-center bg-slate-100 rounded-full text-slate-500"
                      >
                        <i class="fa-solid fa-note-sticky fa-xl"></i>
                      </div>
                    </div>
                    <div class="flex-1 space-y-4 text-center">
                      <h1 class="text-slate-600 font-bold">
                        {{ $t('no_data') }}
                      </h1>
                    </div>
                  </div>
                </blockquote>
              </figure>
            </main>
          </div>
          <div v-else>
            <component
              :is="componentTab"
              v-model="pagination"
              :load="component_load"
              :result="items"
              :summary="summary"
            />
          </div>
        </section>
      </ContainerWrapper>
    </sub-pages-header>
  </div>
</template>

<script>
import ContainerWrapper from '@/components/visits/ContainerWrapper.vue'
import reportsComponent from '@/components/reports/index'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from '@/components/reports/components/CardsContainer.vue'
import SubPagesHeader from '@/components/basics/SubPagesHeader.vue'

export default {
  name: 'SingleVisit',
  components: {
    ContainerWrapper,
    CardsContainer,
    SubPagesHeader,
    ...reportsComponent,
  },
  data() {
    return {
      styles: {
        done: 'bg-sky-500 text-white',
        missed: 'bg-red-500 text-white',
        pending: 'bg-slate-400 text-white',
        extra_mission: 'bg-green-600 text-white',
        extra_mission_done: 'bg-yellow-500 text-white',
        added_by_user: 'bg-green-800 text-white',
        skipped: 'bg-pink-800 text-white',
        cancelled: 'bg-red-600 text-white',
        scheduled: 'bg-slate-200 text-slate-600',
      },
      item: {},
      load: false,
      component_load: false,
      pagination: {
        page: 1,
        sort_key: null,
        sort_type: null,
      },
      items: [],
      visit_tabs_data: {},
      summary: {},
      cards: [
        {
          title: 'Total SKUs',
          value: 0,
          key: 'skus_count',
          translate_key: 'skus',
          icon: 'fa-solid fa-basket-shopping',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'sku-audits',
            },
          },
          style: {
            icon: 'text-yellow-600',
            parent: 'bg-yellow-600/20',
          },
        },
        {
          title: 'Available SKUs',
          value: 0,
          key: 'available_count',
          translate_key: 'available',
          icon: 'fa-solid fa-arrow-trend-up',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'sku-audits',
              is_available: 1,
            },
          },
          style: {
            icon: 'text-blue-500',
            parent: 'bg-blue-500/20',
          },
        },
        {
          title: 'Out of stock',
          value: 0,
          key: 'out_of_stock_count',
          translate_key: 'out_of_stock',
          icon: 'fa-solid fa-arrow-trend-down',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'sku-audits',
              is_available: 0,
            },
          },
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
        {
          title: 'OSA Reports',
          value: 0,
          key: 'osa_report',
          translate_key: 'osa_reports',
          icon: 'fa-solid fa-chart-simple',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'sku-audits',
            },
          },
          style: {
            icon: 'text-orange-500',
            parent: 'bg-orange-500/20',
          },
        },

        {
          title: 'Total returns',
          value: 0,
          key: 'returns_count',
          translate_key: 'returns',
          icon: 'fa-solid fa-right-left',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'return-audits',
            },
          },
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
        {
          title: 'My promotions',
          value: 0,
          key: 'my_promotions_count',
          translate_key: 'my_promotions',
          icon: 'fa-solid fa-hashtag',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Competitors',
          value: 0,
          key: 'competitors_count',
          translate_key: 'competitors',
          icon: 'fa-solid fa-user-group',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Images before',
          value: 0,
          key: 'images_before_count',
          translate_key: 'images_before',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'planogram-audits',
            },
          },
          icon: 'fa-solid fa-images',
          style: {
            icon: 'text-slate-500',
            parent: 'bg-slate-500/20',
          },
        },
        {
          title: 'Image after',
          value: 0,
          key: 'images_after_count',
          translate_key: 'images_after',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'planogram-audits',
            },
          },
          icon: 'fa-solid fa-images',
          style: {
            icon: 'text-teal-500',
            parent: 'bg-teal-500/20',
          },
        },
        {
          title: 'Total Expired',
          value: 0,
          key: 'expiries_count',
          translate_key: 'expiries',
          icon: 'fa-solid fa-arrow-trend-down',
          path: {
            name: 'SingleVisit',
            query: {
              tab: 'expiry-audits',
            },
          },
          style: {
            icon: 'text-red-500',
            parent: 'bg-red-500/20',
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('table', ['page', 'is_called', 'table_paginate', 'search']),
    ...mapGetters('auth', ['tasks']),
    id() {
      return this.$route.params?.visit_id
    },
    hasIsAvailable() {
      return /sku-audits/gi.test(this.$route.query.tab)
        ? this.$route.query.is_available
          ? this.$route.query.is_available
            ? 'available'
            : 'not_available'
          : null
        : null
    },
    componentTab() {
      const tab = this.$route.query.tab
      const selectedTabData = this?.visit_tabs_data[tab?.replace(/(-)/g, '_')]
      this.pagination = {
        // page: 1,
        sort_key: null,
        sort_type: null,
        is_available: this.hasIsAvailable,
        visit_id: this.id,
      }
      this.items = Array.isArray(selectedTabData)
        ? selectedTabData.map((e) => {
            return {
              ...e,
              ...(Object.prototype.hasOwnProperty.call(e, 'images_after')
                ? {
                    images: new Array().concat(e.images_before, e.images_after),
                  }
                : {}),
              visit_date: this.item.visit_date,
            }
          })
        : selectedTabData?.data.map((e) => {
            return {
              ...e,
              ...(Object.prototype.hasOwnProperty.call(e, 'images_after')
                ? {
                    images: new Array().concat(e.images_before, e.images_after),
                  }
                : {}),
              visit_date: this.item.visit_date,
            }
          }) ?? []

      this.summary = this?.visit_tabs_data?.summary || {}
      this.$set(
        this.pagination,
        'total',
        selectedTabData?.pagination?.total ?? 0
      )
      this.$set(
        this.pagination,
        'total_pages',
        selectedTabData?.pagination?.total_pages ?? 0
      )
      return /planogram-audits/gi.test(tab) ? 'planogram-audits-2' : tab
    },
    EndPointName() {
      return this.componentTab === 'planogram-audits-2'
        ? 'planogram_audits'
        : this.componentTab?.replace(/(-)/g, '_')
    },
    filterCards() {
      const tasks = this.$store.getters['auth/tasks']
      const filterPromotions = this.cards.filter((card) => {
        return tasks?.some((task) =>
          new RegExp('(' + task.type?.split('_')?.join('|') + ')', 'gi').test(
            card.key
          )
        )
          ? card
          : false
      })
      const items = this.cards.filter(
        (card) => !/promotion|competitors/gi.test(card.key)
      )

      return new Array().concat(items, filterPromotions).map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },
  watch: {
    page() {
      this.getItems()
      // handler() {
      // },
      // immediate: true,
    },
    is_called() {
      if (this.is_called) {
        this.getItems()
      }
    },
    $route: {
      handler(e) {
        this.getQueriesFromRoute(e)
      },
      immediate: true,
    },
  },

  async created() {
    await this.getItem().finally(() => this.getQueriesFromRoute())
  },
  methods: {
    ...mapMutations('table', [
      'changeApply',
      'changeReset',
      'changeIsCalled',
      'updateSearch',
    ]),
    ...mapMutations('customize', ['setRouteName']),
    async getItem() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          `/visits/${this.id}`
        )
        let { visit, ...anotherItems } = result
        visit = {
          ...visit,
          company_store: visit?.company_branch?.company_store ?? {},
        }
        this.item = visit ?? {}
        // this.setRouteName(visit?.company_branch?.name)
        // change current page name by branch name
        // this.generatePageTitle(visit?.company_branch?.name)
        this.visit_tabs_data = anotherItems
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
        this.$router.go(-1)
      } finally {
        this.component_load = true
        this.load = true
      }
    },
    getItems() {
      const _is_available = this.hasIsAvailable
      const { is_active, is_available, ...others } = this.pagination
      const concatAvailability = is_available || _is_available
      const tableAvailability = this.table_paginate['is_available']
      return this.$store
        .dispatch('getting/getRoute', {
          name: `reports/${this.EndPointName}`,
          options: {
            page: this.page || 1,
            search_key: this.search,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            is_available: !Object.prototype.hasOwnProperty.call(
              this.table_paginate,
              'is_available'
            )
              ? concatAvailability
                ? concatAvailability === 'available'
                  ? 1
                  : 0
                : concatAvailability
              : tableAvailability,
            // visit_id: typeof visit_id !== 'object' ? visit_id : visit_id?.id,
            visit_id: this.$route.params.visit_id,
            // ...others,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result[this.EndPointName]
          this.items = data.map((e) => {
            return {
              ...e,
              visit_date: this.item.visit_date,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
          this.summary = result?.summary || {}
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.component_load = true
          this.changeApply(false)
          this.changeReset(false)
          this.changeIsCalled(false)
        })
    },

    getQueriesFromRoute(e) {
      const val = e || this.$route
      let result = {},
        assign_result = {}
      const keys = ['is_available']
      const queries = val.query
      const notOnlyTab = Object.keys(queries).some((key) =>
        /^((?!tab).)*$/gm.test(key)
      )

      if (notOnlyTab) {
        for (const key of keys) {
          if (Object.prototype.hasOwnProperty.call(queries, key)) {
            const hasIs = /is_\w+/gi.test(key)

            result[key] = hasIs
              ? typeof queries[key] === 'number'
                ? queries[key]
                  ? key.replace(/is_/gi, '')
                  : key.replace(/is_/gi, 'not_')
                : null
              : queries[key]
            assign_result = Object.assign({}, this.pagination, result)
            // this.pagination = {}
          } else {
            result[key] = null
            assign_result = Object.assign({}, this.pagination, result)
          }
        }
        // console.log(assign_result);
        this.pagination = assign_result
        this.getItems()
      }
    },
  },
}
</script>
