<template>
  <div
    class="
      fixed
      bottom-0
      right-0
      rtl:right-auto rtl:left-0
      sm:p-5
      z-[21]
      flex flex-col
      gap-4
      items-end
      pointer-events-none
      w-full
      sm:w-auto
    "
    :class="{
      'p-5': !active,
    }"
  >
    <transition name="open">
      <figure
        class="
          w-full
          sm:w-screen sm:max-w-sm sm:h-[35rem] sm:max-h-[calc(100vh-200px)]
          bg-white
          sm:rounded-2xl
          overflow-hidden
          border border-slate-200
          flex flex-col
          pointer-events-auto
          h-screen
        "
        v-if="active"
      >
        <header class="w-full p-4 bg-gradient-to-tr from-primary to-primary/70">
          <div class="flex items-center gap-3">
            <div>
              <div
                class="
                  w-10
                  h-10
                  rounded-full
                  bg-white
                  flex
                  items-center
                  justify-center
                "
              >
                <i class="fa-solid fa-user text-slate-500"></i>
              </div>
            </div>
            <div class="flex-1">
              <p class="text-sm text-white font-medium">Live chat</p>
            </div>
            <button @click.prevent="active = false">
              <i class="fa-solid fa-minus text-white text-xl"></i>
            </button>
          </div>
        </header>
        <blockquote
          class="flex-1 overflow-y-auto space-y-2 p-4 chat-box"
          @scroll="scrollMessages"
        >
          <div class="space-y-1" v-if="load && chat.length !== 0">
            <SingleMessage
              v-for="(item, idx) in chat"
              :key="idx"
              :message="item"
              :prev="[idx == 0 ? null : chat[idx - 1]]"
            />
          </div>
          <div class="py-4" v-if="load && chat.length === 0">
            <img
              src="@/assets/images/support.png"
              alt="support image"
              class="w-36 h-auto mx-auto table mb-3"
            />
            <h1 class="text-xl text-slate-800 font-bold text-center">
              Welcome to the technical support chat
            </h1>
            <p
              class="
                text-slate-500 text-sm text-center
                mt-3
                font-medium
                rtl:font-semibold
              "
            >
              Hey, {{ ($store.state.auth.user_info || {}).name.split(' ')[0] }},
              you You can contact technical support if you encounter any problem
              during follow-up The activity of your online store and you will be
              contacted by one of the service representatives Our technical
              support.
            </p>
            <p
              class="
                text-slate-500 text-xs text-center
                mt-6
                font-medium
                rtl:font-semibold
              "
            >
              Double click on the message to show the time of the message.
            </p>
            <div class="table w-3/5 mx-auto space-y-2 mt-4">
              <div class="h-10 w-full rounded-md bg-slate-100"></div>
              <div class="h-3 w-10 rounded-md bg-slate-200"></div>
            </div>
          </div>
          <div class="space-y-2" v-if="!load">
            <div
              class="
                h-10
                w-4/5
                rounded-md
                bg-slate-100
                animate-pulse
                odd:ml-auto odd:w-2/5
              "
              v-for="i in 2"
              :key="i"
            ></div>
          </div>
        </blockquote>
        <footer class="w-full p-4 border-t border-slate-100">
          <form class="w-full">
            <div
              class="
                w-full
                flex
                items-center
                p-2
                border-2 border-slate-100
                rounded-lg
              "
            >
              <div class="flex-1">
                <input
                  autocomplete="off"
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Type your message here.."
                  class="
                    w-full
                    h-full
                    placeholder-slate-500
                    text-sm
                    font-medium
                    px-2
                    !ring-0 !ring-offset-0
                  "
                  v-model="add_data.body"
                />
              </div>
              <div>
                <button
                  class="
                    w-8
                    h-8
                    rounded-full
                    bg-gradient-to-tr
                    from-primary
                    to-primary/70
                    text-xs text-white
                  "
                  :disabled="add_disabled || !add_data.body"
                  @click.prevent="sendMessage"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="add_disabled"
                  ></i>
                  <i class="fa-solid fa-paper-plane" v-else></i>
                </button>
              </div>
            </div>
          </form>
        </footer>
      </figure>
    </transition>
    <button
      class="
        w-12
        h-12
        rounded-full
        bg-gradient-to-tr
        from-primary
        to-primary/70
        border border-slate-200
        flex
        items-center
        justify-center
        relative
        pointer-events-auto
      "
      :class="{
        'hidden sm:block': active,
      }"
      @click.prevent="openLiveChat"
      data-tour-name="support"
    >
      <i class="fa-solid fa-headset text-xl text-white"></i>
      <span
        class="
          w-full
          h-full
          bg-primary/50
          rounded-full
          animate-ping
          absolute
          top-0
          right-0
          z-[-1]
        "
        v-if="sender"
      ></span>
    </button>
    <transition name="open">
      <div
        class="
          absolute
          bottom-4
          right-20
          p-4
          bg-white
          rounded-lg
          border border-slate-1
          max-w-[14rem]
        "
        v-if="sender"
      >
        <h1 class="text-slate-800 font-bold">
          {{ (chat[chat.length - 1].admin || {}).name }}
        </h1>
        <p class="line-clamp-2 text-slate-600 text-sm font-medium">
          {{ chat[chat.length - 1].body }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import SingleMessage from '@/components/basics/SingleMessage'
import { mapGetters } from 'vuex'
export default {
  name: 'SupportChat',
  data() {
    return {
      chat: [],
      messages: [],
      load: false,
      active: false,
      add_data: {
        body: '',
      },
      add_disabled: false,
      sender: false,
      startAt: 0,
      endAt: 20,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  watch: {
    getUser() {
      if (this.getUser?.id) {
        this.checkUser()
      }
    },
  },

  methods: {
    checkUser() {
      const token = this.$cookies.get('t') || this.$store.state.auth.token
      if (token) {
        this.getMessages()
        const channel = this.$echo?.private(`user-${this.getUser?.id}-channel`)
        channel?.listen('.support_messages', ({ support_message }) => {
          if (!this.active) {
            this.sender = true
          }
          this.chat.push(support_message)
          new Audio(require('@/assets/sounds/message.mp3')).play()
          const chat = document.querySelector('.chat-box')
          if (support_message && chat) {
            setTimeout(() => {
              if (this.chat.length > 0) {
                chat.scrollTop = chat.scrollHeight
              }
            }, 5)
          }
        })
      }
    },
    getMessages() {
      return this.axios
        .get('support_messages')
        .then(({ data }) => {
          if (data.error_flag === 0) {
            let messages = data.result.chat_messages
            this.messages = messages
            this.endAt = messages.length > 25 ? 25 : messages.length
            this.chat = this.messages.slice(this.startAt, this.endAt).reverse()
          }
        })
        .finally(() => {
          this.load = true
          const chat = document.querySelector('.chat-box')
          if (chat) {
            setTimeout(() => {
              if (this.chat.length > 0) {
                chat.scrollTop = chat.scrollHeight
              }
            }, 5)
          }
        })
    },
    getSingleContact() {},
    sendMessage() {
      this.add_disabled = true

      return this.axios
        .post('support_messages/send', this.add_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = {
              body: '',
            }
            this.getMessages().then(() => {
              setTimeout(() => {
                if (this.chat.length > 0) {
                  const chat = document.querySelector('.chat-box')
                  chat.scrollTop = chat.scrollHeight
                }
              }, 5)
            })
          }
        })
        .finally(() => {
          this.add_disabled = false
        })
    },
    openLiveChat() {
      this.active = true
      setTimeout(() => {
        if (this.chat.length > 0) {
          const chat = document.querySelector('.chat-box')
          chat.scrollTop = chat.scrollHeight
        }
      }, 5)

      if (this.sender) {
        this.sender = false
      }
    },
    scrollMessages(e) {
      let currentScrollTop = e.target.scrollTop
      if (currentScrollTop === 0) {
        if (this.endAt === this.messages.length) {
          this.endAt = this.messages.length
        }
        if (this.messages.length > this.endAt) {
          this.endAt =
            this.messages.length - this.endAt < 25
              ? this.endAt + this.messages.length - this.endAt
              : this.endAt + 25
          console.log(this.endAt)
        }
        this.chat = this.messages.slice(this.startAt, this.endAt).reverse()
      }
    },
  },
  components: { SingleMessage },
}
</script>
<style>
.open-enter-active,
.open-leave-active {
  @apply transition-all duration-300 transform translate-y-0  ease-in-out scale-100;
}
.open-enter, .open-leave-to /* .fade-leave-active below version 2.1.8 */ {
  @apply translate-x-2 opacity-0 scale-95  translate-y-10;
}
</style>
