export default {
  dashboard: 'Dashboard',
  calendar: 'Calendar',
  users: 'Users',
  user_visits: 'User visits',
  user: 'User',
  new_visit: 'New visit',
  reports: 'Reports',
  visits: 'Visits',
  visit: 'Visit',
  skus: 'SKUs',
  expiry: 'Expiry',
  expiries: 'Expiries',
  stock_audits: 'Stock',
  shelf_audits: 'Shelf',
  delivery_audits: 'Delivery',
  shelf: 'Shelf',
  returns: 'Returns',
  promotion: 'Promotion',
  planogram: 'Planogram',
  setup: 'Setup',
  journey_plans: 'Journey plans',
  configuration: 'Configuration',
  shelves: 'Shelves',
  planograms: 'Planograms',
  teams: 'Teams',
  permissions: 'Permissions',
  stores: 'Chains',
  store: 'Chain',
  branches: 'Stores',
  branch: 'Store',
  brands: 'Brands',
  brand: 'Brand',
  categories: 'Categories',
  category: 'Category',
  sub_categories: 'Sub categories',
  sub_category: 'Sub category',
  store_placements: 'Store placements',
  store_placement: 'Store placement',
  units: 'Units',
  unit: 'Unit',
  notifications: 'Notifications',
  messages: 'Messages',
  regions: 'Regions',
  region: 'Region',
  cities: 'Cities',
  city: 'City',
  expiry_skus: 'Expiry SKUs',
  out_of_stock: 'Out of stock',
  supervisors: 'Supervisors',
  supervisor: 'Supervisor',
  leaders: 'Leaders',
  leader: 'Leader',
  sku: 'SKU',
  current_week_visits: 'Current week visits',
  today_visits_per_status: 'Today visits per status',
  visits_per_day: 'Visits per day',
  total_visits: 'Total visits',
  total_branches: 'Total stores',
  visit_summary: 'Visits summary',
  recent_visits: 'Recent visits',
  done: 'Done',
  not_done: 'Not done',
  missed: 'Missed',
  pending: 'Pending',
  extra_mission: 'Extra mission',
  extra_mission_done: 'Extra mission done',
  added_by_user: 'Added by user',
  skipped: 'Skipped',
  cancelled: 'Cancelled',
  scheduled: 'Scheduled',
  active: 'Active',
  not_active: 'Not active',
  available: 'Available',
  not_available: 'Not available',
  yes: 'Yes',
  no: 'No',
  customize: 'Customize',
  download: 'Download',
  send_email: 'Send via email',
  filter: 'Filter',
  add_new: {
    category: 'New category',
    sub_category: 'New sub category',
    store: 'New chain',
    branch: 'New store',
    brand: 'New brand',
    team: 'New team',
    user: 'New user',
    merchandiser: 'New merchandiser',
    client: 'New client',
    supervisor: 'New supervisor',
    visit: 'New visit',
    sku: 'New sku',
    shelf: 'New shelf',
    planogram: 'New planogram',
    new: 'Add new',
    now: 'Add now',
    role: 'New role',
    shift: 'New shift',
    unit: 'New unit',
    store_placement: 'New store placement',
    zone: 'New zone',
    reason: 'New reason',
    city: 'New city',
    group: 'New group',
    competitor: 'New competitor',
  },
  update: 'Update',
  assign_branches: 'Assign to stores',
  assign_units: 'Assign units',
  assign_info: 'Assignee info',
  client_info: 'Client info',
  assignee: 'Assignee',
  delete: 'Delete',
  add: 'Add',
  save: 'Save',
  save_changes: 'Save changes',
  submit: 'Submit',
  cancel: 'Cancel',
  name: 'Name',
  alt_name: 'Alt name',
  email: 'E-mail address',
  mobile: 'Mobile',
  barcode: 'Barcode',
  selling_price: 'Selling price',
  value: 'Value',
  actual_value: 'Actual value',
  tasks: 'Tasks',
  required_tasks: 'Required tasks',
  visit_date: 'Visit date',
  created_at: 'Created date',
  updated_at: 'Last update',
  start_date: 'Check in',
  production_date: 'Production date',
  end_date: 'Check out',
  return_date: 'Return date',
  expiry_date: 'Expiry date',
  joined_date: 'Joined date',
  qty: 'Quantity',
  in_location: 'In location',
  out_location: 'Out location',
  search: 'Search...',
  status: 'Status',
  create_new: {
    category: 'Create new category',
    sub_category: 'Create new sub category',
    store: 'Create new chain',
    branch: 'Create new store',
    brand: 'Create new brand',
    team: 'Create new team',
    user: 'Create new user',
    visit: 'Create new visit',
    sku: 'Create new sku',
    shelf: 'Create new shelf',
    planogram: 'Create new planogram',
  },
  currency: {
    sar: 'SAR',
  },
  next: 'Next',
  prev: 'Previous',
  view_details: 'View details',
  notes: 'Notes',
  reason: 'Reason',
  apply: 'Apply',
  reset: 'Reset',
  image: 'Image',
  images: 'Images',
  images_before: 'Images before',
  images_after: 'Images after',
  type: 'Type',
  visit_type: 'Visit type',
  change_status: 'Change status',
  view: 'View',
  view_update: 'View and update',
  listed: 'Listed stores',
  manager_mobile: 'Store manager mobile',
  address: 'Address',
  lat: 'latitude',
  lng: 'longitude',
  lat_lng: 'latitude and longitude',
  logout: 'Logout',
  edit: 'Edit',
  edit_profile: 'Edit profile',
  window_width: 'Window width',
  view_all: 'View all',
  all: 'All',
  langs: {
    ar: 'Arabic',
    en: 'English',
  },
  emails: 'E-mail addresses',
  customize_excel: 'Customize EXCEL columns',
  customize_table: 'Customizing table',
  total_skus: 'Total SKUs',
  total_visits: 'Total visits',
  records: 'Records',
  password: 'Password',
  new_password: 'New password',
  current_password: 'Current password',
  generate_password: 'Generate password',
  force_password: 'Force to change password',
  multi_client: 'Multi clients',
  no_data: 'There are no data to display',
  role: 'Role',
  username: 'Username',
  merchandiser: 'Merchandiser',
  merchandisers: 'Merchandisers',
  supervisor: 'Supervisor',
  super_user: 'Super user',
  client: 'Client',
  clients: 'Clients',
  administration: 'Administration',
  week_days: {
    SAT: 'SAT',
    SUN: 'SUN',
    MON: 'MON',
    TUE: 'TUE',
    WED: 'WED',
    THU: 'THU',
    FRI: 'FRI',
  },
  visit_time: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    weekly: 'Weekly',
    monthly: 'Monthly',
  },
  weeks: {
    1: '1st week',
    2: '2th week',
    3: '3th week',
    4: '4th week',
  },
  choose_image: 'Choose image',
  choose_photo: 'Choose photo',
  profile_photo: 'Profile photo',
  all_selected: 'All items selected',
  email_note: 'Exit the account will be logged out if you change the email',
  visits_no_data: 'There are no visits on that day',
  user_no: 'There are no users to display',
  user_no_content: `There are no users to display their visits, so you can add a
                  new visit and choose the required store and then select the
                  visits, click the button below to go to the new visit page`,
  completed: 'Completed',
  login: 'Login',
  copyRight: 'Copyright all rights reserved by',
  cost: 'Cost',
  add_info_visit: 'Do you want to add a new visit,',
  location_empty: 'The location has not been determined by the user yet',
  select_all: 'Select all',
  roles: 'Roles',
  role: 'Role',
  images: 'Images',
  visit_details: 'Visit details',
  details: 'Details',
  no_images: 'There are no images',
  profile: 'Profile',
  skus_amount: 'SUKs amount',
  returns_amount: 'Returns amount',
  expiry_amount: 'Expiries amount',
  nearly_expired: 'Nearly expired',
  near_expiring_amount: 'Amount near expiring',
  from: 'From',
  to: 'To',
  allow_camera: 'Allow photos from Gallery',
  allow_add_branch: 'Allow add stores',
  client_added_title: 'Client added successfully',
  client_added_content: `A new client has been added successfully, you must add at
                  least one merchandiser and supervisor in addition to adding
                  your brands to be used in adding products successfully, you
                  can add brands by clicking on the Add Brand button at the
                  bottom or clicking on Add User or cancel it to add them later`,
  listed_stores: 'Listed chains',
  not_listed_stores: 'Unlisted chains',
  listed_branches: 'Listed stores',
  not_listed_branches: 'Unlisted stores',
  not_list_all: 'unlisted all',
  move_visit: 'Move visit',
  temporary: 'Temporary',
  permanent: 'Permanent',
  move_type: 'Move type',
  not_member: "You're not a member,",
  member: 'Already a member?',
  join_request: 'Join request',
  join_content:
    'Welcome, we are pleased to have you join us with one of our best clients, you can fill the data in the following form to be sent the request successfully and you will be notified via e-mail of acceptance by one of our technical support, thank you',
  join_subtitle: 'START FOR FREE',
  fill_form: 'FILL THE FORM',
  company_name: 'Company name',
  contact_name: 'Contact name',
  contact_mobile: 'Contact mobile',
  contact_email: 'Contact email',
  merchandisers_count: 'Merchandisers count',
  join_now: 'Join now',
  join_success: 'Join request sent successfully',
  join_success_content: `The request to join has been sent successfully. You can follow
              your inbox to receive an e-mail from one of our technical support
              with your login data as soon as possible. Thank you for that trust
              and joining us.`,
  go_inbox: 'Go to inbox',
  columns_no_data:
    'There are no columns to display on that page or the appropriate items to be placed here have not been selected',
  actions: 'Actions',
  shifts: 'Shifts',
  osa_reports: 'OSA Reports',
  urgent_visit: 'Urgent visit',
  journey_plan: 'Journey plan',
  reload: 'Reload the current page if customizing the table is not working',
  multi_stores: 'Multi chains',
  multi_branches: 'Multi stores',
  show: 'Show',
  hide: 'Hide',
  notify: {
    title: 'Notify me a few days before the expiration date',
    notify_2: 'Notify me before 2 days',
    notify_1: 'Notify me before 1 day',
    notify_0: 'Notify me before 0 day',
  },
  back: 'Back',
  zones: 'Zones',
  zone: 'Zone',
  duration: 'Shelf life',
  has_exp_date: 'Has expiry date?',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  domain: 'Domain',
  import: 'Import',
  activation_date: 'Activation date',
  _listed: 'Listed',
  _not_listed: 'Not listed',
  shared: 'Shared',
  finish: 'Finish',
  selected: 'Selected',
  my_promotions: 'My promotions',
  competitors: 'Competitors',
  go_website: 'Go to website',
  performance: 'Performance',
  add_urgent_visit: 'Add urgent visit',
  visit_from_diff_location: 'Allow visit from different location',
  email_configurations: 'E-mail configurations',
  logs: 'Logs',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  email_setup: 'Email setup',
  client_user: 'Client user',
  super_user: 'Super user',
  c_reasons: 'Reasons',
  export_pdf: 'Export PDF',
  done_count: 'Visits done',
  end_time: 'End time',
  extra_mission_count: 'Extra mission',
  extra_mission_done_count: 'Extra mission done',
  extra_visits_percentage: 'Extra visits percentage',
  ideal_time: 'Ideal time',
  in_stores_time: 'Time in chains',
  missed_count: 'Missed',
  not_visits_count: 'Not visited',
  scheduled_count: 'Scheduled',
  skipped_count: 'Skipped',
  start_time: 'Start time',
  total_time: 'Total time',
  travel_time: 'Travel time',
  visits_percentage: 'Visits percentage',
  return: 'Return',
  statuses: 'Statuses',
  select: '-- Select --',
  alert_1: '1st alert',
  alert_2: '2th alert',
  alert_3: '3th alert',
  today_visits: 'Today visits',
  deletion_modal: {
    title: 'Are you sure?',
    content:
      'If you still want to delete that chain, this means that both the stores associated with that chain, visits and SKUs will be deleted, if you are already sure, write the name of the chain in the throat below to confirm the successful deletion',
  },
  deletion_branch: {
    day: 'Delete the store from that day only.',
    week: 'Delete the store from the weeks of the same day.',
    month: 'Delete the store from the whole month.',
  },
  deletion_day: {
    day: 'Delete that day only.',
    week: 'Delete the day from the weeks of the same day.',
  },
  assign_to_skus: 'Assign stores to SKUs',
  selected_branches: 'There are _COUNT_ store(s) in selected item',
  run_tomorrow: 'Run tomorrow',
  run: 'Run',
  are_you_sure: 'Are you sure?',
  last_run: 'Last run',
  request_branch: 'Request your own store',
  country: 'Country',
  door_type: 'Door type',
  main: 'Main',
  stock: 'Stock',
  monthly_visits: 'Monthly visits',
  latest_visits: 'Latest visits',
  visits_count: 'Total visits',
  dates: 'Dates',
  main_categories: 'Main categories',
  main_category: 'Main category',
  clear: 'Clear selected',
  logs: 'Logs',
  new_smart_journey_plan: 'New smart journey plan',
  desc: 'Description',
  locations: 'Locations',
  region_groups: 'Region groups',
  district_groups: 'District groups',
  districts: 'Districts',
  clear_all: 'Clear all',
  'export-image': 'Export image',
  'export-images': 'Export images',
  'export-selected-images': 'Export selected images',
  'view-images': 'View images',
  'are-you-sure': 'Are you sure?',
  deletion: {
    title: 'Are you sure you want to delete it?',
    content:
      'If you still want to delete it, you no longer have the authority to reaccess it, and everything related to it will be deleted',
    code: 'Please enter the code sent via e-mail, and the deletion process will be confirmed',
  },
  competitors: 'Competitors',
  shelf: 'Shelf',
  faces: 'Faces',
  meter: 'Meter',
  cm: 'CM',
  sync_skus: 'Sync SKUs',
  delete_replace: 'Delete and replace',
  add_all_skus: 'Add all SKUs',
  delete_current_journey_plan: 'Delete current journey plan',
}
