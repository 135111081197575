<template>
  <div>
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
    <section class="w-full space-y-6" :class="{ 'px-6': !isVisit }">
      <reports-filter v-show="!isVisit"> </reports-filter>
      <cards-container :cards="filterCards" v-if="!isVisit" />
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('planogram')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          :fields="fields"
          v-model="pagination.page"
        >
          <template #head>
            <th v-if="!isVisit && columns.includes('user')">
              {{ $t('user') }}
            </th>
            <th v-if="!isVisit && columns.includes('branch')">
              {{ $t('branch') }}
            </th>
            <th v-if="!isVisit && columns.includes('store')">
              {{ $t('store') }}
            </th>
            <th v-if="columns.includes('store_placement')">
              <div class="sort">
                <div>
                  <p>{{ $t('store_placement') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>

            <th v-if="columns.includes('notes')">{{ $t('notes') }}</th>
            <th v-if="columns.includes('visit_date')">
              <div class="sort">
                <div>
                  <p>{{ $t('visit_date') }}</p>
                </div>
                <div>
                  <button class="sort-btn"></button>
                </div>
              </div>
            </th>
            <th v-if="columns.includes('images_before')">
              {{ $t('images_before') }}
            </th>
            <th v-if="columns.includes('images_after')">
              {{ $t('images_after') }}
            </th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="columns.length" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && result.length !== 0">
            <tr v-for="(item, index) in result" :key="index">
              <td v-if="!isVisit && columns.includes('user')">
                <p>{{ (item.user || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.user || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.user || {}).mobile || '...' }}</a
                >
              </td>
              <td v-if="!isVisit && columns.includes('branch')">
                <p>{{ (item.visit.company_branch || {}).name || '...' }}</p>
                <a
                  :href="`tel:${(item.visit.company_branch || {}).mobile}`"
                  class="text-primary font-medium table"
                  >{{ (item.visit.company_branch || {}).mobile || '...' }}</a
                >
              </td>

              <td v-if="!isVisit && columns.includes('store')">
                {{ (item.visit.company_store || {}).name || '...' }}
              </td>

              <td v-if="columns.includes('store_placement')">
                {{ (item.store_placement || {}).name || '...' }}
              </td>
              <td v-if="columns.includes('notes')">
                <div class="max-w-xs min-w-[10rem]">
                  {{ item.notes || '...' }}
                </div>
              </td>
              <td
                v-if="columns.includes('visit_date')"
                v-html="
                  createTime(
                    !isVisit ? (item.visit || {}).visit_date : item.visit_date
                  )
                "
              ></td>
              <td v-if="columns.includes('images_before')">
                <button
                  class="
                    w-8
                    h-8
                    bg-sky-500
                    text-white
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                  content="View images"
                  v-tippy
                  :disabled="!(item.images_before || []).length"
                  @click="openLightBox(item.images_before)"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </td>
              <td v-if="columns.includes('images_after')">
                <button
                  class="
                    w-8
                    h-8
                    bg-sky-500
                    text-white
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                  content="View images"
                  v-tippy
                  :disabled="!(item.images_after || []).length"
                  @click="openLightBox(item.images_after)"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && result.length === 0">
            <tr>
              <td :colspan="columns.length">
                <p
                  class="
                    text-sm text-slate-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
  </div>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import ReportsFilter from './components/ReportsFilter.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from './components/CardsContainer.vue'

export default {
  name: 'SkuAudits',
  props: {
    value: {
      type: Object,
      default: Object,
    },
    result: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: Object,
    },
    fields: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      pagination: this.value,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      items: [],
      index: null,

      cards: [
        {
          title: 'Total visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'total_visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'company_stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'company_branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Store placements',
          value: 0,
          key: 'store_placements_count',
          translate_key: 'store_placements',
          icon: 'fa-solid fa-cubes-stacked',
          style: {
            icon: 'text-blue-500',
            parent: 'bg-blue-500/20',
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('customize', ['getColumns', 'getSelectedColumns']),
    endpointName() {
      return (
        this.$route.meta?.endpoint_name ||
        this.$route.query?.tab?.replace(/(-)/g, '_')
      )
    },
    columns() {
      if (!this.endpointName) return []
      const data = !this.isVisit
        ? this.getSelectedColumns(this.endpointName)
        : this.getSelectedColumns(this.endpointName).filter(
            (e) => !['branch', 'store', 'user'].includes(e)
          ) || []
      return data
    },
    isVisit() {
      return this.$route.name === 'SingleVisit'
    },
    filterCards() {
      return this.cards.map((e) => ({
        ...e,
        value: this.summary[e.key] || 0,
      }))
    },
  },

  methods: {
    async openLightBox(images) {
      try {
        this.items = await this.loadImages(images)

        this.index = 0
      } catch (error) {
        console.log(error)
      }
    },
    async loadImages(images) {
      let result = []

      for (let i = 0; i < images.length; i++) {
        const item = images[i]

        const error = await this.handleImgLoad(item)
        if (error) {
          result.push(require('@/assets/images/placeholder.png'))
        } else {
          result.push(item)
        }
      }
      return result
    },
    handleImgLoad(item) {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = item
        img.addEventListener('error', () => resolve(true))
        resolve(false)
      })
    },
  },
  components: { Table, TableLoad, ReportsFilter, CardsContainer },
}
</script>
