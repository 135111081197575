var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.dateActive)?_c('span',{staticClass:"date-line my-3"},[_c('span',{staticClass:"\n        bg-white\n        relative\n        table\n        mx-auto\n        px-2\n        z-[1]\n        font-medium\n        rlt:font-semibold\n      "},[_vm._v(_vm._s(_vm._f("moment")(_vm.message.created_at,'MMMM Do YYYY')))])]):_vm._e(),_c('div',{staticClass:"table max-w-[80%] message-item",class:{
      'mr-auto rtl:mr-0 rtl:ml-auto': _vm.message.sender_type === 'user',
      'ml-auto rtl:ml-0 rtl:mr-auto': _vm.message.sender_type === 'admin',
    },attrs:{"data-message":_vm.message.id}},[(!_vm.isSame && _vm.message.sender_type === 'admin')?_c('div',{staticClass:"flex items-center gap-2 mb-2",class:{
        'flex-row': _vm.message.sender_type === 'user',
        'flex-row-reverse': _vm.message.sender_type === 'admin',
      }},[_c('div',[_c('div',{staticClass:"w-8 h-8"},[(_vm.message.sender_type === 'admin')?_c('img',{staticClass:"\n              w-full\n              h-full\n              rounded-full\n              object-cover\n              border border-slate-100\n            ",attrs:{"src":_vm.message.sender_type === 'user' ? _vm.avatar : _vm.admin_avatar}}):_vm._e()])]),(_vm.message.sender_type === 'admin')?_c('div',[_c('h1',{staticClass:"font-bold text-sm"},[_vm._v(" "+_vm._s(_vm.message.sender_type === 'user' ? _vm.message.user.name : _vm.message.admin.name)+" ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"table",class:{
        'mr-auto rtl:mr-0 rtl:ml-auto': _vm.message.sender_type === 'user',
        'ml-auto rtl:ml-0 rtl:mr-auto pr-8 rtl:pr-0 rtl:pl-8':
          _vm.message.sender_type === 'admin',
      }},[_c('div',{staticClass:"py-2 px-4 text-sm font-medium rounded-md max-w-max",class:{
          '!bg-primary !text-white mr-auto rtl:mr-0 rtl:ml-auto':
            _vm.message.sender_type === 'user',
          'bg-slate-200 text-slate-600 ml-auto rtl:ml-0 rtl:mr-auto ':
            _vm.message.sender_type === 'admin',
        },domProps:{"innerHTML":_vm._s(_vm.GenerateContent(_vm.message.body, _vm.message.sender_type === 'user'))},on:{"dblclick":function($event){$event.preventDefault();return _vm.openDetails(_vm.message.id)}}}),_c('p',{staticClass:"text-xs text-slate-500 font-medium mt-2 hidden",class:{
          'text-left rtl:text-right': _vm.message.sender_type === 'user',
          'text-right rtl:text-left': _vm.message.sender_type === 'admin',
        }},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.message.created_at,'hh:mma'))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }